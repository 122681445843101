import React from "react";

const EditInvestorProfileSubHeader = ({ activeButton, setActiveButton }) => {
  return (
    <>
      <div className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2">
        <div className="flex flex-wrap gap-10 justify-between items-end w-full max-md:max-w-full">
          <div className="flex flex-col min-w-[240px] max-md:max-w-full">
            <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
              <div
                className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap"
                onClick={() => {
                  setActiveButton(1);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeButton === 1 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Account
                </div>
              </div>

              <div
                className="flex gap-1 items-center self-stretch my-auto"
                onClick={() => {
                  setActiveButton(2);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeButton === 2 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Profile
                </div>
              </div>

              <div
                className="flex gap-1 items-center self-stretch my-auto font-semibold"
                onClick={() => {
                  setActiveButton(3);
                }}
              >
                <div
                  className={`self-stretch my-auto font-bold cursor-pointer ${
                    activeButton === 3 ? "text-blue-700" : "text-gray-500"
                  }`}
                >
                  Members
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInvestorProfileSubHeader;
