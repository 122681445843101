import React, { useState, useEffect } from "react";
import { Snackbar, Alert, CircularProgress, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomSnackBar = ({ isUploading }) => {
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (isUploading) {
      setOpen(true);
      setCompleted(false);
    } else if (isUploading === false) {
      // Simulate upload completion delay
      setTimeout(() => {
        setCompleted(true);
        setTimeout(() => setOpen(false), 2000); // Close snackbar after 2 seconds
      }, 1000);
    }
  }, [isUploading]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={null}
    >
      <Alert
        severity={completed ? "success" : "info"}
        icon={
          completed ? (
            <CheckCircleIcon
              style={{
                color: "green",
                paddingBottom: `2px`,
              }}
            />
          ) : (
            <CircularProgress
              size={20}
              sx={{
                color: "#00A6FB",
              }}
            />
          )
        }
        sx={{
          fontFamily: "Karla, sans-serif",
          fontSize: "14px",
        }}
      >
        {completed ? `Successfully uploaded!` : `Uploading...`}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;
