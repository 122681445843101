import React from "react";

const TermsOfUse = ({ termsRefs }) => {
  const scrollToSection = (ref, agreementRef) => {
    if (ref && ref.current) {
      agreementRef.current.scrollTo({
        top: ref.current.offsetTop - agreementRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      className="mx-auto p-5 border h-[100%] border-gray-300 rounded-[24px] overflow-scroll"
      ref={termsRefs.agreementRef}
    >
      <h2 className="text-[18px] font-bold mb-4">TABLE OF CONTENTS</h2>
      <ol className="list-decimal pl-6 text-[14px] text-[#696f79]">
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.definitionsRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Definitions and Interpretations
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.scopeRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Scope of Services
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.performanceRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Performance Standards
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.liabilityRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Liability and Indemnification
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.confidentialityRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Confidentiality and Non-Disclosure
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.nonSolicitationRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Non-Solicitation
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.referralRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Investor Referral Obligation
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(termsRefs.enforcementRef, termsRefs.agreementRef)
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Enforcement and Transparency
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.dataProtectionRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Data Protection and Privacy
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.intellectualPropertyRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Intellectual Property Rights
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.termTerminationRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Term and Termination
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              scrollToSection(
                termsRefs.miscellaneousRef,
                termsRefs.agreementRef
              )
            }
            className="text-blue-500 hover:underline cursor-pointer"
          >
            Miscellaneous Provisions
          </button>
        </li>
      </ol>

      <h2
        ref={termsRefs.definitionsRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        1. Definitions and Interpretations
      </h2>
      <h3 className="text-[18px] font-semibold mt-2 mb-2">1.1 Definitions</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Affiliate:</strong> Any entity that directly or indirectly
          controls, is controlled by, or is under common control of a Party.
        </li>
        <li>
          <strong>Agreement:</strong> This Master Service Agreement, including
          all exhibits, schedules, and amendments hereto.
        </li>
        <li>
          <strong>Confidential Information:</strong> Confidential Information
          shall include any and all technical, commercial, financial,
          operational, marketing, legal, regulatory, strategic, intellectual
          property-related, or other information, including but not limited to:
          <ul className="list-disc pl-6">
            <li>
              Business plans, proposals, financial data, projections, and
              forecasts;
            </li>
            <li>
              Trade secrets, intellectual property, inventions, patents,
              designs, processes, methodologies, technologies, know-how, and
              proprietary algorithms;
            </li>
            <li>
              Corporate strategies, product or service roadmaps, research and
              development activities, and intellectual property filings;
            </li>
            <li>
              Marketing strategies, client lists, pricing policies, and market
              analyses;
            </li>
            <li>
              Contracts, agreements, legal opinions, regulatory submissions, and
              filings;
            </li>
            <li>
              Employee details, organizational structures, internal
              communications, and operational data;
            </li>
            <li>
              Any information related to existing or potential business
              relationships with third parties, including but not limited to
              suppliers, vendors, customers, investors, or partners;
            </li>
            <li>
              Any analyses, compilations, studies, summaries, or other documents
              or records prepared by the Receiving Party, its representatives,
              or its affiliates that contain or reflect the Confidential
              Information;
            </li>
            <li>
              Any information disclosed in any form, whether tangible or
              intangible, including oral, written, electronic, visual, or other
              forms, and any information learned through observation,
              discussion, or otherwise.
            </li>
          </ul>
        </li>
        <li>
          <strong>Data Breach:</strong> Unauthorized access, acquisition, use,
          or disclosure of data that compromises the security, confidentiality,
          or integrity of such data.
        </li>
        <li>
          <strong>Disclosing Party:</strong> The party and its affiliates
          providing Confidential Information.
        </li>
        <li>
          <strong>Effective Date:</strong> The date on which this Agreement is
          executed and becomes binding upon the Parties.
        </li>
        <li>
          <strong>Force Majeure:</strong> Any event beyond the reasonable
          control of a Party, including acts of God, natural disasters, war,
          terrorism, governmental actions, and any other unforeseen
          circumstances that prevent a Party, for no fault of its own, from
          performing its obligations under this Agreement.
        </li>
        <li>
          <strong>Indemnified Parties:</strong> Includes a Party and its
          officers, directors, employees, agents, and Affiliates who are
          entitled to indemnification under this Agreement.
        </li>
        <li>
          <strong>Intellectual Property Rights:</strong> All current and future
          worldwide rights under patent law, copyright law, trade secret law,
          trademark law, moral rights law, and other similar rights, whether
          registered or unregistered, and all applications, registrations,
          renewals, and extensions of such rights.
        </li>
        <li>
          <strong>Investor Data:</strong> Any data provided by or collected from
          the Investor, including personal data, investment data, transaction
          details, and any other information relating to the Investor's
          activities on the Fundrev platform.
        </li>
        <li>
          <strong>Platform:</strong> The online interface, software, and mobile
          applications provided by Fundrev that enable Investors to access
          investment opportunities, manage portfolios, and utilize Fundrev's
          services.
        </li>
        <li>
          <strong>Receiving Party:</strong> The party and its affiliates
          receiving Confidential Information.
        </li>
        <li>
          <strong>Services:</strong> The full range of services provided by
          Fundrev to the Investor, including but not limited to access to the
          Platform, data analytics, investment management tools, due diligence
          services, and any additional services as agreed upon by the Parties.
        </li>
        <li>
          <strong>Scheduled Maintenance:</strong> Maintenance work conducted on
          the Platform by Fundrev, which is informed to the Investor along with
          the timeline for its resolution.
        </li>
        <li>
          <strong>Term:</strong> The duration of this Agreement, including the
          initial term and any subsequent renewal terms, during which the
          Parties are bound by the provisions of this Agreement.
        </li>
        <li>
          <strong>Third-Party Service Providers:</strong> Any independent
          contractor, consultant, or vendor engaged by Fundrev to perform any
          portion of the Services under this Agreement.
        </li>
      </ul>

      <h2
        ref={termsRefs.scopeRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        2. Scope of Services
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        2.1 Description of Services
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Provision of Services:</strong> Fundrev agrees to provide the
          Investor with access to its Platform and related Services as described
          in this Agreement and the attached exhibits. The Services include, but
          are not limited to, providing access to investment opportunities, due
          diligence reports, market analytics, and investment management tools.
        </li>
        <li>
          <strong>Customization and Support:</strong> Fundrev shall provide
          reasonable support services to the Investor, including assistance with
          the setup, customization, and maintenance of the Platform to meet the
          Investor's specific requirements.
        </li>
        <li>
          <strong>Updates and Enhancements:</strong> Fundrev may, from time to
          time, introduce updates, enhancements, or new features to the Platform
          and Services. Fundrev reserves the right to modify the scope of the
          Services provided that such modifications do not materially alter the
          overall functionality of the Platform.
        </li>
        <li>
          <strong>Service Limitations:</strong> The Investor acknowledges that
          the Services are provided on an "as-is" basis, and Fundrev makes no
          warranties regarding the completeness, accuracy, or reliability of any
          information provided through the Platform. The Investor is solely
          responsible for evaluating the suitability of any investment
          opportunities presented through the Platform.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        2.2 Compliance with Laws
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Regulatory and Legal Compliances:</strong> The Investor agrees
          to comply with all applicable laws, regulations, and industry
          standards in connection with their use of the Platform and Services,
          including but not limited to securities laws, anti-money laundering
          regulations, data protection laws, and any other applicable regulatory
          requirements.
        </li>
        <li>
          <strong>Legal Review:</strong> The Investor acknowledges that they are
          responsible for obtaining independent legal, taxation, and financial
          advice concerning their use of the Platform and the investments made
          through it. Fundrev does not provide legal, taxation, or financial
          advice and shall not be responsible for the Investor's compliance with
          or breach of any legal or regulatory requirements.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        2.3 Modifications to Services
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Changes to Services:</strong> Fundrev reserves the right to
          modify, discontinue, or suspend any part of the Services with
          reasonable notice to the Investor. Such modifications may include
          changes to the functionality of the Platform, the introduction of new
          features, or the discontinuation of certain services.
        </li>
        <li>
          <strong>Notification of Changes:</strong> Fundrev shall provide the
          Investor with at least 30 (Thirty) days' notice of any material
          changes to the Services.
        </li>
        <li>
          <strong>Effect of Modifications:</strong> Any modifications to the
          Services shall not materially alter the Investor's rights under this
          Agreement. If any modification adversely affects the Investor's
          ability to use the Platform, Fundrev shall work with the Investor to
          mitigate any such impact.
        </li>
      </ul>
      <h2
        ref={termsRefs.performanceRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        3. Performance Standards
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        3.1 Industry Standards
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Service Quality:</strong> Fundrev agrees to provide the
          Services in a professional and workmanlike manner, consistent with
          industry standards. Fundrev shall use reasonable efforts to ensure the
          accuracy, reliability, and timeliness of the Services provided.
        </li>
        <li>
          <strong>Third-Party Audits:</strong> Fundrev may engage third-party
          auditors to conduct regular reviews of its Platform and Services to
          ensure compliance with industry standards and best practices.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        3.2 Performance Metrics
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Uptime Guarantee:</strong> Fundrev shall use commercially
          reasonable efforts to ensure that the Platform is available during
          each calendar month, excluding scheduled maintenance or Force Majeure
          events.
        </li>
        <li>
          <strong>Response Times:</strong> Fundrev shall provide customer
          support to the Investor during normal business hours, i.e., 09:00 AM
          to 17:00 PM. Fundrev commits to responding to support requests within
          24 hours and resolving critical issues within 48 hours.
        </li>
      </ul>
      <h2
        ref={termsRefs.liabilityRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        4. Liability and Indemnification
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        4.1 Limitation of Liability
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Exclusion of Certain Damages:</strong> In no event shall
          Fundrev be liable for any indirect, incidental, special,
          consequential, or punitive damages, including but not limited to lost
          profits, loss of data, or business interruption, even if Fundrev has
          been advised of the possibility of such damages.
        </li>
        <li>
          <strong>No Liability for Third-Party Services:</strong> Fundrev shall
          not be liable for any failures or issues arising from services
          provided by third-party service providers or any third-party content
          or data used in connection with the Services.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        4.2 Indemnification by the Investor
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Indemnity Obligations:</strong> The Investor agrees to
          indemnify, defend, and hold harmless Fundrev and its Indemnified
          Parties from and against any and all claims, damages, losses,
          liabilities, costs, and expenses (including reasonable attorneys'
          fees) arising out of or related to:
          <ul className="list-disc pl-6">
            <li>
              The Investor's use of the Platform and Services in violation of
              this Agreement or any applicable laws or regulations.
            </li>
            <li>
              Any investment decisions made by the Investor based on information
              or services provided by Fundrev.
            </li>
            <li>
              The Investor's breach of any representations, warranties, or
              obligations under this Agreement.
            </li>
            <li>
              Any third-party claims alleging that the Investor's use of the
              Platform infringes or misappropriates any Intellectual Property
              Rights.
            </li>
          </ul>
        </li>
        <li>
          <strong>Notice of Claim:</strong> Fundrev shall promptly notify the
          Investor of any claim for which it seeks indemnification under this
          section, and the Investor shall assume control of the defense and
          settlement of the claim. Fundrev shall cooperate fully in the defense,
          but Fundrev may retain its own counsel at its own expense.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        4.3 Indemnification by Fundrev
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Indemnity Obligations:</strong> Fundrev agrees to indemnify,
          defend, and hold harmless the Investor and its Indemnified Parties
          from and against any claims, damages, losses, liabilities, costs, and
          expenses (including reasonable attorneys' fees) arising out of or
          related to:
          <ul className="list-disc pl-6">
            <li>
              Any breach by Fundrev of its representations, warranties, or
              obligations under this Agreement.
            </li>
            <li>
              Any third-party claims alleging that the Platform or Services
              infringe upon or misappropriate any Intellectual Property Rights
              of a third party, provided that such infringement is not caused by
              the Investor's use of the Platform in violation of this Agreement.
            </li>
          </ul>
        </li>
        <li>
          <strong>Notice of Claim:</strong> The Investor shall promptly notify
          Fundrev of any claim for which it seeks indemnification under this
          section, and Fundrev shall assume control of the defense and
          settlement of the claim. The Investor shall cooperate fully in the
          defense, but the Investor may retain its own counsel at its own
          expense.
        </li>
      </ul>
      <h2
        ref={termsRefs.confidentialityRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        5. Confidentiality and Non-Disclosure
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.1 Obligation to Maintain Confidentiality
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        The Investor agrees to:
        <ul className="list-disc pl-6">
          <li>
            Maintain the confidentiality of all Confidential Information and not
            disclose such information to any third party without the prior
            written consent of Fundrev.
          </li>
          <li>
            Use the Confidential Information solely for the purpose of
            evaluating and/or engaging in investment opportunities with Fundrev.
          </li>
          <li>
            The Receiving Party shall treat all Confidential Information with
            the utmost care and shall implement and maintain appropriate
            security measures to safeguard the Confidential Information,
            including administrative, technical, and physical safeguards that
            are no less rigorous than those it uses to protect its own
            confidential information of a similar nature.
          </li>
          <li>
            The Receiving Party shall not, without the prior written consent of
            the Disclosing Party:
            <ul className="list-disc pl-6">
              <li>
                Disclose, disseminate, or otherwise make available the
                Confidential Information to any third party, except as expressly
                permitted herein;
              </li>
              <li>
                Use the Confidential Information for any purpose other than the
                evaluation or negotiation of the Proposed Transaction; or
              </li>
              <li>
                Reverse engineer, decompile, disassemble, or otherwise attempt
                to derive the source code, algorithms, or proprietary
                methodologies embedded within the Confidential Information.
              </li>
            </ul>
          </li>
          <li>
            Limit access to the Confidential Information to only those
            employees, agents, or representatives who have a need to know such
            information for the purpose of the investment relationship and who
            are bound by confidentiality obligations at least as protective as
            those set forth in this Agreement.
          </li>
          <li>
            The Receiving Party shall ensure that its Representatives are
            informed of the confidential nature of the Confidential Information
            and shall be liable for any breach of this Agreement by its
            Representatives.
          </li>
        </ul>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.2 Permitted Disclosures
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Exceptions to Confidentiality:</strong> The obligations of
          confidentiality shall not apply to information that:
          <ul className="list-disc pl-6">
            <li>
              Was in the public domain at the time of disclosure or subsequently
              entered the public domain through no fault of the Disclosing
              Party.
            </li>
            <li>
              Was already in the possession of the Receiving Party at the time
              of disclosure and was not subject to any obligation of
              confidentiality.
            </li>
            <li>
              Was independently developed by the Receiving Party without
              reference to the Disclosing Party's Confidential Information.
            </li>
            <li>
              Is required to be disclosed by law, regulation, or court order,
              provided that the Receiving Party gives the Disclosing Party
              prompt notice of the required disclosure and cooperates in any
              efforts to prevent or limit such disclosure to the extent of such
              request by law, regulation or court order.
            </li>
          </ul>
        </li>
        <li>
          <strong>Disclosure to Affiliates and Advisors:</strong> Each Party may
          disclose Confidential Information to its Affiliates, legal counsel,
          accountants, and other professional advisors for the limited purpose
          of affecting the terms of this Agreement and for no other use,
          provided that such recipients are bound by confidentiality obligations
          at least as restrictive as those set forth in this Agreement.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.3 Breach of Confidentiality
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Notification of Breach:</strong> In the event that the
          Investor becomes aware of any breach of its confidentiality
          obligations, including any unauthorized access, disclosure, or use of
          Confidential Information, the Investor shall immediately notify
          Fundrev in writing, providing all relevant details of the breach.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.4 Remedies for Breach
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Injunctive Relief:</strong> The Investor acknowledges that any
          breach of confidentiality obligations may cause irreparable harm to
          Fundrev, for which monetary damages may be inadequate. Therefore, in
          the event of a breach, Fundrev shall be entitled to seek injunctive
          relief, specific performance, and/or other equitable remedies without
          the necessity of proving actual damages.
        </li>
        <li>
          <strong>Indemnification:</strong> The Investor agrees to indemnify,
          defend, and hold harmless Fundrev and its affiliates from and against
          any and all losses, liabilities, damages, costs, and expenses
          (including reasonable attorneys' fees) arising out of or in connection
          with any breach of this confidentiality provision by the Investor or
          its representatives.
        </li>
        <li>
          <strong>Monetary Damages:</strong> In addition to equitable remedies,
          Fundrev reserves the right to seek monetary damages from the Investor
          for any actual losses suffered as a result of the breach of
          confidentiality.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.5 Return or Destruction of Confidential Information
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        Upon the termination of the investment relationship or upon written
        request by Fundrev, the Investor shall:
        <ul className="list-disc pl-6">
          <li>
            Promptly return all Confidential Information in its possession,
            custody, or control to Fundrev, or destroy such information if
            requested by Fundrev, and certify in writing to Fundrev that all
            such Confidential Information has been returned or destroyed.
          </li>
          <li>
            Ensure that any Confidential Information that cannot be returned or
            destroyed (e.g., because it is stored on automatic backup systems)
            is subject to continued confidentiality obligations.
          </li>
        </ul>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.6 Survival of Obligations
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Survival of Confidentiality Obligations:</strong> The
          confidentiality obligations and all related provisions under this
          clause shall survive the termination or expiration of the investment
          relationship and any other agreements between the Investor and
          Fundrev.
        </li>
      </ul>
      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.7 Confidentiality Obligations
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Confidentiality Obligations:</strong> Each Party agrees to
          maintain the confidentiality of the other Party's Confidential
          Information and to use such information solely for the purposes of
          fulfilling its obligations under this Agreement. Neither Party shall
          disclose Confidential Information to any third party without the prior
          written consent of the other Party, except as required by law or court
          order.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.8 Security Measures
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Security Measures:</strong> Each Party agrees to implement and
          maintain reasonable security measures to protect the confidentiality
          of the other Party's Confidential Information. Such measures shall
          include, but are not limited to, encryption, access controls, regular
          security audits, etc.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        5.9 Duration of Confidentiality
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Survival of Confidentiality Obligations:</strong> The
          confidentiality obligations set forth in this Agreement shall remain
          in effect during the Term of this Agreement and for a period of (3-5
          years) following the termination or expiration of this Agreement,
          regardless of the reason for termination.
        </li>
      </ul>
      <h2
        ref={termsRefs.nonSolicitationRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        6. Non-Solicitation
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.1 Non-Solicitation of Employees
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Obligations of the Investor:</strong> During the term of this
          Agreement and for a period of two (2) years thereafter, the Investor
          agrees that it shall not, directly or indirectly, solicit, recruit,
          induce, or encourage any employee, consultant, or independent
          contractor of Fundrev, or any of its affiliates, to:
          <ul className="list-disc pl-6">
            <li>Leave their employment or engagement with Fundrev;</li>
            <li>
              Accept employment or engagement with the Investor or any other
              person or entity, whether as an employee, consultant, independent
              contractor, or in any other capacity;
            </li>
            <li>
              Assist in identifying or recruiting any employee, consultant, or
              independent contractor of Fundrev for the purposes described
              above.
            </li>
          </ul>
        </li>
        <li>
          <strong>Employee Contact:</strong> The Investor acknowledges and
          agrees that any contact with Fundrev's employees, consultants, or
          independent contractors that is not authorized in writing by Fundrev,
          and that could reasonably lead to the solicitation or recruitment of
          such persons, will be considered a violation of this Non-Solicitation
          Clause.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.2 Non-Solicitation of Clients and Business Partners
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Non-Solicitation of Clients:</strong> During the term of this
          Agreement and for a period of two (2) years thereafter, the Investor
          agrees that it shall not, directly or indirectly:
          <ul className="list-disc pl-6">
            <li>
              Solicit or attempt to solicit any client, customer, or business
              partner of Fundrev, or any of its affiliates, for the purpose of
              providing products or services that compete with those offered by
              Fundrev;
            </li>
            <li>
              Induce or attempt to induce any client, customer, or business
              partner of Fundrev to terminate or reduce their business
              relationship with Fundrev.
            </li>
          </ul>
        </li>
        <li>
          <strong>Protection of Business Relationships:</strong> The Investor
          agrees that it will not take any action that could interfere with the
          business relationships between Fundrev and its clients, customers,
          business partners, or affiliates.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.3 Permitted Activities
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>General Advertisements:</strong> Notwithstanding the
          foregoing, the Investor may place general advertisements for
          employment that are not specifically targeted at Fundrev's employees,
          consultants, or independent contractors. Such general advertising
          shall not be considered a breach of this Non-Solicitation Clause.
        </li>
        <li>
          <strong>Prior Relationships:</strong> The Investor may engage in
          business with any client, customer, or business partner of Fundrev if
          the Investor had a bona fide business relationship with such party
          prior to the effective date of this Agreement, provided that such
          relationship is disclosed to Fundrev in writing and does not involve
          the disclosure or use of Confidential Information belonging to
          Fundrev.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.4 Remedies for Breach of Non-Solicitation
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Injunctive Relief:</strong> The Investor acknowledges that a
          breach of this Non-Solicitation Clause may cause irreparable harm to
          Fundrev, for which monetary damages may be inadequate. Therefore, in
          the event of a breach or threatened breach, Fundrev shall be entitled
          to seek injunctive relief, specific performance, or other equitable
          remedies without the necessity of proving actual damages.
        </li>
        <li>
          <strong>Monetary Damages:</strong> In addition to equitable remedies,
          Fundrev may seek monetary damages from the Investor for any actual
          losses or damages resulting from a breach of this Non-Solicitation
          Clause, including but not limited to the costs associated with hiring
          and training replacement personnel, and any lost business
          opportunities.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.5 Indemnification
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          The Investor agrees to indemnify and hold harmless Fundrev, its
          affiliates, and their respective directors, officers, employees, and
          agents from and against any and all losses, liabilities, damages,
          costs, and expenses (including reasonable attorneys' fees) arising out
          of or in connection with any breach of this Non-Solicitation Clause by
          the Investor or its representatives.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.6 Breach of Non-Solicitation
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Notification of Breach:</strong> In the event that the
          Investor breaches any provision of this Non-Solicitation Clause, or
          becomes aware of any such breach by its employees, agents, or
          representatives, the Investor shall immediately notify Fundrev in
          writing, providing full details of the breach.
        </li>
        <li>
          <strong>Consequences of Breach:</strong>
          <ul className="list-disc pl-6">
            <li>
              <strong>Termination of Agreement:</strong> Fundrev may, at its
              sole discretion, terminate this Agreement or any other related
              agreements with the Investor upon any breach of this
              Non-Solicitation Clause by the Investor.
            </li>
            <li>
              <strong>Liquidated Damages:</strong> In addition to other remedies
              provided herein, Fundrev may seek liquidated damages as stipulated
              in the Agreement. The parties acknowledge and agree that the
              actual damages caused by a breach of this clause would be
              difficult to ascertain, and the liquidated damages amount
              represents a reasonable estimate of such damages.
            </li>
            <li>
              <strong>Loss of Privileges:</strong> The Investor may lose certain
              privileges or access to Fundrev's services or information, at
              Fundrev's sole discretion, as a result of the breach.
            </li>
            <li>
              <strong>Cumulative Remedies:</strong> The rights and remedies
              provided in this clause are cumulative and in addition to any
              other rights and remedies available at law, in equity, or under
              this Agreement.
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.7 Survival of Obligations
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          The non-solicitation obligations and all related provisions under this
          clause shall survive the termination or expiration of the investment
          relationship and any other agreements between the Investor and Fundrev
          for the duration specified in this Non-Solicitation Clause.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        6.8 No Limitation on Fundrev's Rights
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          Nothing in this Non-Solicitation Clause shall be construed as limiting
          or negating any rights or remedies that Fundrev may have under
          applicable law or in equity with respect to the protection of its
          employees, clients, customers, business partners, or Confidential
          Information.
        </li>
      </ul>
      <h2
        ref={termsRefs.referralRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        7. Investor Referral Obligation
      </h2>

      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Referral of Opportunities:</strong> The Investor agrees that
          any deal, opportunity, or investment presented or viewed on Fundrev's
          platform is subject to the terms and conditions of this Agreement,
          including Fundrev's right to receive transaction fees upon the
          conclusion of a successful investment or financial transaction.
        </li>

        <li>
          <strong>Referrals to Other Investors:</strong> In the event that the
          Investor refers or introduces the Fundraiser's deal, opportunity, or
          business to another investor, entity, or person (hereinafter,
          “Referred Investor”), the Investor shall make best efforts to ensure
          that the Referred Investor engages with the Fundraiser exclusively via
          Fundrev's platform or by inviting the Referred Investor to join
          Fundrev's platform to ensure that Fundrev is adequately compensated
          for facilitating the initial introduction or exposure.
        </li>

        <li>
          <strong>Non-Circumvention:</strong> The Investor acknowledges that any
          Referred Investor who transacts directly with the Fundraiser or
          facilitates a financial transaction shall not circumvent Fundrev's
          rights to receive the transaction fee as outlined in this Agreement.
        </li>

        <li>
          <strong>Notification of Referrals:</strong> The Investor shall notify
          Fundrev of any such referrals, introductions, or communications with a
          Referred Investor that could lead to a financial transaction between
          the Fundraiser and the Referred Investor.
        </li>

        <li>
          <strong>Fundrev's Rights in Case of Non-Compliance:</strong> Fundrev
          reserves the right to impose restrictions or withhold benefits,
          services, or future access to deals on the platform for Investors who
          fail to comply with this provision. Fundrev may take appropriate
          action to recover lost fees due to non-compliance.
        </li>
      </ul>
      <h2
        ref={termsRefs.enforcementRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        8. Enforcement and Transparency
      </h2>

      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Platform's Role:</strong> Both the Fundraiser and Investor
          agree that Fundrev's platform plays an integral role in facilitating
          introductions, interactions, and funding opportunities. Therefore,
          each party agrees to maintain full transparency with Fundrev regarding
          any subsequent discussions, referrals, or introductions to third
          parties that may lead to a financial transaction.
        </li>

        <li>
          <strong>Audit Rights:</strong> Fundrev reserves the right to audit or
          request information from either the Fundraiser or the Investor
          regarding any communication with a Referred Investor to ensure
          compliance with the provisions of this Agreement.
        </li>

        <li>
          <strong>Penalties for Circumvention:</strong> Fundrev may impose
          penalties or additional fees in the event that the Fundraiser or
          Investor knowingly attempts to circumvent the platform or this
          Agreement to avoid paying the applicable transaction fee.
        </li>
      </ul>
      <h2
        ref={termsRefs.dataProtectionRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        9. Data Protection and Privacy
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        9.1 Compliance with Data Protection Laws
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Data Protection Obligations:</strong> Fundrev agrees to comply
          with all applicable data protection and privacy laws, including the
          General Data Protection Regulation (GDPR), the Digital Personal Data
          Protection Act, 2023, and any other relevant legislation, in its
          collection, use, and processing of Investor Data.
        </li>
        <li>
          <strong>Investor Data Rights:</strong> The Investor shall have the
          right to access, correct, delete, or restrict the processing of their
          personal data in accordance with applicable data protection laws.
          Fundrev shall provide the Investor with the necessary tools and
          procedures to exercise these rights.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        9.2 Data Processing Agreement
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Data Processing Agreement:</strong> If required by applicable
          law, the Parties shall enter into a separate Data Processing Agreement
          (DPA) that governs the processing of personal data in connection with
          the Services. The DPA shall outline the roles and responsibilities of
          each Party with respect to data protection.
        </li>
        <li>
          <strong>Data Controller and Data Processor Roles:</strong> The Parties
          acknowledge that the Investor may be the data controller and Fundrev
          the data processor with respect to certain personal data processed in
          connection with the Services. Fundrev shall process such data only in
          accordance with the Investor's instructions and the terms of the DPA.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        9.3 Security Measures
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Data Security:</strong> Fundrev shall implement and maintain
          appropriate technical and organizational measures to protect Investor
          Data against unauthorized access, loss, alteration, or destruction.
          Such measures shall include, but are not limited to, encryption,
          access controls, regular security testing, and incident response
          protocols.
        </li>
        <li>
          <strong>Data Breach Notification:</strong> In the event of a Data
          Breach, Fundrev shall promptly notify the Investor and take all
          necessary steps to mitigate the impact of the breach. Fundrev shall
          cooperate with the Investor in any investigations or reporting
          obligations related to the breach.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        9.4 Data Retention and Deletion
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Data Retention:</strong> Fundrev shall retain Investor Data
          only for as long as necessary to fulfill the purposes for which it was
          collected or as required by applicable law. Upon termination of this
          Agreement, Fundrev shall delete or anonymize Investor Data in
          accordance with its data retention policies, unless otherwise required
          by law.
        </li>
        <li>
          <strong>Data Deletion Requests:</strong> The Investor may request the
          deletion of their personal data at any time, and Fundrev shall comply
          with such requests in accordance with applicable data protection laws.
        </li>
      </ul>
      <h2
        ref={termsRefs.intellectualPropertyRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        10. Intellectual Property Rights
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        10.1 Ownership of Intellectual Property
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Fundrev's Intellectual Property:</strong> Fundrev retains all
          rights, title, and interest in and to the Intellectual Property Rights
          associated with the Platform, including all software, algorithms,
          databases, user interfaces, and proprietary methods used in providing
          the Services. Nothing in this Agreement shall be construed as
          transferring any ownership rights in any of the intellectual
          properties to the Investor.
        </li>
        <li>
          <strong>Investor's Intellectual Property:</strong> The Investor
          retains all rights, title, and interest in and to any data, content,
          or materials provided to Fundrev by the Investor for use in connection
          with the Services. Fundrev shall not acquire any rights in the
          Investor's Intellectual Property except as expressly set forth in this
          Agreement.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        10.2 License Grant
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>License to Use Platform:</strong> Subject to the terms of this
          Agreement, Fundrev grants the Investor a non-exclusive,
          non-transferable, revocable license to access and use the Platform and
          Services solely for the purpose of managing investments and conducting
          other related activities as permitted under this Agreement.
        </li>
        <li>
          <strong>Restrictions on Use:</strong> The Investor shall not, and
          shall not permit any third party to:
          <ul className="list-disc pl-6">
            <li>
              Copy, modify, or create derivative works of the Platform or any
              part thereof.
            </li>
            <li>
              Reverse engineer, decompile, or disassemble the Platform, except
              to the extent expressly permitted by law.
            </li>
            <li>
              Use the Platform for any purpose other than as expressly permitted
              under this Agreement.
            </li>
            <li>
              Transfer, sub-license, or assign the license to use the Platform
              to any third party without Fundrev's prior written consent.
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        10.3 Third-Party Intellectual Property
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Use of Third-Party Content:</strong> The Platform may include
          content or software provided by third parties, including but not
          limited to open-source software, third-party APIs, and data feeds
          ("Third-Party Content"). Fundrev shall use commercially reasonable
          efforts to ensure that its use of Third-Party Content complies with
          applicable licenses and does not infringe upon any third-party
          Intellectual Property Rights.
        </li>
        <li>
          <strong>License to Third-Party Content:</strong> The Investor
          acknowledges that their use of certain features or functionalities of
          the Platform may be subject to separate terms and conditions or
          licenses governing the use of Third-Party Content. The Investor agrees
          to comply with such terms and conditions and acknowledges that Fundrev
          shall have no liability for any Third-Party Content.
        </li>
      </ul>
      <h2
        ref={termsRefs.termTerminationRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        11. Term and Termination
      </h2>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">11.1 Term</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Initial Term:</strong> This Agreement shall commence on the
          Effective Date and continue for an initial period of 12 months
          ("Initial Term"), unless terminated earlier in accordance with the
          terms of this Agreement.
        </li>
        <li>
          <strong>Renewal:</strong> Upon the expiration of the Initial Term,
          this Agreement shall automatically renew for successive periods of 12
          months ("Renewal Term"), unless either Party provides written notice
          of non-renewal at least 30 days prior to the end of the then-current
          term or initial term, as the case may be.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        11.2 Termination for Convenience
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Termination by Investor:</strong> The Investor may terminate
          this Agreement for convenience at any time upon 30 days' written
          notice to Fundrev.
        </li>
        <li>
          <strong>Termination by Fundrev:</strong> Fundrev may terminate this
          Agreement for convenience at any time upon 30 days' written notice to
          the Investor.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        11.3 Termination for Cause
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Termination by Either Party:</strong> Either Party may
          terminate this Agreement for cause if the other Party materially
          breaches any provision of this Agreement and fails to cure the breach
          within 30 days after receiving written notice of the breach.
        </li>
        <li>
          <strong>Termination Due to Insolvency:</strong> Either Party may
          terminate this Agreement immediately upon written notice if the other
          Party becomes insolvent, makes an assignment for the benefit of
          creditors, or is subject to any bankruptcy or similar proceeding.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        11.4 Effect of Termination
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Cessation of Services:</strong> Upon termination or expiration
          of this Agreement, Fundrev shall immediately cease providing the
          Platform and Services to the Investor, and the Investor shall cease
          all use of the Platform and Services.
        </li>
        <li>
          <strong>Return of Investor Data:</strong> Both parties shall return or
          destroy all data in their possession in accordance with the data
          retention and deletion provisions of this Agreement, unless otherwise
          required by law.
        </li>
        <li>
          <strong>Survival:</strong> The provisions of this Agreement that by
          their nature should survive termination or expiration, including but
          not limited to confidentiality, data protection, intellectual property
          rights, liability and indemnification, and payment obligations, shall
          survive any termination or expiration of this Agreement.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        11.5 Transition Assistance
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Post-Termination Support:</strong> At the Investor's request
          and expense, Fundrev shall provide reasonable transition assistance
          for a period of up to 30 days following termination or expiration of
          this Agreement to facilitate the transfer of the Investor's data and
          the transition to a new service provider.
        </li>
      </ul>
      <h2
        ref={termsRefs.miscellaneousRef}
        className="text-[18px] mt-4 font-semibold mb-4"
      >
        12. Miscellaneous Provisions
      </h2>

      <h3
        className="text-[18px] font-semibold mt-2 mb-2"
        ref={termsRefs.jurisdictionRef}
      >
        12.1 Governing Law and Jurisdiction
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Governing Law:</strong> This Agreement shall be governed by
          and construed in accordance with the laws of the jurisdiction of
          Bangalore, India.
        </li>
        <li>
          <strong>Jurisdiction and Venue:</strong> Any legal action or
          proceeding arising out of or relating to this Agreement shall be
          brought exclusively in the courts of Bangalore, and each Party hereby
          consents to the jurisdiction of such courts.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        12.2 Dispute Resolution
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Mediation:</strong> In the event that a dispute arises between
          the Parties, they shall first attempt to resolve it amicably through
          mediation. The Parties agree to participate in good faith in the
          mediation process.
        </li>
        <li>
          <strong>Arbitration:</strong> If the dispute is not resolved within 30
          days after the commencement of mediation, the dispute shall be
          referred to a sole Arbitrator, appointed mutually by both Parties. The
          arbitration shall be conducted in accordance with the provisions of
          the Arbitration and Conciliation Act, 1996. The decision of the Sole
          Arbitrator shall be final and binding on the Parties. The arbitration
          proceedings shall be conducted in English, and the venue shall be
          Bangalore, India.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        12.3 Force Majeure
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Force Majeure Events:</strong> Neither Party shall be liable
          for any failure or delay in performance due to causes beyond its
          reasonable control, including but not limited to acts of God, war,
          terrorism, fire, flood, earthquakes, and governmental actions. The
          affected Party shall promptly notify the other Party of the occurrence
          of a force majeure event and shall use reasonable efforts to mitigate
          its effects.
        </li>
        <li>
          <strong>Suspension of Obligations:</strong> During the continuation of
          a Force Majeure event, the obligations of the affected Party shall be
          suspended to the extent necessary to avoid liability, but the affected
          Party shall continue to perform its other obligations under this
          Agreement to the extent reasonably possible.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">12.4 Assignment</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>No Assignment Without Consent:</strong> Neither Party may
          assign or transfer any of its rights or obligations under this
          Agreement without the prior written consent of the other Party, except
          that either Party may assign this Agreement to an Affiliate or in
          connection with a merger, acquisition, or sale of all or a substantial
          portion of its assets.
        </li>
        <li>
          <strong>Binding Effect:</strong> This Agreement shall be binding upon
          and inure to the benefit of the Parties and their respective
          successors and permitted assigns.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        12.5 Entire Agreement
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Entire Agreement:</strong> This Agreement, together with any
          exhibits, schedules, or other documents incorporated by reference,
          constitutes the entire agreement between the Parties with respect to
          the subject matter hereof and supersedes all prior or contemporaneous
          agreements, understandings, and communications, whether written or
          oral, relating to such subject matter.
        </li>
        <li>
          <strong>Amendments:</strong> No amendment or modification of this
          Agreement shall be valid or binding unless it is in writing and signed
          by authorized representatives of both Parties.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">12.6 Severability</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Severability of Provisions:</strong> If any provision of this
          Agreement is found to be invalid, illegal, or unenforceable by a court
          of competent jurisdiction, such provision shall be severed from the
          Agreement, and the remaining provisions shall remain in full force and
          effect.
        </li>
        <li>
          <strong>Reformation:</strong> If any provision is found to be invalid
          or unenforceable, the Parties agree to negotiate in good faith to
          replace such provision with a valid and enforceable provision that
          achieves, to the greatest extent possible, the original intent and
          economic effect of the invalid provision.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">12.7 Waiver</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>No Waiver:</strong> The failure of either Party to enforce any
          right or provision of this Agreement shall not constitute a waiver of
          such right or provision unless acknowledged and agreed to by the Party
          in writing.
        </li>
        <li>
          <strong>Cumulative Remedies:</strong> The rights and remedies provided
          in this Agreement are cumulative and not exclusive of any other rights
          or remedies provided by law or in equity.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">
        12.8 Independent Contractors
      </h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Relationship of the Parties:</strong> The Parties are
          independent contractors, and nothing in this Agreement shall be
          construed to create a partnership, joint venture, agency, or
          employer-employee relationship between the Parties.
        </li>
        <li>
          <strong>No Authority to Bind:</strong> Neither Party shall have any
          authority to bind the other Party in any manner or make any
          representations or warranties on behalf of the other Party without the
          other Party's express written consent.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mt-2 mb-2">12.9 Counterparts</h3>
      <ul className="list-disc pl-6 text-[14px] text-[#696f79]">
        <li>
          <strong>Execution in Counterparts:</strong> This Agreement may be
          executed in counterparts, each of which shall be deemed an original,
          and all of which together shall constitute one and the same
          instrument.
        </li>
        <li>
          <strong>Electronic Signatures:</strong> The Parties agree that
          electronic signatures shall have the same legal effect as original
          signatures and may be used to execute this Agreement.
        </li>
      </ul>
    </div>
  );
};

export default TermsOfUse;
