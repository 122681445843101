import React from "react";

const MSAAgreement = ({ setCardType, termsRefs }) => {
  const navigateToTerms = (ref, agreementRef) => {
    setCardType("TnC");
    setTimeout(() => {
      agreementRef.current.scrollTo({
        top: ref.current.offsetTop - agreementRef.current.offsetTop,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <div className="mx-auto p-5 h-[100%] overflow-scroll border border-gray-300 rounded-[24px]">
      <p className="mb-6 text-[14px] text-[#696f79]">
        This Master Service Agreement (“Agreement”) is entered into by and
        between Fundrev (“Platform”) and the Investor, collectively referred to
        as “Parties”. The Fundraiser agrees that this MSA, together with the
        Terms of Use for Investors (“ToU”), collectively constitute the entire
        agreement and are binding upon the signatories. By continuing to use the
        Platform, the Fundraiser confirms acceptance of the following terms.
      </p>

      <h2 className="text-[18px] font-bold mb-4">1. Acceptance of Terms</h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I confirm that I have read and understood the terms of this Agreement,
          the Master Service Agreement (MSA), and the Terms of Use. I understand
          that these Terms of Use are an integral part of the Master Service
          Agreement and apply to my rights, responsibilities, and obligations
          while using Fundrev's platform. I agree that by clicking 'I Agree,' I
          am entering into a binding legal contract with Fundrev, voluntarily
          and with full understanding of its terms.
        </li>
      </ul>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.definitionsRef, termsRefs.agreementRef)
        }
      >
        2. Definitions
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I acknowledge that 'Investor' refers to any person or entity using the
          Platform to invest or commit to investing in a Fundraiser or a company
          listed on the Platform.
        </li>
        <li>
          I understand that a 'Fundraiser' refers to any individual, company, or
          entity using the Platform to raise funds or seek capital for the sale
          of a company (M&A) or other financial transactions.
        </li>
      </ul>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.nonSolicitationRef, termsRefs.agreementRef)
        }
      >
        3. Non-Solicitation
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree not to solicit any Fundraiser, employee, or advisor associated
          with Fundrev or introduced through the Platform for a period of 24
          months following the termination of this Agreement.
        </li>
        <li>
          I agree to refer any deals or fundraising opportunities to other
          investors by inviting them to the Platform, rather than directly
          introducing them to the Fundraiser.
        </li>
        <li>
          I agree that I will not attempt to solicit any fundraisers, companies,
          or employees of Fundrev for purposes of establishing competing
          relationships or engaging in investment activities without Fundrev's
          involvement. I understand that any breach of this clause will result
          in Fundrev enforcing legal action, including recovery of lost fees.
        </li>
      </ul>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.confidentialityRef, termsRefs.agreementRef)
        }
      >
        4. Confidentiality
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree to maintain the confidentiality of any non-public information
          obtained from the Platform, Fundrev, or the Fundraiser during the
          course of using the Platform. I understand that unauthorized
          disclosure may result in termination and legal action, including
          claims for damages.
        </li>
      </ul>

      <h2 className="text-[18px] font-bold mb-4">5. Right of Refusal</h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I acknowledge that I retain the right to refuse any investment
          opportunity introduced to me through Fundrev without legal liability,
          subject to notifying Fundrev of my decision. Similarly, Fundrev
          reserves the right to refuse to facilitate certain investment or sale
          transactions for any reason.
        </li>
      </ul>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.jurisdictionRef, termsRefs.agreementRef)
        }
      >
        6. Governing Law and Jurisdiction
      </h2>
      <ul className="list-disc pl-6 mb-6 text-[14px] text-[#696f79]">
        <li>
          I agree that this Agreement shall be governed by and construed in
          accordance with the laws of India.
        </li>
        <li>
          I agree that any disputes arising out of or in connection with this
          Agreement, including any question regarding its existence, validity,
          or termination, shall be referred to and finally resolved by
          arbitration in accordance with the Arbitration and Conciliation Act,
          1996, as amended from time to time.
        </li>
        <li>
          The seat and venue of arbitration shall be Bangalore, India, and the
          proceedings shall be conducted in English.
        </li>
        <li>
          I understand that the courts located in Bangalore, India, shall have
          exclusive jurisdiction over any matters that cannot be resolved
          through arbitration.
        </li>
      </ul>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.agreementRef, termsRefs.agreementRef)
        }
      >
        7. Entire Agreement
      </h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I confirm that this Agreement, along with the MSA and Terms of Use,
        collectively form the entire Agreement between Fundrev and me.
      </p>

      <h2 className="text-[18px] font-bold mb-4">8. Binding Nature of Terms</h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I acknowledge that the terms of this agreement extend to any authorized
        non-signatories, agents, or representatives acting on my behalf or
        Fundrev's behalf. Both parties agree that agents are bound by the
        agreement's terms, and Fundrev retains the right to enforce these terms
        against non-signatories acting on behalf of either party.
      </p>

      <h2
        className="text-[18px] font-bold mb-4 text-blue-500 hover:underline cursor-pointer"
        onClick={() =>
          navigateToTerms(termsRefs.dataProtectionRef, termsRefs.agreementRef)
        }
      >
        9. Collective Binding Nature of the MSA and Terms of Use
      </h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I understand and agree that both the Master Service Agreement (MSA) and
        the Terms of Use for Investors collectively constitute a binding legal
        agreement between myself and Fundrev. I further acknowledge that this
        agreement applies to all fundraising opportunities, company sales, and
        investment transactions facilitated by Fundrev.
      </p>

      <h2 className="text-[18px] font-bold mb-4">
        10. Consent to Data Processing and Privacy Policy Acknowledgment
      </h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I confirm that I have read and agree to Fundrev's Privacy Policy and
        consent to the processing of my personal and business data as outlined.
        I understand that Fundrev will handle this data in compliance with
        applicable data privacy laws, including but not limited to the GDPR and
        the IT Act.
      </p>

      <h2 className="text-[18px] font-bold mb-4">
        11. Non-Circumvention Clause
      </h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I agree not to circumvent Fundrev by attempting to establish direct
        contact with fundraisers or sellers outside the platform if Fundrev
        introduced them to me. I understand that Fundrev is entitled to its fee
        for facilitating such introductions, and any violation of this clause
        will result in legal liability.
      </p>

      <h2 className="text-[18px] font-bold mb-4">
        12. MSA-TOU Integration Clause
      </h2>
      <p className="mb-6 text-[14px] text-[#696f79]">
        I agree that the Master Service Agreement (MSA) and Terms of Use
        collectively form a legally binding agreement. These documents govern
        the entirety of my relationship with Fundrev as an Investor. The MSA
        outlines core obligations, rights, and responsibilities, while the Terms
        of Use, which are uploaded on Fundrev's website, detail the operational
        guidelines and legal standards for platform participation. Together,
        these documents create a complete and binding contract, enforceable
        under the law.
      </p>
    </div>
  );
};

export default MSAAgreement;
