import React, { useState, useRef } from "react";
// import { calculateInvestments } from "../../Common/functions";

import { Box, Typography } from "@mui/material";
// import ProfileCard from "./profileCard";

const InvestorOverview = ({ investor, mode }) => {
  const ref = useRef();
  const elementRef = useRef(null);
  const companyName = investor ? investor.CompanyName : "";
  const [showAll, setShowAll] = useState({
    keyInvestments: false,
    industryVerticals: false,
  });
  const ticketSize =
    investor && investor.ticketSizevalue[0]
      ? JSON.parse(investor.ticketSizevalue[0] || "[]")
      : [];
  const industryVerticalBadges =
    investor && investor.industryVerticalOptions[0]
      ? JSON.parse(investor.industryVerticalOptions[0] || "[]")
      : [];
  const investmentStageBadges =
    investor && investor.investmentStage[0]
      ? JSON.parse(investor.investmentStage[0] || "[]")
      : [];
  const assetClassBadges =
    investor && investor.assetClass[0]
      ? JSON.parse(investor.assetClass[0] || "[]")
      : [];
  const initialBadgeCount = 10;

  const toggleShowAll = (section) => {
    setShowAll((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const handleIndustryVerticalsShowAll = async () => {
    await toggleShowAll("industryVerticals");
    await elementRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className="companyReview"
      style={{
        border: "none",
        marginBottom: mode !== "admin" && "100px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      <Box
        className="companyReview-box"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "8px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="cdetails-first-div"
          style={{ marginRight: "30px", alignItems: "baseline" }}
        >
          <p
            style={{
              color: "#696F79",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: "300",
            }}
          >
            Investor Overview
          </p>
          <Box
            className="investor-id"
            sx={{ padding: "8px", fontSize: "16px" }}
          >
            Investor ID: {investor ? investor.investorID : ""}
          </Box>
        </div>
        <Box
          className="companyReview-allDetails"
          sx={{ display: "flex", gap: "16px", flexDirection: "column" }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <div className="companyReview-img ">
              <img
                src={investor ? investor.investorLogo : ""}
                className="InvestorLogo"
                alt="Upload Image"
                style={{
                  width: "80px",
                  height: "80px",
                  maxWidth: "none",
                  objectFit: "fill",
                  borderRadius: "12px",
                }}
              />
            </div>
            <div className="companyReview-info">
              <div>
                <Typography
                  className="companyReview-companyName"
                  sx={{ fontSize: "20px !important" }}
                >
                  {investor ? investor.companyName : ""}
                </Typography>
                <Typography className="companyReview-comapnyDesc">
                  {investor ? investor.investmentThesis : ""} {/* //doubt */}
                </Typography>
              </div>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "80px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1.2fr",
                backgroundColor: "#FAFAFA",
                width: "100%",
                padding: "16px",
                borderRadius: "16px",
              }}
            >
              <Box>
                <p className="userDetails-heading">Investor Type</p>
                <p className="userDetails-text">{investor?.investorType}</p>
              </Box>
              <Box>
                <p className="userDetails-heading">Founder Year</p>
                <p className="userDetails-text">{investor?.foundedYear}</p>
              </Box>
              <Box>
                <p className="userDetails-heading">Headquarters</p>
                <p className="userDetails-text">{investor?.headquarters}</p>
              </Box>

              <Box>
                <p className="userDetails-heading">Website</p>
                <p className="userDetails-text">{investor?.investorWebsite}</p>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <p
              style={{
                color: "var(--Secondary-Text-Color)",
                fontSize: "14px",
                fontWeight: 300,
              }}
            >
              Key Investments
            </p>
            <div className="badges" style={{ width: "100%" }}>
              <div
                className="badge-soft-secondary"
                style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
              >
                {investor &&
                  (() => {
                    try {
                      return (
                        <>
                          {investor.keyInvestments &&
                            investor.keyInvestments.length > 0 &&
                            investor.keyInvestments
                              .split(",")
                              .map((item) => item.trim())
                              .slice(
                                0,
                                showAll.keyInvestments
                                  ? investor.keyInvestments.split(",").length // Use split(",") before accessing length
                                  : initialBadgeCount
                              )
                              .map((item, index) => (
                                <div className="badge-base-wrapper" key={index}>
                                  <div className="elements">
                                    <div className="small-tag">{item}</div>
                                  </div>
                                </div>
                              ))}

                          {investor.keyInvestments.length >
                            initialBadgeCount && (
                            <p
                              onClick={() => toggleShowAll("keyInvestments")}
                              style={{
                                margin: "5px 5px",
                                color: "#00A6FB",
                                fontWeight: 600,
                                cursor: "pointer",
                              }}
                            >
                              {showAll.keyInvestments
                                ? "Show Less"
                                : "Show More"}
                            </p>
                          )}
                        </>
                      );
                    } catch (error) {
                      console.error("Error processing keyInvestments:", error);
                      return null;
                    }
                  })()}
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <Box
        className="companyReview-investment-focus-box"
        sx={{
          backgroundColor: "#fff",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "8px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <p
            style={{
              color: "#696F79",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "21px",
            }}
          >
            Investment Focus
          </p>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    color: "var(--Secondary-Text-Color)",
                    fontSize: "14px",
                    fontWeight: 300,
                  }}
                >
                  Ticket Size
                </p>
                <Box>
                  <p>
                    Min :&nbsp;{" "}
                    <span className="small-tag">{ticketSize[0]}</span>
                    &nbsp;&nbsp; Max :&nbsp;
                    <span className="small-tag">{ticketSize[1]}</span>
                  </p>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    color: "var(--Secondary-Text-Color)",
                    fontSize: "14px",
                    fontWeight: 300,
                  }}
                >
                  Investment Stage
                </p>
                <Box>
                  <div
                    className="badge-soft-secondary"
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {investor &&
                      investor.investmentStage[0] &&
                      JSON.parse(investor.investmentStage || "[]")
                        .slice(0, investmentStageBadges.length)
                        .map((item, index) => (
                          <div className="badge-base-wrapper" key={index}>
                            <div className="elements">
                              <div className="small-tag">{item}</div>
                            </div>
                          </div>
                        ))}
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    color: "var(--Secondary-Text-Color)",
                    fontSize: "14px",
                    fontWeight: 300,
                  }}
                >
                  Asset Class
                </p>
                <Box>
                  <div
                    className="badge-soft-secondary"
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {investor &&
                      investor.assetClass[0] &&
                      JSON.parse(investor?.assetClass || "[]")
                        .slice(0, assetClassBadges.length)
                        .map((item, index) => (
                          <div className="badge-base-wrapper" key={index}>
                            <div className="elements">
                              <div className="small-tag">{item}</div>
                            </div>
                          </div>
                        ))}
                  </div>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <p
                style={{
                  color: "var(--Secondary-Text-Color)",
                  fontSize: "14px",
                  fontWeight: 300,
                }}
              >
                Preferred Sectors
              </p>
              <Box>
                <div
                  className="badge-soft-secondary"
                  style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                >
                  {investor &&
                    investor.industryVerticalOptions[0] &&
                    JSON.parse(investor?.industryVerticalOptions || "[]")
                      .map((item) => item.trim())
                      .slice(
                        0,
                        showAll.industryVerticals
                          ? industryVerticalBadges.length
                          : initialBadgeCount
                      )
                      .map((item, index) => (
                        <div className="badge-base-wrapper" key={index}>
                          <div className="elements">
                            <div className="small-tag">{item}</div>
                          </div>
                        </div>
                      ))}
                  {industryVerticalBadges.length > initialBadgeCount && (
                    <p
                      onClick={handleIndustryVerticalsShowAll}
                      style={{
                        margin: "5px 5px",
                        color: "#00A6FB",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      {showAll.industryVerticals ? "Show Less" : "Show More"}
                    </p>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div className="keep-me-at-last" ref={elementRef}></div>
    </div>
  );
};

export default InvestorOverview;
