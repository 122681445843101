import React from "react";
import emptyCard from "../../../../Assets/Images/InvestorPage/Dashboard/emptyCard.svg";
const NoDeal = () => {
  return (
    <>
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
      <img className="emptyCard" src={emptyCard} />
    </>
  );
};

export default NoDeal;
