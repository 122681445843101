import React, { useEffect, useState, useMemo, useRef } from "react";
import Editable from "./editable";
import { cols, handleVideoStream } from "../functions";
import CardTags from "./molecules/cardTags";
import ProfileCard from "./profileCard";
import DetailedCardFinancials from "../../Investor/Dashboard/Components/detailedCardFinanicals";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import CompanyOverviewLeftCard from "../../Investor/Dashboard/Components/CompanyOverviewLeftCard";

import { companyState, saveState } from "../../Founders/Dashboard/companyState";
import { useRecoilState, useRecoilValue } from "recoil";
import CommentBox from "../../Founders/Dashboard/MyProfile/commentBox";
import { getDealTeamDetails } from "../../../endpoints/startup";
import { getFundrevAnalystDetailsById } from "../../../endpoints/fundrevAnalyst";
import { fetchMembers } from "../../../endpoints/startup";
import FundrevAnalystDetails from "../../Common/components/fundrevAnalystDetails";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";

import { useLoader } from "../LoaderProvider";
import { Box, styled, Typography } from "@mui/material";
import { dumpInvestorInteraction } from "../../../endpoints/common";

const CompanyOverview = ({ startup, deal, reqToEdit, stage }) => {
  const [activeSection, setActiveSection] = useState(1);
  const [tableData, setTableData] = useState([]);
  const columns = useMemo(() => cols, []);
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [analyst, setAnalyst] = useState({});
  const { numMessages, startupMemberId } = useRecoilValue(companyState);
  const [dealId, setDealId] = useState(null);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const overviewRef = useRef(null);
  const keyhighlightsRef = useRef(null);
  const purposeRef = useRef(null);
  const financialsRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);
  const roundsRef = useRef(null);
  const elevatorPitchRef = useRef(null);
  const analystID = "FN123456";
  const loader = useLoader();
  const [videoURL, setVideoURL] = useState(null);
  const maxLength = 135;

  const [hasPlayed, setHasPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoPlay = () => {
    if (!hasPlayed) {
      dumpInteraction("click", "video");
      setHasPlayed(true);
    }
  };

  const handleOpen = () => {
    const newState = { ...state, notificationBarOpen: true };
    setState(newState);
    saveState(newState);
  };
  const dumpInteraction = async (type, element) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.DealID,
          type: type || "view",
          page: "companyOverview",
          stageOfDeal: stage,
          element: element || "page",
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const handleActiveSection = (num) => {
    setActiveSection(num);

    const sectionRefs = {
      1: overviewRef,
      2: keyhighlightsRef,
      3: purposeRef,
      4: teamRef,
      5: financialsRef,
      6: roundsRef,
      7: contactRef,
      8: elevatorPitchRef,
    };

    const container = document.getElementById("scrollContainer");

    if (sectionRefs[num]?.current) {
      const sectionTop =
        sectionRefs[num].current.offsetTop - container.offsetTop;
      container.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  const StyledHeading = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "21px",
    textAlign: "justified",
  }));
  const StyledContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Karla, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "justified",
    maxWidth: "259px",
  }));
  const StyledTableHeading = styled(Typography)(({ theme }) => ({
    color: "#696F79",
    fontSize: "14px",
  }));
  const tableCellStyle = {
    padding: "0px 15px",
    lineHeight: "48px",
    fontWeight: 500,
    color: "#1A1A1A",
  };
  const parseArray = (arr) => {
    try {
      return JSON.parse(arr);
    } catch (error) {
      return [];
    }
  };
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    return () => {
      if (video) {
        // Pause the video
        video.pause();

        // Reset playback to the beginning
        video.currentTime = 0;

        // Exit Picture-in-Picture if the video is in PiP mode
        if (document.pictureInPictureElement === video) {
          document.exitPictureInPicture().catch((error) => {
            console.error("Error exiting Picture-in-Picture:", error);
          });
        }
      }
    };
  }, []);
  useEffect(() => {
    setTableData(parseArray(deal?.fundingRounds));
  }, [deal]);
  const fetchAnalystDetails = async () => {
    try {
      const response = await getFundrevAnalystDetailsById({
        fundrevAnalystID: analystID,
      });
      setAnalyst(response.data.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAnalystDetails();
  }, []);

  useEffect(() => {
    const getVideoURL = async () => {
      if (deal?.ElevatorPitch && !videoURL) {
        const response = await handleVideoStream(deal.ElevatorPitch);
        setVideoURL(response);
      }
    };
    if (deal) getVideoURL();
  }, [deal]);
  const getTeamDetails = async () => {
    try {
      if (!startup?.startupID) return;
      const response = await getDealTeamDetails(startup.startupID, token);
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(deal.OrganizationID, token);
      loader.stop();
      if (response && response.data) {
        const membersData = response.data.data;
        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to fetch members:", error);
    }
  };
  useEffect(() => {
    fetchMembersData();
  }, [deal]);
  useEffect(() => {
    getTeamDetails();
  }, [startup]);
  return (
    <>
      <CommentBox
        dealId={deal?.DealID}
        token={token}
        memberId={startupMemberId}
      />
      {/* <Box
        className="cdetails-div"
        sx={{
          margin: stage === "companyReview" && "0px",
          paddingX: "10px",
          gap: "16px",
        }}
      >
        <Box
          className="companyReview"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Company Overview
            </p>
            <div
              className="cdetails-first-div"
              style={{
                marginRight: "20px",
                display: reqToEdit ? "flex" : "none",
              }}
            >
              {numMessages > 0 ? (
                <Box onClick={handleOpen} className="comments-div">
                  <p>
                    <img src={commentIcon} />
                    &nbsp; {numMessages} comments
                  </p>
                </Box>
              ) : (
                <div onClick={handleOpen} className="request-to-edit-btn">
                  <img
                    src={reqEdit}
                    className="reqEdit"
                    alt="request to edit"
                  />
                  &nbsp;&nbsp; request to edit
                </div>
              )}
            </div>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <Box className="companyReview-img">
                <img
                  src={deal ? deal.CompanyLogo : ""}
                  className="InvestorLogo"
                  alt="Upload Image"
                  style={{
                    cursor: "pointer",
                    objectFit: "fill",
                    maxWidth: "none",
                    width: "80px",
                    height: "80px",
                    borderRadius: "16px",
                  }}
                />
              </Box>
              <div>
                <p
                  className="companyReview-companyName"
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  {deal ? deal.CompanyName : ""}
                </p>
                <p
                  className="companyReview-comapnyDesc"
                  style={{
                    color: "#696F79",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                >
                  <div
                    className="setInnerHTML"
                    dangerouslySetInnerHTML={{
                      __html: deal ? deal.CompanyDescription : "",
                    }}
                  />
                </p>
              </div>
            </Box>
            <Box
              sx={{
                marginY: "20px",
              }}
            >
              <CardTags deal={deal} page={"detailsPage"} />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                padding: "12px 24px",
                backgroundColor: "#FAFAFA",
                borderRadius: "16px",
                minHeight: "93px",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <StyledHeading>Legal Entity Name</StyledHeading>
                  <StyledContent>
                    {deal ? deal.LegalEntityName : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Headquarters</StyledHeading>
                  <StyledContent>
                    {deal ? deal.Headquarters : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Founded Year</StyledHeading>
                  <StyledContent>{deal ? deal.FoundedYear : "-"}</StyledContent>
                </Box>
                <Box>
                  <StyledHeading>No. of Employees</StyledHeading>
                  <StyledContent>
                    {deal ? deal.NumberOfEmployees : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Website</StyledHeading>
                  <StyledContent>
                    {deal ? deal.CompanyWebsite : "-"}
                  </StyledContent>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="details-key-highlights"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            style={{
              color: "#1A1A1A",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Key Highlights
          </p>
          <div
            className="setInnerHTML details-li-tag"
            dangerouslySetInnerHTML={{
              __html: deal ? deal.KeyHighlights : "",
            }}
          />
        </Box>
        <Box
          className="details-key-highlights"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            style={{
              color: "#1A1A1A",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Purpose of Fundraising
          </p>
          <div
            className="setInnerHTML details-li-tag"
            dangerouslySetInnerHTML={{
              __html: deal ? deal.PurposeOfFundraising : "",
            }}
          />
        </Box>
        <Box
          className="FoundingTeam-box"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="FoundingTeam"
            style={{
              border: "none",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Founding Team
            </p>
            <div className="profile-boxes">
              {deal && deal.FoundingTeamDetails
                ? (() => {
                    try {
                      const teamDetails = JSON.parse(deal.FoundingTeamDetails);
                      return Array.isArray(teamDetails)
                        ? teamDetails.map((member, index) => (
                            <ProfileCard
                              member={member}
                              type="company"
                              key={member?.name || index}
                            />
                          ))
                        : "";
                    } catch (error) {
                      console.error(
                        "Error parsing FoundingTeamDetails:",
                        error
                      );
                      return "";
                    }
                  })()
                : ""}
            </div>
          </div>
        </Box>
        <Box
          className="Financials-box"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <DetailedCardFinancials deal={deal} />
        </Box>
        <Box
          className="CompanyReview-FundingDetails"
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <p
            className="investorDetails-subheading"
            style={{
              color: "#696F79",
              fontSize: "20px",
              fontWeight: "300",
              marginTop: "0px",
            }}
          >
            Previous Rounds
          </p>
          <Box>
            <p
              style={{
                color: "#1A1A1A",
                fontWeight: "16px",
                fontWeight: "600",
              }}
            >
              Key Investors
            </p>
            <p
              style={{
                color: "#696F79",
                fontWeight: "16px",
                fontWeight: "400",
              }}
            >
              {deal ? deal?.ExistingKeyInvestors : ""}
            </p>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "0px", boxShadow: "none" }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ height: "48px" }}>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Date</StyledTableHeading>
                  </TableCell>{" "}
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Round</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Raised</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>% Stake</StyledTableHeading>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 15px", lineHeight: "48px" }}
                  >
                    <StyledTableHeading>Valuation</StyledTableHeading>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index} border>
                    <TableCell sx={tableCellStyle}>
                      {row.date}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {row.round}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {row.raised}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {row.stake}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {row.valuation}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            marginTop: "10px",
            padding: "16px 24px",
            borderRadius: "8px",
            boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
            border: "none !important",
          }}
        >
          <p
            className="investorDetails-subheading"
            style={{
              color: "#696F79",
              fontSize: "20px",
              fontWeight: "300",
              marginTop: "0px",
              marginBottom: "16px",
            }}
          >
            Deal Team
          </p>
          <Box
            className="team-profile-div"
            sx={{ backgroundColor: "#FAFAFA", border: "1px solid #ACACACA" }}
          >
            <img
              loading="lazy"
              src={analyst.profile}
              className="team-profile-img"
              alt="Fundrev Analyst Profile"
            />
            <div className="team-deal-div-2">
              <div className="team-deal-div-3">{analyst.name}</div>
              <div className="team-deal-div-4 blue-text">Fundrev Analyst</div>
              <div className="team-deal-div-5">
                <span className="deal-team-span">Email Id: &nbsp;</span>
                <a
                  href={`mailto:${analyst.email}`}
                  style={{ color: "#1A1A1A", textDecoration: "none" }}
                >
                  {analyst.email}
                </a>
              </div>
              <div className="team-deal-div-5">
                <span className="deal-team-span">Contact Info: &nbsp;</span>
                <a
                  href={`tel:${analyst.phoneNumber}`}
                  style={{ color: "#1A1A1A", textDecoration: "none" }}
                >
                  {analyst.phoneNumber}
                </a>
              </div>
            </div>
          </Box>
        </Box>
      </Box> */}
      <section className="NoNamePreview">
        <div className="NoNamePreview-left">
          <CompanyOverviewLeftCard deal={deal} />
          <div className="noName-navigation">
            <div
              className={`noName-nav-btn ${
                activeSection === 1 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(1)}
            >
              <p>Company Overview</p>
            </div>
            {deal?.ElevatorPitch && (
              <div
                className={`noName-nav-btn ${
                  activeSection === 8 && "selected-nav-btn"
                }`}
                onClick={() => handleActiveSection(8)}
              >
                <p>Elevator Pitch</p>
              </div>
            )}
            <div
              className={`noName-nav-btn ${
                activeSection === 2 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(2)}
            >
              <p>Key Highlights</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 3 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(3)}
            >
              <p>Purpose of Fundraising</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 4 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(4)}
            >
              <p>Founding team</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 5 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(5)}
            >
              <p>Financials, Key Metrics & Other Information</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 6 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(6)}
            >
              <p>Previous Rounds</p>
            </div>
            <div
              className={`noName-nav-btn ${
                activeSection === 7 && "selected-nav-btn"
              }`}
              onClick={() => handleActiveSection(7)}
            >
              <p>Contact Details</p>
            </div>
          </div>
        </div>
        <div
          className="NoNamePreview-right"
          id="scrollContainer"
          style={{ paddingBottom: "120px" }}
        >
          <div className="noNameDetails-block fundrev-card" ref={overviewRef}>
            <p className="fundrev-card-heading">Company Overview</p>
            <p
              className="setInnerHTML details"
              dangerouslySetInnerHTML={{
                __html: deal ? deal.CompanyDescription : "",
              }}
            />
            <Box
              sx={{
                padding: "12px 24px",
                backgroundColor: "#FAFAFA",
                borderRadius: "16px",
                minHeight: "93px",
                alignContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <Box>
                  <StyledHeading>Legal Entity Name</StyledHeading>
                  <StyledContent>
                    {deal ? deal.LegalEntityName : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Headquarters</StyledHeading>
                  <StyledContent>
                    {deal ? deal.Headquarters : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Founded Year</StyledHeading>
                  <StyledContent>{deal ? deal.FoundedYear : "-"}</StyledContent>
                </Box>
                <Box>
                  <StyledHeading>No. of Employees</StyledHeading>
                  <StyledContent>
                    {deal ? deal.NumberOfEmployees : "-"}
                  </StyledContent>
                </Box>
                <Box>
                  <StyledHeading>Website</StyledHeading>
                  <StyledContent>
                    {deal ? deal.CompanyWebsite : "-"}
                  </StyledContent>
                </Box>
              </Box>
            </Box>
          </div>
          {deal?.ElevatorPitch && (
            <div
              className="noNameDetails-block fundrev-card"
              ref={elevatorPitchRef}
            >
              <p className="fundrev-card-heading">Elevator Pitch</p>
              <Box
                sx={{
                  height: "400px",
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  justifyContent: "flex-start",
                  borderRadius: "4px",
                  paddingTop: 0,
                  position: "relative",
                }}
              >
                {isLoading && (
                  <div className="absolute w-full h-[350px] mx-2 flex justify-center items-center bg-transparent top-0 left-0 rounded">
                    <CircularProgress
                      size={`70px`}
                      thickness={2}
                      sx={{
                        color: "#1A1A1A",
                        zIndex: 2,
                      }}
                    />
                  </div>
                )}
                <video
                  ref={videoRef}
                  controls
                  onPlay={handleVideoPlay}
                  onCanPlay={() => setIsLoading(false)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  controlsList="nodownload"
                  id="video-pitch-preview"
                  preload="auto"
                >
                  {videoURL && <source src={videoURL} />}
                  Your browser does not support the video tag.
                </video>
              </Box>
            </div>
          )}
          <div
            className="noNameDetails-block fundrev-card key-highlights-section"
            ref={keyhighlightsRef}
          >
            <p className="fundrev-card-heading">Key Highlights</p>
            <p
              className="setInnerHTML details"
              dangerouslySetInnerHTML={{
                __html: deal ? deal.KeyHighlights : "",
              }}
            />
          </div>
          <div className="noNameDetails-block fundrev-card" ref={purposeRef}>
            <p className="fundrev-card-heading">Purpose of Fundraising</p>
            <p
              className="setInnerHTML details"
              dangerouslySetInnerHTML={{
                __html: deal ? deal.PurposeOfFundraising : "",
              }}
            />
          </div>
          <Box
            ref={teamRef}
            className="FoundingTeam-box"
            sx={{
              backgroundColor: "white",
              padding: "16px 24px",
              borderRadius: "8px",
              boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
              scrollMarginTop: "100px",
            }}
          >
            <div
              className="FoundingTeam"
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <p
                className="investorDetails-subheading"
                style={{
                  color: "#696F79",
                  fontSize: "20px",
                  fontWeight: "300",
                  marginTop: "0px",
                }}
              >
                Founding Team
              </p>
              <div className="profile-boxes">
                {deal && deal.FoundingTeamDetails
                  ? (() => {
                      try {
                        const teamDetails = JSON.parse(
                          deal.FoundingTeamDetails
                        );
                        return Array.isArray(teamDetails)
                          ? teamDetails.map((member, index) => (
                              <ProfileCard
                                member={member}
                                type="company"
                                key={member?.name || index}
                              />
                            ))
                          : "";
                      } catch (error) {
                        console.error(
                          "Error parsing FoundingTeamDetails:",
                          error
                        );
                        return "";
                      }
                    })()
                  : ""}
              </div>
            </div>
          </Box>
          <div ref={financialsRef}>
            <Finanicals deal={deal} page="noNamePreview" />
          </div>
          <Box
            ref={roundsRef}
            className="CompanyReview-FundingDetails"
            sx={{
              backgroundColor: "white",
              marginTop: "10px",
              padding: "16px 24px",
              borderRadius: "8px",
              boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.1)",
              border: "none !important",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <p
              className="investorDetails-subheading"
              style={{
                color: "#696F79",
                fontSize: "20px",
                fontWeight: "300",
                marginTop: "0px",
              }}
            >
              Previous Rounds
            </p>
            <Box>
              <p
                style={{
                  color: "#1A1A1A",
                  fontWeight: "16px",
                  fontWeight: "600",
                }}
              >
                Key Investors
              </p>
              <p
                style={{
                  color: "#696F79",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {deal ? deal?.ExistingKeyInvestors : ""}
              </p>
            </Box>
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "0px", boxShadow: "none" }}
            >
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow sx={{ height: "48px" }}>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Date</StyledTableHeading>
                    </TableCell>{" "}
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Round</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Raised(₹Cr)</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>% Stake</StyledTableHeading>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px 15px", lineHeight: "48px" }}
                    >
                      <StyledTableHeading>Valuation(₹Cr)</StyledTableHeading>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index} border>
                      <TableCell sx={tableCellStyle}>{row.date}</TableCell>
                      <TableCell sx={tableCellStyle}>{row.round}</TableCell>
                      <TableCell sx={tableCellStyle}>{row.raised}</TableCell>
                      <TableCell sx={tableCellStyle}>{row.stake}</TableCell>
                      <TableCell sx={tableCellStyle}>{row.valuation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <div className="noNameDetails-block fundrev-card" ref={contactRef}>
            <p className="fundrev-card-heading">Contact Details</p>
            <FundrevAnalystDetails analystID={"FN123456"} />
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyOverview;
