import React from "react";
import LinkedinComponent from "../components/linkedinPost";

const DataroomPreparationSuggested = () => {
  const posts = [
    {
      name: "Avik Ashar",
      designation: "Principal | Artha Venture Fund",
      message:
        "Advice to speed up raising: Before having the entire fundraising process, it's really important to have all key data in a data room.",
      link: "https://www.linkedin.com/posts/avikashar_venturecapital-funding-fundraising-activity-7247097216845185024-RgyN?utm_source=share&utm_medium=member_desktop",
      profilePhoto:
        "https://media.licdn.com/dms/image/v2/C5603AQHZxt3cShrn9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624942431248?e=2147483647&v=beta&t=mB3t79XQKuDAtUsPM2_X5Zc7Kke6GVz8SB6Dz7cxM1I",
    },
    {
      name: "Burak Buyukdemir",
      designation: "Founder | Startup Istanbul",
      message:
        "Building a Seed Stage Data Room? Here's What You Need! 👇 Keep it simple, organized, and filled with the essentials",
      link: "https://www.linkedin.com/posts/burakbuyukdemir_data-room-check-list-for-seed-stage-startups-ugcPost-7247561884466380802-2hQf?utm_source=share&utm_medium=member_desktop",
      profilePhoto:
        "https://media.licdn.com/dms/image/v2/D4D03AQHmZV0XnWoaYw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1669562584639?e=2147483647&v=beta&t=AFQHwb9AJ7URhMrcTTtfLHQ2LvmAPdkRl61BVpbmf70",
    },
    {
      name: "Sebastian S.",
      designation: "Partnerships @BforeAI ",
      message:
        "What documents do investors want to see?Data rooms usually don't get the attention they deserve compared to pitch decks.",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7231359003434328065/",
      profilePhoto:
        "https://media.licdn.com/dms/image/v2/C5603AQGasDEzyTBKTA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1519931718107?e=1736380800&v=beta&t=AVYwgWBVi4Rcn-Jl8f5N5gmlai21TbJKoIRaz4L04Yg",
    },
  ];
  return (
    <div className="suggested-section">
      <p className="heading">
        Think it is too much we are asking for? Learn from what Top
        VCs/Successful Founders have to say!
      </p>
      {posts.map((post, index) => (
        <LinkedinComponent
          key={index}
          name={post.name}
          designation={post.designation}
          message={post.message}
          profilePhoto={post.profilePhoto}
          link={post.link}
        />
      ))}
    </div>
  );
};

export default DataroomPreparationSuggested;
