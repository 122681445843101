import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CompanyMapperTable from "./CompanyMapperTable";
import Toast from "../../../Common/Toast";
import { updateCompanyMapper } from "../../../../endpoints/admin";
import { ToastContainer } from "react-toastify";

const AnalyticsCompanyMapper = ({ data, dealId }) => {
  const [tableData, setTableData] = useState(data); // Original data for display
  const [editData, setEditData] = useState(
    JSON.parse(JSON.stringify(data) || "[]")
  ); // Copy for editing

  const handleSave = async () => {
    // Update original tableData with the changes made in editData
    setTableData(editData);
    // Reset editData to reflect the newly saved tableData
    setEditData(JSON.parse(JSON.stringify(editData))); // Fresh copy of updated data
    try {
      const response = await updateCompanyMapper(dealId, {
        companyMapper: editData,
      });
      response.data
        ? Toast(
            `Company Mapper updated successfully!`,
            "success",
            "adminCompanyMapper"
          )
        : Toast(`Error Updating the mapper`, `error`, `adminCompanyMapper`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handler to update name field in editData (not tableData)
  const handleNameChange = (pageKey, rowIndex, newName) => {
    setEditData((prevData) => {
      const updatedData = { ...prevData };

      // Access the list of objects for the specific page key
      const pageData = updatedData[pageKey];

      // Update the specific row's name in the editData
      pageData[rowIndex].name = newName;

      // Set the updated page data back in the object
      updatedData[pageKey] = pageData;

      return updatedData;
    });
  };

  // Handler to update visibility (isVisible) in editData (not tableData)
  const handleVisibilityChange = (pageKey, rowIndex, isVisible) => {
    setEditData((prevData) => {
      const updatedData = { ...prevData };

      // Access the list of objects for the specific page key
      const pageData = updatedData[pageKey];

      // Update the specific row's visibility in the editData
      pageData[rowIndex].isVisible = isVisible;

      // Set the updated page data back in the object
      updatedData[pageKey] = pageData;

      return updatedData;
    });
  };

  const pages = {
    summary: "Summary",
    businessAnalytics: "Business Analytics",
    salesAnalytics: "Sales Analytics",
    historicalFinancials: "Historical Financials",
    financialForecast: "Financial Forecast",
    fundingDetails: "Funding Details",
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        containerId={`adminCompanyMapper`}
      />
      <hr
        style={{ height: "2px", backgroundColor: "gray", marginTop: "5px" }}
      />
      <Box>
        <Box
          sx={{
            marginTop: "5px",
            display: "flex",
            marginBottom: "5px",
            justifyContent: "end",
            width: "100%",
          }}
          onClick={handleSave}
        >
          <Button variant="contained">Save</Button>
        </Box>
        <Box>
          {tableData && Object.keys(tableData).length > 0 ? (
            Object.keys(tableData).map((pageKey, index) => {
              return (
                <div key={index}>
                  {index !== 0 && (
                    <hr
                      style={{
                        height: "2px",
                        backgroundColor: "gray",
                        marginTop: "5px",
                      }}
                    />
                  )}
                  <Typography variant="h6">{pages[pageKey]}</Typography>
                  <hr
                    style={{
                      height: "2px",
                      backgroundColor: "gray",
                      marginTop: "5px",
                    }}
                  />
                  <CompanyMapperTable
                    data={tableData[pageKey]} // Display the original tableData
                    editData={editData[pageKey]} // Editing happens in editData
                    onNameChange={(rowIndex, newName) =>
                      handleNameChange(pageKey, rowIndex, newName)
                    } // Update only editData
                    onVisibilityChange={(rowIndex, isVisible) =>
                      handleVisibilityChange(pageKey, rowIndex, isVisible)
                    } // Update only editData's isVisible
                  />
                </div>
              );
            })
          ) : (
            <Typography>No data available</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AnalyticsCompanyMapper;
