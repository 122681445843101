import React, { useEffect, useState } from "react";
import { calculateProfileCompletion } from "../onBoarding/stepCompletion";

import moment from "moment";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";

//We are showing details that are filled by company here as deal might not be created yet by FA.
const DealLive = ({ dealID, details }) => {
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [profileRemainingPercentage, setProfileRemainingPercentage] =
    useState(0);
  function returnRoundType(roundType) {
    try {
      const arr = JSON.parse(roundType);
      return arr[0];
    } catch {
      return "Debt";
    }
  }
  useEffect(() => {
    setProfileRemainingPercentage(100 % -calculateProfileCompletion(details));
  }, [details]);
  return (
    <div className="dashBoard-state-card">
      <div className="inner-content-div">
        <div className="middle-div">
          <p className="roundType-text">{returnRoundType(details.roundType)}</p>
          <p className="roundSize-text">
            <span>Round Size:</span> ₹{details.fundingRequired}Cr
          </p>
          {details.timeline && (
            <p className="roundSize-text">
              <span>End Date:</span>{" "}
              {moment(details.timeline).format("D MMM YYYY")}
            </p>
          )}
        </div>
        <div className="button-div">
          <CustomBlackBtn
            text="Go Live"
            filled={false}
            border="sm"
            variant="sm"
            width="100%"
            onClick={() =>
              Navigate("/company/profilePreview", {
                state: {
                  token: token,
                  DealID: dealID,
                  startupID: details.startupID,
                  hideBtn: true,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DealLive;
