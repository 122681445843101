import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MobileRedirect from "../../../Assets/Images/signup/mobileRedirect.svg";
import Header from "../../LandingPage/header";
const MobileRedirectPage = () => {
  const Navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        Navigate(-1);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header color="black" type="founder" />
      <div
        className="mobileRedirect-section"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          height: "90vh",
        }}
      >
        <img
          src={MobileRedirect}
          style={{
            width: "300px",
          }}
        />
        <div
          className="MobileRedrect-textbox"
          style={{
            width: "350px",
          }}
        >
          <p
            style={{
              color: "#000000",
              textAlign: "center",
              fontSize: "20px",
              fontFamily: "Roboto",
              fontWeight: "500",
              lineHeight: "28px",
              marginTop: "50px",
            }}
          >
            To ensure optimal performance and usability for the upcoming
            processes, please switch to the
            <span className="blue-text"> desktop view :)</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default MobileRedirectPage;
