import React from "react";
import tick from "../../../Assets/Images/signup/tick.svg";
import { investorStepClick } from "../../Common/functions";
const Steps = ({ stepsCompleted, activeStep, isApplicationReviewed }) => {
  const stepNames = [
    "Investor Details",
    "Investment Team",
    "Investment Focus",
    "Industry Focus",
    "Accept T&C",
    "Start Investing",
  ];
  const steps = ["step1", "step2", "step3", "step4", "step5", "step6"];

  return (
    <>
      <div className="div">
        <div className="div-2">Complete Your Profile</div>
        <div
          className="div-10"
          onClick={() => investorStepClick(1, isApplicationReviewed)}
        >
          <div className="div-11 active-step">
            {stepsCompleted.step1 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "1"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 1</div>
            <div className={activeStep === "1" ? "div-7" : "div-13"}>
              Investor Details
            </div>
            <div className={`div-9 ${stepsCompleted.step1 && "complete"}`}>
              {stepsCompleted.step1 ? "Complete" : "In Progress"}
            </div>
          </div>
        </div>
        <div
          className="div-10"
          onClick={() => investorStepClick(2, isApplicationReviewed)}
        >
          <div className={`div-11 ${stepsCompleted.step2 && "active-step"}`}>
            {stepsCompleted.step2 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "2"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 2</div>
            <div className={activeStep === "2" ? "div-7" : "div-13"}>
              Investment Team
            </div>
            <div className={stepsCompleted.step2 ? "div-9 complete" : "div-14"}>
              {stepsCompleted.step2 ? "Complete" : "Pending"}
            </div>
          </div>
        </div>
        <div
          className="div-10"
          onClick={() => investorStepClick(3, isApplicationReviewed)}
        >
          <div className={`div-11 ${stepsCompleted.step3 && "active-step"}`}>
            {stepsCompleted.step3 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "3"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 3</div>
            <div className={activeStep === "3" ? "div-7" : "div-13"}>
              Investment Focus
            </div>
            <div className={stepsCompleted.step3 ? "div-9 complete" : "div-14"}>
              {stepsCompleted.step3 ? "Complete" : "Pending"}
            </div>
          </div>
        </div>
        <div
          className="div-10"
          onClick={() => investorStepClick(4, isApplicationReviewed)}
        >
          <div className={`div-11 ${stepsCompleted.step4 && "active-step"}`}>
            {stepsCompleted.step4 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "4"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 4</div>
            <div className={activeStep === "4" ? "div-7" : "div-13"}>
              Industry Focus
            </div>
            <div className={stepsCompleted.step4 ? "div-9 complete" : "div-14"}>
              {stepsCompleted.step4 ? "Complete" : "Pending"}
            </div>
          </div>
        </div>
        <div
          className="div-10"
          onClick={() => investorStepClick(5, isApplicationReviewed)}
        >
          <div className={`div-11 ${stepsCompleted.step5 && "active-step"}`}>
            {stepsCompleted.step5 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "5"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 5</div>
            <div className={activeStep === "5" ? "div-7" : "div-13"}>
              Accept T&C
            </div>
            <div className={stepsCompleted.step5 ? "div-9 complete" : "div-14"}>
              {stepsCompleted.step5 ? "Complete" : "Pending"}
            </div>
          </div>
        </div>
        <div className="div-10">
          <div className={`div-31 ${stepsCompleted.step6 && "active-step"}`}>
            {stepsCompleted.step6 ? (
              <img src={tick} className="tick" alt="Tick" />
            ) : (
              "6"
            )}
          </div>
          <div className="div-30">
            <div className="div-12">Step 6</div>
            <div className={activeStep === "6" ? "div-7" : "div-13"}>
              Start Investing
            </div>
            <div className={stepsCompleted.step6 ? "div-9 complete" : "div-14"}>
              {stepsCompleted.step6 ? "Complete" : "Pending"}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-div">
        <div className="mobile-div-2">
          <div className="mobile-div-3">
            <div className="mobile-div-5">
              {stepNames[parseInt(activeStep) - 1]}
            </div>
            <div className="mobile-div-6">
              {stepsCompleted[steps[parseInt(activeStep) - 1]]
                ? "Complete"
                : "In progress"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps;
