import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import StayTuned from "../../../Assets/Images/InvestorPage/Dashboard/stayTuned.svg";
import { useLoader } from "../LoaderProvider";
import { getDummyAnalytics } from "../../../endpoints/common";
import { Helmet } from "react-helmet";
import ProgressBar from "./molecules/progressBar";
import AnalyticsOverview from "../../Investor/Dashboard/companyDetails/analytics/analyticsOverview";
import FinancialStatementAnalytics from "../../Investor/Dashboard/companyDetails/analytics/financialStatementAnalytics";
import HistoricalFinancials from "../../Investor/Dashboard/companyDetails/analytics/historicalFinancials";
import BusinessAnalytics from "../../Investor/Dashboard/companyDetails/analytics/businessAnalytics";
import logo from "../../../Assets/Images/InvestorPage/Dashboard/fundrevLogoSmall.svg";
import dropdownwhite from "../../../Assets/Images/InvestorPage/dropdownwhite.svg";
import Sidebar from "../../Founders/Dashboard/Components/sidebar";

const DummyAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState(`summary`);
  const [error, setError] = useState(null);
  const loader = useLoader();
  const [analyticsData, setAnalyticsData] = useState(null);
  console.log(analyticsData);

  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 135;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      const response = await getDummyAnalytics();
      setAnalyticsData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      loader.stop();
      setLoading(false);
    }
  };
  if (loading) {
    loader.start("Fetching data...");
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <p
              style={{
                font: "Karla, sans-serif",
                fontSize: "20px",
                marginBottom: "40px",
              }}
            >
              No analytics available at the moment. Data is being processed.
              Please try again soon.
            </p>
            <img src={StayTuned} style={{ width: "500px" }} />
          </Box>
        </Box>
      </Container>
    );
  }
  return (
    <>
      <div
        className="InvestorSide companyOverview-page"
        style={{ overflowX: "hidden" }}
      >
        <div style={{ pointerEvents: "none" }}>
          <Sidebar active="ongoingDeals" dummy={true} />
        </div>
        <div className="topbardiv">
          <div className="topbardiv-3">
            <div className="topbar-utilities">
              <div className="investor-profile-div">
                <img
                  loading="lazy"
                  src={logo}
                  className="topbarimg-6"
                  alt="profile"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="InvestorSide-box" style={{ overflowX: "hidden" }}>
          <div className="InvestorSide-content">
            <div className="cdetails-div">
              <Helmet>
                <title>{`Startup | Sample - Analytics`}</title>
              </Helmet>
              <section className="NoNamePreview">
                <div className="NoNamePreview-left">
                  <div className="NoName-heading-block">
                    <div className="detailed-page-img-block">
                      <img src={logo} alt="Company logo" />
                      <p className="noName-heading">Sample Analytics</p>
                    </div>
                    <p className="noName-subheading">
                      <span>This is a sample analytics report. </span>
                    </p>
                    {/* <CardTags deal={deal} /> */}
                    <div style={{ width: "100%" }}>
                      <ProgressBar
                        totalFundingRaised={5}
                        fundingRequired={12}
                        roundType={"[`Seed`]"}
                      />
                    </div>
                  </div>
                  <div className="noName-navigation">
                    <div
                      className={`noName-nav-btn ${
                        analytics === "summary" && "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("summary")}
                    >
                      <p>Summary</p>
                    </div>
                    <div
                      className={`noName-nav-btn ${
                        analytics === "businessAnalytics" && "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("businessAnalytics")}
                    >
                      <p>Business Analytics</p>
                    </div>
                    <div
                      className={`noName-nav-btn ${
                        analytics === "salesAnalytics" && "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("salesAnalytics")}
                    >
                      <p>Sales Analytics</p>
                    </div>
                    <div
                      className={`noName-nav-btn ${
                        analytics === "historicalFinancials" &&
                        "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("historicalFinancials")}
                    >
                      <p>Historical Financials</p>
                    </div>
                    <div
                      className={`noName-nav-btn ${
                        analytics === "financialForecast" && "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("financialForecast")}
                    >
                      <p>Financial Forecast</p>
                    </div>
                    <div
                      className={`noName-nav-btn ${
                        analytics === "fundingDetails" && "selected-nav-btn"
                      }`}
                      onClick={() => setAnalytics("fundingDetails")}
                    >
                      <p>Funding Details</p>
                    </div>
                  </div>
                </div>
                <div
                  className="NoNamePreview-right"
                  style={{
                    paddingBottom: "120px",
                    maxWidth: "71%",
                    width: "auto",
                  }}
                >
                  {analytics === "summary" && analyticsData?.summary && (
                    <AnalyticsOverview
                      data={analyticsData.summary}
                      setAnalytics={setAnalytics}
                    />
                  )}
                  {analytics === "businessAnalytics" &&
                    analyticsData?.businessAnalytics && (
                      <BusinessAnalytics
                        data={analyticsData.businessAnalytics}
                      />
                    )}
                  {analytics === "historicalFinancials" &&
                    analyticsData?.historicalFinancials && (
                      <HistoricalFinancials
                        data={analyticsData.historicalFinancials}
                      />
                    )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyAnalytics;
