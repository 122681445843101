import React from "react";
import { ToastContainer } from "react-toastify";
import Topbar from "../../Company/Deal/topbar";
import InvestorSidebar from "./investorSidebar";
import Profile from "../../../Investor/Dashboard/account/profile";
import { useLocation } from "react-router-dom";
const InvestorEditProfile = () => {
  const location = useLocation();
  const { investor } = location.state || {};
  return (
    <div>
      <div className="InvestorSide adminSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" />

        <InvestorSidebar active="investorProfile" investor={investor} />

        <Topbar title="Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div">
              <Profile investorID={investor.investorID} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorEditProfile;
