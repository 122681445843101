import React, { useEffect } from "react";
import CompanyOverview from "../../../Common/components/companyOverview";

import { Helmet } from "react-helmet";
import { dumpInvestorInteraction } from "../../../../endpoints/common";

const Overview = ({ deal, stage }) => {
  const companyName = deal ? deal.CompanyName : "";
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.DealID,
            type: "view",
            page: "companyOverview",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{`Investor | ${companyName} - overview`}</title>
      </Helmet>
      <CompanyOverview deal={deal} reqToEdit={false} stage={stage} />
    </div>
  );
};

export default Overview;
