import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const fetchAllFiles = async (dealId, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/getAllFiles`,
      { dealID: dealId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const createFolder = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/createFolder`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const renameFolder = async (dealId, path, newName, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/renameFolder`,
      { dealID: dealId, path: path, newName: newName },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const deleteFolder = async (dealId, path, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/deleteFolder`,
      { dealID: dealId, path: path },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const deleteFile = async (dealId, path, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/deleteFile`,
      { dealID: dealId, path: path },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const UploadFile = async (formDataInput, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/uploadFile`,
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};

export const downloadZip = async (dealID, path, token) => {
  try {
    const response = await fetch(
      `${backend_url}/dataroom/downloadZip?dealID=${dealID}&path=${encodeURIComponent(
        path
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
export const checkDataroomAccess = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/checkAccess`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
export const requestDataroomAccess = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/requestAccess`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
export const streamDataroomFile = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/streamDataroomFile`,
      formData,
      {
        responseType: "blob",
        headers: {
          Authorization: token,
        },
      }
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const downloadMarkdown = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/dataroom/downloadMarkdown`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
