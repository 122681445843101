import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Topbar from "./Deal/topbar";
import Sidebar from "./Deal/Sidebar";
import { getStartupDetails } from "../../../endpoints/admin";
import Editable from "../../Common/components/editable";
import { RiDeleteBin6Line } from "react-icons/ri";
import InputCell from "../../Common/components/inputCell";
import { Box } from "@mui/material";
import { FileCopy, Person } from "@mui/icons-material";
import { Table } from "react-bootstrap";
import { handleFileDownload, handleVideoStream } from "../../Common/functions";

const AdminCompanyProfile = () => {
  const location = useLocation();
  const { dealID, startupID } = location.state;
  const [data, setData] = useState(null);
  const [fileInfo, setFileInfo] = useState({});
  const [videoURL, setVideoURL] = useState(null);
  const token = localStorage.getItem("token");

  let stringFields = [
    "summary",
    "pitch",
    "businessPlan",
    "competitorAnalysis",
    "incomeStatement",
    "balanceSheet",
    "cashFlowStatement",
    "auditReport",
    "mis",
    "financialModel",
    "invoiceDetail",
    "salesPipelineMetrics",
    "marketingExpenses",
    "profitabilityMetrics",
    "otherMetrics",
    "directorsProfiles",
    "managementTeamProfile",
    "organizationChart",
    "memorandum",
    "certificateIncorporation",
    "taxIdentificationDocuments",
    "sharePurchaseAgreements",
    "debtAgreements",
    "capitalizationTable",
    "taxReturns",
    "employeeCensus",
    "employeeStockOptionPlan",
    "employmentAgreements",
    "productOverview",
    "productRoadmap",
    "recordedProductDemo",
    "trademarks",
    "patents",
    "keyCustomerContracts",
    "keyVendorContracts",
    "securityContracts",
    "otherMaterialAgreement",
  ];

  // const documentsDictionary = {
  //   summary: "One Pager Summary",
  //   pitch: "Pitch deck",
  //   businessPlan: "Business Plan",
  //   competitorAnalysis: "Competitor Analysis",
  //   incomeStatement: "Monthly Income Statement (Last 3 years)",
  //   balanceSheet: "Monthly Balance Sheet (Last 3 years)",
  //   cashFlowStatement: "Monthly Cash Flow Statement (Last 3 Years)",
  //   auditReport: "Audit Report (Last 3 years)",
  //   mis: "Company MIS",
  //   financialModel: "Financial Model",
  //   invoiceDetail: "Invoice Detail",
  //   salesPipelineMetrics: "Historical Sales Pipeline Metrics",
  //   marketingExpenses: "Marketing Expenses / Performance",
  //   profitabilityMetrics: "Profitability Metrics",
  //   otherMetrics: "Any other Metrics",
  //   directorsProfiles: "Directors Profile",
  //   managementTeamProfile: "Management Team Profile",
  //   organizationChart: "Organization Chart",
  //   memorandum: "Memorandum & Articles of Association",
  //   certificateIncorporation: "Certificate of Incorporation",
  //   taxIdentificationDocuments: "Company ID - PAN/TAN",
  //   sharePurchaseAgreements: "Share Purchase Agreements",
  //   debtAgreements: "Debt Agreements (if any)",
  //   capitalizationTable: "Capitalization Table",
  //   taxReturns: "Tax Returns (Last 3 Years)",
  //   employeeCensus: "Employee Census",
  //   employeeStockOptionPlan: "Employee Stock Option Plan (if any)",
  //   employmentAgreements: "Employment Agreements",
  //   productOverview: "Product Overview",
  //   productRoadmap: "Product Roadmap",
  //   recordedProductDemo: "Recorded Product Demo",
  //   trademarks: "Trademarks",
  //   patents: "Granted & Filed Patents",
  //   keyCustomerContracts: "Key Customer Contracts",
  //   keyVendorContracts: "Key Vendor Contracts",
  //   securityContracts: "securityContracts",
  //   otherMaterialAgreement: "Any other material agreement",
  // };

  const getStartupData = async () => {
    try {
      if (token) {
        const response = await getStartupDetails(startupID, token);
        setData(response.data);
        const fields = [];

        const newFileInfo = {};

        const fetchBlobName = (url) => {
          try {
            const str = decodeURIComponent(url.split("/").pop());
            const lastIndex = str.lastIndexOf("_");
            if (lastIndex === -1 || str.indexOf("_") === lastIndex) {
              return str;
            }
            const result = str.replace(/_\d{8}_\d{6}/, "");
            return result;
          } catch (error) {
            console.error("Error extracting file name:", error);
            return "";
          }
        };
        const fetchFullFileName = (url) => {
          try {
            const str = decodeURIComponent(url.split("/").pop());
            return str;
          } catch (error) {
            console.error("Error extracting file name:", error);
            return "";
          }
        };

        stringFields.forEach((field, index) => {
          if (fields[index]) {
            const urls = [];
            const fileUrls = [];

            fields[index].length > 0 &&
              fields[index].map((file, index) => {
                const newFileInfoEntry = {
                  filename: fetchBlobName(file),
                  fullName: fetchFullFileName(file),
                  size: "NA",
                  fileURL: file,
                };
                urls.push(newFileInfoEntry);
                fileUrls.push(file);
              });
            newFileInfo[field] = urls; // Store array of file info objects for each field
          }
        });
        setFileInfo((prevState) => ({
          ...prevState,
          ...newFileInfo,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStartupData();
  }, [startupID]);
  // useEffect(() => {
  //   const getVideoURL = async () => {
  //     if (data?.videoPitch?.length > 0) {
  //       const response = await handleVideoStream(data?.videoPitch[0]);
  //       setVideoURL(response);
  //     }
  //   };
  //   getVideoURL();
  // }, [data]);
  useEffect(() => {}, [videoURL]);
  const parseArray = (arr) => {
    try {
      return JSON.parse(arr);
    } catch (error) {
      return [];
    }
  };

  return (
    <>
      <div className="dealDetailsEdit">
        <Helmet>
          <title>Admin Panel - Company Profile</title>
        </Helmet>
        <Topbar title={`Company Profile`} />
        <Sidebar
          dealID={dealID}
          active={"companyProfile"}
          startupID={startupID}
        />
        <div className="dealDetailsEdit-box">
          {/* {data && (
            <Box
              sx={{
                margin: 0,
                width: "100% !important",
                padding: "10px",
              }}
              className="investorDetails sixth-step"
            >
              <div className="details-section ml-0">
                <div
                  style={{
                    margin: "0px !important",
                    padding: 0,
                  }}
                >
                  <p className="investorDetails-heading-visible">
                    {data.companyName} - Pitch Review
                  </p>
                  <div className="Pitch-Overview">
                    <div className="companyReview">
                      <p className="investorDetails-subheading">
                        Company Overview
                      </p>
                      <div className="companyReview-box">
                        <div className="companyReview-img">
                          <img
                            src={data.companyLogo}
                            className="InvestorLogo"
                            alt="Upload"
                            style={{ cursor: "pointer" }}
                            width={`100px !important`}
                          />
                        </div>
                        <div>
                          <div className="companyReview-info">
                            <div>
                              <p className="companyReview-companyName">
                                {data.companyName ? data.companyName : "-"}
                              </p>
                              <p className="companyReview-comapnyDesc">
                                {data.description ? data.description : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="companyReview-stats laptop-stats">
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.legalEntityName
                                  ? data.legalEntityName
                                  : "-"}
                              </div>
                              <div className="group-subheading">
                                Legal Entity Name
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.headquarters ? data.headquarters : "-"}
                              </div>
                              <div className="group-subheading">
                                Headquarters
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.foundedYear ? data.foundedYear : "-"}
                              </div>
                              <div className="group-subheading">
                                Founded Year
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.numEmployees ? data.numEmployees : "-"}
                              </div>
                              <div className="group-subheading">
                                No. of Employees
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.website ? data.website : "-"}
                              </div>
                              <div className="group-subheading">Website</div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.industryVerticals &&
                                data.industryVerticals.length > 0
                                  ? JSON?.parse(
                                      data?.industryVerticals || "[]"
                                    )?.join(", ")
                                  : "-"}
                              </div>
                              <div className="group-subheading">
                                Industry Verticals
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.endMarket ? data.endMarket : "-"}
                              </div>
                              <div className="group-subheading">End Market</div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.cin ? data.cin : "-"}
                              </div>
                              <div className="group-subheading">CIN/ LLPIN</div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.taxNumber ? data.taxNumber : "-"}
                              </div>
                              <div className="group-subheading">
                                Tax Identification No.
                              </div>
                            </div>
                            <div className="companyReview-group">
                              <div className="group-heading">
                                {data.erpPlatform ? data.erpPlatform : "-"}
                              </div>
                              <div className="group-subheading">
                                ERP Platform Used
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="companyReview-stats mobile-stats">
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.legalEntityName ? data.legalEntityName : "-"}
                          </div>
                          <div className="group-subheading">
                            Legal Entity Name
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.headquarters ? data.headquarters : "-"}
                          </div>
                          <div className="group-subheading">Headquarters</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.foundedYear ? data.foundedYear : "-"}
                          </div>
                          <div className="group-subheading">Founded Year</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.numEmployees ? data.numEmployees : "-"}
                          </div>
                          <div className="group-subheading">
                            No. of Employees
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.website ? data.website : "-"}
                          </div>
                          <div className="group-subheading">Website</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.industryVerticals &&
                            data.industryVerticals.length > 0
                              ? data.industryVerticals.join(", ")
                              : "-"}
                          </div>
                          <div className="group-subheading">
                            Industry Verticals
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.cin ? data.cin : "-"}
                          </div>
                          <div className="group-subheading">CIN/ LLPIN</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.taxNumber ? data.taxNumber : "-"}
                          </div>
                          <div className="group-subheading">
                            Tax Identification No.
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.erpPlatform ? data.erpPlatform : "-"}
                          </div>
                          <div className="group-subheading">
                            ERP Platform Used
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="FoundingTeam"
                      style={{ marginBottom: "0px" }}
                    >
                      <p className="investorDetails-subheading">
                        Founding Team
                      </p>

                      <div className="team-profiles-box">
                        {JSON?.parse(
                          data.foundingMember?.length > 0
                            ? data.foundingMember?
                            : "[]"
                        )?.map((member?, index) => (
                          <div className="team-profile-div">
                            <img
                              loading="lazy"
                              src={member?.foundingTeamProfile}
                              className="team-profile-img"
                              alt="team profile"
                            />
                            <div className="team-deal-div-2">
                              <div
                                className="team-profile-div-3"
                                onClick={() => {
                                  if (member?.linkedin) {
                                    const url = member?.linkedin.startsWith(
                                      "http"
                                    )
                                      ? member?.linkedin
                                      : `https://${member?.linkedin}`;
                                    window.open(url, "_blank");
                                  }
                                }}
                              >
                                {member?.name}
                              </div>
                              <div className="team-deal-div-4">
                                {member?.title ? member?.title : "-"}
                              </div>
                              <div className="team-deal-div-5">
                                <span className="deal-team-span">
                                  Email Id: &nbsp;
                                </span>
                                {member?.email ? member?.email : "-"}
                              </div>
                              <div className="team-deal-div-5">
                                <span className="deal-team-span">
                                  Contact Info: &nbsp;
                                </span>
                                {member?.phoneNumber ? member?.phoneNumber : "-"}
                              </div>

                              <div className="team-profile-div-5">
                                {member?.biography}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="fundingDetails">
                      <p className="investorDetails-subheading">
                        Funding Details
                      </p>
                      <div
                        className="companyReview-stats"
                        style={{ marginBottom: "20px" }}
                      >
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.fundingRequired ? data.fundingRequired : "-"}
                          </div>
                          <div className="group-subheading">
                            Funding Required (₹Cr)
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.stakeOffered ? data.stakeOffered : "-"}
                          </div>
                          <div className="group-subheading">
                            Stake Offered %
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.minPostMoneyValuation
                              ? data.minPostMoneyValuation
                              : "-"}
                          </div>
                          <div className="group-subheading">
                            Min. Post Money Valuation
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.amountRaised ? data.amountRaised : "-"}
                          </div>
                          <div className="group-subheading">
                            Amount Already Raised (₹Cr)
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.roundType.length > 0
                              ? JSON?.parse(data?.roundType[0] || "[]")?.join(
                                  ", "
                                )
                              : `-`}
                          </div>
                          <div className="group-subheading">Round Type</div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.lastRoundType ? data.lastRoundType : "-"}
                          </div>
                          <div className="group-subheading">
                            Last Round Type
                          </div>
                        </div>
                        <div className="companyReview-group">
                          <div className="group-heading">
                            {data.keyInvestors ? data.keyInvestors : "-"}
                          </div>
                          <div className="group-subheading">
                            Existing Key Investors
                          </div>
                        </div>
                      </div>
                      <div
                        className="companyReview-group"
                        style={{ marginTop: "30px" }}
                      >
                        <div className="group-heading">
                          {data.purpose ? data.purpose : "-"}
                        </div>
                        <div className="group-subheading">
                          Purpose of Fundraising
                        </div>
                      </div>
                    </div>
                    <div className="documents">
                      <p className="investorDetails-subheading">Documents</p>
                      <div className="Documents-box">
                        {stringFields.some(
                          (field) =>
                            (fileInfo[field] && fileInfo[field].length) > 0
                        ) ? (
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      width: "40%",
                                      fontFamily: "karla,sans-serif",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Document Type
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontFamily: "karla,sans-serif",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Files
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {stringFields.map((field) => {
                                  if (
                                    fileInfo[field] &&
                                    fileInfo[field].length > 0
                                  ) {
                                    return (
                                      <TableRow key={field}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{
                                            fontFamily: "Karla, sans-serif",
                                          }}
                                        >
                                          {documentsDictionary[field]}
                                        </TableCell>
                                        <TableCell>
                                          <div className="pitch-review-files">
                                            {fileInfo[field].map(
                                              (file, fileIndex) => (
                                                <div
                                                  key={fileIndex}
                                                  className="fileUploads"
                                                  style={{
                                                    width: "fit-content",
                                                  }}
                                                >
                                                  <FileCopy />
                                                  <div className="fileUploads-div-2">
                                                    <p
                                                      className="fileLink"
                                                      onClick={() =>
                                                        handleFileDownload(
                                                          file.fileURL,
                                                          file.filename
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        className="fileUploads-div-3"
                                                        style={{
                                                          width: "fit-content",
                                                        }}
                                                      >
                                                        {file.filename}
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                  return null;
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <div>No Documents Attached</div>
                        )}
                      </div>
                    </div>

                    <div className="documents">
                      <p className="investorDetails-subheading">
                        Elevator Pitch
                      </p>
                      <Box
                        sx={{
                          height: "300px",
                          width: "500px",
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",
                          "@media (max-width: 991px)": {
                            height: "250px",
                            width: "340px",
                            paddingLeft: "16px",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Karla, sans-serif",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          Preview
                        </Typography>
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <video
                            controls
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            controlsList="nodownload"
                            id="video-pitch-preview"
                          >
                            {videoURL && <source src={videoURL} />}
                            Your browser does not support the video tag.
                          </video>
                        </Box>
                      </Box>
                      <div
                        onClick={async () =>
                          await navigator.clipboard.writeText(
                            data?.videoPitch[0]
                          )
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Link: &nbsp;
                        {data?.videoPitch?.length > 0 && data?.videoPitch[0]}
                      </div>
                    </div>

                    <div className="dealTeam">
                      <p className="investorDetails-subheading">Deal Team</p>
                      <div className="team-profiles-box">
                        {data?.dealTeam?.map((member?) => (
                          <div className="team-profile-div">
                            <img
                              loading="lazy"
                              src={member?.member?Img ? member?.member?Img : Person}
                              className="team-profile-img"
                              alt="team profile"
                            />
                            <div className="team-deal-div-2">
                              <div className="team-deal-div-3">
                                {member?.name ? member?.name : "-"}
                              </div>
                              <div className="team-deal-div-4">
                                {member?.title ? member?.title : "-"}
                              </div>
                              <div className="team-deal-div-5">
                                <span className="deal-team-span">
                                  Email Id: &nbsp;
                                </span>
                                {member?.email ? member?.email : "-"}
                              </div>
                              <div className="team-deal-div-5">
                                <span className="deal-team-span">
                                  Contact Info: &nbsp;
                                </span>
                                {member?.phoneNumber ? member?.phoneNumber : "-"}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          )} */}
          {data && (
            <>
              <p className="investorDetails-heading">
                Company Details - {data.companyName}
              </p>
              <Box
                sx={{
                  margin: 0,
                  width: "100% !important",
                  padding: "10px",
                }}
                className="investorDetails sixth-step"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      Object?.entries(data)?.map(([key, value], index) => {
                        let document = false;
                        let foundingTeam = false;
                        let dealTeam = false;
                        if (
                          [
                            "industryVerticals",
                            "endMarket",
                            "roundType",
                            "availableDays",
                            "preferredInvestors",
                          ].includes(key)
                        ) {
                          value = parseArray(value);
                        }
                        if (
                          [
                            "pitchDeck",
                            "videoPitch",
                            "financialDocuments",
                            "invoiceDetails",
                            "bankStatements",
                            "corporateDocuments",
                            "teamDetails",
                            "productTechnology",
                            "marketClientInfo",
                            "legalComplianceDetails",
                            "financialPlanning",
                          ].includes(key)
                        ) {
                          document = true;
                        }
                        if (key === "foundingMember" || key === "dealTeam") {
                          return null;
                        }
                        return (
                          <tr key={index}>
                            <td
                              className={
                                document && `text-[var(--brand-color)]`
                              }
                            >
                              {key}
                            </td>
                            {!foundingTeam && !dealTeam && (
                              <td
                                style={{
                                  maxWidth: "600px", // adjust as needed
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                  overflow: "hidden",
                                  color: document && `var(--link-color)`,
                                }}
                              >
                                {!document && (value?.toString() || `-`)}
                                {document &&
                                  value.map((doc) => (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleFileDownload(doc)}
                                    >
                                      {doc}
                                      <br />
                                    </div>
                                  ))}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Box>
              <p className="investorDetails-subheading">
                Founding Team Details
              </p>
              {data?.foundingMember &&
                parseArray(data?.foundingMember)?.map((member) => (
                  <div className="p-3">
                    <table>
                      <thead>
                        <th>Profile Photo</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Biography</th>
                        <th>LinkedIn</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              loading="lazy"
                              src={member?.foundingTeamProfile || Person}
                              alt="founding team profile"
                              className="h-[100px]"
                            />
                          </td>
                          <td>{member?.name}</td>
                          <td>{member?.title}</td>
                          <td>{member?.email}</td>
                          <td>{member?.phoneNumber}</td>
                          <td
                            style={{
                              maxWidth: "250px", // adjust as needed
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              overflow: "hidden",
                            }}
                          >
                            {member?.biography}
                          </td>
                          <td>
                            <a
                              href={member?.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-400"
                            >
                              LinkedIn
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              <p className="investorDetails-subheading">Deal Team Details</p>
              {data?.dealTeam &&
                data?.dealTeam?.map((member) => (
                  <div className="p-3">
                    <table>
                      <thead>
                        <th>Profile Photo</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Biography</th>
                        <th>LinkedIn</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              loading="lazy"
                              src={member?.memberImg}
                              alt="founding team profile"
                              className="h-[100px] object-contain"
                            />
                          </td>
                          <td>{member?.name}</td>
                          <td>{member?.title}</td>
                          <td>{member?.email}</td>
                          <td>{member?.phoneNumber}</td>
                          <td
                            style={{
                              maxWidth: "250px", // adjust as needed
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              overflow: "hidden",
                            }}
                          >
                            {member?.biography}
                          </td>
                          <td>
                            <a
                              href={member?.linkedIn}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-400"
                            >
                              LinkedIn
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCompanyProfile;
