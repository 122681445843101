import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { companyOnBoardingState, saveState } from "./companyOnBoardingState";
const DetailedCardHeader = (props) => {
  const [activeBtn, setActiveBtn] = useState(props.activeBtn);
  const [state, setState] = useRecoilState(companyOnBoardingState);
  const handleActiveBtn = (activeBtn) => {
    const newState = { ...state, activeBtn: activeBtn };
    setState(newState);
    saveState(newState);
  };
  useEffect(() => {
    setActiveBtn(state.activeBtn);
  }, [state.activeBtn]);
  return (
    <div className="cdetails-first-div">
      <div className="typeOfDeals" style={{ paddingLeft: "0px" }}>
        {/* <button
          className={`typeOfDeals-btn ${
            activeBtn === 1 ? "typeOfDeals-active-btn" : ""
          }`}
          onClick={() => {
            setActiveBtn(1);
            handleActiveBtn(1);
          }}
        >
          Overview
        </button>
        <button
          className={`typeOfDeals-btn ${
            activeBtn === 2 ? "typeOfDeals-active-btn" : ""
          }`}
          onClick={() => {
            setActiveBtn(2);
            handleActiveBtn(2);
          }}
        >
          Analytics
        </button>
        <button
          className={`typeOfDeals-btn ${
            activeBtn === 3 ? "typeOfDeals-active-btn" : ""
          }`}
          onClick={() => {
            setActiveBtn(3);
            handleActiveBtn(3);
          }}
        >
          Dataroom
        </button> */}
        <div className="flex flex-col pt-2 px-4 bg-slate-50 max-md:px-5 border-color-[#EBF0FA] border-b-2 w-[100%]">
          <div className="flex flex-wrap gap-10 justify-between items-end w-full max-md:max-w-full">
            <div className="flex flex-col min-w-[240px] max-md:max-w-full">
              <div className="flex flex-wrap gap-6 items-center text-sm min-h-[32px] max-md:max-w-full">
                <div
                  className="flex gap-1 items-center self-stretch my-auto whitespace-nowrap"
                  onClick={() => {
                    setActiveBtn(1);
                    handleActiveBtn(1);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 1 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Overview
                  </div>
                </div>

                <div
                  className="flex gap-1 items-center self-stretch my-auto"
                  onClick={() => {
                    setActiveBtn(2);
                    handleActiveBtn(2);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 2 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Analytics
                  </div>
                </div>

                <div
                  className="flex gap-1 items-center self-stretch my-auto font-semibold"
                  onClick={() => {
                    setActiveBtn(3);
                    handleActiveBtn(3);
                  }}
                >
                  <div
                    className={`self-stretch my-auto font-bold cursor-pointer ${
                      activeBtn === 3 ? "text-blue-700" : "text-gray-500"
                    }`}
                  >
                    Dataroom
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedCardHeader;
