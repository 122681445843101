import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { plotColorPallete } from "../../../../../Common/functions";
import { calculateDtick } from "./PlotUtilityFunctions";
import TimePeriodButton from "./TimePeriodButton";
import { Box } from "@mui/material";

const FinancialPlot = ({ data, ltm = true }) => {
  const [type, setType] = useState("m");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [type, data]);

  const plotData = [
    {
      x: data.data[type].xLabels,
      y: data.data[type].Revenue,
      type: "bar",
      name: "Revenue",
      marker: { color: plotColorPallete[0] },
      hovertemplate: `<b>Revenue</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].GrossProfit,
      type: "bar",
      name: "Gross Profit",
      marker: { color: plotColorPallete[1] },
      hovertemplate: `<b>Gross Profit</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].EBITDA,
      type: "bar",
      name: "EBITDA",
      marker: { color: plotColorPallete[2] },
      hovertemplate: `<b>EBITDA</b>: %{y:,.0f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].GrossProfitPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "Gross Profit %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: plotColorPallete[4] },
      hovertemplate:
        `<b>Gross Profit Margin</b>: %{y:,.1f}%` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].EBITDAPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "EBITDA %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: plotColorPallete[5] },
      hovertemplate: `<b>EBITDA %</b>: %{y:,.1f}%` + "<extra></extra>",
    },
  ];

  return (
    <Box
      sx={{
        marginBottom: 3,
        background: "white",
        width: "100%",
        height: "382px",
        borderRadius: "4px",
        paddingX: "16px",
        paddingY: "16px",
      }}
    >
      <Box
        sx={{
          // paddingTop: "8px",
          paddingBottom: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <TimePeriodButton
          type={type}
          setType={setType}
          title={data.name}
          ltm={ltm}
        />
        <Plot
          data={plotData}
          layout={{
            barmode: "group",
            width: windowWidth > 1590 ? 991 : windowWidth - windowWidth * 0.4,
            height: 300,
            margin: {
              l: 40,
              r: 60,
              t: 20,
              b: 10,
            },
            xaxis: {
              zeroline: true,
              zerolinewidth: 2,
              dtick: calculateDtick(type, data.data[type].xLabels.length),
              tickangle: 0,
              tickfont: {
                family: "Karla, sans-serif",
              },
            },
            yaxis: {
              tickfont: {
                family: "Karla, sans-serif",
              },
              autorangeoptions: {
                minallowed: 0,
              },
            },
            yaxis2: {
              overlaying: "y",
              side: "right",
              tickfont: {
                family: "Karla, sans-serif",
              },
              range: [0, 100],
              ticksuffix: "%",
              autorange: false,
              showgrid: false,
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#00A6FB",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            hovermode: "x unified",
            legend: {
              orientation: "h",
              x: 0.5,
              y: -0.1,
              xanchor: "center",
              yanchor: "top",
            },
            hoverlabel: {
              bordercolor: "transparent",
              bgcolor: "#FAFAFA",
              font: {
                family: "Karla, sans-serif",
              },
            },
          }}
          config={{
            displaylogo: false,
            responsive: true,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
        />
      </Box>
    </Box>
  );
};

export default FinancialPlot;
