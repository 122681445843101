import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import deals from "../../../../Assets/Images/InvestorPage/Dashboard/deals.svg";
import fundrevLogo from "../../../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import whitelogout from "../../../../Assets/Images/InvestorPage/Dashboard/whitelogout.svg";
import {
  AiFillEdit,
  AiOutlineLineChart as AiFillBoxPlot,
  AiOutlineDownload,
} from "react-icons/ai";
const Sidebar = ({ active, dealID, startupID }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleLogout = () => {
    navigate("/adminPanel");
  };
  useEffect(() => {
    const activeTab = document.getElementById(active);
    if (activeTab) {
      activeTab.classList.add("activeTab");
    }
  }, [active]);
  return (
    <div className="opdiv">
      <img
        loading="lazy"
        src={fundrevLogo}
        className="opimg"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <div className="sidebar-content">
        <div className="opdiv-2">
          <div
            className="normal-link"
            onClick={() =>
              navigate("/admin/dealDetailsEdit", {
                state: {
                  dealID,
                  startupID,
                },
              })
            }
          >
            <div className="opdiv-3" id="dealDetails">
              <AiFillEdit className="opimg-2" />
              <div className="opdiv-4">Admin Profile</div>
            </div>
          </div>
          <div
            className="normal-link"
            onClick={() =>
              navigate("/admin/companyProfile", {
                state: {
                  dealID,
                  startupID,
                },
              })
            }
          >
            <div className="opdiv-3" id="companyProfile">
              <AiFillEdit className="opimg-2" />
              <div className="opdiv-4">Company Profile</div>
            </div>
          </div>
          <div
            className="normal-link"
            onClick={() =>
              navigate("/admin/profilePreview", {
                state: {
                  DealID: dealID,
                  startupID: startupID,
                  token: token,
                  analystID: "FN123456",
                },
              })
            }
          >
            <div className="opdiv-3" id="profilePreview">
              <img loading="lazy" src={deals} className="opimg-2" />
              <div className="opdiv-4">Profile Preview</div>
            </div>
          </div>

          <div
            className="normal-link"
            onClick={() =>
              navigate("/admin/analytics", {
                state: {
                  dealID,
                  startupID,
                },
              })
            }
          >
            <div className="opdiv-3" id="analytics">
              <AiFillBoxPlot className="opimg-2" />
              <div className="opdiv-4">Analytics</div>
            </div>
          </div>
          <div
            className="normal-link"
            onClick={() =>
              navigate("/admin/downloadPDF", {
                state: {
                  dealID,
                },
              })
            }
          >
            <div className="opdiv-3" id="analytics">
              <AiOutlineDownload className="opimg-2" />
              <div className="opdiv-4">Download PDF</div>
            </div>
          </div>
        </div>
        <div className="opdiv-15" onClick={handleLogout}>
          <img loading="lazy" src={whitelogout} className="opimg-8" />
          <div className="opdiv-4">Admin Panel</div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
