import React from "react";
import AnalyticsPageTitle from "../analytics/AnalyticsPlots/AnalyticsPageTitle";
import { Box, Grid } from "@mui/material";
import FinancialPlot from "./FinancialPlot";
import FinanceSummaryTable from "./FinanceSummaryTable";
import IncomeTable from "./IncomeTable";
import SmallCardGrid from "./SmallCardGrid";

const AnalyticsFinancialPerformance = ({ data }) => {
  return (
    <Box
      sx={{
        marginBottom: "100px",
      }}
    >
      <AnalyticsPageTitle title={`Financial Performance`} />
      <SmallCardGrid data={data.fiveCards} />
      <Box
        sx={{
          marginBottom: 3,
          background: "white",
          width: "1151px",
          height: "450px",
          borderRadius: "8px",
          paddingX: "10px",
          paddingTop: "10px",
        }}
      >
        <FinancialPlot data={data.plots.financialPlot} ltm={false} />
      </Box>
      <Grid container spacing={1.5} marginBottom={3}>
        <Grid item xs="auto" md="auto" lg="auto">
          <Box>
            <FinanceSummaryTable
              data={data.tables.historicalIncomeStatement}
              ltm={false}
            />
          </Box>
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <Box>
            <FinanceSummaryTable
              data={data.tables.forecastedIncomeStatement}
              m={false}
              q={false}
              ltm={false}
              t="ltm"
            />
          </Box>
        </Grid>
      </Grid>
      <IncomeTable data={data.tables.detailedIncomeStatement} ltm={false} />
    </Box>
  );
};

export default AnalyticsFinancialPerformance;
