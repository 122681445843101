import React, { useEffect, useState } from "react";
import DataRoom from "../../../Common/components/dataroom";
import CompanyOverviewLeftCard from "../Components/CompanyOverviewLeftCard";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import Toast from "../../../Common/Toast";

import { Helmet } from "react-helmet";

import accessDeniedDataRoom from "../../../../Assets/Images/InvestorPage/Dashboard/accessDeniedDataroom.svg";
import {
  checkDataroomAccess,
  requestDataroomAccess,
} from "../../../../endpoints/dataroom";
import { useRecoilValue } from "recoil";
import { investorState } from "../investorState";
import { useLoader } from "../../../Common/LoaderProvider";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";

import "../../../../CSS/InvestorPage/Dashboard/dataroom.css";
const Dataroom = ({ deal, page }) => {
  const token = localStorage.getItem("token");
  const companyName = deal?.CompanyName;
  const { investorId } = useRecoilValue(investorState);
  const [access, setAccess] = useState(false);
  const [status, setStatus] = useState("pending");
  const [statusText, setStatusText] = useState("Request Access");
  const loader = useLoader();
  const checkAccess = async () => {
    try {
      const formData = {
        investorID: investorId,
        dealID: deal?.DealID,
      };
      loader.start("Checking access");
      const response = await checkDataroomAccess(formData, token);
      loader.stop();
      setAccess(response.data.access);
      setStatus(response.data.status);
    } catch (error) {
      loader.stop();
    }
  };
  const requestAccess = async () => {
    try {
      const formData = {
        dealID: deal?.DealID,
      };
      loader.start("Requesting access");
      const response = await requestDataroomAccess(formData, token);
      loader.stop();
      if (response.status === 201) {
        checkAccess();
        Toast(
          "Access request sent successfully",
          "success",
          "investorDataroom"
        );
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    checkAccess();
  }, [investorId, deal]);
  useEffect(() => {
    if (status === "notasked") {
      setStatusText("Request Access");
    } else if (status === "pending") {
      setStatusText("Request Pending");
    } else if (status === "denied") {
      setStatusText("Access Denied");
    } else {
      setStatusText("Access Granted");
    }
  }, [status]);
  return (
    <>
      <Helmet>
        <title>{`Investor | ${companyName} - dataroom`}</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="investorDataroom" />
      <section className="companyOverview-page NoNamePreview">
        <DataRoom deal={deal} />
      </section>
      {/* {stage === "ioiSubmitted" || stage === "requestAccepted" ? (
        <div style={{ marginTop: "10px" }}>
          <p className="stay-tuned-text">Access Denied</p>
          <p className="under-construction-text">
            Access to the data room will be granted upon acceptance of the IOI
            bid.
          </p>
          <div className="img-box">
            <img src={accessDeniedDataRoom} alt="Access Denied" />
          </div>
        </div>
      ) : (
        <DataRoom deal={props.deal} stage={stage} />
      )}
        // <DataRoom deal={props.deal} />
        <section className="NoNamePreview">
          <div className="NoNamePreview-left">
            <CompanyOverviewLeftCard deal={deal} />
          </div>
          <div
            className="NoNamePreview-right"
            id="scrollContainer"
            style={{ paddingBottom: "120px" }}
          >
            <DataRoom deal={deal} />
          </div>
        </section>
      )} */}
    </>
  );
};

export default Dataroom;
