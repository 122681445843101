import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Steps from "./steps";
import CustomSnackBar from "../../../Common/components/custom/customSnackBar";

import DealDetails from "./Steps/dealDetails";
import DealInterest from "./Steps/dealInterest";
import ExpectedValuation from "./Steps/expectedValuation";
import DealPurpose from "./Steps/dealPurpose";
import PitchCreation from "./Steps/pitchCreation";
import AnalyticsPreparation from "./Steps/analyticsPreperation";
import CalenderSetup from "./Steps/calenderSteup";
import DataroomPreparation from "./Steps/dataroomPreperation";
import TermsAndConditions from "./Steps/TnC";
import ApplicationUnderReview from "./Steps/applicationUnderReview";

import DealDetailsSuggested from "./suggested/dealDetailsSuggested";
import DealInterestSuggested from "./suggested/dealInterestSuggested";
import ExpectedValuationSuggested from "./suggested/expectedValuationSuggested";
import DealPurposeSuggested from "./suggested/dealPurposeSuggested";
import PitchCreationSuggested from "./suggested/pitchCreationSuggested";
import AnalyticsPreparationSuggested from "./suggested/analyticsPreparationSuggested";
import CalenderSetupSuggested from "./suggested/calenderSetupSuggested";
import DataroomPreparationSuggested from "./suggested/dataroomPreparationSuggested";
import TermsAndConditionsSuggested from "./suggested/TermsAndConditionsSuggested";
import ApplicationUnderReviewSuggested from "./suggested/applicatiounUnderReviewSuggested";

import {
  fetchStartupDetails,
  PostDetails,
} from "../../../../endpoints/startup";
import { useLoader } from "../../../Common/LoaderProvider";
import { getUnfilledStepIndices } from "./stepCompletion";
import Toast from "../../../Common/Toast";

import * as Yup from "yup";
import { Formik } from "formik";
import "../../../../CSS/FounderPage/Dashboard/companyOnboarding.css";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const CompanyOnboarding = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [isReviewedByCompany, setIsReviewedByCompany] = useState(
    location.state?.isReviewedByCompany || false
  );
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const google = searchParams.get("google");
  const microsoft = searchParams.get("microsoft");
  const loader = useLoader();
  //For file upload
  const [isUploading, setIsUploading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [startupID, setStartupID] = useState("");
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [onBoardingComplete, setOnBoardingComplete] = useState(false);
  //dealType required separately to shift user to last step if the user has completed onboarding
  const [dealType, setdealType] = useState("");

  //*Also add same fields in completeDealDetails.jsx file, stepCompletion.js
  const [initialValues, setInitialValues] = useState({
    //deal details first stage
    dealType: "",
    timeline: null,
    fundingRequired: "",
    debtFundingRequired: "", // Only visible when the user has chosen equity + debt option
    roundType: [],
    debtType: "",
    term: "",
    preferredInvestors: [],

    //Expected Valuation second stage
    equityType: "",
    pricedEquity: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    nonPricedEquity: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    //Deal Interest third stage
    softCommitments: false,
    amountRaised: 0,
    debtAmountRaised: "", // Only visible when the user has chosen equity + debt option
    // leadInvestor: "",
    coInvestors: "",
    contactedInvestorNames: "",

    //Purpose of fundraising fourth step
    purpose: "",

    //Pitch Creation fifth stage
    pitchDeck: [],
    videoPitch: [],

    //Meeting Calender setup
    availableDays: ["M1", "T2", "W3", "T4", "F5"],
    availableTimeStart: dayjs().set("hour", 10).set("minute", 0),
    availableTimeEnd: dayjs().set("hour", 18).set("minute", 0),
    googleAuthToken: false,
    microsoftAuthToken: false,

    //Analytics preparation seventh stage
    financialDocuments: [],
    invoiceDetails: [],
    bankStatements: [],

    //Dataroom preparation eighth stage
    corporateDocuments: [],
    teamDetails: [],
    productTechnology: [],
    marketClientInfo: [],
    legalComplianceDetails: [],
    financialPlanning: [],

    //Terms and conditions ninth step
    acceptTC: false,

    pitchDeckFiles: "",
    videoPitchFiles: "",
    financialDocumentsFiles: "",
    invoiceDetailsFiles: "",
    bankStatementsFiles: "",

    corporateDocumentsFiles: "",
    teamDetailsFiles: "",
    productTechnologyFiles: "",
    marketClientInfoFiles: "",
    legalComplianceDetailsFiles: "",
    financialPlanningFiles: "",

    deletedFileNames: "",
    isApplicationReviewed: "",
    profileCompleted: "",
  });
  const validationSchema = Yup.object({
    dealType: Yup.string().required("Deal Type is required"),
    contactedInvestorNames: Yup.string().required(
      "Contacted investor names required"
    ),
    softCommitments: Yup.boolean().required("Selection is required"),
    fundingRequired: Yup.number()
      .required("Funding required field is required")
      .positive("Funding required must be a positive number"),
    amountRaised: Yup.number()
      .required("Amount already raised is required")
      .min(0, "Amount already raised must be 0 or a positive number")
      .max(
        Yup.ref("fundingRequired"),
        "Amount raised must be less than funding required"
      ),
  });

  const [schema, setSchema] = React.useState(validationSchema);
  // Function to clear query parameters
  const clearParams = () => {
    navigate("/company/onBoarding", { replace: true });
  };
  useEffect(() => {
    if (step) {
      if (dealType === "Debt") {
        setActiveStep(Number(step));
      } else {
        setActiveStep(Number(step) + 1);
      }
    }
    clearParams();
  }, [step]);
  //In select field if initial value is not specified the placeholder do not appear.
  const fetchDetails = async () => {
    if (!token) return;
    try {
      //Remove loader as it will be shown again and again after each form get submits.
      // loader.start();
      const response = await fetchStartupDetails(token);
      // loader.stop();
      if (response?.data !== null) {
        const data = response.data.data;
        setInitialValues({
          // Deal details first stage
          dealType: data.dealType || "",
          timeline: data.timeline || null,
          fundingRequired: data.fundingRequired || "",
          debtFundingRequired: data.debtFundingRequired || "", // Visible only for equity + debt option
          roundType: (() => {
            try {
              return JSON.parse(data?.roundType);
            } catch (error) {
              return [];
            }
          })(),
          debtType: data.debtType || "",
          term: data.term || "",
          preferredInvestors: (() => {
            try {
              return JSON.parse(data?.preferredInvestors);
            } catch (error) {
              return [];
            }
          })(),

          // Expected Valuation second stage
          equityType: data.equityType || "",
          pricedEquity: data.pricedEquity || "",
          stakeOffered: data.stakeOffered || "",
          minPostMoneyValuation: data.minPostMoneyValuation || "",
          nonPricedEquity: data.nonPricedEquity || "",
          interestRate: data.interestRate || "",
          valuationCap: data.valuationCap || "",
          discountRate: data.discountRate || "",

          // Deal Interest third stage
          softCommitments: data.softCommitments || false,
          amountRaised: data.amountRaised || 0,
          debtAmountRaised: data.debtAmountRaised || "", // Visible only for equity + debt option
          // leadInvestor: data.leadInvestor || "",
          coInvestors: data.coInvestors || "",
          contactedInvestorNames: data.contactedInvestorNames || "",

          // Purpose of fundraising fourth step
          purpose: data.purpose || "",

          // Pitch Creation - Fifth Stage
          pitchDeck: data.pitchDeck || [],
          videoPitch: data.videoPitch || [],

          //Meeting Calender setup
          availableDays: (() => {
            try {
              return JSON.parse(data?.availableDays);
            } catch (error) {
              return ["M1", "T2", "W3", "T4", "F5"];
            }
          })(),
          availableTimeStart: data.availableTimeStart
            ? dayjs(data.availableTimeStart)
            : dayjs().set("hour", 10).set("minute", 0),
          availableTimeEnd: data.availableTimeEnd
            ? dayjs(data.availableTimeEnd)
            : dayjs().set("hour", 18).set("minute", 0),
          googleAuthToken: data.googleAuthToken || google === "success",
          microsoftAuthToken:
            data.microsoftAuthToken || microsoft === "success",

          // Analytics Preparation - Sixth Stage
          financialDocuments: data.financialDocuments || [],
          invoiceDetails: data.invoiceDetails || [],
          bankStatements: data.bankStatements || [],

          // Data Room Preparation - Seventh Stage
          corporateDocuments: data.corporateDocuments || [],
          teamDetails: data.teamDetails || [],
          productTechnology: data.productTechnology || [],
          marketClientInfo: data.marketClientInfo || [],
          legalComplianceDetails: data.legalComplianceDetails || [],
          financialPlanning: data.financialPlanning || [],

          //Terms and conditions ninth step
          acceptTC: data.acceptTC || false,

          //Submit for review
          onBoardingComplete: data.onBoardingComplete || false,
          isApplicationReviewed: data.isApplicationReviewed || false,
        });
        setProfileCompleted(data.profileCompleted);
        setIsApproved(data.isApproved);
        setStartupID(data.startupID);
        setOnBoardingComplete(data.onBoardingComplete);
        setdealType(data.dealType);
      }
    } catch (error) {
      loader.stop();
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [token]);
  const fileNames = [
    "pitchDeckFiles",
    "videoPitchFiles",

    "financialDocumentsFiles",
    "invoiceDetailsFiles",
    "bankStatementsFiles",

    "corporateDocumentsFiles",
    "teamDetailsFiles",
    "productTechnologyFiles",
    "marketClientInfoFiles",
    "legalComplianceDetailsFiles",
    "financialPlanningFiles",
  ];

  const fileDictionary = {
    pitchDeckFiles: "pitchDeck",
    videoPitchFiles: "videoPitch",

    financialDocumentsFiles: "financialDocuments",
    invoiceDetailsFiles: "invoiceDetails",
    bankStatementsFiles: "bankStatements",

    corporateDocumentsFiles: "corporateDocuments",
    teamDetailsFiles: "teamDetails",
    productTechnologyFiles: "productTechnology",
    marketClientInfoFiles: "marketClientInfo",
    legalComplianceDetailsFiles: "legalComplianceDetails",
    financialPlanningFiles: "financialPlanning",
  };
  const prepareFormData = (data) => {
    const formDataInput = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (fileNames.includes(key)) {
            data[key].forEach((item) => {
              if (item instanceof File) {
                formDataInput.append(fileDictionary[key], item);
              }
            });
          } else if (
            key !== "timeline" &&
            key !== "availableTimeStart" &&
            key !== "availableTimeEnd" &&
            typeof data[key] === "object" &&
            !(data[key] instanceof File)
          ) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        } else {
          // Append empty values for optional fields that the user wants to clear
          formDataInput.append(key, data[key]);
        }
      }
    }

    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }

    return formDataInput;
  };
  const submitFormData = async (formData, isLoader) => {
    try {
      if (isLoader) {
        loader.start();
        await PostDetails(formData, token);
        loader.stop();
      } else {
        await PostDetails(formData, token);
      }
    } catch (error) {
      loader.stop();
      // Handle error if needed
      console.error("Error submitting form data:", error);
    }
  };
  //For final submission of details
  const SubmitFormData = async (data) => {
    const formDataInput = prepareFormData(data);
    await submitFormData(formDataInput, true);
  };
  //To save details between steps without loader
  const postFormData = async (data) => {
    const formDataInput = prepareFormData(data);
    await submitFormData(formDataInput, false);

    //We are needing to call this function because when if someone tries to update some fields while uploading the files then
    //we have manually edited that field, but as we are also calling fetchDetails function after file upload it will show the
    //previous value of manually updated the field. The field might be updated in the db but not here. Hence we need to call this
    //function to update the details.
    await fetchDetails();
  };
  useEffect(() => {
    if (onBoardingComplete) {
      if (dealType === "Equity + Debt" || dealType === "Equity") {
        setActiveStep(10);
      } else if (dealType === "Debt") {
        setActiveStep(9);
      } else {
        setActiveStep(9);
      }
    }
  }, [onBoardingComplete]);
  const handleStepChange = (values, direction) => {
    const arr = getUnfilledStepIndices(values);
    if (direction === "next") {
      if (
        (values.dealType === "Debt" && activeStep === 8) ||
        (values.dealType !== "Debt" && activeStep === 9)
      )
        if (arr.length > 0 && arr[0] !== 9) {
          if (values.dealType === "Debt" && activeStep > 1) {
            setActiveStep(arr[0]);
          } else {
            setActiveStep(arr[0] + 1);
          }
          Toast(
            "Please fill the required details",
            "error",
            "companyOnboarding"
          );
        } else {
          values.onBoardingComplete = true;
          values.startupID = startupID;
          postFormData(values);
          setActiveStep(activeStep + 1);
        }
      else {
        postFormData(values);
        if (activeStep) setActiveStep(activeStep + 1);
      }
    } else {
      postFormData(values);
      if (activeStep) setActiveStep(activeStep - 1);
    }
  };
  useEffect(() => {
    //It checks whether someone is trying to create a deal without completing profile or getting verified.
    //First condition to ensure that profileCompleted and isApproved has been updated through fetchDetails
    if (startupID) {
      if (profileCompleted === false || isApproved === false) {
        navigate("/company/dashboard");
      }
    }
  }, [profileCompleted, isApproved, startupID]);
  return (
    <div className="InvestorSide">
      <Helmet>
        <title>Fundrev | Company onboarding</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="companyOnboarding" />
      <Sidebar active="profile" />
      <Topbar title="Company Details" />
      <CustomSnackBar isUploading={isUploading} />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="companyOnboarding">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={postFormData}
              enableReinitialize={true} //Set this field when you are fetching data from an api to fill the form
            >
              {({ handleChange: formikHandleChange, values }) => {
                const handleChange = (e) => {
                  formikHandleChange(e); // Let Formik handle the value change
                };
                const stepsComponents = [
                  <DealDetails />,

                  ...(values.dealType === "Equity" ||
                  values.dealType === "Equity + Debt"
                    ? [<ExpectedValuation />]
                    : []),
                  <DealInterest />,
                  <DealPurpose />,
                  <PitchCreation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  <CalenderSetup startupID={startupID} />,
                  <AnalyticsPreparation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  <DataroomPreparation
                    setIsUploading={setIsUploading}
                    fetchDetails={fetchDetails}
                    setSchema={setSchema}
                    validationSchema={validationSchema}
                  />,
                  <TermsAndConditions />,
                  <ApplicationUnderReview
                    startupID={startupID}
                    isReviewedByCompany={isReviewedByCompany}
                  />,
                ];
                const stepsComponentsSuggested = [
                  <DealDetailsSuggested />,
                  ...(values.dealType === "Equity" ||
                  values.dealType === "Equity + Debt"
                    ? [<ExpectedValuationSuggested />]
                    : []),
                  <DealInterestSuggested />,

                  <DealPurposeSuggested />,
                  <PitchCreationSuggested />,
                  <CalenderSetupSuggested />,
                  <AnalyticsPreparationSuggested />,
                  <DataroomPreparationSuggested />,
                  <TermsAndConditionsSuggested />,
                  <></>,
                ];

                return (
                  <>
                    <Steps
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      values={values}
                      setSchema={setSchema}
                      validationSchema={validationSchema}
                    />
                    <div
                      className="companyOnboarding-form"
                      style={{
                        width:
                          dealType === "Equity + Debt" || dealType === "Equity"
                            ? activeStep === 10 && "80%"
                            : activeStep === 9 && "80%",
                      }}
                    >
                      {stepsComponents[activeStep - 1]}
                    </div>
                    {!values.onBoardingComplete && (
                      <div className="showInterestButtons-div">
                        <div className="btns-insideContent">
                          {activeStep > 1 && (
                            <div>
                              <CustomBlackBtn
                                mode="light"
                                filled={false}
                                text="Previous Step"
                                type="button"
                                onClick={() => handleStepChange(values, "prev")}
                              />
                            </div>
                          )}

                          <div>
                            <CustomBlackBtn
                              text={
                                values.dealType === "Debt"
                                  ? activeStep === 8
                                    ? "Submit"
                                    : "Save & Continue"
                                  : activeStep === 9
                                  ? "Submit"
                                  : "Save & Continue"
                              }
                              type="button"
                              onClick={() => handleStepChange(values, "next")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {stepsComponentsSuggested[activeStep - 1]}
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOnboarding;
