import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { FaCaretUp } from "react-icons/fa";

const CoInvestorTable = ({ data }) => {
  const preStages = ["ioiSubmitted", "ioiAccepted", "loiSubmitted"];
  const stagesName = {
    ioiSubmitted: "IOI Submitted",
    ioiAccepted: "IOI Accepted",
    loiSubmitted: "IOI Submitted",
    loiAccepted: "LOI Accepted",
  };
  const investorStage = data?.investorStage;
  const tableData = data?.tableData;
  function Row(props) {
    const { stage } = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            component="th"
            scope="row"
            sx={{
              width: 123,
              height: 40,
              fontFamily: "Karla, sans-serif",
              background: "#FFF",
              padding: "0px 16px",
              fontWeight: "500",
            }}
            align="left"
          >
            {stagesName[stage] + ` (${tableData[stage]?.numInvestors})`}
          </TableCell>
          <TableCell
            sx={{
              width: 123,
              height: 40,
              fontFamily: "Karla, sans-serif",
              padding: "0px 16px",
              fontWeight: "500",
            }}
            align="left"
          >
            {tableData[stage]?.investmentAmount}
          </TableCell>
          <TableCell
            sx={{
              width: 20,
              height: 40,
              fontFamily: "Karla, sans-serif",
              padding: "0px 16px",
            }}
            align="right"
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <span className={`updownicon ${open ? "rotate" : ""}`}>
                <FaCaretUp />
              </span>
            </IconButton>
          </TableCell>
        </TableRow>
        {investorStage && preStages.includes(investorStage) ? (
          <TableCell
            colSpan={3}
            sx={{
              display: open ? "table-cell" : "none",
              padding: 0,
              animation: open
                ? "fadeIn 0.5s ease-in-out"
                : "fadeOut 0.5s ease-in-out",
              "@keyframes fadeIn": {
                from: {
                  opacity: 0,
                  transform: "translateY(-10px)",
                },
                to: {
                  opacity: 1,
                  transform: "translateY(0)",
                },
              },
              "@keyframes fadeOut": {
                from: {
                  opacity: 1,
                  transform: "translateY(0)",
                },
                to: {
                  opacity: 0,
                  transform: "translateY(-10px)",
                },
              },
            }}
          >
            <Box
              sx={{
                fontFamily: "Karla, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: "#5D5D5D",
                padding: "10px 20px",
              }}
            >
              More details are visible after acceptance of LOI.
            </Box>
          </TableCell>
        ) : (
          tableData &&
          tableData[stage]?.investors.map((investor, index) => (
            <TableRow
              key={index}
              sx={{
                display: open ? "table-row" : "none",
                animation: open
                  ? "fadeIn 0.3s ease-in-out"
                  : "fadeOut 0.3s ease-in-out",
                "@keyframes fadeIn": {
                  from: {
                    opacity: 0,
                    transform: "translateY(-10px)",
                  },
                  to: {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                },
                "@keyframes fadeOut": {
                  from: {
                    opacity: 1,
                    transform: "translateY(0)",
                  },
                  to: {
                    opacity: 0,
                    transform: "translateY(-10px)",
                  },
                },
              }}
            >
              <TableCell
                sx={{
                  width: 123,
                  height: 40,
                  fontFamily: "Karla, sans-serif",
                  padding: "0px 16px",
                  color: "var(--Secondary-Text-Color)",
                }}
                align="left"
              >
                {investor?.name}
              </TableCell>
              <TableCell
                sx={{
                  width: 123,
                  height: 40,
                  fontFamily: "Karla, sans-serif",
                  padding: "0px 16px",
                  color: "var(--Secondary-Text-Color)",
                }}
                align="left"
              >
                {investor?.amount}
              </TableCell>
            </TableRow>
          ))
        )}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          backgroundColor: "#FFFFFF",
          width: "100",
          fontFamily: "Karla, sans-serif",
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label="coInvestors table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "30%",
                    height: 40,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                    padding: "0px 16px",
                    backgroundColor: "#EAEBEB",
                    color: "#696F79",
                    fontWeight: "300",
                  }}
                  align="left"
                >
                  {preStages?.includes(data?.investorStage)
                    ? `Stage`
                    : `Stage/Co-Investor's Name`}
                </TableCell>
                <TableCell
                  sx={{
                    width: "28%",
                    height: 40,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                    padding: "0px 16px",
                    backgroundColor: "#EAEBEB",
                    color: "#696F79",
                    fontWeight: "300",
                  }}
                  align="left"
                >
                  Amount (Cr.)
                </TableCell>
                <TableCell
                  sx={{
                    width: "42%",
                    height: 40,
                    fontFamily: "Karla, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    background: "white",
                    padding: "0px 16px",
                    backgroundColor: "#EAEBEB",
                    color: "#696F79",
                    fontWeight: "300",
                  }}
                  align="left"
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                Object.keys(tableData)
                  .reverse()
                  .map((stage, index) => <Row stage={stage} key={index} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <p
          style={{
            marginTop: "8px",
            fontWeight: 300,
          }}
        >
          * This data is based on current status of investors.
        </p>
      </Box>
    </>
  );
};

export default CoInvestorTable;
