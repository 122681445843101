// import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import "../../CSS/Signup/style.css";
// import LeftSection from "./LeftSection.jsx";
// import RightSection from "./RightSection.jsx";
// import { useNavigate } from "react-router-dom";
// import { handleNavigation } from "../Common/functions.jsx";
// const SignUp = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     handleNavigation(navigate);
//   }, [navigate]);
//   return (
//     <>
//       <Helmet>
//         <title>Fundrev | Sign Up</title>
//       </Helmet>
//       <div className="signUp-div">
//         <LeftSection
//           heading="Accelerated Deal Closures"
//           content="Fundrev closes deals 75% faster, saving time and resources."
//         />
//         <div className="investorRegisterWithSignUpText">
//           <RightSection />
//         </div>
//       </div>
//     </>
//   );
// };

// export default SignUp;
import React, { useEffect } from "react";
import "../../CSS/Signup/style.css";
import Logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import InvestorSignUp from "../../Assets/Images/signup/investorSignup.svg";
import CompanySignUp from "../../Assets/Images/signup/companySignup.svg";
import { handleNavigation } from "../Common/functions";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const SignUp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    handleNavigation(navigate);
  }, [navigate]);
  return (
    <div className="signUp-page">
      <Helmet>
        <title>Fundrev | Sign Up</title>
      </Helmet>
      <div className="signUp-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <div className="signUp-middle">
        <div className="heading-block">
          <p className="fundrev-heading">Create your account</p>
        </div>
        <div className="signUp-middle-box">
          <div className="upper-section">
            <p className="text-heading">Join Us!</p>
            <p className="text-subheading">
              To begin this journey, tell us what type of account you'd be
              opening.
            </p>
          </div>
          <div className="main-section">
            <div
              className="main-section-box"
              onClick={() => navigate("/investor/Register")}
            >
              <div className="user-image">
                <img src={InvestorSignUp} alt="investor-logo" />
              </div>
              <div className="user-statement-div">
                <p className="text-heading">Investor</p>
                <p className="text-subheading">
                  Make data-driven investments efficiently on Fundrev.
                </p>
              </div>
            </div>
            <div
              className="main-section-box"
              onClick={() => navigate("/company/Register")}
            >
              <div className="user-image">
                <img src={CompanySignUp} alt="investor-logo" />
              </div>
              <div className="user-statement-div">
                <p className="text-heading">Company</p>
                <p className="text-subheading">
                  Pitch, Connect, Close: Accelerate Deals with Fundrev.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="signUp-lower-section">
          <p>
            Already have an account?{" "}
            <Link to="/signin">
              <span className="link-blue-text">Sign In</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
