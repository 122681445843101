import React, { useEffect, useState } from "react";
import {
  formatDistanceToNow,
  parseISO,
  isWithinInterval,
  subDays,
} from "date-fns";
import {
  reducedStages,
  stageRenameMap,
  stageMap,
} from "../../../Common/functions";
import { getInvestorsByDealID } from "../../../../endpoints/deal";
import { getDetailsById } from "../../../../endpoints/investor";
import OngoingInvestorCard from "../Components/ongoingInvestorCard";
import { useLoader } from "../../../Common/LoaderProvider";
import { useParams } from "react-router-dom";

const OngoingInvestors = ({ dealId }) => {
  const token = localStorage.getItem("token");
  const [investors, setInvestors] = useState([]);
  const [investorDetails, setinvestorDetails] = useState({});
  const [dealStages] = useState(reducedStages);
  const [groupedDeals, setGroupedDeals] = useState([]);
  const loader = useLoader();
  const { type } = useParams();
  //
  function dealsLength(type) {
    try {
      const length = type.length ? type.length : 0;
      return length;
    } catch (error) {
      return 0;
    }
  }
  const fetchInvestorsByDealId = async () => {
    try {
      const response = await getInvestorsByDealID(dealId, token);
      setInvestors(response.data);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (dealId) {
      fetchInvestorsByDealId();
    }
  }, [dealId]);

  const getFormattedDate = (date) => {
    const createdAt = new Date(date);
    const tenDaysAgo = subDays(new Date(), 10);
    const isWithinLastTenDays = isWithinInterval(createdAt, {
      start: tenDaysAgo,
      end: new Date(),
    });

    if (isWithinLastTenDays) {
      return createdAt.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    } else {
      return formatDistanceToNow(parseISO(date), { addSuffix: true }).replace(
        " ago",
        ""
      );
    }
  };

  useEffect(() => {
    const fetchInvestorDetails = async () => {
      const details = {};
      for (const investor of investors) {
        try {
          const response = await getDetailsById(investor.investorID, token);
          details[investor.investorID] = response.data.data;
        } catch (error) {
          loader.stop();
        }
      }
      setinvestorDetails(details);
      loader.stop();
    };

    if (investors ? investors.length > 0 : false) {
      fetchInvestorDetails();
    } else if (investors?.length === 0 && dealId) {
      loader.stop();
    }
  }, [investors]);

  //
  const renderDealCard = (investor, stage, index) => {
    const details = investorDetails[investor.investorID];
    if (!details) return null;
    const latestActivity = getFormattedDate(investor.createdAt);

    return (
      <OngoingInvestorCard
        latestActivity={latestActivity}
        key={investor.investorID}
        investor={investor}
        details={details}
        passedStage={investor.Stage}
        dealId={dealId}
        fetchInvestorsByDealId={fetchInvestorsByDealId}
      />
    );
  };
  const handleGroupedFilteredDeals = () => {
    // Filter deals based on the `type`
    let filteredDeals;
    switch (type) {
      case "current":
        filteredDeals = investors
          ? investors.filter((investor) => investor.IsInterested === true)
          : [];
        break;
      case "dead":
        filteredDeals = investors
          ? investors.filter(
              (investor) =>
                investor.IsInterested === false || investor.IsRejected === true
            )
          : [];
        break;
      default:
        filteredDeals = investors || [];
    }

    // Now, group the filtered deals by stage
    const groupedDeals = dealStages.reduce((acc, stage) => {
      acc[stage] = filteredDeals.filter(
        (deal) => stageMap[deal.Stage] === stage
      );
      return acc;
    }, {}); // Initialize with an empty object as the accumulator

    // Set the grouped deals
    setGroupedDeals(groupedDeals);
  };
  useEffect(() => {
    handleGroupedFilteredDeals();
  }, [type, investors]);
  return (
    <>
      <div className="onGoingDeals-div">
        {dealStages.map((stage, index) => (
          <div
            key={index}
            className="onGoingDeals-col"
            id={stage}
            style={{
              display:
                (type === "watchlist") === (stage === "watchlist")
                  ? "flex"
                  : "none",
            }}
          >
            <div
              className="onGoingDeals-types-div"
              style={{ border: index === 3 && "none" }}
            >
              <div className="onGoingDeals-types-div-2">
                <div className="onGoingDeals-types-div-3">
                  <div className="onGoingDeals-types-div-4">
                    {stageRenameMap[stage]}
                  </div>
                  <div className="onGoingDeals-types-div-5">
                    {dealsLength(groupedDeals[stage])}
                  </div>
                </div>
              </div>
              <div className="onGoingDeals-allCards">
                {groupedDeals &&
                  groupedDeals[stage] &&
                  groupedDeals[stage].map((investor, index) =>
                    renderDealCard(investor, stage, index)
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default OngoingInvestors;
