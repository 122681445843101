import React from "react";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import { FaLock } from "react-icons/fa";
import { HiOutlineDocumentText } from "react-icons/hi";
import { RxCrossCircled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
function CreateDeal({ companyName, handleCreateDealClick }) {
  const Navigate = useNavigate();
  const instructions = [
    {
      icon: FaLock,
      text: "Provide Complete Information",
    },
    {
      icon: RxCrossCircled,
      text: "Avoid Soliciting Investors",
    },
    {
      icon: HiOutlineDocumentText,
      text: "Adhere to Platform Terms of Use",
    },
  ];

  const logos = [
    {
      src: "https://fundrevstorage2.blob.core.windows.net/website-images/a91.png",
      alt: "A91 ",
    },
    {
      src: "https://fundrevstorage2.blob.core.windows.net/website-images/rockstud.png",
      alt: "Rockstud Capital",
    },
    {
      src: "https://fundrevstorage2.blob.core.windows.net/website-images/adityaBirla.png",
      alt: "Aditya Birala Ventures",
    },
    {
      src: "https://fundrevstorage2.blob.core.windows.net/website-images/trifecta.png",
      alt: "Trifecta Capital",
    },
  ];

  return (
    <main className="flex flex-col items-center px-6 py-6 bg-white rounded-3xl max-w-[628px] shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 m-auto top-[300px]">
      <section className="flex flex-col items-center max-w-full w-[522px]">
        <header className="flex flex-col items-center w-full">
          <h1 className="text-xl font-light leading-9 text-right text-gray-500">
            Welcome {companyName},
          </h1>
          <p className="mt-2 text-xl text-zinc-900">
            You're about to pitch to 100+ investors!
          </p>
        </header>
        <div className="flex flex-col mt-2 max-w-full w-[400px]">
          <div className="flex flex-col items-center w-full">
            <div className="flex gap-2 items-start">
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className="flex overflow-hidden flex-col justify-center items-center p-4 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 w-[94px]"
                >
                  <img
                    loading="lazy"
                    src={logo.src}
                    alt={logo.alt}
                    className={`object-contain w-[50px] h-[25px]`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <p className="mt-2 text-base leading-7 text-center text-gray-500 max-md:max-w-full">
          And the <strong className="font-bold underline">success</strong> of
          your pitch depends on the strength of your profile. Please make sure
          to:
        </p>
        <div className="flex gap-10 justify-between items-start w-full mt-2">
          {instructions.map((instruction, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center p-1 rounded-lg"
            >
              <div className="flex flex-col items-center">
                <div className="shadow-md rounded-full p-2">
                  <instruction.icon className="w-5 h-5 text-gray-700" />
                </div>
                <div className="flex flex-col items-end mt-3">
                  <p className="text-xs text-center text-gray-500">
                    {instruction.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="mt-[24px]">
        <CustomBlackBtn text="Let's Go!" onClick={handleCreateDealClick} />
      </div>
    </main>
  );
}

export default CreateDeal;
