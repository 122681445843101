import React, { useState, useEffect } from "react";
import {
  customStyles,
  inputCustomStyles,
  dealTypeOptions,
  preferredInvestorsOptions,
  debtTypeOptions,
  equityTypeOptions,
  pricedEquityOptions,
  nonPricedEquityOptions,
} from "../../../Common/functions";
import Editable from "../../../Common/components/editable";
import InputCell from "../../../Common/components/inputCell";
import {
  getDealDetailsByDealId,
  updateDeal,
  sendForReview,
} from "../../../../endpoints/deal";
import { getValues } from "../../../../endpoints/startup";
import Toast from "../../../Common/Toast";
import SendForReviewModal from "../../../Common/modals/sendForReviewModal";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import Select from "react-select";
import { TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import dayjs for date handling

import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import previous from "../../../../Assets/Images/signup/previous.svg";
import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";

import "../../../../CSS/common/setInnerHTML.css";
const DealDetailsForm = ({ updateDetails }) => {
  const location = useLocation();
  const { dealID } = location.state || {};
  const [fileUrl, setFileUrl] = useState();
  const [companyLogoFile, setCompanyLogoFile] = useState("");
  const token = localStorage.getItem("token");
  const [newhighlight, setNewHighlight] = useState();
  const [newPurpose, setNewPurpose] = useState();
  const [newtag, setNewTag] = useState();
  const [isApplicationReviewd, setIsApplicationReviewd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState("");
  const [sendForReviewModal, setSendForReviewModal] = useState(false);
  const [formData, setFormData] = useState({
    DealID: "",
    OrganizationID: "",
    Equity: "",
    Debt: "",
    FundingRequired: "",
    CompanyLogo: "",
    CompanyWebsite: "",
    Location: "",
    CompanyDescription: "",
    LegalEntityName: "",
    Headquarters: "",
    FoundedYear: "",
    NumberOfEmployees: "",
    PurposeOfFundraising: "",
    LastRoundType: "",
    ExistingKeyInvestors: "",
    CIN: "",
    TaxNumber: "",
    EndMarketFocus: [],
    FundingType: [],
    IndustryVerticals: [],
    Title: "",
    SubHeading: "",
    Description: "",
    KeyHighlights: "",
    mrrGrowth: "",
    grossMargin: "",
    monthlyBurn: "",
    IOIBids: "",
    InvestorsNum: "",
    OwnerName: "",
    FinancialSubHeading: "",
    arr: "",
    Techstack: "",
    KeyMetrics: {},
    DealTags: [],
    CalendlyLink: "",
    ElevatorPitch: "",

    dealType: "",
    timeline: "",
    FundingRequired: "",
    debtFundingRequired: "",
    RoundType: [],
    debtType: "",
    term: "",
    preferredInvestors: "",

    equityType: "",
    pricedEquity: "",
    StakeOffered: "",
    MinPostMoneyValuation: "",
    nonPricedEquity: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    softCommitments: "",
    TotalFundingRaised: "",
    debtAmountRaised: "",
    coInvestors: "",
    contactedInvestorNames: "",

    DataAvailabilityWithFundrev: {
      Analytics: false,
      Financial: false,
      Dataroom: false,
      ForecastModel: false,
      ManagementPresentation: false,
    },
    fundingRounds: [
      {
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
      },
    ],
  });
  const [industryVerticalOptions, setIndustryVerticalOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [roundTypeOptions, setRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [lastRoundTypeOptions, setLastRoundTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [endMarketFocusOptions, setEndMarketFocusOptions] = useState([
    {
      value: "B2B",
      label: "B2B",
    },
    {
      value: "B2C",
      label: "B2C",
    },
    {
      value: "B2B2C",
      label: "B2B2C",
    },
    {
      value: "D2C",
      label: "D2C",
    },
  ]);
  const [fundingTypeOptions, setFundingTypeOptions] = useState([
    {
      value: "VC Backed",
      label: "VC Backed",
    },
    {
      value: "Angel Backed",
      label: "Angel Backed",
    },
    {
      value: "Bootstrapped",
      label: "Bootstrapped",
    },
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        placeholder: "date",
        Cell: InputCell,
      },
      {
        Header: "Round",
        accessor: "round",
        placeholder: "round",
        Cell: InputCell,
      },
      {
        Header: "Raised (₹Cr)",
        accessor: "raised",
        placeholder: "raised",
        Cell: InputCell,
      },
      {
        Header: "% Stake",
        accessor: "stake",
        placeholder: "stake",
        Cell: InputCell,
      },
      {
        Header: "Valuation (₹Cr)",
        accessor: "valuation",
        placeholder: "valuation",
        Cell: InputCell,
      },
      {
        id: "delete",
        Cell: ({ row }) =>
          row.index > 0 && (
            <RiDeleteBin6Line
              onClick={() => deleteRow(row.index)}
              className="red-bin"
            />
          ),
        width: 50,
      },
    ],
    []
  );
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addFieldToKeyMetrics();
    }
  };
  const [data, setData] = useState(formData && formData.fundingRounds);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const fileURL = URL.createObjectURL(file);
      setFileUrl(fileURL);
      setCompanyLogoFile(file);
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only).",
        "info",
        "dealDetailsEdit"
      );
    }
  }
  async function fetchDealDetails() {
    try {
      const response = await getDealDetailsByDealId(dealID, token);
      if (!response) {
        Toast("failed to fetch details", "error", "dealDetailsEdit");
      }
      const parsedDetails = response.data.fundingRounds.map((detail) =>
        JSON.parse(detail)
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        DealID: response.data.DealID,
        OrganizationID: response.data.OrganizationID || "",

        Equity: response.data.Equity || "",
        Debt: response.data.Debt || "",
        CompanyLogo: response.data.CompanyLogo || "",
        CompanyWebsite: response.data.CompanyWebsite || "",
        CompanyDescription: response.data.CompanyDescription || "",
        LegalEntityName: response.data.LegalEntityName || "",
        Headquarters: response.data.Headquarters || "",
        FoundedYear: response.data.FoundedYear || "",
        NumberOfEmployees: response.data.NumberOfEmployees || "",
        LastRoundType: response.data.LastRoundType || "",
        ExistingKeyInvestors: response.data.ExistingKeyInvestors || "",
        CIN: response.data.CIN || "",
        TaxNumber: response.data.TaxNumber || "",
        FinancialSubHeading: response.data.FinancialSubHeading || "",
        arr: response.data.arr,
        mrrGrowth: response.data.mrrGrowth,
        grossMargin: response.data.grossMargin,
        monthlyBurn: response.data.monthlyBurn,
        Techstack: response.data.Techstack || "",
        CalendlyLink: response.data.CalendlyLink || "",
        ElevatorPitch: response.data.ElevatorPitch || "",
        KeyMetrics: response.data.KeyMetrics || {},

        dealType: response.data.dealType || "",
        timeline: response.data.timeline || "",
        FundingRequired: response.data.FundingRequired || "",
        debtFundingRequired: response.data.debtFundingRequired || "",
        RoundType:
          response.data?.RoundType.map((detail) => JSON.parse(detail))[0] || [],
        debtType: response.data.debtType || "",
        term: response.data.term || "",
        preferredInvestors:
          response.data?.preferredInvestors.map((detail) =>
            JSON.parse(detail)
          )[0] || [],

        equityType: response.data.equityType || "",
        pricedEquity: response.data.pricedEquity || "",
        StakeOffered: response.data.StakeOffered || "",
        MinPostMoneyValuation: response.data.MinPostMoneyValuation || "",
        nonPricedEquity: response.data.nonPricedEquity || "",
        interestRate: response.data.interestRate || "",
        valuationCap: response.data.valuationCap || "",
        discountRate: response.data.discountRate || "",

        softCommitments: response.data.softCommitments || false,
        TotalFundingRaised: response.data.TotalFundingRaised || "",
        debtAmountRaised: response.data.debtAmountRaised || "",
        coInvestors: response.data.coInvestors || "",
        contactedInvestorNames: response.data.contactedInvestorNames || "",

        DataAvailabilityWithFundrev: {
          Analytics:
            response.data.DataAvailabilityWithFundrev?.Analytics === "true"
              ? true
              : false,
          Financial:
            response.data?.DataAvailabilityWithFundrev?.Financial === "true"
              ? true
              : false,
          Dataroom:
            response.data?.DataAvailabilityWithFundrev?.Dataroom === "true"
              ? true
              : false,
          ForecastModel:
            response.data?.DataAvailabilityWithFundrev?.ForecastModel === "true"
              ? true
              : false,
          ManagementPresentation:
            response.data.DataAvailabilityWithFundrev
              ?.ManagementPresentation === "true"
              ? true
              : false,
        },
        KeyHighlights: response.data.KeyHighlights || "",
        DealTags:
          response.data.DealTags.map((detail) => JSON.parse(detail))[0] || [],
        PurposeOfFundraising: response.data.PurposeOfFundraising || "",
        IndustryVerticals:
          response.data?.IndustryVerticals.map((detail) =>
            JSON.parse(detail)
          )[0] || [],
        EndMarketFocus:
          response.data?.EndMarketFocus.map((detail) =>
            JSON.parse(detail)
          )[0] || [],
        FundingType:
          response.data?.FundingType.map((detail) => JSON.parse(detail))[0] ||
          [],

        fundingRounds:
          parsedDetails.length > 0
            ? parsedDetails[0]
            : [
                {
                  fund: "",
                  vintage: "",
                  investments: "",
                  aum: "",
                },
              ],
      }));
      if (parsedDetails.length > 0) {
        setData(parsedDetails[0]);
      }
      setIsApplicationReviewd(response.data?.isApplicationReviewed);
      setIsOpen(response.data?.isOpen);
    } catch (err) {}
  }
  useEffect(() => {
    if (dealID && token) {
      fetchDealDetails();
    }
  }, [dealID, updateDetails]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await getValues();
        if (result.error) {
          console.error("Error fetching data:", result.error);
        } else {
          // Process each options set
          if (result.data[0]) {
            if (result.data[0].IndustryVerticalOptions) {
              const formattedIndustryVerticalOptions =
                result.data[0].IndustryVerticalOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setIndustryVerticalOptions(formattedIndustryVerticalOptions);
            }

            if (result.data[0].RoundTypeOptions) {
              const formattedRoundTypeOptions =
                result.data[0].RoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setRoundTypeOptions(formattedRoundTypeOptions);
            }

            if (result.data[0].LastRoundTypeOptions) {
              const formattedLastRoundTypeOptions =
                result.data[0].LastRoundTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setLastRoundTypeOptions(formattedLastRoundTypeOptions);
            }
          }
        }
      } catch (error) {
        console.error("Unexpected error fetching data:", error);
      }
    };

    loadData();
  }, [dealID]);
  async function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const handleKeyMetricsChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      KeyMetrics: {
        ...prevData.KeyMetrics,
        [name]: value,
      },
    }));
  };
  const handleIndustryVerticalChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setFormData((prev) => ({
      ...prev,
      IndustryVerticals: selectedValues,
    }));
  };
  const handleMultiSelectChange = (selectedOption, fieldName) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setFormData((prev) => ({
      ...prev,
      [fieldName]: selectedValues,
    }));
  };

  const handleSingleSelectChange = (selected, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: selected.value,
    }));
  };
  const handleEndMarketChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      EndMarketFocus: selectedValues,
    });
  };
  const handleFundingTypeChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setFormData({
      ...formData,
      FundingType: selectedValues,
    });
  };
  const handleKeyHighlightsChange = () => {
    const highlights = formData.KeyHighlights;
    highlights.push(newhighlight);
    setFormData((prev) => ({
      ...prev,
      KeyHighlights: highlights,
    }));
    setNewHighlight("");
  };
  const handleDealTagsChange = () => {
    const tags = formData.DealTags;
    tags.push(newtag);
    setFormData((prev) => ({
      ...prev,
      DealTags: tags,
    }));
    setNewTag("");
  };
  const handlePurposeChange = () => {
    const purposes = formData.PurposeOfFundraising;
    purposes.push(newPurpose);
    setFormData((prev) => ({
      ...prev,
      PurposeOfFundraising: purposes,
    }));
    setNewPurpose("");
  };
  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const deleteHighlight = (indexToDelete) => {
    setFormData((oldData) => {
      const updatedKeyHighlights = oldData.KeyHighlights.filter(
        (_, index) => index !== indexToDelete
      );
      return {
        ...oldData,
        KeyHighlights: updatedKeyHighlights,
      };
    });
  };
  const deleteDealTag = (indexToDelete) => {
    setFormData((oldData) => {
      const updatedDealTags = oldData.DealTags.filter(
        (_, index) => index !== indexToDelete
      );
      return {
        ...oldData,
        DealTags: updatedDealTags,
      };
    });
  };
  const deletePurpose = (indexToDelete) => {
    setFormData((oldData) => {
      const updatedPurposes = oldData.PurposeOfFundraising.filter(
        (_, index) => index !== indexToDelete
      );
      return {
        ...oldData,
        PurposeOfFundraising: updatedPurposes,
      };
    });
  };
  const addNew = () => {
    setData((oldData) => {
      // Validate last row data before adding a new row
      const lastRow = oldData[oldData.length - 1];
      if (
        !lastRow.date ||
        !lastRow.round ||
        !lastRow.raised ||
        !lastRow.stake ||
        !lastRow.valuation
      ) {
        Toast(
          "Please fill in all fields of the last row before adding a new one.",
          "info",
          "dealDetailsEdit"
        );
        return oldData; // Return the existing data without adding a new row if validation fails
      }

      // If validation is successful, add a new row
      return [
        ...oldData,
        { date: "", round: "", raised: "", stake: "", valuation: "" },
      ];
    });
  };

  const deleteRow = (rowIndex) => {
    setData((currentData) => {
      if (currentData.length > 1) {
        setFormData((data) => {
          const updatedFundingRounds = data.fundingRounds.filter(
            (_, index) => index !== rowIndex
          );
          return {
            ...data,
            FundingRounds: updatedFundingRounds,
          };
        });

        return currentData.filter((_, index) => index !== rowIndex);
      }
      return currentData;
    });
  };
  const handleSave = async () => {
    const formDataInput = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== undefined) {
        if (
          typeof formData[key] === "object" &&
          !(formData[key] instanceof File)
        ) {
          formDataInput.append(key, JSON.stringify(formData[key]));
        } else {
          formDataInput.append(key, formData[key]);
        }
      }
    }
    if (companyLogoFile) {
      formDataInput.append("CompanyLogo", companyLogoFile);
    }
    try {
      const response = await updateDeal(formDataInput, token);
      if (response.data) {
        Toast("Data saved successfully", "success", "dealDetailsEdit");
      } else {
        Toast("Failed to save the data", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to save the data", "error", "dealDetailsEdit");
    }
  };
  useEffect(() => {
    const updatedFundingRounds = data.map((row) => {
      return row;
    });

    setFormData((prev) => ({
      ...prev,
      fundingRounds: updatedFundingRounds,
    }));
  }, [data]);
  const handleDataAvailability = (field, value) => {
    if (value === 1) {
      setFormData((prev) => ({
        ...prev,
        DataAvailabilityWithFundrev: {
          ...prev.DataAvailabilityWithFundrev,
          [field]: true,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        DataAvailabilityWithFundrev: {
          ...prev.DataAvailabilityWithFundrev,
          [field]: false,
        },
      }));
    }
  };
  const addFieldToKeyMetrics = () => {
    if (field.trim()) {
      setFormData((prevData) => ({
        ...prevData,
        KeyMetrics: {
          ...prevData.KeyMetrics,
          [field]: "",
        },
      }));
      setField("");
    }
  };
  const deleteKeyMetric = (key) => {
    setFormData((prevData) => {
      const updatedMetrics = { ...prevData.KeyMetrics };
      delete updatedMetrics[key];
      return {
        ...prevData,
        KeyMetrics: updatedMetrics,
      };
    });
  };
  const sendEmail = async () => {
    try {
      const startupID = formData.OrganizationID;
      const data = {
        startupId: startupID,
        dealId: dealID,
      };
      const response = await sendForReview(data, token);
      if (response.data.message === "Sent for review") {
        Toast(
          "Data sent for review successfully",
          "success",
          "dealDetailsEdit"
        );
        setIsApplicationReviewd(false);
        setSendForReviewModal(false);
        fetchDealDetails();
      } else {
        Toast("Failed to send data for review", "error", "dealDetailsEdit");
      }
    } catch (err) {
      Toast("Failed to send data for review", "error", "dealDetailsEdit");
    }
  };
  const renderKeyMetricsRows = (groupSize) => {
    const keyMetrics = Object.entries(formData?.KeyMetrics || {});

    const rows = [];
    for (let i = 0; i < keyMetrics.length; i += groupSize) {
      const group = keyMetrics.slice(i, i + groupSize);
      const emptyFieldsCount = groupSize - group.length;

      rows.push(
        <div key={i} className="input-div">
          {group.map(([key, value], index) => (
            <FormControl
              key={index}
              className="form-control investorDetails-row-input"
              required
              size="lg"
              color="primary"
            >
              <FormLabel className="label">
                {key}
                <RiDeleteBin6Line
                  className="red-bin"
                  onClick={() => {
                    deleteKeyMetric(key);
                  }}
                />
              </FormLabel>

              <Input
                className="input"
                placeholder={`Add value of ${key}`}
                color="neutral"
                name={key}
                type="text"
                value={value}
                onChange={handleKeyMetricsChange}
                required
              />
            </FormControl>
          ))}
          {Array.from({ length: emptyFieldsCount }).map((_, index) => (
            <FormControl
              key={`empty-${index}`}
              className="form-control investorDetails-row-input random-input"
            >
              <Input style={{ visibility: "hidden" }} className="input" />
            </FormControl>
          ))}
        </div>
      );
    }

    return rows;
  };
  return (
    <div
      className="investorDetails dealDetailsEdit"
      style={{
        margin: "0px 0px 0px 0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 20px)",
      }}
    >
      <ToastContainer position="top-center" containerId="dealDetailsEdit" />
      <SendForReviewModal
        open={sendForReviewModal}
        setOpen={setSendForReviewModal}
        sendEmail={sendEmail}
      />
      <div
        className="admin-dealdetailsedit-box"
        style={{ margin: "0px", paddingBottom: "100px" }}
      >
        <div className="admin-company-overview fundrev-card">
          <div className="add-member-div">
            <div>
              <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                Company Details
              </p>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div className="add-row OrgId">
                Org. ID: {formData.OrganizationID}{" "}
              </div>
              <div className="add-row OrgId">Deal. ID: {formData.DealID} </div>
            </div>
          </div>
          <div class="upload-btn-wrapper">
            <button class="upload-img-btn">
              <img
                src={
                  !companyLogoFile
                    ? formData.CompanyLogo
                      ? formData.CompanyLogo
                      : uploadImg
                    : fileUrl
                }
                className="InvestorLogo"
                alt="Upload"
                style={{ cursor: "pointer" }}
              />
            </button>

            <label htmlFor="company-logo" className="edit-img-btn">
              <img src={editImg} className="editImg" alt="Edit" />
            </label>

            <input
              id="company-logo"
              type="file"
              name="image"
              accept="image/png, image/jpeg"
              style={{ cursor: "pointer" }}
              onChange={(e) => handleImageUpload(e)}
            />
          </div>
          <form className="input-section">
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Company Name</FormLabel>
                <Input
                  className="input"
                  placeholder="Amazon"
                  color="neutral"
                  name="CompanyName"
                  type="text"
                  value={formData.CompanyName}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Legal Entity Name</FormLabel>
                <Input
                  className="input"
                  placeholder="Amazon Inc."
                  color="neutral"
                  name="LegalEntityName"
                  type="text"
                  value={formData.LegalEntityName}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Headquarters</FormLabel>
                <Input
                  className="input"
                  placeholder="Bengaluru, India"
                  color="neutral"
                  name="Headquarters"
                  type="text"
                  value={formData.Headquarters}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Founded Year</FormLabel>
                <Input
                  className="input"
                  placeholder="2018"
                  color="neutral"
                  name="FoundedYear"
                  type="number"
                  value={formData.FoundedYear}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Number of Employees</FormLabel>
                <Input
                  className="input"
                  placeholder="1000"
                  color="neutral"
                  name="NumberOfEmployees"
                  type="number"
                  value={formData.NumberOfEmployees}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Company Website</FormLabel>
                <Input
                  className="input"
                  placeholder="www.amazon.com"
                  color="neutral"
                  name="CompanyWebsite"
                  type="url"
                  value={formData.CompanyWebsite}
                  required
                  onChange={handleInputChange}
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Industry Verticals</FormLabel>

                <Select
                  // className="input"
                  isMulti
                  className="react-select-input"
                  options={industryVerticalOptions}
                  name="industryVerticals"
                  onChange={handleIndustryVerticalChange}
                  value={
                    formData.IndustryVerticals
                      ? industryVerticalOptions.filter((option) =>
                          formData.IndustryVerticals.includes(option.value)
                        )
                      : []
                  }
                  placeholder="Please Select"
                  required
                  styles={customStyles}
                  isClearable={true}
                  isSearchable={true}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">End Market Focus</FormLabel>
                <Select
                  className="react-select-input"
                  options={endMarketFocusOptions}
                  placeholder="Please Select"
                  required
                  isMulti
                  styles={inputCustomStyles}
                  value={
                    formData.EndMarketFocus
                      ? endMarketFocusOptions.filter((option) =>
                          formData.EndMarketFocus.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleEndMarketChange}
                  isClearable={true}
                  isSearchable={true}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Funding Type</FormLabel>
                <Select
                  className="react-select-input"
                  options={fundingTypeOptions}
                  placeholder="Please Select"
                  required
                  isMulti
                  styles={inputCustomStyles}
                  value={
                    formData.FundingType
                      ? fundingTypeOptions.filter((option) =>
                          formData.FundingType.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleFundingTypeChange}
                  isClearable={true}
                  isSearchable={true}
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">ERP Platform Used</FormLabel>

                <Input
                  className="input"
                  placeholder="Epicore"
                  color="neutral"
                  name="ErpPlatform"
                  type="text"
                  value={formData.ErpPlatform}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="text"
              >
                <FormLabel className="label">Owner Name</FormLabel>
                <Input
                  className="input"
                  placeholder="Bill gates"
                  color="neutral"
                  name="OwnerName"
                  type="text"
                  value={formData.OwnerName}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Tech Stack</FormLabel>
                <Input
                  className="input"
                  placeholder="ABCTY1234D"
                  color="neutral"
                  name="Techstack"
                  type="text"
                  value={formData.Techstack}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">CIN/ LLPIN</FormLabel>
                <Input
                  className="input"
                  placeholder="U45690TN0983PTC567904"
                  color="neutral"
                  name="CIN"
                  type="text"
                  value={formData.CIN}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Tax Identification Number
                </FormLabel>
                <Input
                  className="input"
                  placeholder="ABCTY1234D"
                  color="neutral"
                  name="TaxNumber"
                  type="text"
                  value={formData.TaxNumber}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl className="form-control investorDetails-row-input random-input">
                <Input style={{ visibility: "hidden" }} className="input" />
              </FormControl>
            </div>
            <div className="input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Business Description (Visible after request accepted to
                  investor)
                </FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Describe your business"
                  name="CompanyDescription"
                  type="text"
                  value={formData.CompanyDescription}
                  onChange={handleInputChange}
                  required
                  multiline
                  minRows={3}
                />
              </FormControl>
            </div>
            <p>Rendered Business description</p>
            <div
              className="setInnerHTML"
              dangerouslySetInnerHTML={{ __html: formData.CompanyDescription }}
            />
          </form>
        </div>

        <div className="admin-Funding-details-div">
          <div className="admin-deal-details fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Deal Details
            </p>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Deal Type</FormLabel>
                <Select
                  // className="input"
                  className="react-select-input"
                  options={dealTypeOptions}
                  name="dealType"
                  value={formData.dealType}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "dealType")
                  }
                  placeholder={
                    formData.dealType ? formData.dealType : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Timeline to raise</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formData.timeline ? dayjs(formData.timeline) : null}
                    onChange={(newDate) => {
                      setFormData((prev) => ({
                        ...prev,
                        timeline: newDate ? newDate.format("MM/DD/YYYY") : "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className="custom-date-input"
                        variant="outlined"
                        label={"Timeline to raise"}
                        placeholder="mm/dd/yyyy"
                        InputProps={{
                          ...params.InputProps,
                          placeholder: "mm/dd/yy",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Funding Required (₹Cr)</FormLabel>
                <Input
                  className="input"
                  placeholder="11.1"
                  color="neutral"
                  name="FundingRequired"
                  type="number"
                  value={formData.FundingRequired}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Debt Funding Required (₹Cr)
                </FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="debtFundingRequired"
                  type="number"
                  value={formData.debtFundingRequired}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Round Type</FormLabel>

                <Select
                  // className="input"
                  className="react-select-input"
                  isMulti
                  options={roundTypeOptions}
                  name="roundType"
                  value={
                    formData.RoundType
                      ? roundTypeOptions.filter((option) =>
                          formData.RoundType.includes(option.value)
                        )
                      : []
                  }
                  onChange={(selectedOption) =>
                    handleMultiSelectChange(selectedOption, "roundType")
                  }
                  placeholder={"Please select"}
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Debt Type</FormLabel>
                <Select
                  // className="input"
                  className="react-select-input"
                  options={debtTypeOptions}
                  name="dealType"
                  value={formData.debtType}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "debtType")
                  }
                  placeholder={
                    formData.debtType ? formData.debtType : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
            </div>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Term</FormLabel>
                <Input
                  className="input"
                  placeholder="In months"
                  color="neutral"
                  name="term"
                  type="number"
                  value={formData.term}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Preferred Investors</FormLabel>

                <Select
                  // className="input"
                  className="react-select-input"
                  isMulti
                  options={preferredInvestorsOptions}
                  name="preferredInvestors"
                  value={
                    formData.preferredInvestors
                      ? preferredInvestorsOptions.filter((option) =>
                          formData.preferredInvestors.includes(option.value)
                        )
                      : []
                  }
                  onChange={(selectedOption) =>
                    handleMultiSelectChange(
                      selectedOption,
                      "preferredInvestors"
                    )
                  }
                  placeholder={"Please select"}
                  required
                  styles={customStyles}
                />
              </FormControl>
            </div>
          </div>
          <div className="admin-expected-valuation fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Expected Valuation
            </p>
            <div className="third-step-third-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Equity Type</FormLabel>
                <Select
                  // className="input"
                  className="react-select-input"
                  options={equityTypeOptions}
                  name="equityType"
                  value={formData.equityType}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "equityType")
                  }
                  placeholder={
                    formData.equityType ? formData.equityType : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Select instrument type (priced)
                </FormLabel>
                <Select
                  // className="input"
                  className="react-select-input"
                  options={pricedEquityOptions}
                  name="pricedEquity"
                  value={formData.pricedEquity}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "pricedEquity")
                  }
                  placeholder={
                    formData.pricedEquity
                      ? formData.pricedEquity
                      : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Select instrument type (non-priced)
                </FormLabel>
                <Select
                  // className="input"
                  className="react-select-input"
                  options={nonPricedEquityOptions}
                  name="pricedEquity"
                  value={formData.nonPricedEquity}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "nonPricedEquity")
                  }
                  placeholder={
                    formData.nonPricedEquity
                      ? formData.nonPricedEquity
                      : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
            </div>
            <div className="third-step-third-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Interest rate</FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="interestRate"
                  type="number"
                  value={formData.interestRate}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Valuation Cap</FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="valuationCap"
                  type="number"
                  value={formData.valuationCap}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Discount rate</FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="discountRate"
                  type="number"
                  value={formData.discountRate}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Stake Offered %</FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="StakeOffered"
                  type="number"
                  value={formData.StakeOffered}
                  onChange={handleInputChange}
                  required
                />
                <div>
                  {formData.Equity && formData.StakeOffered && (
                    <p style={{ marginTop: "10px" }}>
                      Post money valuation (₹Cr) :
                      {(
                        (formData.Equity / formData.StakeOffered) *
                        100
                      ).toFixed(2)}
                    </p>
                  )}
                </div>
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Min. Post Money Valuation (₹Cr)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="0.12"
                  color="neutral"
                  name="MinPostMoneyValuation"
                  type="number"
                  value={formData.MinPostMoneyValuation}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl className="form-control investorDetails-row-input random-input">
                <Input style={{ visibility: "hidden" }} className="input" />
              </FormControl>
            </div>
          </div>
          <div className="admin-deal-interest fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Deal Interest
            </p>
            <div className="third-step-first-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Amount Raised (₹Cr) (full or equity)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="0.12"
                  color="neutral"
                  name="TotalFundingRaised"
                  type="number"
                  value={formData.TotalFundingRaised}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Debt amount raised</FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="debtAmountRaised"
                  type="number"
                  value={formData.debtAmountRaised}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Lead/Co-Investors</FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="coInvestors"
                  type="text"
                  value={formData.coInvestors}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="third-step-third-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Soft Commitments</FormLabel>
                <div className="flex gap-[24px] mt-[16px]">
                  <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                    <input
                      type="checkbox"
                      name="softCommitments"
                      checked={formData.softCommitments === true}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          softCommitments: true,
                        })
                      }
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                    />
                    Yes
                  </label>
                  <label className="flex gap-[8px] text-[#8692A6] font-semibold">
                    <input
                      type="checkbox"
                      name="softCommitments"
                      checked={formData.softCommitments === false}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          softCommitments: false,
                        })
                      }
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                    />
                    No
                  </label>
                </div>
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  List Current Investor Contacts
                </FormLabel>
                <Input
                  className="input"
                  placeholder="contactedInvestorNames"
                  color="neutral"
                  name="investor"
                  type="text"
                  value={formData.contactedInvestorNames}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl className="form-control investorDetails-row-input random-input">
                <Input style={{ visibility: "hidden" }} className="input" />
              </FormControl>
            </div>
          </div>
          <div className="admin-purpose fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Purpose of fundraising
            </p>
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">Purpose of Fundraising</FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Expansion of company"
                  type="text"
                  name="PurposeOfFundraising"
                  value={formData.PurposeOfFundraising}
                  onChange={handleInputChange}
                  multiline
                  minRows={3}
                />
              </FormControl>
            </div>
            <p>Rendered Purpose of Fundraising</p>
            <div
              className="setInnerHTML"
              dangerouslySetInnerHTML={{
                __html: formData.PurposeOfFundraising,
              }}
            />
          </div>
          <div className="admin-purpose fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Historical rounds
            </p>
            <div className="third-step-third-row">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Last Round Type</FormLabel>

                <Select
                  // className="input"
                  className="react-select-input"
                  options={lastRoundTypeOptions}
                  name="LastRoundType"
                  value={formData.LastRoundType}
                  onChange={(selectedOption) =>
                    handleSingleSelectChange(selectedOption, "LastRoundType")
                  }
                  placeholder={
                    formData.LastRoundType
                      ? formData.LastRoundType
                      : "Please Select"
                  }
                  required
                  styles={customStyles}
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">Key Investors</FormLabel>
                <Input
                  className="input"
                  placeholder="Aman Gputa, Peeyush Bansal"
                  color="neutral"
                  name="ExistingKeyInvestors"
                  type="text"
                  value={formData.ExistingKeyInvestors}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="third-step-fourth-row">
              <div className="investmentDetails-table">
                <FormControl required>
                  <FormLabel className="label">Funding Rounds</FormLabel>
                </FormControl>
                <div className="table-details">
                  <Editable
                    columns={columns}
                    data={data}
                    updateData={updateData}
                    deleteRow={deleteRow}
                  />
                  <div className="table-edit-buttons">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        addNew();
                      }}
                      className="add-row"
                    >
                      <AiOutlinePlus />
                      &nbsp;&nbsp; Add Row
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-key-metrics fundrev-card">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
                Key Metrics
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <Input
                  placeholder="field"
                  type="text"
                  name="field"
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <Button onClick={addFieldToKeyMetrics}>Add field</Button>
              </div>
            </div>
            {renderKeyMetricsRows(3)}
          </div>
        </div>
        <div className="noName-details-div">
          <div className="admin-noName-details fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
              No_name details
            </p>
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">
                  Heading (title - Write in less than 60 characters)
                </FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Enter your title here..."
                  name="Title"
                  type="text"
                  value={formData.Title}
                  onChange={handleInputChange}
                  multiline
                  minRows={1}
                />
              </FormControl>
            </div>
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">
                  Sub Heading (Traction - Write in less tahn 120 characters )
                </FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Enter your title here..."
                  name="SubHeading"
                  type="text"
                  value={formData.SubHeading}
                  onChange={handleInputChange}
                  multiline
                  minRows={1}
                />
              </FormControl>
            </div>
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">Company Description</FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Enter your description here..."
                  name="Description"
                  type="text"
                  value={formData.Description}
                  onChange={handleInputChange}
                  multiline
                  minRows={3}
                />
              </FormControl>
            </div>
            <p>Rendered Company Description</p>
            <div
              className="setInnerHTML"
              dangerouslySetInnerHTML={{ __html: formData.Description }}
            />
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">Key highlights</FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Enter your key highlights..."
                  name="KeyHighlights"
                  type="text"
                  value={formData.KeyHighlights}
                  onChange={handleInputChange}
                  multiline
                  minRows={3}
                />
              </FormControl>
            </div>
            <p>Rendered Key Highlights</p>
            <div
              className="setInnerHTML"
              dangerouslySetInnerHTML={{ __html: formData.KeyHighlights }}
            />
            <div className="third-step-second-row input-div companyDetails-business-desc-div">
              <FormControl
                className="business-desc-form-control"
                size="lg"
                color="primary"
                required
              >
                <FormLabel className="label">Deal tags</FormLabel>
                <TextField
                  className="large-input"
                  placeholder="Enter your tag here..."
                  name="purpose"
                  type="text"
                  value={newtag}
                  onChange={(e) => setNewTag(e.target.value)}
                  multiline
                  minRows={1}
                />
              </FormControl>
            </div>
            <div className="table-edit-buttons">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleDealTagsChange();
                }}
                className="add-row"
              >
                <AiOutlinePlus />
                &nbsp;&nbsp; Add tag
              </button>
            </div>
            <div className="hightlights-div">
              <b>Deal tags</b>
              <ol style={{ listStyleType: "decimal" }}>
                {formData.DealTags.map((tag, index) => (
                  <li key={index} style={{ textWrap: "no-wrap" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>{tag}</p>
                      <RiDeleteBin6Line
                        className="red-bin"
                        onClick={() => deleteDealTag(index)}
                      />
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>

          <div className="calculated-fields-div fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
              Calculated Fields
            </p>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                sx={{
                  width: "50%",
                }}
              >
                <FormLabel className="label">
                  Financials Sub Heading Text
                </FormLabel>
                <Input
                  className="input"
                  placeholder=""
                  color="neutral"
                  name="FinancialSubHeading"
                  type="text"
                  value={formData.FinancialSubHeading}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  ARR (mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="0.12"
                  color="neutral"
                  name="arr"
                  type="text"
                  value={formData.arr}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="text"
              >
                <FormLabel className="label">
                  MRR growth (don't mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="43L"
                  color="neutral"
                  name="mrrGrowth"
                  value={formData.mrrGrowth}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Gross Margin (Don't mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="14L"
                  color="neutral"
                  name="grossMargin"
                  type="text"
                  value={formData.grossMargin}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
              >
                <FormLabel className="label">
                  Monthly Burn (Don't mention with units)
                </FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="monthlyBurn"
                  type="text"
                  value={formData.monthlyBurn}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="number"
              >
                <FormLabel className="label">Term Sheets (in Crores)</FormLabel>
                <Input
                  className="input"
                  placeholder="18"
                  color="neutral"
                  name="IOIBids"
                  type="number"
                  value={formData.IOIBids}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="number"
              >
                <FormLabel className="label">InvestorNumber</FormLabel>
                <Input
                  className="input"
                  placeholder="10"
                  color="neutral"
                  name="InvestorsNum"
                  type="text"
                  value={formData.InvestorsNum}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
          </div>
          <div className="DataAvailabilityWithFundrev-div fundrev-card">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)] ">
              Data Availability With Fundrev
            </p>
            <div className="input-div">
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Analytics
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.Analytics
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Analytics", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.Analytics
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Analytics", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Financial
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.Financial
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Financial", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.Financial
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Financial", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  Dataroom
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.Dataroom
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Dataroom", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.Dataroom
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("Dataroom", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
            </div>
            <div className="input-div">
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  ForecastModel
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev?.ForecastModel
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("ForecastModel", 1)}
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev?.ForecastModel
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() => handleDataAvailability("ForecastModel", 0)}
                  >
                    NO
                  </button>
                </div>
              </FormControl>
              <FormControl
                required
                color="primary"
                className="DataAvailability-child-div"
              >
                <FormLabel className="label" required>
                  ManagementPresentation
                </FormLabel>
                <div className="yes-or-no-button-div">
                  <button
                    className={`yes-or-no-button yes-button ${
                      formData.DataAvailabilityWithFundrev
                        ?.ManagementPresentation
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() =>
                      handleDataAvailability("ManagementPresentation", 1)
                    }
                  >
                    YES
                  </button>
                  <button
                    className={`yes-or-no-button ${
                      !formData.DataAvailabilityWithFundrev
                        ?.ManagementPresentation
                        ? "selected-button"
                        : ""
                    }`}
                    onClick={() =>
                      handleDataAvailability("ManagementPresentation", 0)
                    }
                  >
                    NO
                  </button>
                </div>
              </FormControl>
            </div>
          </div>
          <div className="admin-links fundrev-card mt-4">
            <p className="fundrev-card-heading !font-[400] !text-[var(--Primary-Text-Color)]">
              Links
            </p>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="text"
              >
                <FormLabel className="label">Calendly Link</FormLabel>
                <Input
                  className="input"
                  placeholder="Paste link here"
                  color="neutral"
                  name="CalendlyLink"
                  type="text"
                  value={formData.CalendlyLink}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <FormControl
                className="form-control investorDetails-row-input"
                required
                size="lg"
                color="primary"
                type="text"
              >
                <FormLabel className="label">Elevator Pitch Link</FormLabel>
                <Input
                  className="input"
                  placeholder="Paste link here"
                  color="neutral"
                  name="ElevatorPitch"
                  type="text"
                  value={formData.ElevatorPitch}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-buttons">
        <button className="previousStep" style={{ visibility: "hidden" }}>
          <img src={previous} alt="back-arrow" />
          &nbsp;&nbsp; Previous Step
        </button>
        <div className="next-buttons">
          <CustomBlackBtn onClick={handleSave} filled={false} text="save" />
          {isOpen ? (
            <div className="stage-status-btn">Deal live</div>
          ) : isApplicationReviewd ? (
            <div className="stage-status-btn">Sent for review</div>
          ) : (
            <CustomBlackBtn
              text="Send for review"
              onClick={() => {
                setSendForReviewModal(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DealDetailsForm;
