import React, { useState, useEffect } from "react";
import { StyledFormControl, StyledFormLabel } from "../../Common/functions";

import CustomInput from "../../Common/components/custom/customInput";
import CustomPhoneInput from "../../Common/components/custom/customPhoneInput";
import CustomPasswordInput from "../../Common/components/custom/customPasswordInput";
import CustomSelect from "../../Common/components/custom/customSelect";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
import { useLoader } from "../../Common/LoaderProvider";
import Toast from "../../Common/Toast";

import {
  fetchInvestorDetails,
  fetchMembers,
  memberSignUp,
} from "../../../endpoints/investor";

import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { matchIsValidTel } from "mui-tel-input";

import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import Back from "../../../Assets/Images/signup/back.svg";

import "../../../CSS/Signup/signup.css";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import LeftSection from "../../SignUp/LeftSection";
// import Header from "../../LandingPage/header";
// import InvestorRegisterBlock from "./investorRegisterBlock";
// import { ToastContainer } from "react-toastify";

const InvestorRegister = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const { id } = useParams();
  const [token, setToken] = useState();
  const [name, setName] = useState();
  const [currentPage, setCurrentPage] = useState("");
  const loader = useLoader();
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    investorType: Yup.string()
      .required("Investor Type is required")
      .test(
        "is-not-default",
        "Investor Type is required",
        (value) => value !== ""
      ),
    firmName: Yup.string().required("Investment Firm Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const validationSchema2 = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const InvestorTypeOptions = [
    { value: "Private Equity", label: "Private Equity" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Family Office", label: "Family Office" },
    { value: "Corporate", label: "Corporate" },
    { value: "HNI/Angel Investor", label: "HNI/Angel Investor" },
    { value: "Others", label: "Others" },
  ];
  const initialValues = {
    fullName: "",
    phoneNumber: "",
    investorType: "",
    firmName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };

  const [investorData, setInvestorData] = useState();

  const [memberData, setMemberData] = useState({
    name: "",
    organizationId: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const handleSubmit = async (values) => {
    // if (props.startPoint === "noNameDeals") {
    //   ReactGA.event({
    //     category: "Sign Up",
    //     action: "button_click",
    //     label: "Investor Sign Up Button From No Name Deals",
    //   });
    //   mixpanel.track("Sign Up From No Name Deal", {
    //     name: finalFormData?.name,
    //     investmentFirmName: finalFormData?.companyName,
    //   });
    // }
    if (currentPage === "investorMemberSignup") {
      // Update memberData with the new values
      const newMemberData = {
        email: memberData.email,
        name: values.fullName,
        phoneNumber: values.phoneNumber,
        password: values.password,
      };

      // Optionally set the new member data in state if needed
      setMemberData((prevState) => ({
        ...prevState,
        ...newMemberData, // Merging new values
      }));

      try {
        loader.start("Signing up...");
        const response = await memberSignUp(newMemberData); // Use newMemberData directly
        if (response.error) {
          if (response.error.status === 401) {
            Toast(
              "You have already accepted the Terms and Conditions. Redirecting to respective page",
              "info",
              "investorRegister"
            );
            loader.stop();
          }
          setTimeout(() => {
            Navigate("/signIn", {
              state: { sentemail: newMemberData.email, type: "Investor" },
            });
          }, 1000);
        } else {
          localStorage.setItem("token", response.data.data.data.token);
          localStorage.setItem("role", "investor");
          loader.stop();
          if (response.data !== null) {
            Toast(
              "Congratulations you signed up successfully",
              "success",
              "investorRegister"
            );
            Navigate("/investor/acceptTC", {
              state: {
                email: newMemberData.email,
                currentPage: currentPage,
                investorID: newMemberData.organizationId,
              },
            });
            loader.stop();
          } else {
            loader.stop();
            Toast("Error in signUp", "error", "investorRegister");
          }
        }
      } catch (error) {
        loader.stop();
        Toast("An error occurred during signup", "error", "investorRegister");
      }
    } else {
      Navigate("/investor/acceptTC", {
        state: {
          values: values,
        },
      });
    }
  };

  //For fetching data if the member is invited
  useEffect(() => {
    if (location.pathname.startsWith("/investorMemberSignup")) {
      setCurrentPage("investorMemberSignup");
    } else if (location.pathname.startsWith("/investor/Register")) {
      setCurrentPage("investorRegister");
    }

    if (currentPage === "investorMemberSignup") {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      setToken(token);
      const senderName = queryParams.get("name");
      setName(senderName);
      setMemberData((prev) => ({
        ...prev,
      }));

      const fetchDetails = async () => {
        try {
          loader.start("Loading Investor Details...");
          const response = await fetchInvestorDetails(token);
          loader.stop();
          if (response) {
            const investorDetails = response.data.data;
            setInvestorData(investorDetails);

            if (investorDetails) {
              setMemberData((prev) => ({
                ...prev,
                companyName: investorDetails.companyName,
                organizationId: investorDetails.investorID,
              }));
            }
          }
        } catch (error) {
          loader.stop();
        }
      };

      fetchDetails();
    }
  }, [location, currentPage]);

  //Checking if the investor revoked the permission from the invited user
  useEffect(() => {
    const fetchAllMembers = async () => {
      try {
        if (currentPage === "investorMemberSignup") {
          loader.start("Loading Members...");
          const response = await fetchMembers(memberData.organizationId, token);
          loader.stop();
          if (response) {
            const members = response.data.data;

            if (members && members.length > 0) {
              const member = members.find((member) => member._id === id);
              if (member === undefined) {
                Navigate("/investor/revoked");
              } else if (member) {
                setMemberData((prev) => ({
                  ...prev,
                  email: member.email,
                }));
              }
            }
          }
        }
      } catch (error) {
        loader.stop();
      }
    };

    fetchAllMembers();
  }, [memberData.organizationId, token]);
  return (
    <div className="investor-sign-up-page">
      <ToastContainer position="top-center" containerId="investorRegister" />
      <Helmet>
        <title>Fundrev | Investor Register</title>
      </Helmet>
      <ToastContainer position="top-center" containerId={"investorRegister"} />
      <div className="investor-sign-up-page-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <div className="sign-up-block">
        <div className="heading-block">
          <img
            src={Back}
            alt="back"
            className="back-icon phone-design"
            onClick={() => Navigate(-1)}
          />
          <p className="fundrev-heading investor-register-heading">
            {currentPage === "investorMemberSignup" ? (
              <>
                {name} from {memberData.companyName}
                <br /> has invited you to join Fundrev!
              </>
            ) : (
              <>Create your investor account</>
            )}
          </p>
        </div>
        <div className="middle-block">
          <div className="sign-up-left-section">
            <img
              src={Back}
              alt="back"
              className="back-icon"
              onClick={() => Navigate(-1)}
            />
          </div>
          <div className="sign-up-right-block">
            <Formik
              initialValues={initialValues}
              validationSchema={
                currentPage != "investorMemberSignup"
                  ? validationSchema
                  : validationSchema2
              }
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    width: "100%",
                  }}
                >
                  <div className="sign-up-input-fields">
                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Full Name</StyledFormLabel>
                      <CustomInput
                        placeholder="Enter Full Name"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.fullName && errors.fullName)}
                        helperText={touched.fullName && errors.fullName}
                      />
                    </StyledFormControl>

                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Phone Number</StyledFormLabel>
                      <CustomPhoneInput
                        value={values.phoneNumber}
                        onChange={(value) =>
                          setFieldValue("phoneNumber", value)
                        }
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </StyledFormControl>

                    {currentPage !== "investorMemberSignup" ? (
                      <>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Investor Type</StyledFormLabel>
                          <CustomSelect
                            label="Investor Type"
                            name="investorType"
                            value={values.investorType}
                            onChange={handleChange}
                            options={InvestorTypeOptions}
                            placeholder="Select Investor Type"
                            error={Boolean(
                              touched.investorType && errors.investorType
                            )}
                            helperText={
                              touched.investorType && errors.investorType
                            }
                          />
                        </StyledFormControl>

                        <StyledFormControl fullWidth>
                          <StyledFormLabel>
                            Investment Firm Name
                          </StyledFormLabel>
                          <CustomInput
                            placeholder="Enter investment firm name"
                            name="firmName"
                            value={values.firmName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.firmName && errors.firmName)}
                            helperText={touched.firmName && errors.firmName}
                          />
                        </StyledFormControl>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>
                            Official Email Address
                          </StyledFormLabel>
                          <CustomInput
                            placeholder="Enter work email address"
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </StyledFormControl>
                      </>
                    ) : (
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>
                          Official Email Address
                        </StyledFormLabel>
                        <CustomInput
                          placeholder="Enter work email address"
                          name="email"
                          type="email"
                          value={memberData.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.email && errors.email)}
                          disabled
                          helperText={touched.email && errors.email}
                        />
                      </StyledFormControl>
                    )}

                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Create Password</StyledFormLabel>
                      <CustomPasswordInput
                        placeholder="Enter password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        sendStatesToParent={receiveStatesFromChild}
                      />
                    </StyledFormControl>

                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Confirm Password</StyledFormLabel>

                      <CustomPasswordInput
                        placeholder="Re-enter password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        sendStatesToParent={receiveStatesFromChild}
                      />
                    </StyledFormControl>
                    <StyledFormControl
                      className="random-input"
                      style={{ visibility: "hidden" }}
                    >
                      <div>random</div>
                    </StyledFormControl>
                  </div>
                  <div className="sign-up-btn-section laptop-design">
                    <CustomBlackBtn type="submit" text="Continue" />
                    <p>
                      Already have an account?{" "}
                      <Link to="/signin">
                        <span className="link-blue-text">Sign In</span>
                      </Link>
                    </p>
                  </div>

                  <div className="sign-up-btn-section-phone phone-design">
                    <CustomBlackBtnPhone type="submit" text="Continue" />
                    <p>
                      Already have an account?{" "}
                      <Link to="/signin">
                        <span className="link-blue-text">Sign In</span>
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorRegister;
