import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getAllInvestors } from "../../../../endpoints/investor";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

import { useLocation, useNavigate } from "react-router-dom";
import {
  clearDealIdIndustryVerticals,
  getInvestorIdIndustryVerticals,
  setDealToInvestor,
} from "../../../../endpoints/admin";
import Toast from "../../../Common/Toast";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";

import closeIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

const InvestorPanel = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [investors, setInvestors] = useState([]);
  const [fitSelections, setFitSelections] = useState({});
  const token = localStorage.getItem("token");
  const [selectInvestorID, setSelectedInvestorID] = useState();
  const [fit, setFit] = useState("");
  const { dealID, startupID } = location.state;

  useEffect(() => {
    const fetchAllInvestors = async () => {
      try {
        const response = await getAllInvestors(token);
        setInvestors(response.data);
      } catch (error) {}
    };
    fetchAllInvestors();
  }, []);

  useEffect(() => {
    const fetchInvestorDeals = async () => {
      try {
        const response = await getInvestorIdIndustryVerticals(dealID, token);
        const fitMap = {};
        response.data.forEach((investor) => {
          fitMap[investor.investorId] = {
            ideal: investor.ideal,
            moderate: investor.moderate,
            nonFit: investor.nonFit,
          };
        });
        setFitSelections(fitMap);
      } catch (error) {}
    };
    fetchInvestorDeals();
  }, []);

  const handleFitChange = async () => {
    try {
      const response = await setDealToInvestor(
        selectInvestorID,
        dealID,
        fit,
        token
      );
      setFitSelections((prevState) => ({
        ...prevState,
        [selectInvestorID]: {
          ideal: fit === "ideal",
          moderate: fit === "moderate",
          nonFit: fit === "nonFit",
        },
      }));
      Toast("Fit type set successfully!", "success", "investorPanel");
      setConfirmationModalOpen(false);
      setSelectedInvestorID("");
      setFit("");
    } catch (error) {
      Toast("Failed to set fit type.", "error", "investorPanel");
    }
  };

  const handleClearSelection = async (investorId) => {
    try {
      const response = await clearDealIdIndustryVerticals(
        investorId,
        dealID,
        token
      );
      setFitSelections((prevState) => {
        const newState = { ...prevState };
        delete newState[investorId];
        return newState;
      });
      Toast("Fit type cleared successfully!", "success", "investorPanel");
    } catch (error) {
      Toast("Failed to clear fit type.", "error", "investorPanel");
    }
  };

  return (
    <>
      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box"
          style={{ width: "600px", height: "fit-content" }}
        >
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{
              cursor: "pointer",
              position: "fixed",
              right: "20px",
              top: "20px",
            }}
            onClick={() => setConfirmationModalOpen(false)}
          ></img>
          <div className="text-box">
            <div className="heading">
              <p className="heading text-[20px] text-[var(--Primary-Text-Color)]">
                Are you sure?
              </p>
            </div>
            <div className="subheading text-[16px] text-[var(--Secondary-Text-Color)]">
              <p>This will trigger an email to associated investor</p>
            </div>
          </div>

          <div className="btn-box">
            <CustomBlackBtn
              text="Cancel"
              filled={false}
              onClick={() => setConfirmationModalOpen(false)}
            />
            <CustomBlackBtn text="Confirm" onClick={handleFitChange} />
          </div>
        </Box>
      </Modal>
      <ToastContainer position="top-center" containerId="investorPanel" />
      <div className="InvestorSide-box table-container">
        <table>
          <thead>
            <tr>
              <th>Investor Firm Name</th>
              <th>Website</th>
              <th>Investor ID</th>
              <th>Onboarding Completed</th>
              <th>Preview</th>
              <th>Ideal Fit</th>
              <th>Moderate Fit</th>
              <th>Non Fit</th>
              <th>Clear</th>
            </tr>
          </thead>
          <tbody>
            {investors.map((investor) => (
              <tr key={investor.investorId}>
                <td>{investor.companyName}</td>
                <td>
                  <a
                    href={`https:\\${investor.investorWebsite}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {investor.investorWebsite}
                  </a>
                </td>
                <td>{investor.investorID}</td>
                <td>
                  {investor.onBoardingComplete ? "Completed" : "Not Completed"}
                </td>
                <td>
                  <button
                    className="toggleStatusbtn"
                    onClick={() =>
                      navigate("/admin/investor/preview", {
                        state: {
                          investor,
                          startupID,
                          dealID,
                          state: "companies",
                        },
                      })
                    }
                  >
                    Preview
                  </button>
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.ideal ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="ideal"
                    checked={fitSelections[investor.investorID]?.ideal || false}
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("ideal");
                      setConfirmationModalOpen(true);
                    }}
                  />
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.moderate ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="moderate"
                    checked={
                      fitSelections[investor.investorID]?.moderate || false
                    }
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("moderate");
                      setConfirmationModalOpen(true);
                    }}
                  />
                </td>
                <td>
                  {/* {fitSelections[investor.investorID]?.nonFit ? <span>&#8226;</span> : null} */}
                  <input
                    type="radio"
                    name={`fit-${investor.investorID}`}
                    value="non"
                    checked={
                      fitSelections[investor.investorID]?.nonFit || false
                    }
                    onChange={() => {
                      setSelectedInvestorID(investor.investorID);
                      setFit("nonFit");
                      setConfirmationModalOpen(true);
                    }}
                  />
                </td>

                <td>
                  <button
                    onClick={() => handleClearSelection(investor.investorID)}
                    className="download_btn"
                  >
                    Clear
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvestorPanel;
