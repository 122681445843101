import React, { useState, useEffect } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Account from "./account";
// import Profile from './profile'

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { investorState } from '../investorState'

import "../../../../CSS/InvestorPage/Dashboard/account.css";
// import { companyState } from "../companyState";
// import Deals from "./deals";
import { ToastContainer } from "react-toastify";
const CompanyAccountPage = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { activeButton } = location.state || { activeButton: 1 };
  const [activeBtn, setActiveBtn] = useState(activeButton);
  return (
    <div className="InvestorSide">
      <Sidebar />
      <Topbar title="Settings" />
      <ToastContainer position="top-center" containerId="accountPage" />
      <div className="InvestorSide-box" style={{ overflowX: "hidden" }}>
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            {/* <div className="cdetails-first-div header-with-border-bottom">
              <div
                className="typeOfDeals"
                style={{ paddingLeft: "0px", marginTop: "0px" }}
              >
                <button
                  className={`typeOfDeals-btn ${
                    activeBtn === 1 ? "typeOfDeals-active-btn" : ""
                  }`}
                  onClick={() => {
                    setActiveBtn(1);
                    handleActiveBtn(1);
                  }}
                  style={{
                    width: "140px",
                  }}
                >
                  Account
                </button>
                <button
                  className={`typeOfDeals-btn ${
                    activeBtn === 2 ? "typeOfDeals-active-btn" : ""
                  }`}
                  onClick={() => {
                    setActiveBtn(2);
                    handleActiveBtn(2);
                  }}
                  style={{
                    width: "170px",
                  }}
                >
                  Your Deals
                </button>
                <button
                  className={`typeOfDeals-btn ${
                    activeBtn === 3 ? "typeOfDeals-active-btn" : ""
                  }`}
                  onClick={() => {
                    setActiveBtn(3);
                    handleActiveBtn(3);
                  }}
                  style={{
                    width: "140px",
                  }}
                >
                  Members
                </button>
              </div>
              <div className="typeOfDeals-border"></div>
            </div> */}
            <Account />
            {/* {activeBtn === 2 && <Deals />}
            {activeBtn === 3 && <Members />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccountPage;
