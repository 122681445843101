import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter.js";

const BalanceSheetTable = ({ data }) => {
  const [type, setType] = useState("m");
  useEffect(() => {}, [type, data]);
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "fit-content",
          padding: "16px",
          borderRadius: "4px",
        }}
      >
        <TimePeriodButton
          type={type}
          setType={setType}
          ltm={true}
          title={data.name}
          paddingRight={`0px`}
        />
        {data && (
          <Paper
            sx={{
              marginTop: "16px",
              width: "100%",
              overflow: "hidden",
              boxShadow: 0,
            }}
          >
            <TableContainer>
              <Table aria-label="Balance Sheet Table">
                <TableHead>
                  <TableRow>
                    {[
                      "Balance Sheet",
                      ...(Object.keys(
                        data?.data?.[type]?.[
                          Object.keys(data?.data?.[type] || {})[0]
                        ]?.values || {}
                      ) || []),
                    ].map((column, index) => (
                      <TableCell
                        key={index}
                        align="left"
                        sx={{
                          position: "sticky",
                          left: index === 0 ? 0 : "auto",
                          background: index === 0 ? "#B9BBBD100" : "#B9BBBD30",
                          zIndex: index === 0 ? 1 : 0,
                          width: 123,
                          whiteSpace: "nowrap",
                          fontWeight: 300,
                          fontSize: "14px",
                          fontFamily: "Karla, sans-serif",
                          // borderRight: index !== 0 && "1px solid #B8B8B8",
                          // borderBottom: "1px solid #B8B8B8",
                          padding: index === 0 ? 0 : "3px 10px",
                          paddingY: "0px",
                        }}
                      >
                        <div
                          style={{
                            // borderRight: index === 0 && "1px solid #B8B8B8",
                            height: "100%",
                            width: "100%",
                            padding: index === 0 && "3px 10px",
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          {column}
                          {index === 0 && (
                            <Typography
                              sx={{
                                fontFamily: `Karla, sans-serif`,
                                fontSize: "10px",
                                fontWeight: "300",
                                color: "#696F79",
                              }}
                            >
                              (in lakhs)
                            </Typography>
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object?.entries(data?.data?.[type] || {})?.map(
                    ([key, row], index) => {
                      return (
                        <>
                          {/* For empty title */}
                          <TableRow key={key + `-empty`}>
                            <TableCell
                              sx={{
                                textWrap: "nowrap",
                                padding: 0,
                                position: "sticky",
                                left: 0,
                                background: "white",
                                zIndex: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  // borderRight: "1px solid #B8B8B8",
                                  height: "100%",
                                  width: "100%",
                                  padding: "3px 10px",
                                  fontWeight: 700,
                                  color: "var(--brand-color)",
                                }}
                              >
                                {row.name}
                              </Box>
                            </TableCell>
                            {Object.keys(row.emptyValues).map((key, index) => (
                              <TableCell
                                sx={{
                                  padding: "3px 10px",
                                }}
                                key={index}
                              >
                                {" "}
                              </TableCell>
                            ))}
                          </TableRow>
                          {row.mappers &&
                            row.mappers.map((mapper) => {
                              const mapperKey = Object.keys(mapper)[0];
                              const keys = Object.keys(mapper[mapperKey]);
                              return keys && keys.length > 1 ? (
                                <>
                                  {/* For  mapper empty title */}
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        textWrap: "nowrap",
                                        padding: 0,
                                        position: "sticky",
                                        left: 0,
                                        background: "white",
                                        zIndex: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          // borderRight: "1px solid #B8B8B8",
                                          height: "100%",
                                          width: "100%",
                                          padding: "3px 10px",
                                          paddingLeft: "25px",
                                          fontWeight: 600,
                                          color: "var(--brand-color)",
                                        }}
                                      >
                                        {mapperKey}
                                      </Box>
                                    </TableCell>
                                    {Object.keys(row.emptyValues).map(
                                      (key, index) => (
                                        <TableCell
                                          sx={{
                                            padding: "3px 10px",
                                          }}
                                          key={index}
                                        >
                                          {" "}
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                  {/* Detailed mapper with values */}
                                  {Object.entries(mapper[mapperKey]).map(
                                    ([key, values], index) => (
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            textWrap: "nowrap",
                                            padding: 0,
                                            position: "sticky",
                                            left: 0,
                                            background: "white",
                                            zIndex: 1,
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              // borderRight: "1px solid #B8B8B8",
                                              height: "100%",
                                              width: "100%",
                                              padding: "3px 10px",
                                              paddingLeft: "40px",
                                            }}
                                          >
                                            {key}
                                          </Box>
                                        </TableCell>
                                        {Object?.values(values).map(
                                          (value, key) => (
                                            <TableCell
                                              sx={{
                                                padding: "3px 10px",
                                                // borderRight: "1px dotted #DADADA",
                                                fontFamily: "Karla, sans-serif",
                                              }}
                                            >
                                              {formatToLakhs(value) || `-`}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    )
                                  )}
                                </>
                              ) : (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      textWrap: "nowrap",
                                      padding: 0,
                                      position: "sticky",
                                      left: 0,
                                      background: "white",
                                      zIndex: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        // borderRight: "1px solid #B8B8B8",
                                        height: "100%",
                                        width: "100%",
                                        padding: "3px 10px",
                                        paddingLeft: "25px",
                                      }}
                                    >
                                      {mapperKey}
                                    </Box>
                                  </TableCell>
                                  {Object.values(
                                    mapper[mapperKey][mapperKey]
                                  ).map((value, index) => (
                                    <TableCell
                                      sx={{
                                        padding: "3px 10px",
                                        // borderRight: "1px dotted #DADADA",
                                        fontFamily: "Karla, sans-serif",
                                      }}
                                      key={index}
                                    >
                                      {formatToLakhs(value) || `-`}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              );
                            })}
                          {/* Title with Values */}
                          {row.name !== "" && (
                            <TableRow key={key + `-values`}>
                              <TableCell
                                sx={{
                                  textWrap: "nowrap",
                                  padding: 0,
                                  position: "sticky",
                                  left: 0,
                                  background: "white",
                                  zIndex: 1,
                                  borderTop: "1.5px solid var(--brand-color)",
                                  borderBottom:
                                    "1.5px solid var(--brand-color)",
                                }}
                              >
                                <Box
                                  sx={{
                                    // borderRight: "1px solid #B8B8B8",
                                    height: "100%",
                                    width: "100%",
                                    padding: "3px 10px",
                                    fontWeight: 700,
                                    color: "var(--brand-color)",
                                  }}
                                >
                                  {`Total ` + row?.name}
                                </Box>
                              </TableCell>
                              {row.values &&
                                Object.keys(row?.values).map((key, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      padding: "3px 10px",
                                      // borderRight: "1px dotted #DADADA",
                                      fontFamily: "Karla, sans-serif",
                                      borderTop:
                                        "1.5px solid var(--brand-color)",
                                      borderBottom:
                                        "1.5px solid var(--brand-color)",
                                    }}
                                  >
                                    {formatToLakhs(row.values[key]) || `-`}
                                  </TableCell>
                                ))}
                            </TableRow>
                          )}
                        </>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default BalanceSheetTable;
