import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const getAllQnAs = async (fundrevAnalystID, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/qnA/getallQnAs/${fundrevAnalystID}`,
      {},
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const getqnAs = async (formData) => {
  try {
    const response = await axios.post(`${backend_url}/qnA/getqnA`, formData);
    return response;
  } catch (error) {
    return error;
  }
};
export const postqnAs = async (formData) => {
  try {
    const response = await axios.post(`${backend_url}/qnA/postqnA`, formData);
    return response;
  } catch (error) {
    console.error("Failed to save data:", error);
    return error;
  }
};
