import React, { useState } from "react";
import { Box, Typography, ButtonBase, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";

const CustomUploadInput = ({ onFileUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      onFileUpload(uploadedFile);
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    onFileUpload(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!file ? (
        <ButtonBase
          component="label"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "12px",
            border: "1px solid #E5E5E5",
            borderRadius: "8px",
            cursor: "pointer",
            backgroundColor: "#F9F9F9",
            "&:hover": {
              borderColor: "#3C82F6",
            },
          }}
        >
          <input type="file" hidden onChange={handleFileChange} />
          <Box sx={{ display: "flex", gap: 1 }}>
            <UploadFileIcon sx={{ color: "#696F79" }} />
            <Typography
              variant="body1"
              sx={{
                fontFamily: "'Karla', sans-serif",
                fontSize: "16px",
                color: "#696F79",
              }}
            >
              Upload your documents here
            </Typography>
          </Box>
        </ButtonBase>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px 8px 4px 18px",
            border: "1px solid #E5E5E5",
            borderRadius: "8px",
            backgroundColor: "#F9F9F9",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Karla', sans-serif",
              fontSize: "16px",
              color: "#696F79",
            }}
          >
            {file.name}
          </Typography>
          <IconButton
            onClick={handleDeleteFile}
            sx={{
              color: "#FF0000",
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default CustomUploadInput;
