import React, { useState, useEffect } from "react";
import Topbar from "../Company/Deal/topbar";
import { getqnAs, postqnAs } from "../../../endpoints/qnA";
import Toast from "../../Common/Toast";

import { useLocation } from "react-router-dom";
import { Box, Modal, TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { ToastContainer } from "react-toastify";

import noQuestions from "../../../Assets/Images/FounderPage/Dashboard/noQuestions.svg";
import Comment from "../../../Assets/Images/FounderPage/Dashboard/comment.svg";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import invite from "../../../Assets/Images/signup/invite.svg";

const AnswerQnAs = () => {
  const location = useLocation();
  const [qnAs, setqnAs] = useState([]);
  const { startupID, fundrevAnalystID } = location.state || {};
  const [qnAID, setqnAID] = useState();
  const [inputValue, setInputValue] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const BluePrefix = styled("span")({
    color: "#00a6fb",
    position: "absolute",
    top: "0px",
    left: "2px",
    fontSize: "18px",
  });
  const postAnswer = async () => {
    if (inputValue === "") {
      return;
    }
    const formData = {
      qnAID: qnAID,
      answer: inputValue,
      answeredBy: fundrevAnalystID,
    };
    const response = await postqnAs(formData);
    if (response) {
      setAnswerModalOpen(false);
      getqnAdata();
      setInputValue("");
    } else {
      setAnswerModalOpen(false);
      Toast("Error posting answer", "error");
      setInputValue("");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      postAnswer();
    }
  };
  const addOrUpdateQnAs = (qnAData) => {
    const qnAMap = new Map(qnAs.map((qnA) => [qnA.qnAID, qnA]));

    qnAData.forEach((qnA) => {
      qnAMap.set(qnA.qnAID, qnA);
    });

    setqnAs(Array.from(qnAMap.values()));
  };
  const getqnAdata = async () => {
    if (fundrevAnalystID && startupID) {
      const response = await getqnAs({
        fundrevAnalyst: fundrevAnalystID,
        startupID: startupID,
      });
      try {
        if (response.response.data === "No qnA found") {
          return;
        }
      } catch {
        const qnAData = response.data;
        addOrUpdateQnAs(qnAData);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    getqnAdata();
  }, [fundrevAnalystID, startupID]);
  const handleAnswerBtnClick = (id, que, ans) => {
    setqnAID(id);
    if (ans) {
      setInputValue(ans);
    } else {
      setInputValue("");
    }
    setSelectedQuestion(que);
    setSelectedAnswer(ans);
    setAnswerModalOpen(true);
  };
  return (
    <>
      <ToastContainer position="top-center" />
      <Modal
        className="ask-question-modal"
        style={{ padding: "20px 30px" }}
        open={answerModalOpen}
        onClose={() => setAnswerModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box question-box" style={{ width: "700px" }}>
          <div
            className="reason-box-first-div"
            style={{ alignItems: "center" }}
          >
            <p className="qna-section-text">Q&A Section</p>
            <img
              className="reason-box-closeicon"
              src={closeIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setAnswerModalOpen(false)}
              alt="close"
            />
          </div>
          <div className="qna-section">
            <div className="QnA answer-question-div">
              <div className="QnA-question">
                <span className="blue-text">Q.</span>&nbsp;&nbsp;{" "}
                {selectedQuestion}
              </div>
              <div className="QnA-answer">
                <TextField
                  id="outlined-multiline-static"
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  onKeyDown={handleKeyPress}
                  multiline
                  minRows={2}
                  fullWidth
                  variant="outlined"
                  placeholder="Write answer"
                  startAdornment={<span className="blue-text">A.</span>}
                  style={{ marginTop: "0px", padding: "0px" }}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BluePrefix>A.</BluePrefix>
                      </InputAdornment>
                    ),
                    style: {
                      padding: "0px 20px",
                      fontFamily: "Karla,sans-serif",
                      fontSize: "18px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="post-question-btn"
            onClick={postAnswer}
            style={{
              backgroundColor: inputValue === "" ? "#b9bbbd" : "#00a6fb",
              cursor: inputValue === "" ? "not-allowed" : "pointer",
            }}
          >
            <p>{selectedAnswer ? "Update" : "Post"}</p>
            <img src={invite} alt="invite" />
          </button>
        </Box>
      </Modal>
      <Topbar title="Answer Questions" />
      <div className="QnA-div" style={{ margin: "120px 0px 0px 30px" }}>
        {qnAs.length > 0 ? (
          qnAs.map((qnA) => {
            return (
              <div className="QnA" style={{ marginBottom: "30px" }}>
                <div className="QnA-question" id={qnA.qnAID}>
                  <span className="blue-text">Q.</span> {qnA.question}{" "}
                  &nbsp;&nbsp;
                  <img
                    src={Comment}
                    alt="add answer"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAnswerBtnClick(qnA.qnAID, qnA.question, qnA.answer)
                    }
                  />
                </div>
                {qnA.answer && (
                  <div className="QnA-answer">
                    <span className="blue-text">A.</span> {qnA.answer}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>
            <p
              style={{
                fontSize: "20px",
                color: "#696F79",
                margin: "20px 0px 0px 30px",
              }}
            >
              Currently, there are no questions or answers posted
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <img src={noQuestions} alt="No questions" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnswerQnAs;
