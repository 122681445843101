import React, { useState, useEffect } from "react";
import Marquee from "../Common/animations/marquee";
import frame1 from "../../Assets/Images/LandingPage/frames/frame1.svg";
import frame2 from "../../Assets/Images/LandingPage/frames/frame2.svg";
import frame3 from "../../Assets/Images/LandingPage/frames/frame3.svg";
import frame4 from "../../Assets/Images/LandingPage/frames/frame4.svg";
import frame5 from "../../Assets/Images/LandingPage/frames/frame5.svg";
import frame6 from "../../Assets/Images/LandingPage/frames/frame6.svg";

const firstRow = [frame1, frame2, frame3];
const secondRow = [frame4, frame5, frame6];

const MarqueeDemo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const frameWidth =
    windowWidth > 1700
      ? 300
      : windowWidth < 991
      ? 300
      : windowWidth < 1300
      ? 180
      : 180;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="relative flex h-[90%] w-[100%] pl-5 flex-row items-center justify-between overflow-hidden rounded-lg bg-background m-auto">
      <Marquee
        pauseOnHover
        className="[--duration:30s] !h-[100%] opacity-30"
        style={{ width: "300px" }}
      >
        {firstRow.map((frame) => (
          <div style={{ width: "280px", height: "380px" }}>
            <img src={frame} />
          </div>
        ))}
      </Marquee>
      <Marquee
        reverse
        pauseOnHover
        className="[--duration:30s] !h-[100%] opacity-30"
        style={{ width: frameWidth }}
      >
        {secondRow.map((frame) => (
          <div style={{ width: "280px", height: "380px" }}>
            <img src={frame} />
          </div>
        ))}
      </Marquee>
      {/* <Marquee
        pauseOnHover
        className={`[--duration:30s] !h-[100%] opacity-60`}
        style={{ width: frameWidth }}
      >
        {secondRow.map((frame) => (
          <div style={{ width: "280px", height: "380px" }}>
            <img src={frame} />
          </div>
        ))}
      </Marquee> */}
    </div>
  );
};
export default MarqueeDemo;
