import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const FinancialStatementSmallComparisonTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = Object.keys(data?.data[type]["Revenue"]);
  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(null);
  const rows = [
    "Revenue",
    "Cost of Revenue",
    "Gross Profit",
    "Gross Profit %",
    "SG&A",
    "EBITDA",
    "EBITDA %",
  ];
  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];
  useEffect(() => {
    switch (option) {
      case "previousm":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastm":
        setCKey(keys.slice(-13)[0]);
        break;
      case "previousq":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastq":
        setCKey(keys.slice(-5)[0]);
        break;
      case "previousltm":
        setCKey(keys.slice(-13)[0]);
        break;
      default:
        setCKey(keys.slice(-1)[0]);
    }
  }, [data, key, ckey, option, keys]);
  useEffect(() => {
    setKey(Object.keys(data?.data[type]["Revenue"]).slice(-1)[0]);
  }, [type, data]);

  function getVariance(row) {
    if (row !== "Gross Profit %" && row !== "EBITDA %" && row !== "SG&A") {
      const a = data?.data[type][row][key];
      const b = data?.data[type][row][ckey];
      return ((a / b - 1) * 100).toFixed(1);
    } else if (row === "SG&A") {
      const a = data?.data[type][row]["Total SG&A Expenses"][key];
      const b = data?.data[type][row]["Total SG&A Expenses"][ckey];
      return ((a / b - 1) * 100).toFixed(1);
    }
  }
  return (
    <>
      <TimePeriodButton
        title={data?.name}
        type={type}
        setType={setType}
        select={true}
        ltm={true}
        year={false}
        option={option}
        setOption={setOption}
      />
      <Paper
        sx={{
          boxShadow: 0,
        }}
      >
        <TableContainer
          sx={{
            borderRadius: "4px",
          }}
        >
          <Table aria-label="Financial statement comparison table" size="small">
            <TableHead>
              <TableRow>
                {["Summary", ckey, key, "%Change"].map((column, index) => (
                  <TableCell
                    sx={{
                      width: index === 0 ? "169px" : "125px",
                      paddingY: "3px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 300,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "#B9BBBD30",
                    }}
                    key={index}
                    align={index === 3 ? "right" : "left"}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        gap: "2px",
                        alignItems: "baseline",
                        justifyContent: index === 3 && "end",
                        color: "#696F79",
                      }}
                    >
                      {column}
                      {index === 0 && (
                        <Typography
                          sx={{
                            fontFamily: `Karla, sans-serif`,
                            fontSize: "10px",
                            fontWeight: "300",
                            color: "#696F79",
                          }}
                        >
                          (in lakhs)
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      width: "169px",
                      paddingY: "5px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "var(--brand-color)",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop:
                        IMPRows.includes(row) && "2px solid var(--brand-color)",
                    }}
                  >
                    {row}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "125px",
                      paddingY: "5px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "var(--brand-color)",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop:
                        IMPRows.includes(row) && "2px solid var(--brand-color)",
                    }}
                  >
                    {row === "SG&A"
                      ? formatToLakhs(
                          data?.data[type][row]["Total SG&A Expenses"][ckey]
                        ) || `-`
                      : formatToLakhs(data?.data[type][row][ckey]) || `-`}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "125px",
                      paddingY: "5px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (row === "Gross Profit" || row === "EBITDA") &&
                        "var(--brand-color)",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop:
                        IMPRows.includes(row) && "2px solid var(--brand-color)",
                    }}
                  >
                    {row === "SG&A"
                      ? formatToLakhs(
                          data?.data[type][row]["Total SG&A Expenses"][key]
                        ) || `-`
                      : formatToLakhs(data?.data[type][row][key]) || `-`}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "129px",
                      paddingY: "5px",
                      fontFamily: "Karla, sans-serif",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      fontWeight:
                        row === "Gross Profit" || row === "EBITDA" ? 700 : 400,
                      color: getVariance(row) > 0 ? "#00CD9E" : "#D32F2F",
                      fontStyle: percentageRows.includes(row)
                        ? "italic"
                        : "normal",
                      borderTop:
                        IMPRows.includes(row) && "2px solid var(--brand-color)",
                    }}
                    align="right"
                  >
                    {getVariance(row) ? getVariance(row) + `%` : `-`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default FinancialStatementSmallComparisonTable;
