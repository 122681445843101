import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import { StyledFormControl, StyledFormLabel } from "../../Common/functions";
import CustomInput from "../../Common/components/custom/customInput";
import CustomPhoneInput from "../../Common/components/custom/customPhoneInput";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../../Common/components/custom/customBlackBtnPhone";
const InvestorToolkitStep1 = ({ handleSubmit, data }) => {
  const initialValues = {
    fullName: data.fullName || "",
    phoneNumber: data.phoneNumber || "",
    email: data.email || "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: "100%",
            }}
          >
            <div className="sign-up-input-fields">
              <StyledFormControl fullWidth>
                <StyledFormLabel>Full Name</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Full Name"
                  name={`fullName`}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.fullName && errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Phone Number</StyledFormLabel>
                <CustomPhoneInput
                  value={values.phoneNumber}
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Official Email Address</StyledFormLabel>
                <CustomInput
                  placeholder="Enter work email address"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </StyledFormControl>
            </div>
            <div className="sign-up-btn-section laptop-design">
              <CustomBlackBtn type="submit" text="Continue" />
            </div>

            <div className="sign-up-btn-section-phone phone-design">
              <CustomBlackBtnPhone type="submit" text="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvestorToolkitStep1;
