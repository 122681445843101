import React, { useState, useEffect } from "react";
import Arrow from "../../../Assets/Images/LandingPage/arrow-small-right.svg";
import dashboard from "../../../Assets/Images/InvestorPage/dashboard.svg";
import dashboardlarge from "../../../Assets/Images/InvestorPage/dashboard-large-1.svg";
import dashboardsmall from "../../../Assets/Images/InvestorPage/dashboard-small.svg";
import { Link } from "react-router-dom";
const InvestorPageFront = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl =
    windowWidth > 1400
      ? dashboardlarge
      : windowWidth < 991
      ? dashboardsmall
      : dashboard;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="investor-div">
      <section className="investor-section">
        <header className="investor-header">
          <button className="investor-header-btn">FOR INVESTORS</button>
        </header>
        <div className="investor-content">
          <div className="content-main">
            <div className="content-column">
              <h1 className="main-heading">
                Pre-screen promising investment opportunities
              </h1>
            </div>
            <div className="content-column action-column">
              <Link
                to="/investor/deals"
                state={{ active: "investor" }}
                className="get-started-link"
              >
                <button className="get-started-btn">
                  Check Listing
                  <img className="arrow-investor" src={Arrow} alt={"arrow"} />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <img src={imageUrl} alt="" className="banner-image" />
      </section>
    </div>
  );
};

export default InvestorPageFront;
