import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { plotColorPallete } from "../../../../Common/functions";
import { calculateDtick } from "../analytics/AnalyticsPlots/PlotUtilityFunctions";
import TimePeriodButton from "../analytics/AnalyticsPlots/TimePeriodButton";

const FinancialPlot = ({ data, ltm = true }) => {
  const [type, setType] = useState("m");

  useEffect(() => {}, [type, data]);

  const plotData = [
    {
      x: data.data[type].xLabels,
      y: data.data[type].Revenue,
      type: "bar",
      name: "Revenue",
      marker: { color: plotColorPallete[0] },
      hovertemplate: `<b>Revenue</b>: %{y:,.1f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].GrossProfit,
      type: "bar",
      name: "Gross Profit",
      marker: { color: plotColorPallete[1] },
      hovertemplate: `<b>Gross Profit</b>: %{y:,.1f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].EBITDA,
      type: "bar",
      name: "EBITDA",
      marker: { color: plotColorPallete[2] },
      hovertemplate: `<b>EBITDA</b>: %{y:,.1f}` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].GrossProfitPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "Gross Profit %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: plotColorPallete[3] },
      hovertemplate:
        `<b>Gross Profit Margin</b>: %{y:,.1f}%` + "<extra></extra>",
    },
    {
      x: data.data[type].xLabels,
      y: data.data[type].EBITDAPercentage,
      type: "scatter",
      yaxis: "y2",
      name: "EBITDA %",
      line: { shape: "spline" },
      mode: "lines",
      marker: { color: plotColorPallete[4] },
      hovertemplate: `<b>EBITDA %</b>: %{y:,.1f}%` + "<extra></extra>",
    },
  ];

  return (
    <div>
      <TimePeriodButton
        type={type}
        setType={setType}
        title={data.name}
        ltm={ltm}
      />
      <Plot
        data={plotData}
        layout={{
          barmode: "group",
          width: 1141,
          height: 395,
          margin: {
            l: 50,
            r: 50,
            t: 50,
            b: 100,
          },
          xaxis: {
            zeroline: true,
            zerolinewidth: 2,
            dtick: calculateDtick(type, data.data[type].xLabels.length),
            tickangle: 0,
            tickfont: {
              family: "Karla, sans-serif",
            },
          },
          yaxis: {
            tickfont: {
              family: "Karla, sans-serif",
            },
            autorangeoptions: {
              minallowed: 0,
            },
          },
          barcornerradius: 3,

          yaxis2: {
            overlaying: "y",
            side: "right",
            tickfont: {
              family: "Karla, sans-serif",
            },
            range: [0, 100],
            ticksuffix: "%",
            autorange: false,
            gridcolor: "white",
          },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
          modebar: {
            activecolor: "#00A6FB",
            bgcolor: "transparent",
            color: "grey",
            orientation: "v",
          },
          hovermode: "x unified",
          legend: {
            orientation: "h",
            x: 0.5,
            y: -0.25,
            xanchor: "center",
            yanchor: "top",
          },
          hoverlabel: {
            bordercolor: "transparent",
            bgcolor: "#E5F5FE",
            font: {
              family: "Karla, sans-serif",
            },
          },
        }}
        config={{
          displaylogo: false,
          responsive: true,
          modeBarButtonsToRemove: [
            "autoScale2d",
            "lasso2d",
            "select2d",
            "zoomIn2d",
            "zoomOut2d",
          ],
        }}
      />
    </div>
  );
};

export default FinancialPlot;
