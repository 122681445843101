import React, { useState } from "react";
import { Modal } from "@mui/joy";
import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import Close from "../../../Assets/Images/signup/close.svg";
import { useNavigate } from "react-router-dom";

import CustomBlackBtn from "../components/custom/customBlackBtn";
import ScheduleMeetingWithAnalystModal from "./ScheduleMeetingWithAnalystModal";
import { Box } from "@mui/material";

const WelcomeCompany = ({ values, open }) => {
  const [scheduleMeetingModal, setScheduleMeetingModal] = useState(false);
  const Navigate = useNavigate();

  function NavigateToCompanyDetails() {
    Navigate("/company/dashboard");
  }
  return (
    <>
      <ScheduleMeetingWithAnalystModal
        modal={scheduleMeetingModal}
        setModal={setScheduleMeetingModal}
        values={values}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex flex-col p-8 bg-white rounded-3xl border w-[400px] lg:w-[600px] border-gray-100 border-solid max-w-[90%] md:max-w-[729px] shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 m-auto absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <div className="flex flex-col w-full text-xl leading-9">
            <img
              onClick={() => Navigate("/signin")}
              loading="lazy"
              src={Close}
              className="object-contain shrink-0 w-8 md:w-10 aspect-square cursor-pointer self-end"
              alt="close"
            />
            <img
              loading="lazy"
              src={Verify}
              alt="verify"
              className="object-contain self-center max-w-[80px] md:max-w-[114px] w-full aspect-[1.11]"
            />
            <div className="flex flex-col mt-6 w-full">
              <div className="self-center font-light text-center text-gray-500 text-lg md:text-xl">
                Welcome {values?.name},
              </div>
              <div className="mt-2 w-full text-center text-zinc-900 text-base md:text-lg">
                You have been successfully registered!
              </div>
              <div
                className="self-center mt-6"
                onClick={NavigateToCompanyDetails}
              >
                <CustomBlackBtn type="button" text="Continue to Fundrev" />
              </div>
            </div>
          </div>

          <div
            className="self-center mt-6 text-sm md:text-base font-semibold leading-8 text-center text-blue-500 cursor-pointer"
            onClick={() => setScheduleMeetingModal(true)}
          >
            <span className="text-[var(--Primary-Text-Color)]">
              Need guided assistance for onboarding? &nbsp;
            </span>
            Speak to our deal analyst
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WelcomeCompany;
