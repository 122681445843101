import React, { useState } from "react";

const CustomRedBtn = ({ type, text }) => {
  const [hover, setHover] = useState(false); // Manage hover state

  return (
    <button
      type={type ? type : "button"}
      onMouseEnter={() => setHover(true)} // Trigger hover effect
      onMouseLeave={() => setHover(false)} // Remove hover effect
      style={{
        width: "fit-content",
        display: "flex",
        height: "48px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "100px",
        background: hover ? "#fff" : "#fff",
        boxShadow: hover
          ? "0px 6px 12px rgba(0, 0, 0, 0.2)"
          : "0px 4px 8px rgba(0, 0, 0, 0.1)",
        color: "#D32F2F",
        border: "1px solid #D32F2F",
        cursor: "pointer",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease", // Smooth transition
      }}
    >
      {text}
    </button>
  );
};

export default CustomRedBtn;
