import React from "react";

const DealDetailsSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">You're one step away to Fundraising</p>
      <p className="subheading" style={{ lineHeight: "1.5" }}>
        <ul style={{ listStyleType: "disc", margin: "0px 0px 16px 16px" }}>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Deal Type: </b>Choose between Equity, Debt, or a mix based on
            your funding strategy.
          </li>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Target Close Date: </b>Set a realistic deadline for closing this
            funding round.
          </li>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Funding Required: </b>Specify amounts for Equity and/or Debt, and
            be clear about allocation plans.
          </li>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Current Round: </b>Select your round (e.g., Seed, Series A)
            depending on what was your last round.
          </li>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Debt Type: </b>If applicable, choose the debt structure (e.g.,
            RBF) that suits your revenue model.
          </li>
          <li
            style={{
              marginBottom: "12px",
            }}
          >
            <b>Term: </b>Enter the repayment period for any debt, showing
            investors your repayment timeline.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default DealDetailsSuggested;
