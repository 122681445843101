import React from "react";
import sampleAnalytics from "../../../../../Assets/Images/FounderPage/Dashboard/sampleAnalytics.png";
const AnalyticsPreparationSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">We prepare what investors want from you!</p>
      <p className="subheading"></p>
      <img
        src={sampleAnalytics}
        alt="Analytics-image"
        style={{
          border: `1px solid var(--Primary-Text-Color)`,
          borderRadius: "4px",
          padding: "8px",
        }}
      />
      <button
        style={{
          backgroundColor: "#002132",
          padding: "4px 8px",
          borderRadius: "4px",
          color: "#fff",
          width: "fit-content",
          fontSize: "16px",
        }}
      >
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/company/sampleAnalytics`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          See in Action
        </a>{" "}
      </button>
    </div>
  );
};

export default AnalyticsPreparationSuggested;
