import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
const InvestorToolkitPlot = ({ data, title }) => {
  const colorPallete = ["#D2DCFF", "#A8B0CC", "#7E8499", "#525664", "#2A2C33"];
  const xLabels = data?.xLabels;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const plotData = Object?.entries(data?.values)?.map(
    ([category, value], index) => {
      if (value.every((x) => x === 0 || x === "0M")) {
        return {};
      }
      return {
        x: xLabels,
        y: value,
        name: category,
        marker: {
          color: colorPallete[index % colorPallete.length],
        },
        type: "bar",
        width: 0.5,
        textposition: "none",
      };
    }
  );
  return (
    <Box
      sx={{
        height: "300px",
        width: windowWidth < 521 ? "90%" : "510px",
        borderRadius: "8px",
        border: "0.5px solid #8692A6",
        padding: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Karla, sans-serif",
          fontSize: "16px",
          fontWeight: "medium",
          color: "#1A1A1A",
          marginBottom: "20px",
        }}
      >
        {title}
      </Typography>
      <Plot
        data={plotData}
        layout={{
          xaxis: {
            tickangle: windowWidth < 400 ? -20 : 0,
            tickfont: {
              family: "Karla, sans-serif",
            },
          },
          yaxis: {
            gridwidth: 1,
            tickfont: {
              family: "Karla, sans-serif",
              size: "14px",
            },
          },
          barcornerradius: 3,
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
          modebar: {
            activecolor: "#00A6FB",
            bgcolor: "transparent",
            color: "grey",
            orientation: "v",
          },
          autosize: false,
          margin: {
            b: 80,
            t: 5,
            r: 0,
            l: 35,
          },
          width: windowWidth < 521 ? windowWidth - windowWidth * 0.4 : 480,
          height: 290,
          showlegend: windowWidth < 525 ? false : true,
          legend: {
            orientation: "v",
            x: 1,
          },
          hovermode: "x unified",
          hoverlabel: {
            bordercolor: "transparent",
            borderradius: "10px",
            bgcolor: "white",
            font: {
              family: "Karla, sans-serif",
            },
          },
          barmode: "stack",
        }}
        config={{
          responsive: true,
          displayModeBar: false,
          displaylogo: false,
          modeBarButtonsToRemove: [
            "autoScale2d",
            "lasso2d",
            "select2d",
            "zoomIn2d",
            "zoomOut2d",
          ],
        }}
      />
    </Box>
  );
};

export default InvestorToolkitPlot;
