//Keeping nda signed stage in this page it may be required later
import React from "react";
import { fetchBlobName } from "../../Common/functions";

import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import userProfile from "../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
import { companyState } from "../Dashboard/companyState";
import FileImg from "../../../Assets/Images/signup/file.svg";
import download from "../../../Assets/Images/InvestorPage/Dashboard/download.svg";

const Message = (props) => {
  const { startupMemberId } = useRecoilValue(companyState);
  return (
    <div className="message-div companyOnboarding-message-div">
      <div className="message-div-2 flex items-center">
        <img
          loading="lazy"
          srcSet={props.profilePhoto ? props.profilePhoto : userProfile}
          className="message-img"
          alt="message-img"
        />
        <div className="nameandrole">
          <div className="message-div-3">{props.name}</div>
          <div className="message-div-6">{props.role}</div>
        </div>
        {startupMemberId === props.startupMemberID && (
          <div
            style={{
              fontSize: "12px",
              backgroundColor: "#00A6FB",
              color: "white",
              padding: "2px 4px",
              marginBottom: "5px",
              borderRadius: "2px",
            }}
          >
            You
          </div>
        )}
        <div className="message-div-4">{props.time}</div>
      </div>
      {props.file ? (
        <div className="NDA-document">
          <div className="NDA-document-left">
            <img src={FileImg} className="FileImg" alt="fileImg" />
            <div className="NDA-document-name">
              <p>
                {fetchBlobName(props.file).length > 30
                  ? fetchBlobName(props.file).slice(0, 30) + `...`
                  : fetchBlobName(props.file)}
              </p>
            </div>
          </div>
          <div className="NDA-document-right">
            <Link to={props.file}>
              <img src={download} alt="downloadBtn" />
            </Link>
          </div>
        </div>
      ) : null}
      {props.message && (
        <div className="message-div-5 ml-1">{props.message}</div>
      )}
    </div>
  );
};

export default Message;
