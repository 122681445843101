import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import TimePeriodButton from "../analytics/AnalyticsPlots/TimePeriodButton";

const ChurnTable = ({ data }) => {
  const headers = data.data.headers;
  const rows = data.data.values;
  const IMPRows = ["Churn Rate", "Retention Rate"];
  const formatter = (x) => {
    if (typeof x === "string") {
      if (x.includes(`%`)) return x;
      const numx = Number(x);
      if (["NaN", "Infinity"].includes(numx)) {
        return 0;
      }
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(numx);
    } else {
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(x);
    }
  };
  return (
    <Box>
      <TimePeriodButton
        title={data.name}
        year={false}
        q={false}
        type={`m`}
        paddingRight={`20px`}
        m={false}
      />
      <Paper
        sx={{
          width: "529px",
          overflow: "hidden",
          boxShadow: "0",
          marginTop: "10px",
        }}
      >
        <TableContainer
          sx={{
            borderRadius: "10px",
            border: "1px solid #B8B8B875",
          }}
        >
          <Table aria-label={data.name}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    sx={{
                      width: index === 0 ? "169px" : "125px",
                      paddingX: "5px",
                      paddingY: "10px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 700,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#eaeaea",
                      border: "1px solid #D3D9EE5050",
                      color: "#1a1a1a",
                      borderTop: 0,
                      borderRight: 0,
                      borderLeft: index === 0 && 0,
                    }}
                    align={index !== 0 ? "center" : "left"}
                    key={header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(rows)?.map(([key, row], index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      // width: "169px",
                      paddingX: "5px",
                      paddingY: "8px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: IMPRows.includes(key) ? 700 : 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: "normal",
                      borderTop: IMPRows[0] === key && "1px solid #00A6FB",
                      color: IMPRows.includes(key) && "#00A6FB",
                      borderBottom: 0,
                    }}
                  >
                    {key}
                  </TableCell>
                  {row.map((cell, index) => (
                    <TableCell
                      sx={{
                        // width: "169px",
                        paddingX: "5px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(key) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        border: "0.25px solid #D3D9EE50",
                        fontStyle: "normal",
                        borderTop: IMPRows[0] === key && "1px solid #00A6FB",
                        color: IMPRows.includes(key) && "#00A6FB",
                        borderBottom: 0,
                        borderRight: 0,
                      }}
                      align="center"
                      key={index}
                    >
                      {formatter(cell)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ChurnTable;
