import React, { useState } from "react";
import UploadFiles from "../components/uploadFiles";
import { MdLockOutline } from "react-icons/md";

const DataroomPreparation = ({
  fetchDetails,
  validationSchema,
  setSchema,
  setIsUploading,
  uploadFileData,
}) => {
  const [lastHoveredIndex, setLastHoveredIndex] = useState(null);
  return (
    <div className="DataroomPreparation fundrev-card onboardingForm-card relative">
      <p className="heading">Dataroom Preparation</p>
      <p className="subheading">
        Upload your files to give insights of your business to potential
        investors
      </p>
      <p className="flex flex-row gap-[8px] text-[14px] color-[#696F79] bg-zinc-50 p-[4px] rounded-[4px] items-center justify-center absolute top-[16px] right-[16px]">
        <MdLockOutline />
        Your info is secured with us
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <UploadFiles
          filename="Corporate Documents"
          name="corporateDocuments"
          filesArray={"corporateDocumentsFiles"}
          documentsRequiredText="Incorporation Certificate, AOA/MOA Shareholder Agreements, Cap Table, Stock Option Plans."
          index={1}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Team and Advisors"
          name="teamDetails"
          filesArray={"teamDetailsFiles"}
          documentsRequiredText="Bios and Resumes of Key Team Members, Organizational Chart, Advisory Board Member Agreements, Employee Contracts, ESOP (Employee Stock Option Plan) Documents."
          index={2}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Product and Intellectual Property"
          name="productTechnology"
          filesArray={"productTechnologyFiles"}
          documentsRequiredText="Product Roadmap, Technical Specifications, Patent Applications, IP Assignment Agreements, Product Demos or Screenshots."
          index={3}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Market and Client Information"
          name="marketClientInfo"
          filesArray={"marketClientInfoFiles"}
          documentsRequiredText="Client Contracts, Pipeline (with information on potential clients and lead stages), Market Analysis, Competitive Analysis, Customer Testimonials, Case Studies."
          index={4}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Legal and Compliance"
          name="legalComplianceDetails"
          filesArray={"legalComplianceDetailsFiles"}
          documentsRequiredText="Key Contracts (e.g., customer agreements, supplier agreements, partnership agreements), Data Protection and Privacy Policies, Business Licenses, Insurance Agreements, Regulatory Compliance Documents."
          index={5}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
        <UploadFiles
          filename="Additional Financial Planning and Forecasting"
          name="financialPlanning"
          filesArray={"financialPlanningFiles"}
          documentsRequiredText="Budget Outline, Financial Projections beyond those already provided, 12-18 Month Revenue Forecasts, Detailed Use of Funds (including hiring, investments, and projected spends)."
          index={6}
          lastHoveredIndex={lastHoveredIndex}
          setLastHoveredIndex={setLastHoveredIndex}
          fetchDetails={fetchDetails}
          setSchema={setSchema}
          validationSchema={validationSchema}
          setIsUploading={setIsUploading}
          uploadFileData={uploadFileData}
        />
      </div>
    </div>
  );
};

export default DataroomPreparation;
