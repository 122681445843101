import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "./LoaderProvider"; // Custom loader component
import { getOriginalURL } from "../../endpoints/common"; // API call to get original URL

const RedirectURL = () => {
  const { shortURL } = useParams(); // Get short URL from route parameters
  const navigate = useNavigate(); // Hook to navigate to other routes
  const loader = useLoader(); // Custom loader start/stop logic

  useEffect(() => {
    const navigateToUrl = async () => {
      try {
        loader.start();
        const response = await getOriginalURL(shortURL); // Fetch original URL
        if (response) {
          loader.stop();
          navigate(`/${response}`); // Navigate to the original URL
        } else {
          loader.stop();
          navigate("/investor/deals"); // Navigate to fallback URL in case of error
        }
      } catch (e) {
        loader.stop();
        navigate("/investor/deals"); // Navigate to fallback URL in case of error
      }
    };

    if (shortURL) {
      navigateToUrl(); // Call the function only when the shortURL is available
    } else {
      navigate("/investor/deals"); // Fallback if no shortURL is provided
    }
  }, [shortURL, navigate, loader]); // Dependencies: shortURL, navigate, and loader

  return <p>Redirecting...</p>;
};

export default RedirectURL;
