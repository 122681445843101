import Toast from "../../../Common/Toast";

// Common/utils.js
export const isProfileComplete = (companyDetails, foundingMembersJSON) => {
  // Check if all required fields in companyDetails are filled
  const companyFieldsFilled =
    companyDetails.companyName &&
    companyDetails.legalEntityName &&
    companyDetails.headquarters &&
    companyDetails.foundedYear &&
    companyDetails.numEmployees &&
    companyDetails.website &&
    companyDetails.industryVerticals.length > 0 &&
    companyDetails.cin &&
    companyDetails.taxNumber &&
    companyDetails.endMarket.length > 0 &&
    companyDetails.erpPlatform &&
    companyDetails.companyLogo;

  if (!companyFieldsFilled) {
    Toast(`Please fill all company details.`, `info`, `editCompanyProfile`);
    return false; // If any company details are not filled, profile cannot be complete
  }
  // Parse the foundingMembers JSON string
  let foundingMembers = [];
  try {
    foundingMembers = JSON.parse(foundingMembersJSON);
  } catch (error) {
    Toast(
      `Please fill all founding member details.`,
      `info`,
      `editCompanyProfile`
    );
    console.error("Error parsing founding members JSON:", error);
    return false; // If parsing fails, profile cannot be complete
  }

  // Check if at least one founding member has complete details
  const atLeastOneMemberFilled = foundingMembers.every((member) => {
    return (
      member.name &&
      member.title &&
      member.email &&
      member.linkedin &&
      member.phoneNumber &&
      member.biography &&
      member.foundingTeamProfile &&
      member.foundingTeamProfile !== ""
    );
  });
  console.log(!companyFieldsFilled, atLeastOneMemberFilled);

  if (!companyFieldsFilled) {
    Toast(`Please fill all company details.`, `info`, `editCompanyProfile`);
  } else if (!atLeastOneMemberFilled) {
    Toast(
      `Please fill all founding member details.`,
      `info`,
      `editCompanyProfile`
    );
  }

  return companyFieldsFilled && atLeastOneMemberFilled;
};
