import React from "react";
import NoDealsImg from "../../../../Assets/Images/InvestorPage/Dashboard/noDeals.svg";

const NoDeals = ({ text }) => {
  const dealText = text || "deals";
  return (
    <div className="bg-[#fff] max-w-[825px] flex columns flex-col justify-center items-center p-[40px] m-auto mt-[200px] rounded-[24px]">
      <img src={NoDealsImg} alt="No Deals available" className="w-[62px]" />
      <p className="text-[#1A1A1A] font-semibold">
        You currently don't have anything in your {dealText}!
      </p>
    </div>
  );
};

export default NoDeals;
