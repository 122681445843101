import React, { useEffect, useState } from "react";
import { File, Folder, Tree } from "./filetree";
import { downloadMarkdown } from "../../../endpoints/dataroom";
import { useLoader } from "../LoaderProvider";
import {
  MdOutline10K,
  MdOutlineDownload,
  MdOutlineFileDownload,
} from "react-icons/md";
import CustomTooltip from "./custom/customToolTip";

function TreeNode({
  node,
  index,
  parentIndex = "",
  folderClick,
  activePath,
  isAccessible,
}) {
  // Calculate the full index for the current folder
  const currentIndex = parentIndex
    ? `${parentIndex}.${index + 1}`
    : `${index + 1}`;

  if (node.isFolder) {
    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
          folderClick(node.path, currentIndex, true);
        }}
        style={{
          opacity: isAccessible(node.type) ? 1 : 0.5,
        }}
      >
        <Folder value={node.id} element={node.name}>
          {node.children.map((child, childIndex) => (
            <TreeNode
              key={child.id}
              node={child}
              index={childIndex}
              parentIndex={currentIndex} // Pass the current folder's index to children
              folderClick={folderClick}
              activePath={activePath}
              isAccessible={isAccessible}
            />
          ))}
        </Folder>
      </div>
    );
  } else {
    // Ignore files completely
    return null;
  }
}
function getAllOpenFolderIds(nodes) {
  const folderIds = [];
  function traverse(node) {
    if (node.isFolder && node.children && node.children.length > 0) {
      // It's a folder
      folderIds.push(node.id);
      node.children.forEach(traverse);
    }
  }
  nodes.forEach(traverse);
  return folderIds;
}

function FileTree({
  filesData,
  folderClick,
  activePath,
  isAccessible,
  dealID,
}) {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [tree, setTree] = useState([]);
  const [initialExpandedItems, setInitialExpandedItems] = useState([]);
  useEffect(() => {
    setInitialExpandedItems(getAllOpenFolderIds(tree));
  }, [tree]);

  const getAllPaths = () => {
    const arr = filesData
      .filter((file) => file.isFolder === true)
      .map((file) => ({
        path: file.path,
        isFolder: file.isFolder,
        type: file.type,
      }));

    buildTree(arr);
  };
  function buildTree(paths) {
    const tree = [];

    paths.forEach(({ path, isFolder, type }) => {
      const segments = path.split("/").filter(Boolean);
      addNode(tree, segments, "", path, isFolder, type);
    });
    setTree(tree);
  }

  // Recursive function to add nodes to the tree
  function addNode(nodes, segments, parentPath, path, isFolder, type) {
    if (segments.length === 0) return;

    const segment = segments[0];
    const currentPath = `${parentPath}/${segment}`;

    let node = nodes.find((n) => n.id === currentPath);

    if (!node) {
      node = {
        id: currentPath,
        name: segment,
        path: path,
        isFolder: isFolder,
        type: type,
        children: [],
      };
      nodes.push(node);
    }

    if (segments.length > 1) {
      addNode(
        node.children,
        segments.slice(1),
        currentPath,
        path,
        isFolder,
        type
      );
    }
  }
  useEffect(() => {
    getAllPaths();
  }, [filesData]);
  const handleMarkdownDownload = async () => {
    try {
      loader.start("Fetching data...");
      const formData = {
        dealID: dealID,
      };
      const response = await downloadMarkdown(formData, token);
      loader.stop();
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "markdown.md");
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return { success: true };
      } else {
      }
    } catch (error) {}
  };
  return (
    <div className="relative flex h-[600px] w-[100%] p-4 gap-2 flex-col overflow-hidden bg-background shadow-[var(--whiteBg-back-shadow)] bg-[#fff] rounded-[4px]">
      <div className="flex justify-between items-center">
        <p className="text-[var(--Primary-Text-Color)] font-semibold text-[20px]">
          Dataroom
        </p>
        <CustomTooltip
          title="Download markdown"
          position="bottom"
          theme="black"
        >
          <div>
            <MdOutlineFileDownload
              fontSize={24}
              style={{ cursor: "pointer" }}
              onClick={handleMarkdownDownload}
            />
          </div>
        </CustomTooltip>
      </div>
      <Tree
        key={initialExpandedItems.join(",")}
        className="p-2 overflow-hidden rounded-md bg-background"
        initialSelectedId="7"
        initialExpandedItems={initialExpandedItems}
        elements={tree}
      >
        {tree.map((node, index) => (
          <TreeNode
            key={node.id}
            node={node}
            folderClick={folderClick}
            index={index}
            activePath={activePath}
            isAccessible={isAccessible}
          />
        ))}
      </Tree>
    </div>
  );
}

export default FileTree;
