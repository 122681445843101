import React from "react";
import Chart from "react-apexcharts";

const SmallAreaPlot = ({ data, compareData }) => {
  // Split data into positive and negative values
  const positiveData = data?.map((val) => (val >= 0 ? val : null));
  const negativeData = data?.map((val) => (val < 0 ? val : null));
  // if (positiveData) {
  //   if (
  //     positiveData.slice(-1)[0] !== null &&
  //     positiveData.slice(-2)[0] === null
  //   ) {
  //     const y = negativeData.pop();
  //     negativeData.push(0);
  //     // negativeData.push(0);
  //     const x = positiveData.pop();
  //     positiveData.push(0);
  //     positiveData.push(x);

  //     console.log(positiveData, negativeData);
  //   }
  // }
  const generateColors = () => {
    return (
      data &&
      data.map((d, idx) => {
        let color = d > 0 ? "#00A6FB20" : "#FF000020";

        return {
          offset: (idx / (data.length - 1)) * 100,
          color,
          opacity: 1,
        };
      })
    );
  };
  const options = {
    chart: {
      height: "95px",
      width: "210px",
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [0.85, 1.5], // Increase the width for the dashed line
      dashArray: [0, 2], // Keep the dash pattern for second series
      colors: ["#00A6FB", "#98A6D4"], // Stroke colors
    },
    grid: {
      show: false,
    },
    markers: {
      size: [0, 0, 0],
    },
    fill: {
      type: ["gradient"],
      gradient: {
        shade: "light",
        type: "horizontal",
        // opacityFrom: 0.7,
        // opacityTo: 0.9,
        colorStops: generateColors(),
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: Math.min(data)[0],
    },
  };

  const series = [
    {
      name: "+ve Area Data",
      type: "area",
      data: data,
    },
    // {
    //   name: "-ve Area Data",
    //   type: "area",
    //   data: negativeData,
    // },
    // compareData?.length > 0 && {
    //   name: "Compare Data",
    //   type: "line",
    //   data: compareData,
    // },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width={`200`}
      height={`95`}
    />
  );
};

export default SmallAreaPlot;
