import React, { useState, useEffect, useRef } from "react";
import Toast from "../Common/Toast";
import { StyledFormControl, StyledFormLabel } from "../Common/functions";

import { startupMemberLogin } from "../../endpoints/startup";
import { investorMemberLogin } from "../../endpoints/investor";

import CustomInput from "../Common/components/custom/customInput";
import CustomPasswordInput from "../Common/components/custom/customPasswordInput";
import CustomBlackBtn from "../Common/components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../Common/components/custom/customBlackBtnPhone";

import { useLoader } from "../Common/LoaderProvider";

import { Formik, Form } from "formik";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as Yup from "yup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg"; // Fundrev logo
import "../../CSS/SignIn/signin.css";
import { Helmet } from "react-helmet";

const SignIn = ({ startPoint }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, sentemail } = location.state || {};
  const [time, setTime] = useState(5 * 60);
  const [cardType, setCardType] = useState(type || "Investor");
  const [token, setToken] = useState();
  const loader = useLoader();
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
  };
  // Validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    userPassword: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    userPassword: "",
  };

  // const handleSubmit = (values) => {
  //   console.log("Sign In values:", values);
  //   navigate("/dashboard"); // Navigate to dashboard or wherever after successful sign-in
  // };

  // Styled form label
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("role");
    if (userRole === "investor") {
      navigate("/investor/newDeals", {
        state: {
          token: token,
        },
      });
    } else if (userRole === "company") {
      navigate("/company/dashboard", {
        state: {
          tkn: token,
        },
      });
    }
    window.scrollTo(0, 0);
  }, []);
  const toggleButtonStyles = {
    textTransform: "capitalize !important",
    color: "#000",
    height: "32px",
    border: "none",
    padding: "18px 8px",
    fontSize: "16px",
    width: "50%",
    "&.Mui-selected": {
      backgroundColor: "#fff !important",
      borderRadius: "8px !important",
      color: "#000 !important",
      border: "1px solid #AEB3C1",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#115293",
    },
  };

  const handleSignInSubmit = async (values) => {
    // In try block there will be signin flow but handling all the cases except server error which is being handled by catch block

    try {
      if (cardType === "Investor") {
        loader.start("Signing In...");
        const signInData = {
          email: values?.email,
          password: values?.userPassword,
        };
        const responseData = await investorMemberLogin(signInData);
        loader.stop();
        if (responseData.data !== null) {
          const response = responseData.data;
          if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("role", "investor");
            // localStorage.setItem(
            //   "isApplicationReviewed",
            //   response.data.data.isApplicationReviewed
            // );
            // localStorage.setItem(
            //   "onBoardingComplete",
            //   response.data.data.onBoardingComplete
            // );
          }
          if (startPoint === "noNameDeals") {
            navigate("/investor/deals");
          } else {
            navigate("/investor/newDeals/recommended", {
              state: {
                token: response.data.data.token,
              },
            });
            // } else {
            //   navigate("/investorDetails", {
            //     state: {
            //       investorEmail: signInData.email,
            //       investorName: response.data.data.name,
            //       tkn: response.data.data.token,
            //     },
            //   });
            // }
            Toast("Login Successful", "success", "signIn");
          }
        } else if (responseData.error.response.status === 401) {
          Toast("Invalid Credentials", "error", "signIn");
        } else if (responseData.error.response.status === 403) {
          Toast(
            "Admin has not yet approved the joining request. Please wait till approval",
            "info",
            "signIn"
          );
        } else {
          Toast("Invalid Credentials", "error", "signIn");
        }
      } else if (cardType === "Startup") {
        loader.start("Signing In...");
        const signInData = {
          email: values?.email,
          password: values?.userPassword,
        };
        const responseData = await startupMemberLogin(signInData);
        loader.stop();
        if (responseData.data !== null) {
          const response = responseData.data;
          if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("role", "company");
            navigate("/company/dashboard");
          }
        } else if (responseData.error.response.status === 401) {
          Toast("Invalid Credentials", "error", "signIn");
        } else if (responseData.error.response.status === 403) {
          Toast(
            "Admin has not yet approved the joining request. Please wait till approval",
            "info",
            "signIn"
          );
        } else {
          Toast("Invalid Credentials", "error", "signIn");
        }
      }
    } catch {
      loader.stop();
      Toast("Internal server error", "error", "signIn");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignInSubmit();
    }
  };
  return (
    <div className="sign-in-page">
      <Helmet>
        <title>Fundrev | signin</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="signIn" />
      <div className="sign-in-page-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>

      <div className="sign-in-block">
        <div className="heading-block">
          <p className="fundrev-heading">Welcome Back!</p>
          <p className="fundrev-subheading">
            Please enter your details to sign in.
          </p>
        </div>
        <div className="middle-block">
          <ToggleButtonGroup
            color="primary"
            value={cardType}
            exclusive
            onChange={handleCardTypeChange}
            aria-label="card-type"
            sx={{
              backgroundColor: "#D2DCFF",
              padding: "8px 16px",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <ToggleButton value="Investor" sx={toggleButtonStyles}>
              Investor
            </ToggleButton>
            <ToggleButton value="Startup" sx={toggleButtonStyles}>
              Company
            </ToggleButton>
          </ToggleButtonGroup>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignInSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  width: "100%",
                }}
              >
                <div className="sign-in-input-fields">
                  <div className="first-section">
                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Email Address</StyledFormLabel>
                      <CustomInput
                        placeholder="Enter email address"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        handleKeyDown={handleKeyDown}
                      />
                    </StyledFormControl>

                    <StyledFormControl fullWidth>
                      <StyledFormLabel>Password</StyledFormLabel>
                      <CustomPasswordInput
                        placeholder="Enter password"
                        name="userPassword"
                        value={values.userPassword}
                        onChange={handleChange}
                        error={Boolean(
                          touched.userPassword && errors.userPassword
                        )}
                        helperText={touched.userPassword && errors.userPassword}
                        handleKeyDown={handleKeyDown}
                      />
                    </StyledFormControl>
                  </div>

                  <div className="forgot-password">
                    <p
                      onClick={() =>
                        navigate("/reset-password", {
                          state: {
                            role: cardType,
                          },
                        })
                      }
                    >
                      Forgot password
                    </p>
                  </div>
                </div>

                <div className="sign-in-btn-section laptop-design">
                  <CustomBlackBtn type="submit" text="Sign In" />
                  <p>
                    Don't have an account?{" "}
                    <Link to="/signup" className="link-blue-text">
                      Sign Up
                    </Link>
                  </p>
                </div>
                <div className="sign-in-btn-section phone-design">
                  <CustomBlackBtnPhone type="submit" text="Sign In" />
                  <p>
                    Don't have an account?{" "}
                    <Link to="/signup" className="link-blue-text">
                      Sign Up
                    </Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
