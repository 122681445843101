import React from "react";

const TermsAndConditionsSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">Our terms are straightforward</p>
      <p className="subheading">
        <b>2% fee</b> on each successful deal close, <b>no</b> onboarding fee,
        and <b>no </b>
        retainer
      </p>
      <p className="subheading">
        We're committed to helping you achieve a successful raise. Please agree
        to:
      </p>
      <ul
        style={{
          listStyleType: "decimal",
          marginLeft: "16px",
          color: "var(--Secondary-Text-Color)",
          fontSize: "14px",
        }}
      >
        <li>Avoid reaching out to investors outside the platform.</li>
        <li>Provide accurate information and keep it updated as needed.</li>
      </ul>
    </div>
  );
};

export default TermsAndConditionsSuggested;
