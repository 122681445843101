import React, { useEffect, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import File from "./file";
import tick from "../../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Tick.svg";
import { useFormikContext } from "formik";
import Toast from "../../../../Common/Toast";

import { fetchBlobName, fetchFullFileName } from "../../../../Common/functions";

const UploadFiles = ({
  filename,
  index,
  name,
  filesArray,
  fetchDetails,
  setSchema,
  validationSchema,
  lastHoveredIndex,
  setLastHoveredIndex,
  setIsUploading,
  documentsRequiredText,
}) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 300 * 1024 * 1024) {
      Toast(
        "Total file size should be less than 300MB",
        "error",
        "companyOnboarding"
      );
      return;
    }
    await setFieldValue(filesArray, files);
    await setSchema(null);
    setIsUploading(true);
    await submitForm().then(() => {
      setSchema(validationSchema);
    });
    await fetchDetails();
    handleUploadFiles();
    setIsUploading(false);
  };

  const handleMouseEnter = () => {
    setTimeout(() => {
      setLastHoveredIndex(index);
    }, [300]);
  };

  const handleDeleteFile = async (filename, index) => {
    const currentDeletedFiles = values.deletedFileNames || {};
    const updatedDeletedFiles = {
      ...currentDeletedFiles,
      [name]: [...(currentDeletedFiles[name] || []), filename],
    };

    setFieldValue("deletedFileNames", updatedDeletedFiles);
    setUploadedFiles((prevUploadedFiles) => [
      ...prevUploadedFiles.slice(0, index),
      ...prevUploadedFiles.slice(index + 1),
    ]);
    await setSchema(null);
    await submitForm().then(() => {
      setSchema(validationSchema);
    });
    await fetchDetails();
  };

  const handleUploadFiles = () => {
    setUploadedFiles((prev) => {
      const combinedFiles = new Set([
        ...prev,
        ...(Array.isArray(values[name]) ? values[name] : []),
      ]);
      return Array.from(combinedFiles);
    });
  };

  useEffect(() => {
    handleUploadFiles();
  }, [values[name]]);

  return (
    <>
      <div onMouseEnter={handleMouseEnter}>
        <div
          className="onboarding-uploadFiles flex-col overflow-hidden gap-2.5 items-start px-4 py-2 rounded bg-zinc-50 cursor-pointer relative"
          onClick={() => document.getElementById(`fileInput-${index}`).click()}
        >
          <input
            id={`fileInput-${index}`}
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div className="flex flex-wrap flex-1 shrink gap-10 justify-between items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col items-start text-sm text-center text-gray-500 min-w-[100%]">
              <div className="flex justify-between w-[100%]">
                <div className="flex gap-2 items-center font-medium">
                  <div className="bg-[#fff] p-[8px] text-[24px] rounded-[50%] text-[#000]">
                    <IoCloudUploadOutline />
                  </div>
                  <div className="self-stretch my-auto text-[14px]">
                    {index}.{" "}
                  </div>
                  <div className="self-stretch my-auto text-[14px]">
                    {filename}
                  </div>
                </div>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <div className="flex flex-row gap-[8px]">
                    <p className="text-[14px] text-[var(--Secondary-Text-Color)] flex flex-row gap-[8px] items-center">
                      No. of documents -{" "}
                      <div className="w-[24px] h-[24px] bg-[#D9D9D9] rounded-[50%] text-[var(--Primary-Text-Color)] flex justify-center items-center">
                        {uploadedFiles.length}
                      </div>
                    </p>
                    <img
                      loading="lazy"
                      src={tick}
                      className="object-contain w-[24px]"
                    />
                  </div>
                )}
              </div>

              <div className="font-light text-[14px] text-justify">
                {documentsRequiredText}
              </div>
            </div>
          </div>

          {/* Uploaded files list, displayed below the upload component */}
          {lastHoveredIndex === index && uploadedFiles.length > 0 && (
            <ul className="list-disc grid grid-cols-2 gap-[8px] mt-[8px]">
              {uploadedFiles.map((file, index) => (
                <File
                  key={index}
                  filename={fetchBlobName(file)}
                  onDelete={() =>
                    handleDeleteFile(fetchFullFileName(file), index)
                  }
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadFiles;
