import React, { useEffect, useState } from "react";
import Sidebar from "./Components/sidebar";
import Topbar from "./Components/topbar";
import OnGoingDealCard from "./Components/onGoingDealCard";
import Card from "../../Common/components/Card";
import { getDealsByInvestorID, getDealByStage } from "../../../endpoints/deal";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import {
  reducedStages,
  stageRenameMap,
  stageMap,
} from "../../Common/functions";

import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import "../../../CSS/InvestorPage/Dashboard/onGoingDeals.css";
import { useLoader } from "../../Common/LoaderProvider";

const OngoingDeals = () => {
  const loader = useLoader();
  const Navigate = useNavigate();
  const { type } = useParams();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [investorId, setInvestorId] = useState("");
  const [deals, setDeals] = useState([]);
  const [dealDetails, setDealDetails] = useState({});
  const [groupedDeals, setGroupedDeals] = useState([]);
  const [title, setTitle] = useState("");
  const [dealStages] = useState(reducedStages);
  // const open = localStorage.getItem("isApplicationReviewed");
  // useEffect(() => {
  //   if (open === "false") {
  //     Navigate("/investorDetails");
  //   }
  // });
  // const [filteredDeals, setFilteredDeals] = useState([]);
  function dealsLength(type) {
    try {
      const length = type.length ? type.length : 0;
      return length.toString().padStart(2, "0");
    } catch (error) {
      return "00";
    }
  }
  const fetchDetails = async () => {
    const token = localStorage.getItem("token");
    let response;
    if (token) {
      response = await fetchInvestorMemberDetails(token);
    }
    if (token) {
      if (response && response.data !== null) {
        setName(response.data.data.name);
        setInvestorId(response.data.data.investorID);
      } else {
      }
    }
  };
  useEffect(() => {
    loader.start("Fetching deals...");
    fetchDetails();
  }, [token]);

  useEffect(() => {
    const fetchDeals = async () => {
      if (investorId) {
        try {
          loader.start("Fetching deals...");
          const response = await getDealsByInvestorID(investorId, token);
          setDeals(response.data);
        } catch (error) {
          loader.stop();
        }
      }
    };

    fetchDeals();
  }, [investorId]);

  useEffect(() => {
    const fetchDealDetails = async () => {
      const details = {};
      if (token) {
        for (const deal of deals) {
          try {
            const response = await getDealByStage(deal.DealID, token);
            details[deal.DealID] = response.data;
          } catch (error) {}
        }
        setDealDetails(details);
        loader.stop();
      }
      setDealDetails(details);
    };
    if (deals) {
      fetchDealDetails();
    } else if (deals.length === 0 && investorId) {
      loader.stop();
    }
  }, [deals]);

  const renderDealCard = (deal, stage, index) => {
    const details = dealDetails[deal.DealID];
    if (!details) return null;
    // const latestActivity = getFormattedDate(deal.createdAt);
    const latestActivity = moment(deal.createdAt)
      .tz("Asia/Kolkata")
      .format("D MMM YYYY");
    let EndMarketFocus;

    try {
      EndMarketFocus = JSON.parse(details.EndMarketFocus);
    } catch (error) {
      EndMarketFocus = details.EndMarketFocus;
    }
    return (
      <OnGoingDealCard
        index={index}
        key={deal.DealID}
        requestedDate={
          deal.Stage === "requestSent" ? latestActivity : undefined
        }
        submittedDate={
          deal.Stage === "ioiSubmitted" || stage === "loiSubmitted"
            ? latestActivity
            : undefined
        }
        acceptedDate={
          deal.Stage === "requestAccepted" ||
          deal.Stage === "ioiAccepted" ||
          deal.Stage === "loiAccepted"
            ? latestActivity
            : undefined
        }
        IOIBidSubmittedAmount={
          deal.Stage === "ioiSubmitted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        IOIBidAcceptedAmount={
          deal.Stage === "ioiAccepted"
            ? deal?.IOI_Bid_Details?.investmentAmount
            : undefined
        }
        LOIBidSubmittedAmount={
          deal.Stage === "loiSubmitted"
            ? deal?.LOI_Bid_Details?.investmentAmount
            : undefined
        }
        LOIBidAcceptedAmount={
          deal.Stage === "loiAccepted"
            ? deal.LOI_Bid_Details.investmentAmount
            : undefined
        }
        closingDate={deal.Stage === "dealClosed" ? latestActivity : undefined}
        deal={details}
        stage={deal.Stage}
        isInterested={deal.IsInterested}
        isRejected={deal.IsRejected}
      />
    );
  };

  // const handleTabClick = (tab) => {
  //   setSelectedTab(tab);
  // };
  const handleGroupedFilteredDeals = () => {
    // Filter deals based on the `type`
    let filteredDeals;
    switch (type) {
      case "current":
        filteredDeals = deals
          ? deals.filter((deal) => deal.IsInterested === true)
          : [];
        break;
      case "dead":
        filteredDeals = deals
          ? deals.filter(
              (deal) => deal.IsInterested === false || deal.IsRejected === true
            )
          : [];
        break;
      case "watchlist":
        filteredDeals = deals
          ? deals.filter((deal) => deal.Stage === "watchlist")
          : [];
        break;
      default:
        filteredDeals = deals || [];
    }

    // Now, group the filtered deals by stage
    const groupedDeals = dealStages.reduce((acc, stage) => {
      acc[stage] = filteredDeals.filter(
        (deal) => stageMap[deal.Stage] === stage
      );
      return acc;
    }, {}); // Initialize with an empty object as the accumulator

    // Set the grouped deals
    setGroupedDeals(groupedDeals);
  };

  useEffect(() => {
    handleGroupedFilteredDeals();
  }, [type, deals]);
  useEffect(() => {
    if (!type) {
      Navigate("/investor/ongoingDeals/current");
    }
  });
  useEffect(() => {
    setTitle(
      type === "current"
        ? "Ongoing Deals"
        : type === "dead"
        ? "dead"
        : type === "watchlist"
        ? "watchlist"
        : "Ongoing Deals"
    );
  }, [type]);
  return (
    <div className="InvestorSide onGoingDeals-section newOngoingDeals">
      <Helmet>
        <title>Investor | Ongoing Deals</title>
      </Helmet>
      <Sidebar active="ongoingDeals" type={type} />
      <Topbar title={title} />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="onGoingDeals-div">
            {dealStages.map((stage, index) => (
              <div
                key={index}
                className="onGoingDeals-col"
                id={stage}
                style={{
                  display:
                    (type === "watchlist") === (stage === "watchlist")
                      ? "flex"
                      : "none",
                }}
              >
                <div className="onGoingDeals-types-div">
                  <div className="onGoingDeals-types-div-2">
                    <div className="onGoingDeals-types-div-3">
                      <div className="onGoingDeals-types-div-4">
                        {stageRenameMap[stage]}
                      </div>
                      <div className="onGoingDeals-types-div-5">
                        {dealsLength(groupedDeals[stage])}
                      </div>
                    </div>
                  </div>
                  <div className="onGoingDeals-allCards">
                    {groupedDeals &&
                      groupedDeals[stage] &&
                      groupedDeals[stage].map((deal, index) =>
                        renderDealCard(deal, stage, index)
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingDeals;
