import React, { useState, useEffect } from "react";
import Logo from "../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import whiteLogo from "../../Assets/Images/LandingPage/FUNDREV_LOGO_WHITE.svg";
import arrowBlueRight from "../../Assets/Images/LandingPage/arrow-blue-right.svg";
import arrowWhiteRight from "../../Assets/Images/LandingPage/arrow-small-right.svg";
import { Link, useNavigate } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./loader";
import "../../CSS/Header/header.css";
import { GiHamburgerMenu } from "react-icons/gi";
const Header = (props) => {
  const Navigate = useNavigate();
  const [cardType, setCardType] = useState();
  const handleCardTypeChange = (event, newCardType) => {
    if (newCardType !== null) setCardType(newCardType);
    if (newCardType === "Investors") {
      Navigate("/investor");
    } else if (newCardType === "Founders") {
      Navigate("/Founder");
    }
  };
  useEffect(() => {
    if (props.type === "investor") {
      setCardType("Investors");
    } else if (props.type === "founder") {
      setCardType("Founders");
    }
  }, [props]);
  const [headerBackground, setHeaderBackground] = useState("transparent");
  const color = props.color;
  const anticolor =
    color === "white" || color === "#193946" ? "black" : "white";
  const [linkbg, setLinkbg] = useState(color);
  const [logo, setLogo] = useState(
    (color === "white" || color === "#193946") &&
      props.type !== "investor" &&
      props.type !== "founder"
      ? whiteLogo
      : Logo
  );
  const handleScroll = () => {
    // const elements = document.getElementsByClassName("sign-in-span");
    if (window.scrollY > 100) {
      // elements[0].style.color = "#00A6FB";
      // elements[1].style.color = "#00A6FB";

      color === "#193946"
        ? setHeaderBackground("#193946")
        : setHeaderBackground("#002132");
      color === "white"
        ? setLinkbg(anticolor)
        : color === "#193946"
        ? setLinkbg("#FFF")
        : setLinkbg(color);
      color === "white"
        ? setLogo(Logo)
        : setLogo(
            props.type !== "investor" && props.type !== "founder"
              ? whiteLogo
              : Logo
          );
    } else {
      // elements[0].style.color = props.type === "landing" && "#FFF";
      // elements[1].style.color = props.type === "landing" && "#FFF";
      color === "white"
        ? setHeaderBackground("#002132")
        : color === "#193946"
        ? setHeaderBackground("#193946")
        : setHeaderBackground("#fff");
      color === "white"
        ? setLogo(
            props.type !== "investor" && props.type !== "founder"
              ? whiteLogo
              : Logo
          )
        : color === "#193946"
        ? setLogo(whiteLogo)
        : setLogo(Logo);
      color === "#193946" ? setLinkbg("#FFF") : setLinkbg(color);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (color === "#193946") {
      setHeaderBackground("#193946");
      setLinkbg("white");
    }
  }, [color]);
  return (
    <section className="Header">
      <div className="landing-header" style={{ backgroundColor: "#002132" }}>
        <div>
          <Link to="/">
            {" "}
            <img className="company-logo" src={whiteLogo} />
          </Link>
        </div>
        <div className="cm-header-wrap">
          <div className="cm-menu-wrap">
            <div
              className="cm-menu-btn fa fa-align-right"
              style={{ color: "#fff" }}
            >
              <GiHamburgerMenu />
            </div>
            <div
              className="cm-menu-inner"
              style={{ backgroundColor: "#002132" }}
            >
              <ul className="menu-ul clear-all">
                <li className="has-child phone-header">
                  <Link
                    to="/investor"
                    className="text-wrapper-2"
                    style={{
                      fontWeight: props.type === "investor" ? 600 : 300,
                    }}
                  >
                    For Investors
                  </Link>
                  <Link to="/investor" className="arrow-link">
                    <img src={arrowBlueRight} className="bluearrowright"></img>
                  </Link>
                </li>
                <li className="has-child phone-header">
                  <Link
                    to="/founder"
                    className="text-wrapper-2"
                    style={{
                      fontWeight: props.type === "founder" ? 600 : 300,
                    }}
                  >
                    For Founders
                  </Link>
                  <Link to="/founder" className="arrow-link">
                    <img src={arrowBlueRight} className="bluearrowright"></img>
                  </Link>
                </li>
                <ToggleButtonGroup
                  color="primary"
                  value={cardType}
                  exclusive
                  onChange={handleCardTypeChange}
                  aria-label="card-type"
                  className="landing-page-toggle laptop-header"
                >
                  <ToggleButton
                    className="landing-page-toggle-button-1"
                    value="Investors"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#c2f970 !important",
                        borderRadius: "100px",
                        color: "#000 !important",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#115293", // Customize hover when selected
                      },
                    }}
                  >
                    For Investors
                  </ToggleButton>
                  <ToggleButton
                    className="landing-page-toggle-button-2"
                    value="Founders"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#c2f970 !important",
                        borderRadius: "100px",
                        color: "#000 !important",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#115293", // Customize hover when selected
                      },
                    }}
                  >
                    For Founders
                  </ToggleButton>
                </ToggleButtonGroup>
                {/* <button className="get-started mobile-stats">
                  <div className="content">
                    <div className="text-wrapper">
                      <Link
                        to="/signup"
                        state={{ active: "investor" }}
                        className="get-started-link"
                      >
                        Get Started
                      </Link>
                      <img
                        src={arrowWhiteRight}
                        className="whitearrowright"
                      ></img>
                    </div>
                  </div>
                </button> */}

                {/* <AnimatedShinyText bgcolor={"#FFFFFF"} color={"#000"} /> */}
                {/* <AnimatedShinyText
                  bgcolor={"[#00A6FB]"}
                  color={"#fff"}
                  type="sign-in"
                /> */}
                <div className="landing-CTA-btns">
                  <Link to="/signin">
                    <button className="landing-login-btn">Login</button>
                  </Link>
                  <Link to="/signup">
                    <button className="landing-signin-btn">Get Started</button>
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
