import React, { useEffect, useState } from "react";

import CustomInput from "../../../Common/components/custom/customInput";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import CustomPhoneInput from "../../../Common/components/custom/customPhoneInput";
import { isProfileComplete } from "./profileCompletion";

import { StyledFormControl, StyledFormLabel } from "../../../Common/functions";

import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../../Common/LoaderProvider";
import Toast from "../../../Common/Toast";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import {
  fetchStartupDetails,
  getProfileCompletionPercentage,
  PostDetails,
} from "../../../../endpoints/startup";
import { matchIsValidTel } from "mui-tel-input";
import { GoPlus } from "react-icons/go";
import { useRecoilState } from "recoil";
import { companyState } from "../companyState";

const FoundingTeamProfile = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [startupID, setStartupID] = useState();
  const [state, setState] = useRecoilState(companyState);
  const [profileImagePreview, setProfileImagePreview] = useState([uploadImg]);
  const [foundingMember, setFoundingMember] = useState([]);
  const [companyDetails, setCompanyDetails] = useState("");
  const [initialValues, setInitialValues] = useState({
    foundingMember: [
      {
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
        foundingTeamProfile: null,
      },
    ],
    isApproved: false,
  });

  const validationSchema = Yup.object().shape({
    foundingMember: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Full Name is required"),
        title: Yup.string().required("Title is required"),
        email: Yup.string()
          .email("Enter a valid Email")
          .required("Email is required"),
        linkedin: Yup.string()
          .url("Enter a valid LinkedIn URL")
          .required("LinkedIn is required"),
        phoneNumber: Yup.string()
          .required("Phone Number is required")
          .test("invalid", "Please enter a valid phone number", (value) =>
            matchIsValidTel(value)
          ),
        biography: Yup.string().required("Biography is required"),
        foundingTeamProfile: Yup.mixed().required("Profile Image is required"),
      })
    ),
  });

  const fetchDetails = async () => {
    if (!token) return;
    try {
      loader.start();
      const response = await fetchStartupDetails(token);
      loader.stop();
      if (response?.data !== null) {
        const data = response.data.data;
        setInitialValues({
          foundingMember: (() => {
            try {
              return JSON.parse(data?.foundingMember);
            } catch (error) {
              return [
                {
                  name: "",
                  title: "",
                  email: "",
                  linkedin: "",
                  phoneNumber: "",
                  biography: "",
                  foundingTeamProfile: null,
                },
              ];
            }
          })(),
          isApproved: data.isApproved,
        });
        setCompanyDetails(data);
        setFoundingMember(data.foundingMember);
        setStartupID(data.startupID);

        // Set profile image previews
        if (data.foundingMember) {
          const members = JSON.parse(data.foundingMember);
          setProfileImagePreview(
            members.map((member) => member.foundingTeamProfile || uploadImg)
          );
        }
      }
    } catch (error) {
      loader.stop();
      // console.error(error);
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentage: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);

  // const postFormData = async (data) => {
  //   const formDataInput = new FormData();
  //   for (const member of data.foundingMember) {
  //     for (const key in member) {
  //       if (member.hasOwnProperty(key)) {
  //         if (member[key]) {
  //           if (
  //             typeof member[key] === "object" &&
  //             !(member[key] instanceof File)
  //           ) {
  //             formDataInput.append(key, JSON.stringify(member[key]));
  //           } else {
  //             formDataInput.append(key, member[key]);
  //           }
  //         } else {
  //           formDataInput.append(key, member[key]);
  //         }
  //       }
  //     }
  //   }
  //   try {
  //     loader.start();
  //     const response = await PostDetails(formDataInput, token);
  //     loader.stop();
  //     if (response) {
  //       Toast("Details saved successfully", "success", "editTeamProfile");
  //     }
  //   } catch (error) {
  //     loader.stop();
  //   }
  // };
  const postFormData = async (data) => {
    const formDataInput = new FormData();
    const changedIndices = [];
    const foundingMembersJSON = JSON.stringify(data["foundingMember"]) || "[]";
    data.profileCompleted = isProfileComplete(
      companyDetails,
      foundingMembersJSON
    );
    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Check if the property is an array or an object that needs to be stringified
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "foundingMember") {
              data[key].map((item, index) => {
                if (item.foundingTeamProfile instanceof File) {
                  formDataInput.append(
                    "foundingTeamProfile",
                    item.foundingTeamProfile
                  );
                  changedIndices.push(index);
                }
              });
              formDataInput.append(key, JSON.stringify(data[key]));
            } else {
              formDataInput.append(key, JSON.stringify(data[key]));
            }
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (changedIndices.length > 0) {
      formDataInput.append("changedIndices", JSON.stringify(changedIndices));
    }
    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }
    try {
      loader.start();
      const response = await PostDetails(formDataInput, token);
      if (response.data) {
        Toast(
          "Founding team details successfully updated",
          "success",
          "editCompanyProfile"
        );
        profileCompletionPercentage();
      }
      loader.stop();
      if (response) {
      }
    } catch (error) {
      Toast("Error updating team details", "error", "editCompanyProfile");
      loader.stop();
    }
  };
  return (
    <div className="FoundingTeamProfile fundrev-card">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={postFormData}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <FieldArray name="foundingMember">
              {({ push, remove }) => (
                <>
                  {values.foundingMember.map((member, index) => (
                    <div key={index} className="editTeamProfile-form">
                      <div className="editTeamProfile-form-upload-btn-wrapper">
                        <button
                          type="button"
                          className="upload-img-btn"
                          onClick={() =>
                            document
                              .getElementById(`foundingTeamProfile-${index}`)
                              .click()
                          }
                        >
                          <img
                            src={profileImagePreview[index] || uploadImg}
                            className="InvestorLogo"
                            alt="profile"
                            style={{ cursor: "pointer" }}
                          />
                        </button>

                        <input
                          id={`foundingTeamProfile-${index}`}
                          type="file"
                          name={`foundingMember[${index}].foundingTeamProfile`}
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setFieldValue(
                                `foundingMember[${index}].foundingTeamProfile`,
                                file
                              );
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                setProfileImagePreview((prev) => {
                                  const newPreviews = [...prev];
                                  newPreviews[index] = reader.result;
                                  return newPreviews;
                                });
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                        {errors.foundingMember?.[index]
                          ?.foundingTeamProfile && (
                          <div className="error text-[#d32f2f] text-xs ">
                            {errors.foundingMember[index].foundingTeamProfile}
                          </div>
                        )}
                      </div>
                      <div className="team-profile-input-fields">
                        <div className="Add-member-button">
                          {index > 0 && (
                            <CustomBlackBtn
                              text="Remove Member"
                              border="sm"
                              mode="danger"
                              filled={false}
                              onClick={() => remove(index)}
                            />
                          )}
                          {index === 0 && (
                            <CustomBlackBtn
                              text={
                                <>
                                  <GoPlus />
                                  Add Member
                                </>
                              }
                              border="sm"
                              mode="light"
                              filled={false}
                              onClick={() =>
                                push({
                                  name: "",
                                  title: "",
                                  email: "",
                                  linkedin: "",
                                  phoneNumber: "",
                                  biography: "",
                                  foundingTeamProfile: null,
                                })
                              }
                            />
                          )}
                        </div>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Full Name</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Full Name"
                            name={`foundingMember[${index}].name`}
                            value={member.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.name &&
                                errors.foundingMember?.[index]?.name
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.name &&
                              errors.foundingMember?.[index]?.name
                            }
                          />
                        </StyledFormControl>

                        {/* Title */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Designation</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Designation"
                            name={`foundingMember[${index}].title`}
                            value={member.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.title &&
                                errors.foundingMember?.[index]?.title
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.title &&
                              errors.foundingMember?.[index]?.title
                            }
                          />
                        </StyledFormControl>

                        {/* Email */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Email</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter Email"
                            name={`foundingMember[${index}].email`}
                            type="email"
                            value={member.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.email &&
                                errors.foundingMember?.[index]?.email
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.email &&
                              errors.foundingMember?.[index]?.email
                            }
                          />
                        </StyledFormControl>

                        {/* LinkedIn */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>LinkedIn</StyledFormLabel>
                          <CustomInput
                            placeholder="Enter LinkedIn Profile URL"
                            name={`foundingMember[${index}].linkedin`}
                            type="url"
                            value={member.linkedin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.foundingMember?.[index]?.linkedin &&
                                errors.foundingMember?.[index]?.linkedin
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.linkedin &&
                              errors.foundingMember?.[index]?.linkedin
                            }
                          />
                        </StyledFormControl>

                        {/* Phone Number */}
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Phone Number</StyledFormLabel>

                          <CustomPhoneInput
                            value={member.phoneNumber}
                            onChange={(value) =>
                              setFieldValue(
                                `foundingMember[${index}].phoneNumber`,
                                value
                              )
                            }
                            error={Boolean(
                              touched.foundingMember?.[index]?.phoneNumber &&
                                errors.foundingMember?.[index]?.phoneNumber
                            )}
                            helperText={
                              touched.foundingMember?.[index]?.phoneNumber &&
                              errors.foundingMember?.[index]?.phoneNumber
                            }
                          />
                        </StyledFormControl>

                        {/* Biography */}
                        <StyledFormControl></StyledFormControl>
                        <div
                          style={{
                            gridColumn: "1/-1",
                          }}
                        >
                          <StyledFormControl fullWidth>
                            <StyledFormLabel>Biography</StyledFormLabel>
                            <CustomInput
                              placeholder="Enter Biography"
                              name={`foundingMember[${index}].biography`}
                              value={member.biography}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.foundingMember?.[index]?.biography &&
                                  errors.foundingMember?.[index]?.biography
                              )}
                              helperText={
                                touched.foundingMember?.[index]?.biography &&
                                errors.foundingMember?.[index]?.biography
                              }
                              multiline
                              minRows={3}
                            />
                          </StyledFormControl>
                        </div>
                      </div>
                      {/* <div className="remove-member-button">
                        <CustomBlackBtn
                          type="button"
                          text="Remove Member"
                          onClick={() => remove(index)}
                        />
                      </div> */}
                    </div>
                  ))}
                  {/* <div className="add-member-button">
                    <CustomBlackBtn
                      type="button"
                      text="Add Member"
                      onClick={() => push(initialValues.foundingMember[0])}
                    />
                  </div> */}
                </>
              )}
            </FieldArray>

            <div className="editCompanyDetails-submitBtn-section">
              <CustomBlackBtn type="submit" text="Save" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FoundingTeamProfile;
