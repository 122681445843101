import React from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Topbar from "../Company/Deal/topbar";
import InvestorSidebar from "../Investor/Onboarding/investorSidebar";
import { Box, Typography } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";

const UnifiedInvestorPreview = () => {
  const location = useLocation();
  const { investor } = location.state;
  return (
    <>
      <div className="InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer
          position="top-center"
          containerId={`unifiedInvestorPreview`}
        />
        <InvestorSidebar active={`investorPreview`} />
        <Topbar title="Unified Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div">
              <div className="companyReview">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="investorDetails-subheading"
                    style={{
                      display: "flex",
                    }}
                  >
                    Investor Overview
                  </p>
                  <button className="investor-id">{investor.uid}</button>
                </div>
                <Box
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="h5">{investor.investor_name}</Typography>
                  <Typography>
                    {" "}
                    Description:{" "}
                    {investor.investor_description || `No description`}
                  </Typography>
                  <Typography>
                    Onboarded_Id :{" "}
                    {investor.oboarded_investor_id || `Not onboarded`}{" "}
                  </Typography>
                  <Typography>
                    Investment Thesis :{" "}
                    {investor.investment_thesis || `No investment thesis`}{" "}
                  </Typography>
                </Box>
              </div>
              <div className="FundingDetails">
                <p className="investorDetails-subheading">Investment Focus</p>
                <Box>
                  <Typography>AUM : {investor.aum || `No aum`}</Typography>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: "center",
                    }}
                  >
                    Ticket Size : <CurrencyRupee fontSize="10px" />
                    {investor.ticket_size[`min`]} -{" "}
                    {investor.ticket_size[`max`]} Cr
                  </Typography>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: "center",
                    }}
                  >
                    Total Co Invested Amount : <CurrencyRupee fontSize="10px" />
                    {investor.total_coInvested_amount} Cr
                  </Typography>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnifiedInvestorPreview;
