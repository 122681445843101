import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getAllInvestors } from "../../endpoints/investor";
import { useLocation, useNavigate } from "react-router-dom";
import { setToggleApprovalInvestor } from "../../endpoints/admin";

const Investors = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [investors, setInvestors] = useState([]);
  const token = localStorage.getItem("token");
  const fetchAllInvestors = async () => {
    try {
      const response = await getAllInvestors(token);
      setInvestors(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchAllInvestors();
  }, []);
  const toggleApproval = async (id) => {
    try {
      const response = await setToggleApprovalInvestor(id, token);
      fetchAllInvestors();
    } catch (error) {
      console.error("Error toggling approval:", error);
    }
  };
  return (
    <>
      <div className="admin-panel-investors">
        <ToastContainer position="top-center" containerId="investorPanel" />
        <div className="InvestorSide-box table-container">
          <table>
            <thead>
              <tr>
                <th>Investor Firm Name</th>
                <th>Website</th>
                <th>Investor ID</th>
                <th>Profile Completed</th>
                <th>Preview</th>
                <th>Approve</th>
              </tr>
            </thead>
            <tbody>
              {investors?.map((investor) => (
                <tr key={investor.investorId}>
                  <td>{investor.companyName}</td>
                  <td>
                    <a
                      href={`https:\\${investor.investorWebsite}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {investor.investorWebsite}
                    </a>
                  </td>
                  <td>{investor.investorID}</td>
                  <td>
                    {investor.profileCompleted ? "Completed" : "Not Completed"}
                  </td>
                  <td>
                    <button
                      className="toggleStatusbtn"
                      onClick={() =>
                        navigate("/admin/investor/preview", {
                          state: {
                            investor,
                            state: "investors",
                          },
                        })
                      }
                    >
                      Preview
                    </button>
                  </td>
                  <td>
                    <button
                      className={`toggleStatusbtn ${
                        investor.isApplicationReviewed
                          ? "Approved-btn"
                          : "Pending-btn"
                      }`}
                      onClick={() => toggleApproval(investor._id)}
                    >
                      {investor.isApplicationReviewed ? "Approved" : "Pending"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Investors;
