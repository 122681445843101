import * as React from "react";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";
import NoDeals from "../../../../Assets/Images/InvestorPage/Dashboard/noDeals.gif";
function CompleteProfile() {
  const Navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center p-6 text-base bg-white rounded-3xl shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 max-w-[850px]  mx-auto mt-[28px]">
      <div className="flex flex-col items-center max-md:max-w-full">
        <div className="flex flex-col items-center text-center max-md:max-w-full">
          <div className="font-medium text-zinc-900 max-md:max-w-full">
            Your profile is incomplete!
          </div>
          <div className="mt-2 leading-7 text-gray-500 max-md:max-w-full">
            Complete it to start creating deals on the platform.
          </div>
        </div>
        <img
          loading="lazy"
          srcSet={NoDeals}
          className="object-contain mt-6 w-60 max-w-full aspect-square"
        />
        <div
          onClick={() => {
            Navigate("/company/editProfile");
          }}
        >
          <CustomBlackBtn type="button" text="Complete Profile" />
        </div>
      </div>
    </div>
  );
}

export default CompleteProfile;
