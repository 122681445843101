import React, { useState, useRef, useEffect } from "react";
import CardTags from "./molecules/cardTags";
import Finanicals from "../../Investor/Dashboard/Components/finanicals";
import { addDealToWishlist, removeDealFromWishlist } from "../functions";
import FundrevAnalystDetails from "../../Common/components/fundrevAnalystDetails";

import { investorState } from "../../Investor/Dashboard/investorState";

import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import "../../../CSS/FounderPage/Dashboard/noNamePreview.css";
import ProgressBar from "./molecules/progressBar";
import { dumpInvestorInteraction } from "../../../endpoints/common";
const NoNamePreview = ({
  deal,
  stage,
  isStar,
  setisStar,
  dealID,
  cardTags,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 135;
  const overviewRef = useRef(null);
  const keyhighlightsRef = useRef(null);
  const purposeRef = useRef(null);
  const financialsRef = useRef(null);
  const contactRef = useRef(null);
  const location = useLocation();
  const Navigate = useNavigate();
  const { passedStage, isPassed } = location.state || {};
  const { investorId, name } = useRecoilValue(investorState);
  const [activeSection, setActiveSection] = useState(1);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal.DealID,
            type: "view",
            page: "noNameDetails",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);

  const handleAddToWishlist = async (startupId, investorId) => {
    setisStar(true);
    await addDealToWishlist(startupId, investorId);
  };

  const handleRemoveFromWishlist = async (startupId, investorId) => {
    setisStar(false);
    await removeDealFromWishlist(startupId, investorId);
  };
  const handleActiveSection = (num) => {
    setActiveSection(num);

    const sectionRefs = {
      1: overviewRef,
      2: keyhighlightsRef,
      3: purposeRef,
      4: financialsRef,
      5: contactRef,
    };

    const container = document.getElementById("scrollContainer");

    if (sectionRefs[num]?.current) {
      const sectionTop =
        sectionRefs[num].current.offsetTop - container.offsetTop;
      container.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="NoNamePreview">
      {/* <div
        className="cdetails-div"
        style={{ margin: stage === "companyOnBoarding" && "0px" }}
      >
        <div className="cdetails-first-div">
          <div className="cdetails-div-2">{deal ? deal.Title : ""}</div>
          {isPassed !== true && (
            <>
              {stage === "companyOnBoarding" ? (
                ""
              ) : stage === "initialStage" && isStar === false ? (
                <button
                  className="add-to-wishlist"
                  onClick={() =>
                    handleAddToWishlist(
                      deal ? deal.OrganizationID : "",
                      investorId
                    )
                  }
                >
                  <img src={star} alt="star" />
                  Add to wishlist
                </button>
              ) : (
                stage === "initialStage" &&
                isStar === true && (
                  <button
                    className="add-to-wishlist"
                    onClick={() =>
                      handleRemoveFromWishlist(
                        deal ? deal.OrganizationID : "",
                        investorId
                      )
                    }
                  >
                    <img src={filledStar} alt="filled star" />
                    Added to wishlist
                  </button>
                )
              )}
              {stage === "requestSent" && (
                <button className="stage-display-btn req-pending">
                  Approval Pending
                </button>
              )}
              {stage === "requestAccepted" && (
                <button className="stage-display-btn req-accepted">
                  Request Accepted
                </button>
              )}
            </>
          )}
        </div>
        <div className="laptop-stats">
          <p
            className="cdetails-div-3"
            style={{ display: "inline-block", whiteSpace: "normal" }}
          >
            {deal ? deal.SubHeading : ""}
          </p>
        </div>
        <div className="mobile-stats">
          <p className="cdetails-subheading">{deal ? deal.SubHeading : ""}</p>
        </div>
        <div
          className="setInnerHTML cdetails-div-15"
          dangerouslySetInnerHTML={{ __html: deal ? deal.Description : "" }}
        />
        <CardTags deal={deal} page={"noNameDetailsPage"} cardTags={cardTags} />
        <div className="cdetails-div-11" />
        <div className="cdetails-div-12">Key Highlights</div>
        <div className="cdetails-div-13">
          <div className="cdetails-div-14">
            <div className="column">
              <div
                className="cdetails-div-15 key-highlights-col"
                style={{ marginLeft: "30px" }}
              ></div>
              <div
                className="setInnerHTML cdetails-div-15"
                dangerouslySetInnerHTML={{
                  __html: deal ? deal.KeyHighlights : "",
                }}
              />
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <div className="cdetails-div-12">Purpose Of Fundraising</div>
        <div className="cdetails-div-13">
          <div className="cdetails-div-14">
            <div className="column">
              <div
                className="setInnerHTML cdetails-div-15"
                dangerouslySetInnerHTML={{
                  __html: deal ? deal.PurposeOfFundraising : "",
                }}
              />
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <Finanicals deal={deal} page="noNamePreview" />
        <div className="cdetails-div-11" />
        <div className="cdetails-div-60">Data Availability with Fundrev</div>
        <div className="cdetails-div-61">
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal && deal.DataAvailabilityWithFundrev
                    ? deal.DataAvailabilityWithFundrev.CustomerData === "true"
                      ? greenTick
                      : redMark
                    : ""
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Customer data</div>
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal && deal.DataAvailabilityWithFundrev
                    ? deal.DataAvailabilityWithFundrev.Financial === "true"
                      ? greenTick
                      : redMark
                    : greenTick
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Financial</div>
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal && deal.DataAvailabilityWithFundrev
                    ? deal.DataAvailabilityWithFundrev.Dataroom === "true"
                      ? greenTick
                      : redMark
                    : greenTick
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Dataroom</div>
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal && deal.DataAvailabilityWithFundrev
                    ? deal.DataAvailabilityWithFundrev.ForecastModel === "true"
                      ? greenTick
                      : redMark
                    : greenTick
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Forecast Model</div>
              </div>
            </div>
          </div>
          <div className="cdetails-div-62">
            <div className="cdetails-div-63">
              <img
                loading="lazy"
                src={
                  deal && deal.DataAvailabilityWithFundrev
                    ? deal.DataAvailabilityWithFundrev
                        .ManagementPresentation === "true"
                      ? greenTick
                      : redMark
                    : greenTick
                }
                className="cdetails-img-3"
              />
              <div className="cdetails-div-66">
                <div className="cdetails-div-64">Management Presentation</div>
              </div>
            </div>
          </div>
        </div>
        <div className="cdetails-div-11" />
        <div style={{ marginBottom: stage !== "companyOnBoarding" && 80 }}>
          <FundrevAnalystDetails analystID={"FN123456"} />
        </div>
      </div> */}
      <div className="NoNamePreview-left">
        <div className="NoName-heading-block">
          <p className="noName-heading">{deal ? deal.Title : ""}</p>
          <p className="noName-subheading">
            {" "}
            <span>
              {deal &&
                deal?.SubHeading &&
                deal.SubHeading.length > maxLength && (
                  <>
                    {isExpanded
                      ? deal.SubHeading
                      : deal.SubHeading.substr(
                          0,
                          deal.SubHeading.lastIndexOf(" ", maxLength)
                        )}
                    {isExpanded ? (
                      <span
                        onClick={toggleExpand}
                        style={{ color: "#00A6FB", cursor: "pointer" }}
                      >
                        {" show less"}
                      </span>
                    ) : (
                      <span
                        onClick={toggleExpand}
                        style={{ color: "#00A6FB", cursor: "pointer" }}
                      >
                        {"... more"}
                      </span>
                    )}
                  </>
                )}
              {deal &&
                deal?.SubHeading &&
                deal.SubHeading.length <= maxLength && (
                  <span>{deal.SubHeading}</span>
                )}
            </span>
          </p>
          <CardTags deal={deal} page={"noNameDetailsPage"} />
          <div style={{ width: "100%" }}>
            <ProgressBar
              totalFundingRaised={deal?.TotalFundingRaised}
              fundingRequired={deal?.FundingRequired}
              roundType={deal?.RoundType}
            />
          </div>
        </div>
        <div className="noName-navigation">
          <div
            className={`noName-nav-btn ${
              activeSection === 1 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(1)}
          >
            <p>Company Overview</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 2 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(2)}
          >
            <p>Key Highlights</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 3 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(3)}
          >
            <p>Purpose of Fundraising</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 4 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(4)}
          >
            <p>Financials, Key Metrics & Other Information</p>
          </div>
          <div
            className={`noName-nav-btn ${
              activeSection === 5 && "selected-nav-btn"
            }`}
            onClick={() => handleActiveSection(5)}
          >
            <p>Contact Details</p>
          </div>
        </div>
      </div>
      <div className="NoNamePreview-right" id="scrollContainer">
        <div className="noNameDetails-block fundrev-card" ref={overviewRef}>
          <p className="fundrev-card-heading">Company Overview</p>
          <p
            className="setInnerHTML details"
            dangerouslySetInnerHTML={{ __html: deal ? deal.Description : "" }}
          />
        </div>
        <div
          className="noNameDetails-block key-highlights-section fundrev-card"
          ref={keyhighlightsRef}
        >
          <p className="fundrev-card-heading">Key Highlights</p>
          <p
            className="setInnerHTML details"
            dangerouslySetInnerHTML={{ __html: deal ? deal.KeyHighlights : "" }}
          />
        </div>
        <div className="noNameDetails-block fundrev-card" ref={purposeRef}>
          <p className="fundrev-card-heading">Purpose of Fundraising</p>
          <p
            className="setInnerHTML details"
            dangerouslySetInnerHTML={{
              __html: deal ? deal.PurposeOfFundraising : "",
            }}
          />
        </div>
        <div ref={financialsRef}>
          <Finanicals deal={deal} page="noNamePreview" />
        </div>
        <div className="fundrev-card noNameDetails-block" ref={contactRef}>
          <p className="fundrev-card-heading">Contact Details</p>
          <FundrevAnalystDetails analystID={"FN123456"} />
        </div>
      </div>
    </section>
  );
};

export default NoNamePreview;
