import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SmallAreaPlot from "./SmallAreaPlot";
import { ArrowDropDown, ArrowDropUp, eq } from "@mui/icons-material";
import { formatToLakhs } from "../../../../../utilities/numberFormatter";
const SmallCardGrid = ({ data }) => {
  function calculateRelative(x, y) {
    return ((x - y) / Math.abs(y)) * 100 || 0;
  }
  return (
    <>
      <Grid container spacing={2} marginBottom={3} marginTop={1}>
        {data?.map((item, index) => {
          const comparedPercent = !item.percentage
            ? calculateRelative(item?.value, item?.compareValue)
            : null;
          const flag = [
            "Monthly Burn & Runway",
            "Estimated Valuation",
          ].includes(item?.name);
          return (
            <Grid item xs="auto" md="auto" lg="auto" key={index}>
              <Box
                sx={{
                  width: "217px",
                  height: "198px",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: flag ? "normal" : "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Karla, sans-serif",
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  {item?.name}
                </Typography>
                <Box
                  sx={{
                    position: flag && "relative",
                    top: flag && "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontSize: flag ? "20px" : "18px",
                      fontWeight: "700",
                      marginLeft: "10px",
                    }}
                  >
                    {formatToLakhs(
                      item?.value,
                      item.format,
                      item.currency,
                      item.currency && item.format,
                      item.percentage
                    )}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {comparedPercent ? (
                      comparedPercent > 0 ? (
                        <ArrowDropUp
                          sx={{
                            color: "#00CD9E",
                          }}
                        />
                      ) : (
                        <ArrowDropDown
                          sx={{
                            color: "#D32F2F",
                          }}
                        />
                      )
                    ) : (
                      <p></p>
                    )}
                    {!flag && (
                      <Typography
                        sx={{
                          font: "Karla, sans-serif",
                          fontWeight: "500",
                          color: comparedPercent > 0 ? "#00CD9E" : "#D32F2F",
                          fontSize: "11px",
                        }}
                      >
                        {comparedPercent
                          ? formatToLakhs(
                              comparedPercent,
                              false,
                              false,
                              false,
                              true
                            )
                          : `-`}
                        &nbsp;
                      </Typography>
                    )}

                    <Typography
                      sx={{
                        font: "Karla, sans-serif",
                        fontWeight: "500",
                        color: `#8692A6`,
                        fontSize: flag ? "13px" : "11px",
                        marginLeft: flag && "5px",
                      }}
                    >
                      {!flag && `vs`} &nbsp;
                      {["-", "-%", "NaN%", "0.0%"].includes(
                        formatToLakhs(
                          item?.compareValue,
                          item.format,
                          item.currency,
                          item.currency && item.format,
                          item.percentage
                        )
                      )
                        ? `NA`
                        : formatToLakhs(
                            item?.compareValue,
                            item.format,
                            item.currency,
                            item.currency && item.format,
                            item.percentage
                          )}
                    </Typography>
                  </Box>
                </Box>
                {item.plotData && (
                  <SmallAreaPlot
                    data={item?.plotData}
                    compareData={item?.compareData}
                  />
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SmallCardGrid;
