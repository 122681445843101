import React from "react";

import { FormControl, FormLabel } from "@mui/joy";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import download from "../../../Assets/Images/signup/download.svg";
import Bin from "../../../Assets/Images/signup/bin.svg";
import tooltipIcon from "../../../Assets/Images/signup/tooltip.svg";
import { useLoader } from "../../Common/LoaderProvider";
import { handleFileDownload } from "../../Common/functions";

const FileUploadComponent = ({
  label,
  id,
  fileInfo,
  handleFileUpload,
  handleDeleteFile,
  toolTipInfo,
  noDownload,
  inputProps,
}) => {
  const loader = useLoader();
  function splitString(inputStr) {
    const maxLength = 25;

    if (inputStr.length <= maxLength) {
      return [inputStr];
    }

    let splitIndex = inputStr.lastIndexOf(" ", maxLength);

    if (splitIndex === -1) {
      splitIndex = maxLength;
    }

    const firstPart = inputStr.substring(0, splitIndex).trim();
    const secondPart = inputStr.substring(splitIndex).trim();

    return [firstPart, secondPart];
  }
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));
  return id === "randomInput" ? (
    <FormControl className="form-control investorDetails-row-input random-input" />
  ) : (
    <FormControl
      className="form-control investorDetails-row-input"
      size="lg"
      color="primary"
    >
      <div className="upload-file-div-download">
        <FormLabel className="label">
          <div className="upload-file-label">
            {splitString(label)[0]}
            {splitString(label)[1] && (
              <>
                <br />
                {splitString(label)[1]}
              </>
            )}
            {toolTipInfo !== "" && (
              <LightTooltip title={toolTipInfo} arrow>
                <IconButton>
                  <img src={tooltipIcon} className="tooltip-img" />
                </IconButton>
              </LightTooltip>
            )}
          </div>
        </FormLabel>

        <div className="upload-file-wrapper">
          <button className="upload-img-btn">
            <img src={download} alt="Upload" />
            <input
              multiple
              id={id}
              type="file"
              onChange={(e) => handleFileUpload(id, "fourth-step", e)}
              style={{ cursor: "pointer" }}
              {...inputProps}
            />
          </button>
        </div>
      </div>
      <div className="multiple-file-uploads">
        {fileInfo[id] &&
          fileInfo[id].map((file, index) => (
            <div className="fileUploads" key={index}>
              <div className="fileUploads-div-2">
                <p
                  className="fileLink"
                  onClick={() =>
                    !noDownload &&
                    handleFileDownload(
                      file.fileURL,
                      file.filename,
                      loader,
                      `companyOnboarding`
                    )
                  }
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div className="fileUploads-div-3">{file.filename}</div>
                </p>
              </div>
              <img
                cursor="pointer"
                loading="lazy"
                src={Bin}
                className="fileUploads-img"
                alt="Upload file"
                onClick={() =>
                  handleDeleteFile(id, index, "fourth-step", file.fullName)
                }
              />
            </div>
          ))}
      </div>
    </FormControl>
  );
};

export default FileUploadComponent;
