import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import Toast from "../../Common/Toast";
import star from "../../../Assets/Images/InvestorPage/Dashboard/star.svg";
import filledStar from "../../../Assets/Images/InvestorPage/Dashboard/filledStar.svg";
import pendingProfile from "../../../Assets/Images/InvestorPage/Dashboard/pendingProfile.svg";

import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { investorState } from "../../../Components/Investor/Dashboard/investorState";

import "react-circular-progressbar/dist/styles.css";
import "../../../CSS/InvestorPage/Dashboard/card.css";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
} from "../../../endpoints/investor";
import { getDealsByInvestorID } from "../../../endpoints/deal";
import ProgressBar from "./molecules/progressBar";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";
import CustomBlackBtn from "./custom/customBlackBtn";

const Card = ({
  deal,
  isStar,
  investorId,
  startPoint,
  isPassed,
  stage,
  investorVerified,
  profileCompleted,
  elementRef,
  index,
  setWishlistDealIds,
}) => {
  const {
    OrganizationID,
    Title,
    SubHeading,
    RoundType,
    IndustryVerticals,
    FundingType,
    EndMarketFocus,
    arr,
    mrrGrowth,
    grossMargin,
    monthlyBurn,
    FundingRequired,
    TotalFundingRaised,
    DealTags,
  } = deal;
  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [state, setState] = useRecoilState(investorState);
  const [cardTags, setCardTags] = useState([]);
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [remainingTags, setRemainingTags] = useState(0);
  const [isStarFilled, setIsStarFilled] = useState(isStar);
  const [firstTag, setFirstTag] = useState();
  const [cardClick, setCardClick] = useState(false);

  function processFirstTag() {
    try {
      setFirstTag(JSON.parse(DealTags)[0]);
    } catch (error) {}
  }
  useEffect(() => {
    processFirstTag();
  }, [DealTags]);
  const addDealToWishlist = async (startupId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await addStartuptoWishlist(startupId, investorId, token);

      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return [...prev, OrganizationID];
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Added to wishlist", "success");
      }
    } catch (error) {}
  };

  const removeDealFromWishlist = async (startupId, investorId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await removeStartupFromWishlist(
        startupId,
        investorId,
        token
      );
      if (response.data.status === 200) {
        setWishlistDealIds((prev) => {
          return prev.filter((id) => id !== OrganizationID);
        });
      }
      if (!response) {
      } else if (response.data !== null) {
        // Toast("Removed from wishlist", "success");
      }
    } catch (error) {}
  };
  useEffect(() => {
    const computeCardTags = () => {
      const tags = [];

      // Handle IndustryVerticals parsing
      try {
        if (IndustryVerticals.length > 0) {
          const industryVerticals = JSON.parse(IndustryVerticals);
          if (Array.isArray(industryVerticals)) tags.push(...industryVerticals);
        }
      } catch (error) {
        console.error("Error parsing Industry Verticals:", error);
      }
      // Handle EndMarketFocus parsing
      try {
        if (EndMarketFocus.length > 0) {
          const endMarketFocus = JSON.parse(EndMarketFocus);
          if (Array.isArray(endMarketFocus)) tags.push(...endMarketFocus);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
        tags.push("_"); // Fallback value
      }
      try {
        if (FundingType.length > 0) {
          const fundingTypes = JSON.parse(FundingType);
          if (Array.isArray(fundingTypes)) tags.push(...fundingTypes);
        }
      } catch (error) {
        console.error("Error parsing End Market Focus:", error);
      }
      return tags;
    };

    // Set computed card tags
    setCardTags(computeCardTags());
  }, [RoundType, IndustryVerticals, EndMarketFocus]);
  // useEffect(() => {
  //   const getInteractedDeals = async () => {
  //     //
  //     try {
  //       const response = await getDealsByInvestorID(investorId, token);
  //       //
  //       const interactedDeals = response.data;
  //       for (let index = 0; index < interactedDeals.length; index++) {
  //         const element = interactedDeals[index];
  //         //
  //         if (deal.DealID === element.DealID) {
  //           const response = await removeStartupFromWishlist(
  //             OrganizationID,
  //             investorId,
  //             token
  //           );
  //           //
  //         }
  //       }
  //     } catch (error) {}
  //   };
  //   getInteractedDeals();
  // }, [deal]);

  const handleStarClick = async () => {
    setIsStarFilled((prevIsStarFilled) => {
      const newIsStarFilled = !prevIsStarFilled;
      //
      if (newIsStarFilled) {
        addDealToWishlist(OrganizationID, investorId);
      } else {
        removeDealFromWishlist(OrganizationID, investorId);
      }
      return newIsStarFilled;
    });
  };
  function navigateToCompanyDetails() {
    if (role === "company") {
      Navigate("/company/overview", {
        state: {
          deal,
        },
      });
    }
    if (startPoint === "noNameDeals") {
      const label = `No Name Deals Card - ${deal.DealID}`;
      ReactGA.event({
        category: "navigation",
        action: "button_click",
        label: label,
      });
      mixpanel.track("No Name Deal Card Click", {
        dealId: deal?.DealID,
      });
      if (token && role === "investor") {
        Navigate("/investor/newDeals", {
          state: {
            deal: deal,
            passedStage: stage,
          },
        });
      } else {
        elementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (!investorVerified || !profileCompleted) {
        setCardClick(true);
        return;
      }
      if (state.verified === false) {
      }
      Navigate(`/investor/companyDetails`, {
        state: {
          deal: deal,
          passedStage: stage,
        },
      });
    }
  }
  const handleHover = () => {
    const ele1 = document.getElementsByClassName("card-with-details")[index];
    const ele2 = document.getElementsByClassName("pending-profile")[index];

    if (ele1 && ele2) {
      ele2.style.height = `${ele1.offsetHeight}px`;
      ele2.style.justifyContent = "center";
    }
    if (role == "company") {
      return;
    }
    if (
      (!investorVerified || !profileCompleted) &&
      startPoint !== "noNameDeals"
    ) {
      setCardClick(true);
      return;
    }
  };
  const handleHoverLeave = () => {
    setCardClick(false);
  };
  //Calculates the no. of tags that can fit in the width
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth * 0.9;
      let totalWidth = 0;
      let tagsToShow = [];
      let remainingCount = 0;

      cardTags.forEach((tag, index) => {
        const tagElement = document.createElement("div");
        tagElement.style.display = "inline-block";
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.className = "Card-div-6";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth;
        document.body.removeChild(tagElement);

        if (totalWidth + tagWidth <= containerWidth) {
          totalWidth += tagWidth;
          tagsToShow.push(tag);
        } else {
          remainingCount = cardTags.length - tagsToShow.length;
          return false; // Break out of the loop
        }
      });

      setVisibleTags(tagsToShow);
      setRemainingTags(remainingCount);
    }
  }, [cardTags]);
  return (
    <>
      <div
        className="Card-div"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        <ToastContainer position="top-center" containerId={"card-container"} />
        <div
          className="card-with-details"
          style={{ cursor: "pointer", display: cardClick ? "none" : "block" }}
          onClick={() => navigateToCompanyDetails()}
        >
          <div className="card-content-container">
            <div className="card-section card-section-1">
              <div className="Card-div-3">{Title}</div>

              <div className="Card-div-5" ref={containerRef}>
                {visibleTags.map((tag, index) => (
                  <div key={index} className="Card-div-6 blue-text">
                    {tag}
                  </div>
                ))}
                {remainingTags > 0 && (
                  <div className="Card-div-7 blue-text">+{remainingTags}</div>
                )}
                {startPoint !== "noNameDeals" && (
                  <img
                    src={isStarFilled ? filledStar : star}
                    className="card-star"
                    alt="star"
                    style={{
                      cursor: "pointer",
                      display: `${isPassed && "none"}`,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleStarClick();
                    }}
                  />
                )}
              </div>
              <div className="Card-div-4">{SubHeading}</div>
            </div>

            {/* <div className="card-section Card-section-2">
              <div className="Card-div-9 Financials">
                <div className="Card-div-10">
                  <div className="Card-div-11">₹{Revenue}</div>
                  <div className="Card-div-12">arr</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>

                <div className="Card-div-10">
                  <div className="Card-div-11">-%</div>
                  <div className="Card-div-12">MRR Growth %</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>

                <div className="Card-div-10">
                  <div className="Card-div-11">
                    {((mrrGrowth / Revenue) * 100).toFixed(2)}%
                  </div>
                  <div className="Card-div-12">GM%</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>

                <div className="Card-div-10">
                  <div className="Card-div-11">
                    ₹
                    {(() => {
                      const parseEBITDA = (ebitda) => {
                        if (!ebitda) return 0; 
                        let amount = parseFloat(ebitda);
                        if (ebitda.includes("Cr")) {
                          amount *= 10000000;
                        } else if (ebitda.includes("L")) {
                          amount *= 100000;
                        }
                        return amount;
                      };
                      const ebitdaValue = parseEBITDA(EBITDA);
                      return ebitdaValue < 0 ? -ebitdaValue : 0;
                    })().toFixed(2)}
                  </div>
                  <div className="Card-div-12">Monthly Burn</div>
                </div>
              </div>
            </div> */}
            <div className="card-section Card-section-2">
              <div className="Card-div-9 Financials">
                <div className="Card-div-10">
                  <div className="Card-div-11"> ₹{arr}</div>
                  <div className="Card-div-12">ARR</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>
                <div className="Card-div-10">
                  <div className="Card-div-11">{mrrGrowth}%</div>
                  <div className="Card-div-12">MRR Growth</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>
                <div className="Card-div-10">
                  <div className="Card-div-11">{grossMargin}%</div>
                  <div className="Card-div-12">Gross Margin</div>
                </div>
                <div style={{ borderRight: "0.5px solid #4642CC" }}></div>
                <div className="Card-div-10">
                  <div className="Card-div-11">₹{monthlyBurn}</div>
                  <div className="Card-div-12">Monthly Burn</div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-section-3">
            <ProgressBar
              totalFundingRaised={TotalFundingRaised}
              fundingRequired={FundingRequired}
              roundType={RoundType}
              DealTag={firstTag}
            />
          </div>
        </div>
        <div
          className="pending-profile"
          style={{ display: cardClick ? "flex" : "none" }}
        >
          <img src={pendingProfile} />
          <p>
            {profileCompleted
              ? "You're on the Waitlist! Hang tight—exciting investment opportunities are just around the corner!"
              : "Please complete your profile to view the details of the company."}
          </p>
          <div
            onClick={() => {
              Navigate("/investor/account", {
                state: {
                  activeButton: 2,
                },
              });
            }}
            style={{
              display: profileCompleted ? "none" : "flex",
            }}
          >
            <CustomBlackBtn text="Complete Profile" type="button" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
