import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import Plot from "react-plotly.js";
import { plotColorPallete } from "../../../../../Common/functions";
import { calculateDtick, calculateTotals } from "./PlotUtilityFunctions";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";
import { Box } from "@mui/material";

const PlotTemplate = ({
  data,
  m = true,
  q = true,
  y = true,
  tooltip = false,
  precision = `0`,
  colors = [],
  height,
}) => {
  const [type, setType] = useState("m");
  // Function to calculate dtick based on type and length of ticks
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [type, data]);

  // Prepare data for Plotly
  const xLabels = data.data.xLabels[type];
  const totalValues = calculateTotals(data.data.values[type], type);
  const getPercentages = (values) => {
    return values.map((value, index) => (value / totalValues[index]) * 100);
  };

  const plotData = Object.entries(data.data.values[type]).map(
    ([category, dataValues], index) => {
      // Determine if values are already percentages or if there's only one category
      // const isPercentageGraph = dataValues[type].every((value) => value <= 100);
      const isPercentageGraph = data.data.values.isPercentageGraph;
      const hasSingleCategory =
        Object.keys(data.data.values[type]).length === 1;
      const percentages = getPercentages(dataValues[type]);
      return {
        x: xLabels,
        y: dataValues[type],
        name: hasSingleCategory ? data?.name : category,
        text: percentages,
        textposition: "none",
        marker: {
          // line: {
          //   // width: 1, // You can control the line width
          // },
          color:
            colors.length > 0
              ? colors[index % colors.length]
              : plotColorPallete[index % plotColorPallete.length],
        },
        hovertemplate:
          `<b>${hasSingleCategory ? data?.name : category}</b>: %{y:,${
            isPercentageGraph ? `.1f` : `.${precision}f`
          }}${isPercentageGraph ? "%" : ""}` +
          (!isPercentageGraph && !hasSingleCategory ? `(%{text:,.1f}%)` : "") +
          "<extra></extra>",
        ...data?.data?.values?.data,
      };
    }
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <TimePeriodButton
          type={type}
          setType={setType}
          title={data?.name}
          m={m}
          q={q}
          year={y}
          tooltip={tooltip}
        />
        <Plot
          data={plotData}
          layout={{
            xaxis: {
              dtick: calculateDtick(type, data.data.xLabels[type]?.length),
              tickangle: 0,
              tickfont: {
                family: "Karla, sans-serif",
              },
            },
            yaxis: {
              gridwidth: 1,
              tickfont: {
                family: "Karla, sans-serif",
                size: "14px",
              },
              // tickvals: [
              //   1000, 100000, 1000000, 10000000, 100000000, 1000000000,
              // ],
              // ticktext: ["1L", "10L", "1Cr", "10Cr", "100Cr"],
            },
            barcornerradius: 3,
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            modebar: {
              activecolor: "#114fee",
              bgcolor: "transparent",
              color: "grey",
              orientation: "v",
            },
            margin: {
              b: 20,
              t: 10,
              r: 30,
              l: 40,
            },
            width: windowWidth > 1590 ? 495 : windowWidth - windowWidth * 0.7,
            height: height || 252,
            showlegend: false,
            legend: {
              orientation: "h",
              x: 0.1,
              traceorder: "normal",
              font: {
                family: "Karla, sans-serif",
                size: 8,
                color: "var(--Primary-Text-Color)",
              },
            },
            hovermode: "x unified",
            hoverlabel: {
              bordercolor: "transparent",
              borderRadius: "10px",
              bgcolor: "#FAFAFA",
              font: {
                family: "Karla, sans-serif",
              },
            },
            ...data?.data?.values?.layout,
          }}
          config={{
            displaylogo: false,
            modeBarButtonsToRemove: [
              "autoScale2d",
              "lasso2d",
              "select2d",
              "zoomIn2d",
              "zoomOut2d",
            ],
          }}
        />
      </Box>
    </>
  );
};

export default PlotTemplate;
