import React from "react";

const PitchCreationSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">Video Pitches receive 3x more engagement!</p>
      <p className="subheading">
        <ol
          style={{
            listStyleType: "decimal",
            letterSpacing: "0.28px",
            paddingLeft: "16px",
            lineHeight: "1.5",
          }}
        >
          <li className="mb-3">Use a good camera for clear video and sound.</li>
          <li className="mb-3">
            Start with a brief intro of the problem statement and the solution.
          </li>
          <li className="mb-3">
            Introduce your team and their roles for credibility.
          </li>
          <li className="mb-3">
            Highlight what makes your product or service unique.
          </li>
          <li className="mb-3">
            Finish with a clear call to action for investors.
          </li>
          <li className="mb-3">
            Keep it under <b>3 minutes.</b>
          </li>
        </ol>
      </p>
      <iframe
        src="https://www.youtube.com/embed/r-iETptU7JY?si=ouVjOSHT51PdoiSS"
        title="Elevator pitch"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default PitchCreationSuggested;
