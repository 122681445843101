import React, { useState } from "react";
import { fetchBlobName, timeDiff } from "../functions";
import { ToggleResolveThread } from "../../../endpoints/deal";
import Toast from "../Toast";

import { Link } from "react-router-dom";
import moment from "moment";
import CustomTooltip from "./custom/customToolTip";

import editIcon from "../../../Assets/Images/InvestorPage/Dashboard/editIcon.svg";
import reply from "../../../Assets/Images/InvestorPage/Dashboard/reply.svg";
import resolved from "../../../Assets/Images/InvestorPage/Dashboard/resolved.svg";
import resolvedBlue from "../../../Assets/Images/InvestorPage/Dashboard/resolvedBlue.svg";
import FileImg from "../../../Assets/Images/signup/file.svg";
import download from "../../../Assets/Images/InvestorPage/Dashboard/download.svg";
const Thread = ({
  messages,
  handleReplyData,
  personalID,
  fetchMessages,
  handleEditMessage,
  option,
  latestStage,
}) => {
  const token = localStorage.getItem("token");
  const [editOptionOpen, setEditOptionOpen] = useState(false);

  const handleReply = (name, threadID) => {
    handleReplyData(name, threadID);
  };
  const handleResolveThread = async (threadID, personalID, status) => {
    const data = {
      resolvedBy: personalID,
      threadID: threadID,
    };

    const result = await ToggleResolveThread(data, token);
    if (result.data) {
      fetchMessages();
      if (status) {
        Toast("Thread opened Successfully", "success");
      } else {
        Toast("Thread resolved Successfully", "success");
      }
    }
  };
  const handleEdit = (message, messageID) => {
    handleEditMessage(message, messageID);
  };
  return (
    <div
      className={`message-div ${
        messages[0].isThreadResolved ? "resolved-div" : ""
      }`}
      style={{
        display:
          (option === "resolved" && messages[0].isThreadResolved) ||
          (option === "unresolved" && !messages[0].isThreadResolved) ||
          option === "all"
            ? "flex"
            : "none",
      }}
    >
      {messages.map((message, index) => (
        <div
          key={index}
          className={`${index > 0 ? "message-div-1" : "message-div-first"} ${
            index === messages.length - 1 ? "message-div-last" : ""
          }`}
        >
          <div className="message-div-2">
            <div className="message-div-2-left">
              <img
                loading="lazy"
                srcSet={message.profilePhoto}
                className="message-img"
              />
              <div className="nameandrole">
                <div className="message-div-3">
                  {message.name}&nbsp;&nbsp;
                  {(message.investorMemberID &&
                    message.investorMemberID === personalID) ||
                  (!message.investorMemberID &&
                    message.startupMemberID === personalID) ? (
                    <button className="you-btn">you</button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="message-div-6">
                  {message.role}
                  {message.firmName ? ` | ${message.firmName}` : ""}
                </div>
              </div>

              <div className="message-div-4">
                {moment(message.time).format("DD/MM/YYYY h:mm A")}
              </div>
            </div>
            {index !== 0 &&
              !message.isThreadResolved &&
              message.stage === latestStage &&
              (message.investorMemberID === personalID ||
                message.startupMemberID === personalID ||
                message.fundrevAnalystID === personalID) &&
              timeDiff(message.time, 12) && (
                <CustomTooltip
                  title="Edit this message"
                  position="bottom"
                  theme="black"
                >
                  <img
                    className="edit-option-img"
                    src={editIcon}
                    onClick={() =>
                      handleEdit(message.message, message.messageID)
                    }
                  />
                </CustomTooltip>
              )}
          </div>
          {message.file ? (
            <div className="NDA-document">
              <div className="NDA-document-left">
                <img src={FileImg} className="FileImg" />
                <div className="NDA-document-name">
                  <p>{fetchBlobName(message.file)}</p>
                </div>
              </div>
              <div className="NDA-document-right">
                <Link to={message.file}>
                  <img src={download} />
                </Link>
              </div>
            </div>
          ) : null}
          <div className="message-div-5">{message.message}</div>
          {index === 0 && (
            <div className="edit-option">
              {!message.isThreadResolved &&
              message.stage === latestStage &&
              (message.userRole === "Investor" ||
                (message.userRole === "Company" &&
                  message.stage !== "requestAccepted")) ? (
                <CustomTooltip
                  title="Reply to this message"
                  position="bottom"
                  theme="black"
                >
                  <img
                    className="edit-option-img"
                    src={reply}
                    onClick={() => handleReply(message.name, message.threadID)}
                  />
                </CustomTooltip>
              ) : message.isThreadResolved ? (
                <div className="resolvedBy-div">
                  <img
                    className="edit-option-img"
                    src={resolvedBlue}
                    onClick={() =>
                      handleResolveThread(
                        message.threadID,
                        personalID,
                        message.isThreadResolved
                      )
                    }
                  />
                  {message.resolvedBy && (
                    <div className="resolved-by">
                      <span className="nda-bold-text">
                        {message.resolvedBy}
                      </span>{" "}
                      Resolved the thread on{" "}
                      <span className="nda-bold-text">
                        {moment(message.updatedAt).format("DD/MM/YYYY h:mm A")}
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
              {!message.isThreadResolved &&
                message.stage === latestStage &&
                (message.userRole === "Investor" ||
                  (message.userRole === "Company" &&
                    message.stage !== "requestAccepted")) && (
                  <CustomTooltip
                    title="Resolve this thread"
                    position="bottom"
                    theme="black"
                  >
                    <img
                      className="edit-option-img"
                      src={resolved}
                      onClick={() =>
                        handleResolveThread(
                          message.threadID,
                          personalID,
                          message.isThreadResolved
                        )
                      }
                    />
                  </CustomTooltip>
                )}
              {!message.isThreadResolved &&
                message.stage === latestStage &&
                (message.investorMemberID === personalID ||
                  message.startupMemberID === personalID ||
                  message.fundrevAnalystID === personalID) &&
                (message.userRole === "Investor" ||
                  (message.userRole === "Company" &&
                    message.stage !== "requestAccepted")) &&
                timeDiff(message.time, 12) && (
                  <img
                    className="edit-option-img"
                    src={editIcon}
                    onClick={() =>
                      handleEdit(message.message, message.messageID)
                    }
                  />
                )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Thread;
