import React from "react";
import { microsoftAuth } from "../../endpoints/startup";
import outlook from "../../Assets/Images/companiesIcons/outlook.svg";

const CalendarSync = () => {
  const token = localStorage.getItem("token");
  const handleMicrosoftClick = async (access) => {
    await microsoftAuth(token, true, access);
  };
  return (
    <div className="flex gap-2">
      <div>
        dhiraj@fundrev.ai
        <div
          className="p-[16px] flex flex-col justify-center items-center gap-[8px] shadow-md w-[160px] cursor-pointer rounded-[4px]"
          onClick={() => handleMicrosoftClick(false)}
        >
          <img src={outlook} alt="connect gmail" />
          <p className="text-[--Secondary-Text-Color]">Outlook</p>
        </div>
      </div>
      <div>
        deals@fundrev.ai
        <div
          className="p-[16px] flex flex-col justify-center items-center gap-[8px] shadow-md w-[160px] cursor-pointer rounded-[4px]"
          onClick={() => handleMicrosoftClick(true)}
        >
          <img src={outlook} alt="connect gmail" />
          <p className="text-[--Secondary-Text-Color]">Outlook</p>
        </div>
      </div>
    </div>
  );
};

export default CalendarSync;
