import React from "react";

const DealPurposeSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">How to Write the Purpose of Fundraising</p>
      <p className="subheading">
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Be Specific:</b> Outline exactly what the funds will be used for
          (e.g., product development, marketing).
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Show Impact: </b> Explain how the funds will help you reach key
          milestones and drive growth.
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Align with Goals:</b> Ensure the purpose supports your long-term
          vision.
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Use Metrics:</b> Quantify goals where possible to make your purpose
          more compelling.
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Include a Timeline: </b> Share when you plan to use the funds and
          reach targets.
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <b>Keep it Concise: </b> Capture your purpose in a few clear
          sentences.
        </div>
      </p>
    </div>
  );
};

export default DealPurposeSuggested;
