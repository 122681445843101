import React, { useState, useEffect } from "react";
import Toast from "../../../Common/Toast";
import {
  isValidLinkedInUrl,
  StyledFormControl,
  StyledFormLabel,
} from "../../../Common/functions";
import {
  fetchInvestorMemberDetails,
  memberUpdate,
} from "../../../../endpoints/investor";
import ChangePasswordModal from "../../../Common/modals/changePasswordModal";
import { useLoader } from "../../../Common/LoaderProvider";

import { FormControl, FormLabel, Input } from "@mui/joy";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import editImg from "../../../../Assets/Images/signup/edit-img.svg";
import uploadProfile from "../../../../Assets/Images/signup/upload-profile.svg";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { Form, Formik } from "formik";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomPhoneInput from "../../../Common/components/custom/customPhoneInput";

const Account = () => {
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const [userDetails, setUserDetails] = useState({});
  const [email, setEmail] = useState("");
  const [investorID, setInvestorID] = useState("");
  const [linkedinUrlError, setLinkedinUrlError] = useState("");
  const [memberImgFile, setMemberImgFile] = useState("");
  const [isPushSwitchOn, setPushSwitchOn] = useState(true);
  const [isEmailSwitchOn, setEmailSwitchOn] = useState(true);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(uploadProfile);

  const initialValues = {
    name: userDetails?.name || "",
    title: userDetails?.title || "",
    email: userDetails.email || "",
    linkedIn: userDetails?.linkedIn || "",
    phoneNumber: userDetails?.phoneNumber || "",
    biography: userDetails.biography || "",
    memberImg: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    title: Yup.string().required("Title is required"),
    linkedIn: Yup.string()
      .required("LinkedIn Profile is required")
      .test("invalid", "Please enter a valid LinkedIn profile URL", (value) =>
        isValidLinkedInUrl(value)
      ),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("invalid", "Please enter a valid phone number", (value) =>
        matchIsValidTel(value)
      ),
    biography: Yup.string().required("Biography is required"),
  });

  const fetchDetails = async () => {
    let response;
    if (token) {
      try {
        loader.start("Fetching investor details...");
        response = await fetchInvestorMemberDetails(token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
    }
    if (token) {
      if (response && response.data !== null) {
        setUserDetails(response.data.data);
        setEmail(response.data.data.email);
        setInvestorID(response.data.data.investorID);
        setPushSwitchOn(response.data.data.pushNotifications);
        setEmailSwitchOn(response.data.data.emailNotifications);

        // Set the image preview if the logo is available
        if (response.data.data.memberImg) {
          setCompanyLogoPreview(response.data.data.memberImg);
        }
      } else {
        loader.stop();
      }
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const handleFormSubmit = async (values) => {
    for (const key in values) {
      if (
        values.hasOwnProperty(key) &&
        key !== "memberImg" &&
        key !== "biography" &&
        key !== "pushNotifications" &&
        key !== "emailNotifications" &&
        key !== "linkedIn"
      ) {
        if (!values[key]) {
          Toast("Please fill all the fields", "warning", "investorAccount");
          return;
        }
      }
    }

    const data = {
      ...values,
      memberImg: memberImgFile,
    };
    const formDataInput = new FormData();
    for (const key in data) {
      if (data[key] === true || data[key] === false) {
        formDataInput.append(key, data[key]);
      } else if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (!data.investorID) {
      formDataInput.append("investorID", investorID);
    }
    loader.start("Updating member details...");
    const response = await memberUpdate(formDataInput, token);
    loader.stop();
    if (response.data !== null) {
      if (response.data.status === 200) {
        Toast(
          "Member details updated successfully",
          "success",
          "investorAccount"
        );
      } else {
        loader.stop();

        Toast("Failed to update member details", "error", "investorAccount");
      }
    } else {
      loader.stop();

      console.error("Error updating member details:", response.error);
    }
  };

  return (
    <>
      <div className="investorDetails investor-account-box">
        <Helmet>
          <title>Investor | Account</title>
        </Helmet>
        <ChangePasswordModal
          passwordModalOpen={passwordModalOpen}
          setPasswordModalOpen={setPasswordModalOpen}
          token={token}
          containerId="investorAccount"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="investor-personal-details-section fundrev-card mt-4">
              <div className="upload-btn-wrapper" style={{ marginTop: "0px" }}>
                <p className="fundrev-card-heading mb-[16px]">
                  Account Details
                </p>
                <button
                  type="button"
                  className="upload-img-btn"
                  onClick={() => document.getElementById("memberImg").click()}
                >
                  <img
                    src={
                      memberImgFile
                        ? URL.createObjectURL(memberImgFile)
                        : companyLogoPreview
                    }
                    className="InvestorLogo"
                    alt="UploadProfile"
                    style={{ cursor: "pointer" }}
                  />
                </button>
                {(memberImgFile instanceof File ||
                  companyLogoPreview !== uploadProfile) && (
                  <label htmlFor="memberImg" className="edit-img-btn">
                    <img src={editImg} className="editImg" alt="Edit" />
                  </label>
                )}
                <input
                  id="memberImg"
                  type="file"
                  name="memberImg"
                  accept="image/png, image/jpeg" // Accept only JPEG and PNG
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setFieldValue("memberImg", file);
                      setMemberImgFile(file);
                      setCompanyLogoPreview(URL.createObjectURL(file));
                    }
                  }}
                  style={{ display: "none" }}
                />
                {touched.memberImg && errors.memberImg && (
                  <div className="error">{errors.memberImg}</div>
                )}
              </div>
              <div className="input-section">
                <StyledFormControl>
                  <StyledFormLabel>Full Name</StyledFormLabel>
                  <CustomInput
                    name={`name`}
                    placeholder={`Full Name`}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </StyledFormControl>
                {/* Title */}
                <StyledFormControl fullWidth>
                  <StyledFormLabel>Title</StyledFormLabel>
                  <CustomInput
                    placeholder="Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </StyledFormControl>

                {/* LinkedIn Profile */}

                <StyledFormControl fullWidth>
                  <StyledFormLabel>Email</StyledFormLabel>
                  <CustomInput
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    disabled
                  />
                </StyledFormControl>
                <StyledFormControl fullWidth>
                  <StyledFormLabel>LinkedIn Profile</StyledFormLabel>
                  <CustomInput
                    placeholder="LinkedIn Profile"
                    name="linkedIn"
                    value={values.linkedIn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.linkedIn && errors.linkedIn)}
                    helperText={touched.linkedIn && errors.linkedIn}
                  />
                </StyledFormControl>
                {/* Phone Number */}
                <StyledFormControl fullWidth>
                  <StyledFormLabel>Phone Number</StyledFormLabel>
                  <CustomPhoneInput
                    defaultCountry="IN"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={(phoneNumber) =>
                      setFieldValue("phoneNumber", phoneNumber)
                    }
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </StyledFormControl>

                {/* Biography */}
                <StyledFormControl></StyledFormControl>
                <div
                  style={{
                    gridColumn: "1/-1",
                  }}
                >
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Biography</StyledFormLabel>
                    <CustomInput
                      placeholder="Biography"
                      name="biography"
                      value={values.biography}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.biography && errors.biography)}
                      helperText={touched.biography && errors.biography}
                      multiline={true}
                      minRows={3}
                    />
                  </StyledFormControl>
                </div>
              </div>
              <CustomBlackBtn type="submit" text="Save" />
            </Form>
          )}
        </Formik>

        <div className="investor-account-change-password fundrev-card">
          <p className="fundrev-card-heading mb-[16px]">
            Password and Authentication
          </p>

          <CustomBlackBtn
            text="Change Password"
            mode="light"
            onClick={() => setPasswordModalOpen(true)}
          />
        </div>
        <div className="investor-account-delete fundrev-card">
          <p className="fundrev-card-heading mb-[16px]">Delete Account</p>
          <p className="investor-account-delete-text">
            If you like to delete your Fundrev account linked with:{" "}
            <span className="blue-text">{email},</span> <br />
            <span>
              Please write us on{" "}
              <a
                href="mailto:admin@fundrev.ai"
                className="blue-text"
                style={{
                  textDecoration: "None",
                }}
              >
                admin@fundrev.ai
              </a>
              .
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Account;
