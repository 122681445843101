import React from "react";
import AnalyticsPageTitle from "../analytics/AnalyticsPlots/AnalyticsPageTitle";
import { Box, Grid } from "@mui/material";
import PlotTemplate from "../analytics/AnalyticsPlots/PlotTemplate";
import CohortTable from "./CohortTable";
import ChurnTable from "./ChurnTable";
import SmallCardGrid from "./SmallCardGrid";

const AnalyticsCustomerAnalytics = ({ data }) => {
  return (
    <Box
      sx={{
        marginBottom: "100px",
      }}
    >
      <AnalyticsPageTitle title={`Customer Analytics`} />
      <SmallCardGrid data={data.fiveCards} />
      <Grid container spacing={1.5}>
        {[
          "revenue",
          "customerType",
          "numOrders",
          "customers",
          "aov",
          "aru",
          "opu",
          "clv",
        ].map((plot) => (
          <Grid item xs="auto" md="auto" lg="auto" key={plot}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "569px",
                borderRadius: "8px",
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                fontFamily: "Karla, sans-serif",
                padding: 2,
                paddingRight: 0,
                paddingLeft: 2.5,
              }}
            >
              <PlotTemplate
                data={data.plots[plot]}
                q={!["customerType", "clv"].includes(plot)}
                y={!["customerType", "clv"].includes(plot)}
                m={["customerType", "clv"].includes(plot) ? false : true}
                tooltip={
                  plot === "clv" && `Avg. Revenue per User * 1/Churn Rate`
                }
                precision={plot === "opu" && `1`}
                colors={plot === "customerType" && ["#C2F970", "#00A6FB"]}
              />
            </Box>
          </Grid>
        ))}
        {["revenueRetention", "userRetention", "purchaseFrequency"].map(
          (table) => (
            <Grid item xs="auto" md="auto" lg="auto" key={table}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "569px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
                  fontFamily: "Karla, sans-serif",
                  padding: 2,
                  paddingRight: 0,
                  paddingLeft: 2.5,
                }}
              >
                <CohortTable data={data.tables[table]} />
              </Box>
            </Grid>
          )
        )}
        <Grid item xs="auto" md="auto" lg="auto">
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "569px",
              borderRadius: "8px",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
              fontFamily: "Karla, sans-serif",
              padding: 2,
              paddingRight: 0,
              paddingLeft: 2.5,
            }}
          >
            <ChurnTable data={data.tables.churn} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsCustomerAnalytics;
