import React, { useEffect, useState } from "react";
import { isValidEmail } from "../../../Common/functions";
import Toast from "../../../Common/Toast";
import DeleteAccountModal from "../../../Common/modals/deleteAccountModal";
import {
  fetchInvestorMemberDetails,
  fetchMembers,
  memberResendInvite,
  investorAddMember,
  deleteMember,
  approveJoiningRequest,
} from "../../../../endpoints/investor";
import { useLoader } from "../../../Common/LoaderProvider";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TagsInput from "react-tagsinput";
import { Helmet } from "react-helmet";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import IconButton from "@mui/material/IconButton";

import Crown from "../../../../Assets/Images/FounderPage/Dashboard/Crown.svg";
import CloseIcon from "../../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

import "react-tagsinput/react-tagsinput.css";

const Members = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [email, setEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [investorID, setInvestorID] = useState("");
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [DeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [accountDeleteEmail, setAccountDeleteEmail] = useState("");
  const [isRevoke, setIsRevoke] = useState(false);
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(investorID, token);
      loader.stop();
      if (response && response.data) {
        const membersData = response.data.data;
        setInvitedMembers(membersData);
      }
    } catch (error) {
      loader.stop();

      console.error("Failed to fetch members:", error);
    }
  };
  const fetchMemberDetails = async () => {
    if (!token) return;
    try {
      loader.start("Fetching member details");
      const response = await fetchInvestorMemberDetails(token);
      if (response.data !== null) {
        const responseData = response.data;
        const { data } = responseData;
        setInvestorID(data.investorID);
        setEmail(data.email);
        if (data.isAdmin) {
          setAdminEmail(data.email);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchMemberDetails();
  }, []);
  useEffect(() => {
    if (investorID && token) {
      fetchMembersData();
    }
  }, [investorID]);
  const resendInvite = async (memberEmail) => {
    try {
      const data = {
        investorID: investorID,
        memberEmail: memberEmail,
        email: email,
      };
      loader.start("Resending invite");
      const response = await memberResendInvite(data, token);
      loader.stop();
      if (response && response.data) {
        Toast("Invite Resent", "success", "investorAccount");
      }
    } catch (error) {
      loader.stop();
      console.error("Failed to resend invite:", error);
    }
  };
  const handleChange = (emails) => {
    if (emails.length === 0) {
      setEmails(emails);
      return;
    }
    if (isValidEmail(emails[emails.length - 1])) {
      setEmails(emails);
    } else {
      Toast("Please enter a valid email address.", "info", "investorAccount");
    }
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of the space bar
      const trimmedValue = inputValue.trim(); // Trim the current input value
      if (trimmedValue) {
        if (isValidEmail(trimmedValue) === false) {
          Toast(
            "Please enter a valid email address.",
            "error",
            "investorAccount"
          );
          return;
        }
        handleChange([...emails, trimmedValue]);
        setInputValue(""); // Clear the input field after adding the tag
      }
    }
  };
  const sendInvite = async (emails) => {
    try {
      if (emails.length === 0) {
        if (inputValue.trim() !== "") {
          if (isValidEmail(inputValue) === false) {
            Toast(
              "Please enter a valid email address.",
              "error",
              "investorAccount"
            );
            setEmails([]);
            setInputValue("");
            return;
          }
          emails.push(inputValue);
        } else {
          Toast(
            "Please enter atlease one email address.",
            "error",
            "investorAccount"
          );
          setEmails([]);
          setInputValue("");
          return;
        }
      }
      loader.start("Sending invites");
      const response = await investorAddMember(emails, token);
      loader.stop();

      if (response.data !== null) {
        setOpenSaveModal(true);
        setEmails([]);
      } else {
        Toast(
          "Member is already registered with Fundrev.",
          "error",
          "investorAccount"
        );
      }
    } catch (error) {
      loader.stop();
      Toast("Error in inviting member", "error", "investorAccount");
    }
    fetchMembersData();
  };
  const deleteFounderMember = async (email) => {
    const member = invitedMembers.find((member) => member.email === email);

    try {
      loader.start("Updating member");
      const response = await deleteMember(member._id, member.investorID, token);
      loader.stop();
      if (response) {
        Toast("Member deleted successfully", "info", "investorAccount");
        setAccountDeleteEmail("");
        setDeleteAccountModalOpen(false);
      } else {
      }
    } catch (error) {
      loader.stop();
      console.error("Error deleting member:", error);
    }
    fetchMembersData();
  };
  const approveRequest = async (email, approval) => {
    try {
      const formData = {
        email: email,
        approval: approval,
      };
      const response = await approveJoiningRequest(formData, token);
      if (response.data) {
        fetchMembersData();
      } else {
        Toast(
          "Error in approving request. Please try again later",
          "success",
          "investorAccount"
        );
      }
    } catch (error) {}
  };
  const handleDeleteAccountModal = (email, isRevoke) => {
    setAccountDeleteEmail(email);
    setDeleteAccountModalOpen(true);
    setIsRevoke(isRevoke);
  };
  return (
    <div className="editDealTeam fundrev-card">
      <Helmet>
        <title>Investor | Members</title>
      </Helmet>
      <div className="members-first-div">
        <CustomBlackBtn
          className="invite-members-div"
          text={
            <>
              <PersonAddAltIcon
                sx={{
                  height: "20px",
                }}
              />{" "}
              Invite Members
            </>
          }
          mode={`light`}
          border="sm"
          onClick={() => setOpen(true)}
        />
      </div>
      <DeleteAccountModal
        deleteAccountModalOpen={DeleteAccountModalOpen}
        setDeleteAccountModalOpen={setDeleteAccountModalOpen}
        deleteFounderMember={deleteFounderMember}
        email={accountDeleteEmail}
        revoke={isRevoke}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            setOpen(false);
          },
          sx: {
            width: "700px", // Adjust width as needed
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Invite Team members
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={CloseIcon} alt="close" />
        </IconButton>
        <DialogContent>
          <div className="dialogcontent">
            <div className="select-peers-input">
              <TagsInput
                value={emails}
                onChange={handleChange}
                inputValue={inputValue}
                onChangeInput={handleInputChange}
                onlyUnique
                inputProps={{
                  placeholder: "Select peers",
                  onKeyDown: handleKeyDown,
                }}
              />
              <CustomBlackBtn
                text="Send Invite"
                onClick={() => sendInvite(emails)}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <table className="members-table">
        <thead>
          <tr className="members-header">
            <th>Name</th>
            <th>Official Email Address</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invitedMembers.map((member, index) => (
            <tr key={index}>
              <td className="name-cell">
                {member.name}
                {member.isAdmin && (
                  <img src={Crown} style={{ marginLeft: "10px" }} />
                )}
                <div>
                  {member.email === email && (
                    <p className="you-btn" style={{ marginLeft: "10px" }}>
                      You
                    </p>
                  )}
                </div>
              </td>
              <td className="email-cell">{member.email}</td>
              <td className="email-cell">
                {/* If accepted TC field is true but isActive is not true that
                means that member has send a request to join the organization. */}
                {member.isActive ? (
                  <div>
                    <div className="green-dot" />
                    Active
                  </div>
                ) : member.acceptedTC ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <button
                      className="invite-members-div"
                      onClick={() => approveRequest(member.email, true)}
                    >
                      Approve request
                    </button>
                    <button
                      onClick={() => approveRequest(member.email, false)}
                      style={{ color: "#FF0000" }}
                    >
                      Decline request
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="yellow-dot" />
                    Pending
                  </div>
                )}
              </td>
              <td className="resend-invite">
                {!member.isActive && !member.acceptedTC ? (
                  <p onClick={() => resendInvite(member.email)}>
                    Resend Invite
                  </p>
                ) : (
                  ""
                )}
              </td>
              <td className="delete-account">
                {email === adminEmail &&
                  !member.isActive &&
                  !member.acceptedTC && (
                    <p
                      onClick={() =>
                        handleDeleteAccountModal(member.email, !member.isActive)
                      }
                    >
                      {"Revoke"}
                    </p>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Members;
