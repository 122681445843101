import React, { useState, useEffect } from "react";
import "../../../../CSS/InvestorPage/Dashboard/profileBox.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { companyState, saveState, useCompanyLogout } from "../companyState";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/joy";

import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";
const ProfileBox = ({ modal }) => {
  const Navigate = useNavigate();
  const logout = useCompanyLogout();
  const [logoutModal, setLogoutModal] = useState(false);
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [comMemImg, setComMemImg] = useState("");
  const [comMemName, setComMemName] = useState("");

  const { name, memberImg } = useRecoilValue(companyState);
  useEffect(() => {
    const fetchDetails = async () => {
      if (token) {
        const response = await fetchStartupMemberDetails(token);
        if (response && response.data) {
          const newState = {
            ...state,
            startupId: response.data.data.startupId,
            name: response.data.data.name,
            memberImg: response.data.data.memberImg,
          };
          setComMemName(response.data.data.name);
          setComMemImg(response.data.data.memberImg);
          setState(newState);
          saveState(newState); // Sync to localStorage
        }
      }
    };

    fetchDetails();
  }, [token, setState]);
  const handleLogout = () => {
    logout();
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("onBoardingComplete");
    localStorage.removeItem("role");
    Navigate("/signin", {
      state: {
        type: "Startup",
      },
    });
  };

  return (
    <div
      className="profile-box z-30"
      style={{ display: modal ? "block" : "none" }}
    >
      <Modal
        open={logoutModal}
        onClose={() => setLogoutModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "400px", height: "170px" }}>
          Are you sure you want to logout?
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div onClick={() => setLogoutModal(false)}>
              <CustomBlackBtn text="No" type="button" mode="light" />
            </div>
            <div onClick={handleLogout}>
              <CustomBlackBtn text="Yes" type="button" />
            </div>
          </Box>
        </Box>
      </Modal>
      <div className="flex flex-col max-w-[179px] shadow-[4px_4px_12px_rgba(161,161,161,0.16)] rounded-[8px]">
        <div className="flex overflow-hidden gap-2 items-center p-4 w-full bg-white rounded-[8px]">
          <img
            loading="lazy"
            srcSet={
              memberImg
                ? memberImg
                : comMemImg === undefined || comMemImg === ""
                ? userProfile
                : comMemImg
            }
            className="object-cover shrink-0 self-stretch my-auto w-10 h-10 rounded-[50%] border-[1px] border-[#8692A6];"
          />
          <div className="flex flex-col justify-center my-auto">
            <div className="text-base font-medium text-zinc-900 ">
              {name ? name : comMemName ? comMemName : ""}
            </div>
            <div className="mt-1 text-xs text-gray-500">Company</div>
          </div>
        </div>
        <div
          onClick={() => {
            Navigate("/company/account", {
              state: {
                activeButton: 2,
              },
            });
          }}
          className="profile-btn flex overflow-hidden gap-10 items-center px-4 py-5 w-full text-base font-medium whitespace-nowrap bg-white border-y-[1px] border-[#000] min-h-[56px] text-zinc-900 cursor-pointer"
        >
          <div className="gap-4 self-stretch my-auto">Account</div>
        </div>
        <div
          onClick={() => setLogoutModal(true)}
          className="flex overflow-hidden gap-10 items-center px-4 py-5 w-full text-base font-medium whitespace-nowrap bg-white min-h-[56px] text-zinc-900 rounded-[0px_0px_8px_8px] cursor-pointer"
        >
          <div className="gap-4 self-stretch my-auto">Logout</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBox;
