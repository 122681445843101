import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { postStageData } from "../../../endpoints/deal";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";

import { StyledFormControl, StyledFormLabel } from "../functions";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import "../../../CSS/Signup/modal.css";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomInput from "../components/custom/customInput";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { ToastContainer } from "react-toastify";
const PassDealModal = ({
  openReasonModal,
  setOpenReasonModal,
  stage,
  isCompany,
  containerId,
  type,
  DealID,
  investorID,
  sentBy,
  setDealPassed,
}) => {
  const [openPassDealModal, setOpenPassDealModal] = useState(false);
  const containerName = containerId ? containerId : "companyDetails";
  const request = type === "withdraw" ? "withdrawing" : "passing";
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const loader = useLoader();
  const handleNotInterested = async (values) => {
    if (isCompany) {
      if (values.reason !== "") {
        const updatedFormData = {
          Pass_Deal_Reason: values.reason,
          DealID: DealID,
          investorID: investorID,
          sentBy: sentBy,
          IsRejected: true,
          Stage: stage,
        };
        try {
          loader.start("Declining deal...");
          const response = await postStageData(updatedFormData, token);
          setDealPassed(true);
          loader.stop();
        } catch (error) {
          loader.stop();
        }
        Navigate("/company/fundraising");
        setOpenReasonModal(false);
      } else {
        Toast("Please enter the reason", "error", containerName);
      }
    } else {
      if (values.reason !== "") {
        const updatedFormData = {
          DealID: DealID,
          investorID: investorID,
          sentBy: sentBy,
          Not_Interested_Reason: values.reason,
          IsInterested: false,
          Stage: stage,
        };

        loader.start("Declining deal...");
        try {
          const response = await postStageData(updatedFormData, token);
          loader.stop();
        } catch (error) {
          loader.stop();
        }

        setOpenReasonModal(false);
      } else {
        Toast("Please enter the reason", "error", containerName);
      }
    }
  };

  const addTowatchlist = async () => {
    try {
      const updatedFormData = {
        DealID: DealID,
        investorID: investorID,
        sentBy: sentBy,
        IsInterested: true,
        IsRejected: false,
        Stage: "watchlist",
      };
      loader.start("Adding deal to watchlist...");
      try {
        const response = await postStageData(updatedFormData, token);
        if (response.data !== null) {
          Navigate(
            stage === "initialStage"
              ? "/investor/newDeals"
              : "/investor/ongoingdeals"
          );
        } else {
          Toast(
            "Error in adding to watchlist. Please try again later",
            "error",
            containerName
          );
        }
        loader.stop();
      } catch (error) {
        loader.stop();
      }

      setOpenReasonModal(false);
      Navigate("/investor/ongoingdeals");
    } catch (error) {}
  };

  return (
    <>
      <Modal
        open={isCompany ? openReasonModal : openPassDealModal}
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box reason-box" style={{ width: "550px" }}>
          <div className="modal-content">
            <div className="modal-right-section">
              <div className="reason-box-first-div">
                <p className="modal-heading">
                  Enter reason for {request} the deal:
                </p>
                <img
                  className="reason-box-closeicon"
                  src={closeIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    isCompany
                      ? setOpenReasonModal(false)
                      : setOpenPassDealModal(false)
                  }
                  alt="close"
                />
              </div>
              <div className="reasons-box">
                <Formik
                  initialValues={{ reason: "" }}
                  validationSchema={Yup.object({
                    reason: Yup.string().required("Reason is required"),
                  })}
                  onSubmit={handleNotInterested}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "36px",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div className="input-fields" style={{ width: "100%" }}>
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Reason for Passing</StyledFormLabel>
                          <CustomInput
                            placeholder="Please enter your reason"
                            name="reason"
                            value={values.reason}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.reason && errors.reason)}
                            helperText={touched.reason && errors.reason}
                          />
                        </StyledFormControl>
                      </div>

                      <div className="submit-btn-section">
                        <CustomBlackBtn type="submit" text="Pass" />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isCompany ? openPassDealModal : openReasonModal}
        onClose={() => setOpenReasonModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-box reason-box p-4"
          sx={{ width: "550px", padding: "16px" }}
        >
          <div className="modal-content">
            <Box
              className="modal-right-section"
              sx={{ display: "flex", flexDirection: "column", gap: "40px" }}
            >
              <p className="modal-heading mt-4">Add to watchlist instead</p>
              <img
                className="reason-box-closeicon"
                src={closeIcon}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "16px",
                  top: "16px",
                }}
                onClick={() => setOpenReasonModal(false)}
                alt="close"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <div
                  onClick={() => {
                    setOpenPassDealModal(true);
                    setOpenReasonModal(false);
                  }}
                >
                  <CustomBlackBtn text="No" mode="light" />
                </div>
                <div onClick={addTowatchlist}>
                  <CustomBlackBtn text="Yes" />
                </div>
              </div>
            </Box>
            <div className="modal-left-section"></div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PassDealModal;
