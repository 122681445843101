import React from "react";

import moment from "moment";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import { useNavigate } from "react-router-dom";

import { LuClock } from "react-icons/lu";

import "../../../../CSS/FounderPage/Dashboard/dashboardState.css";
import { Box, Typography } from "@mui/joy";
import { BorderLinearProgress } from "../../../Common/functions";

//We are showing details that are filled by company here as deal might not be created yet by FA.
const LiveDeal = ({ dealID, details, deal, status }) => {
  const Navigate = useNavigate();
  const token = localStorage.getItem(`token`);
  function daysRemaining(targetDate) {
    const target = moment(targetDate).startOf("day");
    const today = moment().startOf("day");

    const difference = target.diff(today, "days");

    return difference > 0 ? difference : 0;
  }
  const parseData = (data) => {
    try {
      return JSON?.parse(data[0])[0];
    } catch {}
  };
  const progress =
    deal.FundingRequired > 0
      ? (deal.TotalFundingRaised / deal.FundingRequired) * 100
      : 0;
  return (
    <div className="dashBoard-state-card">
      <div className="inner-content-div">
        <div className="flex justify-between">
          <div className="text-sm font-semibold">
            {parseData(deal?.RoundType) || ` `}
          </div>
          <div className="deal-status">{status}</div>
        </div>

        <div className="middle-div">
          {deal.dealCreatedDate && (
            <p className="roundSize-text">
              <span>Live from:</span>{" "}
              {moment(deal.dealCreatedDate).format("D MMM YYYY")}
            </p>
          )}
          {details.timeline && (
            <div className="flex justify-between">
              {details.timeline && (
                <p className="roundSize-text">
                  <span>End Date:</span>{" "}
                  {moment(details.timeline).format("D MMM YYYY")}
                </p>
              )}
              <p className="remaining-days">
                <LuClock /> {daysRemaining(details.timeline)} days left
              </p>
            </div>
          )}
        </div>
        <div>
          <Box
            sx={{ position: "relative", display: "inline-flex", width: "100%" }}
          >
            <BorderLinearProgress
              variant="determinate"
              value={(
                (deal.FundingRequired > 0
                  ? deal.TotalFundingRaised / deal.FundingRequired
                  : 0) * 100
              ).toFixed(2)}
              sx={{
                height: 15,
                width: "100%",
                borderRadius: "10px",
                overflow: "hidden",
                "& .MuiLinearProgress-bar": {
                  borderRadius: "0px",
                  backgroundColor: "#00A6FB",
                },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: progress >= 60 ? "50%" : `${progress + 12}%`, // Center if progress > 60, else at the end
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                className="card-raised-text"
                sx={{ whiteSpace: "nowrap" }}
                style={{
                  color: progress >= 60 ? "#FFF" : "#1a1a1a",
                }}
              >
                {`${Math.round(progress)}% raised`}
              </Typography>
            </Box>
          </Box>
          <div className="Card-div-32">
            <div className="Card-div-33">
              <p className="Card-div-34">₹{deal.TotalFundingRaised}Cr</p>
            </div>
            <div>
              <p className="Card-div-34">₹{deal.FundingRequired}Cr</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end gap-4">
          <div>
            <CustomBlackBtn
              text="Deal Profile"
              filled={false}
              variant="sm"
              onClick={() =>
                Navigate("/company/profilePreview", {
                  state: {
                    startupID: deal?.OrganizationID,
                    token: token,
                    DealID: deal?.DealID,
                  },
                })
              }
            />
          </div>
          <div>
            <CustomBlackBtn
              text="Deal Details"
              variant="sm"
              onClick={() => Navigate("/company/ongoingDeals/current")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveDeal;
