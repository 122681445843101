import { CurrencyRupee } from "@mui/icons-material";
import React from "react";

const FiveCards = ({ data, fundingRequired }) => {
  return (
    <>
      <div className="flex justify-normal gap-6 items-center w-full">
        <div className="h-[65px] max-w-[281px] bg-white rounded w-full flex justify-center items-center">
          <div className="flex flex-col items-center">
            <div className="text-base font-semibold text-[var(--Primary-Text-Color)]">
              {data?.profileViews}
            </div>
            <div className="text-sm font-light text-[#696F79]">
              Profile views
            </div>
          </div>
        </div>
        <div className="h-[65px] max-w-[281px] bg-white rounded w-full flex justify-center items-center">
          <div className="flex flex-col items-center">
            <div className="text-base font-semibold text-[var(--Primary-Text-Color)]">
              {data?.noNameRequests}{" "}
              <span className="text-xs font-light text-[#696F79]">
                requests
              </span>
            </div>
            <div className="text-sm font-light text-[#696F79]">
              Investor Screening
            </div>
          </div>
        </div>
        <div className="p-4 pt-3.5 h-[65px] max-w-[281px] bg-white rounded w-full">
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center gap-1 text-base font-semibold text-[var(--Primary-Text-Color)]">
              {data?.bidsReceived[0]?.totalIOI_Bids +
              data?.bidsReceived[0]?.totalLOI_Bids ? (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" />{" "}
                  {data?.bidsReceived[0]?.totalIOI_Bids +
                    data?.bidsReceived[0]?.totalLOI_Bids}
                  Cr{" "}
                </div>
              ) : (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" /> 0Cr
                </div>
              )}
              <div className="text-xs font-light text-[#696F79]">
                Bids Received{" "}
              </div>
            </div>
            {fundingRequired && (
              <div className="text-xs text-[#4FB24A]">
                {data?.bidsReceived[0]?.totalIOI_Bids +
                data?.bidsReceived[0]?.totalLOI_Bids
                  ? (
                      ((data?.bidsReceived[0]?.totalIOI_Bids +
                        data?.bidsReceived[0]?.totalLOI_Bids) /
                        Number(fundingRequired)) *
                      100
                    )?.toFixed(0) + `% Round`
                  : ``}
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center gap-1 text-xs font-semibold text-[var(--Primary-Text-Color)] pl-1">
              {data?.bidsReceived[0]?.countIOI_Bids +
                data?.bidsReceived[0]?.countLOI_Bids || 0}{" "}
              <div className="text-xs font-light text-[#696F79]">
                Term Sheet Received{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 pt-3.5 h-[65px] max-w-[281px] bg-white rounded w-full">
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center gap-1 text-base font-semibold text-[var(--Primary-Text-Color)]">
              {data?.bidsAccepted[0]?.totalIOI_Bids +
              data?.bidsAccepted[0]?.totalLOI_Bids ? (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" />{" "}
                  {data?.bidsAccepted[0]?.totalIOI_Bids +
                    data?.bidsAccepted[0]?.totalLOI_Bids}
                  Cr{" "}
                </div>
              ) : (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" /> 0Cr
                </div>
              )}{" "}
              <div className="text-xs font-light text-[#696F79]">
                Bids Accepted{" "}
              </div>
            </div>
            {fundingRequired && (
              <div className="text-xs text-[#4FB24A]">
                {data?.bidsAccepted[0]?.totalIOI_Bids +
                data?.bidsAccepted[0]?.totalLOI_Bids
                  ? (
                      ((data?.bidsAccepted[0]?.totalIOI_Bids +
                        data?.bidsAccepted[0]?.totalLOI_Bids) /
                        Number(fundingRequired)) *
                      100
                    )?.toFixed(0) + `% Round`
                  : ``}
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center gap-1 text-xs font-semibold text-[var(--Primary-Text-Color)] pl-1">
              {data?.bidsAccepted[0]?.countIOI_Bids +
                data?.bidsAccepted[0]?.countLOI_Bids || 0}{" "}
              <div className="text-xs font-light text-[#696F79]">
                Term Sheet Accepted{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 pt-3.5 h-[65px] max-w-[281px] bg-white rounded w-full">
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center gap-1 text-base font-semibold text-[var(--Primary-Text-Color)]">
              {data?.dealClosed?.totalInvestmentAmount ? (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" />
                  {data?.dealClosed?.totalInvestmentAmount + `Cr`}{" "}
                </div>
              ) : (
                <div className="flex items-center">
                  <CurrencyRupee fontSize="16px" /> 0Cr
                </div>
              )}
              <div className="text-xs font-light text-[#696F79]">Closed </div>
            </div>
            {fundingRequired && (
              <div className="text-xs text-[#4FB24A]">
                {(
                  ((data?.dealClosed?.totalInvestmentAmount || 0) /
                    Number(fundingRequired)) *
                  100
                )?.toFixed(0) + `% Round`}{" "}
              </div>
            )}
          </div>
          <div>
            <div className="flex items-center gap-1 text-xs font-semibold text-[var(--Primary-Text-Color)] pl-1">
              {data?.dealClosed?.count || 0}{" "}
              <div className="text-xs font-light text-[#696F79]">Investors</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiveCards;
