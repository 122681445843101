import { Info } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Select,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";

const TimePeriodButton = ({
  type,
  setType,
  title,
  ltm,
  select,
  year = true,
  option,
  setOption,
  m = true,
  q = true,
  paddingRight,
  tooltip = false,
}) => {
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== null) {
      setType(newAlignment);
    }
  };
  const getText = () => {
    switch (type) {
      case "m":
        return `Month`;
      case "q":
        return `Quarter`;
      case "y":
        return `Year`;
      case "ltm":
        return "ltm";
      default:
        return ``;
    }
  };
  useEffect(() => {
    setOption && setOption(`previous${type}`);
  }, [type]);
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: theme.spacing(0.5),
      borderRadius: "4px",
      height: "28px",
      fontFamily: "Karla, sans-serif",
      fontWeight: 500,
      color: "var(--brand-color)",
      border: "0.5px solid var(--brand-color)",
      '&[aria-pressed="true"]': {
        color: "white",
        background: "var(--brand-color)",
        "&:hover": {
          backgroundColor: "var(--brand-color)",
        },
      },
    },
  }));
  return (
    <>
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={{
          paddingRight: paddingRight || "5px",
          height: "28px",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Karla,sans-serif",
            color: "var(--Primary-Text-Color)",
            alignContent: "center",
          }}
        >
          {title}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <Info
                fontSize="small"
                sx={{
                  marginLeft: "3px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          )}
        </p>

        <div>
          {select && type !== "ltm" && type !== "y" && (
            <Select
              value={option}
              onChange={handleSelect}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "max-content",
                height: "28px",
                borderRadius: "4px",
                fontFamily: "Karla,sans-serif",
                fontSize: "14px",
                color: "var(--Secondary-Text-Color)",
              }}
            >
              <MenuItem
                sx={{
                  fontFamily: "Karla,sans-serif",
                  fontSize: "14px",
                  color: "var(--Secondary-Text-Color)",
                }}
                value={`previous${type}`}
              >
                {/* Previous {getText()} */}
                MoM
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: "Karla,sans-serif",
                  fontSize: "14px",
                  color: "var(--Secondary-Text-Color)",
                }}
                value={`last${type}`}
              >
                {/* Previous Year */}
                PY
              </MenuItem>
            </Select>
          )}
          <StyledToggleButtonGroup
            exclusive
            aria-label="Period"
            value={type}
            onChange={handleChange}
          >
            {m && <ToggleButton value="m">M</ToggleButton>}
            {q && <ToggleButton value="q">Q</ToggleButton>}
            {year && <ToggleButton value="y">Y</ToggleButton>}
            {ltm && <ToggleButton value="ltm">LTM</ToggleButton>}
          </StyledToggleButtonGroup>
        </div>
      </Box>
    </>
  );
};

export default TimePeriodButton;
