import React, { useState, useEffect } from "react";
import { formatSize, handleFileDownload, timeDiff } from "../functions";
import Toast from "../Toast";
import { fetchAllFiles, downloadZip } from "../../../endpoints/dataroom";
import moment from "moment";
import { FileIcon, defaultStyles } from "react-file-icon";
import FileTree from "./filestructure";

import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import { MdOutlineFileDownload } from "react-icons/md";
import CustomToolTip from "../../Common/components/custom/customToolTip";

import home from "../../../Assets/Images/InvestorPage/Dashboard/home.svg";
import { useLoader } from "../LoaderProvider";
import { dumpInvestorInteraction } from "../../../endpoints/common";
import { ToastContainer } from "react-toastify";
const Dataroom = ({ deal, stage }) => {
  const loader = useLoader();
  const [activeDataRoomBtn, setActiveDataRoomBtn] = useState(1);
  const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState([]);
  const [recentlyUpdatedFiles, setRecentlyUpdatedFiles] = useState([]);
  const [activeFiles, setActiveFiles] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [activePath, setActivePath] = useState("");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [fileInfo, setFileInfo] = useState([]);
  const [activeIndices, setActiveIndices] = useState("");
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      label: "abc",
      path: "",
    },
  ]);
  useEffect(() => {
    const dumpInteraction = async () => {
      try {
        await dumpInvestorInteraction(
          {
            dealId: deal?.DealID,
            type: "view",
            page: "dataroom",
            stageOfDeal: stage,
            element: "page",
          },
          token
        );
      } catch (error) {
        console.error("Error dumping interaction", error);
      }
    };
    dumpInteraction();
  }, []);

  const dumpFolderViewInteraction = async (path) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal?.DealID,
          type: "view",
          page: "dataroom",
          stageOfDeal: stage,
          element: "folderView",
          pageSection: path,
        },
        token
      );
    } catch (error) {
      console.error("Error dumping interaction", error);
    }
  };
  const folderClick = (path, index, fileStructure) => {
    if (activePath === path) {
      // Prevent reprocessing if the same path is clicked
      return;
    }
    let newActiveIndex;
    if (fileStructure) {
      //This is case when someone trie to click from the file structure given.
      newActiveIndex = index ? `${index}` : ``;
    } else {
      newActiveIndex = activeIndices
        ? `${activeIndices}.${index + 1}`
        : `${index + 1}`;
    }

    // Update state
    setActiveIndices(newActiveIndex);
    setActiveFiles([]);
    setActivePath(path);
  };
  const BreadCrumbClick = (path, index) => {
    if (activePath === path) {
      // Prevent reprocessing if the same path is clicked
      return;
    }

    const newActiveIndices = activeIndices.split(".").slice(0, index).join(".");

    setActiveIndices(newActiveIndices);
    setActiveFiles([]);
    setActivePath(path);
  };
  const getFilesAndFolders = (path) => {
    fileInfo.map((file) => {
      if (file.path.startsWith(path)) {
        const remainingPath = file.path.substring(path.length);
        const len = remainingPath.split("/").length;
        if (len === 2) {
          setActiveFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (!fileExists) {
              return [...prev, file];
            }
            return prev;
          });
          setRecentlyUploadedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.createdAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
          setRecentlyUpdatedFiles((prev) => {
            const fileExists = prev.some(
              (existingFile) => existingFile.path === file.path
            );
            if (timeDiff(file.updatedAt, 168) && !fileExists) {
              return [...prev, file];
            }

            return prev;
          });
        } else {
        }
      }
    });
  };

  const fetchAllFilesAndFolders = async () => {
    try {
      loader.start("Fetching all files and folders...");
      const response = await fetchAllFiles(deal.DealID, token);
      loader.stop();
      if (response.data) {
        setFileInfo(response.data);
      } else {
        Toast("Incorrect details provided", "error", "admindataroom");
      }
    } catch (err) {
      loader.stop();

      Toast("Failed to fetch all files and folders", "error", "admindataroom");
    }
  };
  const handlePathChange = () => {
    const pathnames = activePath.split("/").filter((x) => x);
    pathnames.unshift("home");
    setBreadCrumbs(pathnames);

    const breadcrumbList = pathnames.map((label, index) => {
      const path =
        index === 0 ? "" : `/${pathnames.slice(1, index + 1).join("/")}`;
      return { label, path };
    });

    setBreadCrumbs(breadcrumbList);
  };
  const calculateFolderSize = (path) => {
    let size = 0;
    fileInfo.map((file) => {
      if (file.path.startsWith(path) && !file.isFolder) {
        size += file.size;
      }
    });
    return size;
  };
  useEffect(() => {
    if (deal?.DealID && token) {
      fetchAllFilesAndFolders();
    }
  }, [token, deal?.DealID]);
  useEffect(() => {
    if (fileInfo.length > 0) {
      getFilesAndFolders(activePath);
    }
  }, [fileInfo, activePath]);
  useEffect(() => {
    handlePathChange();
  }, [activePath]);
  const downloadAZip = async (path) => {
    try {
      await dumpInvestorInteraction(
        {
          dealId: deal.DealID,
          type: "download",
          page: "dataroom",
          stageOfDeal: stage,
          element: "downloadZipButton",
          pageSection: path || `/`,
        },
        token
      );
    } catch (e) {
      console.error("Error dumping interaction", e);
    }
    try {
      loader.start("Getting data...");
      //By default all the zip
      const path = "/";
      const response = await downloadZip(deal?.DealID, path, token);
      loader.stop();
      const blob = await response.blob();

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element, set its href to the blob URL, and simulate a click
      const a = document.createElement("a");
      a.href = url;
      a.download = `${deal.CompanyName}.zip`; // The filename to save as
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading zip:", error);
      Toast("Failed to download zip", "error", "admindataroom");
    }
  };
  function isAccessible(type) {
    if (role === "company") {
      return true;
    } else if (type === "preliminary") {
      return true;
    } else if (
      type === "fullDD" &&
      (stage === "requestSent" ||
        stage === "requestAccepted" ||
        stage === "ioiSubmitted")
    ) {
      return false;
    }
  }
  useEffect(() => {
    if (activeDataRoomBtn === 1) setAllFiles(activeFiles);
    else if (activeDataRoomBtn === 2) setAllFiles(recentlyUploadedFiles);
    else if (activeDataRoomBtn === 3) setAllFiles(recentlyUpdatedFiles);
    else setAllFiles(setActiveFiles);
  }, [
    activeDataRoomBtn,
    activeFiles,
    recentlyUpdatedFiles,
    recentlyUploadedFiles,
  ]);
  return (
    <>
      <div className="NoNamePreview-left">
        <FileTree
          dealID={deal.DealID}
          filesData={fileInfo}
          folderClick={folderClick}
          activePath={activePath}
          isAccessible={isAccessible}
        />
      </div>
      <ToastContainer position="top-center" containerId={"dataroomComponent"} />
      <div
        className="NoNamePreview-right"
        id="scrollContainer"
        style={{ paddingBottom: "120px" }}
      >
        <div className="dataroom-div">
          <div className="dataroom-first-div">
            <div className="dataroom-breadcrumbs">
              <img src={home} alt="Home" />
              &nbsp;
              {breadCrumbs.map((breadCrumb, index) => (
                <span key={index}>
                  {index !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      BreadCrumbClick(breadCrumb.path, index);
                    }}
                  >
                    {breadCrumb.label}
                  </span>
                </span>
              ))}
            </div>

            <>
              <div className="dataroom-btns-left">
                <CustomToolTip
                  title="Download Zip"
                  position="left"
                  theme="black"
                >
                  <div onClick={() => downloadAZip(activePath)}>
                    <MdOutlineFileDownload
                      style={{
                        fontSize: "26px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </CustomToolTip>
                <button
                  className={`dataroom-btn ${
                    activeDataRoomBtn === 1 && "active-dataroom-btn"
                  }`}
                  onClick={() => setActiveDataRoomBtn(1)}
                >
                  All
                </button>
                <button
                  className={`dataroom-btn ${
                    activeDataRoomBtn === 2 && "active-dataroom-btn"
                  }`}
                  onClick={() => setActiveDataRoomBtn(2)}
                >
                  Recently Uploaded
                </button>
                <button
                  className={`dataroom-btn ${
                    activeDataRoomBtn === 3 && "active-dataroom-btn"
                  }`}
                  onClick={() => setActiveDataRoomBtn(3)}
                >
                  Recently Updated
                </button>
              </div>
            </>
          </div>
          <table className="dataroom-table" style={{ width: "100%" }}>
            <thead className="dataroom-header">
              <tr>
                {/* <th style={{ width: "2%" }} className="top-left-th"></th> */}
                <th className="dataroom-table-header" style={{ width: "6%" }}>
                  #
                </th>
                <th className="dataroom-table-header" style={{ width: "20%" }}>
                  File name
                </th>
                <th className="dataroom-table-header" style={{ width: "20%" }}>
                  Size
                </th>
                <th className="dataroom-table-header" style={{ width: "20%" }}>
                  Type
                </th>
                <th className="dataroom-table-header" style={{ width: "20%" }}>
                  Date Uploaded
                </th>
                <th className="dataroom-table-header" style={{ width: "20%" }}>
                  Last Updated
                </th>
              </tr>
            </thead>
            <tbody>
              {allFiles &&
                allFiles.map((item, index) => (
                  <tr
                    key={index}
                    className="dataroom-row"
                    style={{
                      opacity: isAccessible(item.type) ? 1 : 0.5,
                    }}
                  >
                    <td className="dataroom-folder">
                      {activeIndices === ""
                        ? `${index + 1}`
                        : `${activeIndices}.${index + 1}`}
                    </td>
                    <td
                      className="dataroom-folder"
                      style={{
                        backgroundColor: "CCCCCC",
                      }}
                    >
                      <div
                        className="folder-cell"
                        style={{ cursor: "pointer" }}
                      >
                        {item.isFolder ? (
                          <div>
                            <ContextMenuTrigger id={item.path}>
                              <div
                                className="admin-folder-name"
                                onClick={() => folderClick(item.path, index)}
                              >
                                <div className="dataroom-folder-img">
                                  <FileIcon
                                    extension={"Folder"}
                                    color="#FF9933"
                                    {...defaultStyles[
                                      item.path.split(".").pop()
                                    ]}
                                  />
                                </div>
                                <p>{item.path.split("/").slice(-1)[0]}</p>
                              </div>
                            </ContextMenuTrigger>
                            <ContextMenu id={item.path}>
                              <ContextMenuItem
                                onClick={() => folderClick(item.path, index)}
                              >
                                Open
                              </ContextMenuItem>
                              <ContextMenuItem
                                onClick={() => downloadAZip(item.path)}
                              >
                                Download
                              </ContextMenuItem>
                            </ContextMenu>
                          </div>
                        ) : (
                          <div className="admin-folder-name">
                            <div className="dataroom-folder-img">
                              <FileIcon
                                extension={item.path.split(".").pop()}
                                {...defaultStyles[item.path.split(".").pop()]}
                              />
                            </div>
                            <p
                              onClick={() =>
                                handleFileDownload(
                                  item?.url,
                                  item?.name,
                                  loader,
                                  `dataroomComponent`,
                                  true,
                                  deal.DealID,
                                  token
                                )
                              }
                            >
                              {item.name}
                            </p>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="dataroom-folder">
                      {item.isFolder
                        ? formatSize(calculateFolderSize(item.path))
                        : formatSize(item.size)}
                    </td>
                    <td className="dataroom-folder capitalize whitespace-nowrap">
                      {`${item.type} ${isAccessible(item.type) ? "" : "🔒"}`}
                    </td>
                    <td className="dataroom-folder">
                      {moment(item.createdAt)
                        .tz("Asia/Kolkata")
                        .format("MMM D, YYYY")}
                    </td>
                    <td className="dataroom-folder">
                      {moment(item.updatedAt)
                        .tz("Asia/Kolkata")
                        .format("MMM D, YYYY")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dataroom;
