import axios from "axios";
const token = localStorage.getItem("token");

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const getDealSummaryPageData = async (dealId) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/getDealSummaryData`,
      { dealId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (e) {
    console.error("There was a problem with fetching data:", e);
    return { data: null, error: e.message };
  }
};

export const getAllDeals = async (token) => {
  try {
    const response = await axios.get(`${backend_url}/deals/getAllDeals`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const addDealInteraction = async (messageData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/addDealInteraction`,
      messageData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getCoInvestorData = async (data) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/getCoinvestorData/${data.dealId}/${data.investorId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getTotalAmountRaisedByStartup = async (data) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/getTotalAmountRaised/${data.dealId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getIOIDetails = async (data) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/getIOIDetails/${data.dealId}`,
      {
        headers: {
          Authorization: data.token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealInteraction = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/getDealInteractions`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getAllEditRequests = async (token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/allEditRequests`,
      {},
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const ToggleResolveThread = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/toggleResolveThread`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getNoNameDeals = async (investorId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/noNameDeals/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/noNameDeals/${investorId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getAllNoNameDeals = async (token) => {
  try {
    const response = await axios.get(`${backend_url}/deals/allNoNameDeals`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getPassedDeals = async (investorId, token) => {
  try {
    const response = await axios.get(
      `${backend_url}/deals/passedDeals/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
      // `http://localhost:3000/deals/passedDeals/${investorId}`
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const updateDealInteraction = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/updateDealInteraction`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealByStage = async (dealId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByStage/${dealId}/${investorId}`
      `${backend_url}/deals/dealsByStage/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getAllDealInteractions = async (dealId, investorId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/allDealsByInvestorId/${investorId}/${dealId}`
      `${backend_url}/deals/allDealsByInvestorId/${investorId}/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const postStageData = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/stageData/`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getDealIdbyStartupId = async (startupId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealIdByStartupId/${startupId}`
      `${backend_url}/deals/getDealId/${startupId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getDealsByInvestorID = async (investorId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByInvestorId/${investorId}`
      `${backend_url}/deals/dealsByInvestorId/${investorId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

export const getInvestorsByDealID = async (dealId, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByDealId/${dealId}`
      `${backend_url}/deals/dealsByDealId/${dealId}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const dealInterestByStage = async (dealId, investorID, stage, token) => {
  try {
    const response = await axios.get(
      // `http://localhost:3000/deals/dealsByInvestorId/${investorId}`
      `${backend_url}/deals/dealInterestByStage/${dealId}/${investorID}/${stage}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    //
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};
export const getDealDetailsByDealId = async (dealId, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/dealDetails/${dealId}`,
      {},
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const publishStartup = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/publishStartup`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const sendForReview = async (data, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/sendForReview`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const updateDeal = async (formDataInput, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/dealUpdate`,
      formDataInput,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const showInterestInDeal = async (dealId, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/showInterestInDeal`,
      { dealId },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const toggleOpenListing = async (DealID, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/toggleOpenListing`,
      { DealID },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const createOpenListing = async (token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/createOpenListing`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
export const referInvestor = async (formData, token) => {
  try {
    const response = await axios.post(
      `${backend_url}/deals/referInvestor`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return { response, error: null };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};
