import React, { useState, useEffect } from "react";
import Header from "../../Common/components/header";
import Editable from "../../Common/components/editable";
import { FaFilterCircleXmark, FaFilter } from "react-icons/fa6";

import InputCell from "../../Common/components/inputCell";
import { investorStepClick } from "../../Common/functions";
import Toast from "../../Common/Toast";
import {
  PostDetails,
  fetchInvestorDetails,
  fetchInvestorMemberDetails,
  getValues,
  deleteMember,
  investorAddMember,
  fetchMembers,
} from "../../../endpoints/investor";
import {
  isValidUrl,
  isValidLinkedInUrl,
  inputCustomStyles,
  isValidEmail,
  inputCustomStylesAssestClass,
} from "../../Common/functions";
import Step from "./steps";

import { useLocation, useNavigate } from "react-router";
import { Box, Checkbox, FormControl, FormLabel, Input } from "@mui/joy";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import Select from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TagsInput from "react-tagsinput";
import { Button, Grid, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { IoMdFastforward } from "react-icons/io";
import CloseIcon from "@mui/icons-material/Close";

import uploadImg from "../../../Assets/Images/signup/upload-img.svg";
import editImg from "../../../Assets/Images/signup/edit-img.svg";
import uploadProfile from "../../../Assets/Images/signup/upload-profile.svg";
import Next from "../../../Assets/Images/signup/next.svg";
import previous from "../../../Assets/Images/signup/previous.svg";
import members from "../../../Assets/Images/signup/members.svg";
import invite from "../../../Assets/Images/signup/invite.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import Verify from "../../../Assets/Images/signup/InvestorRegister/Verify.png";
import Illustration from "../../../Assets/Images/signup/illustration.svg";
import exploreDeals from "../../../Assets/Images/signup/exploreDeals.svg";
import Bin from "../../../Assets/Images/signup/bin.svg";
import investorOnboardingProcess from "../../../Assets/Images/signup/investorOnboardingProcess.svg";
import investorOnboardingProcessSmall from "../../../Assets/Images/signup/investorOnboardingProcessSmall.svg";

import "../../../CSS/Signup/investorDetails.css";
import "../../../CSS/InvestorOnBoarding/style.css";
import "react-tagsinput/react-tagsinput.css";
import { useLoader } from "../../Common/LoaderProvider";
import TnC from "../RegisterPage/TnC";

const Investordetails = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const loader = useLoader();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { investorName, investorEmail, investorPhoneNumber, type, tkn } =
    location.state || {};
  const getToken = localStorage.getItem("token");
  const [token, setToken] = useState(getToken !== null ? getToken : tkn);
  const [width, setWidth] = useState(window.innerWidth);
  const [rows, setRows] = useState(4);
  const [investorID, setInvestorID] = useState("");
  const [foundingTeamProfileFile, setFoundingTeamProfileFile] = useState([]);
  const [assetClass, setAssetClass] = useState([]);
  const [investmentStage, setInvestmentStage] = useState([]);
  const [financialCriteria, setFinancialCriteria] = useState(null);
  const [coInvestment, setCoInvestment] = useState(false);
  const [industryVerticalOptions, setIndustryVerticalOptions] = useState([]);
  const [idealFocusOptions, setIdealFocusOptions] = useState([]);
  const [moderatelySuitableOptions, setModeratelySuitableOptions] = useState(
    []
  );
  const [negativeListOptions, setNegativeListOptions] = useState([]);
  const [endMarketFocus, setEndMarketFocus] = useState([]);
  const [investorType, setinvestorType] = useState(type);
  const [ticketSizevalue, setTicketSizeValue] = useState([0, 0]);
  const [totalExposure, setTotalExposure] = useState([0, 0]);
  const [stakeAcquired, setStakeAcquired] = useState([0, 0]);
  const [fileUploads, setFileUploads] = useState({});
  const [investorLogoFile, setInvestorLogoFile] = useState("");
  const [investorImageFile, setInvestorImageFile] = useState("");
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [linkedinUrlError, setLinkedinUrlError] = useState("");
  const [investorWebsiteError, setInvestorWebsiteError] = useState("");
  const [filteredOptions1, setFilteredOptions1] = useState([]);
  const [filteredOptions2, setFilteredOptions2] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const investorOnboardingProcessImg =
    windowWidth > 991
      ? investorOnboardingProcess
      : investorOnboardingProcessSmall;
  const [formData, setFormData] = useState({
    investorLogo: investorLogoFile,
    companyName: "",
    foundedYear: "",
    headquarters: "",
    numEmployees: "",
    investorWebsite: "",
    investmentThesis: "",
    otherCriteria: "",
    assetClass: [],
    investmentStage: [],
    financialCriteria: [],
    industryVerticalOptions: [],
    idealFocusOptions: [],
    moderatelySuitableOptions: [],
    negativeListOptions: [],
    endMarketFocus: [],
    investorType: type ? type : "",
    ticketSizevalue: [],
    totalExposure: [],
    stakeAcquired: [],
    coInvestment: false,
    name: investorName,
    email: investorEmail,
    phoneNumber: investorPhoneNumber,
    investmentDetails: [
      {
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
        dryPowder: "",
      },
    ],
    investorProfile: investorImageFile,
    acceptTC: false,
    title: "",
    linkedIn: "",
    onBoardingComplete: false,
    invitedMembers: [],
    inviteSentMembers: [],
    isApplicationReviewed: false,
    profileCompleted: false,
  });

  const [stepOneData, setStepOneData] = useState({
    investorLogo: investorLogoFile,
    companyName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    investorType: type ? type : "",
    investorWebsite: "",
    investmentThesis: "",
    keyInvestments: "",
    investmentDetails: [
      {
        fund: "",
        vintage: "",
        investments: "",
        aum: "",
        dryPowder: "",
      },
    ],
  });

  const [stepTwoData, setStepTwoData] = useState({
    foundingMember: [
      {
        foundingTeamProfile: "",
        name: "",
        title: "",
        email: "",
        linkedIn: "",
        phoneNumber: "",
        biography: "",
      },
    ],
  });

  const [stepThreeData, setStepThreeData] = useState({
    ticketSizevalue: [],
    totalExposure: [],
    stakeAcquired: [],
    investmentStage: [],
    assetClass: [],
    coInvestment: false,
    financialCriteria: [],
    otherCriteria: "",
  });

  const [stepFourData, setStepFourData] = useState({
    industryVerticalOptions: [],
    idealFocusOptions: [],
    moderatelySuitableOptions: [],
    negativeListOptions: [],
    endMarketFocus: [],
  });

  const [stepFiveData, setStepFiveData] = useState({
    acceptTC: false,
    onBoardingComplete: false,
  });

  const [stepSixData, setStepSixData] = useState({
    isApplicationReviewed: false,
  });

  const [stepsCompleted, setStepsCompleted] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  });
  const allSteps = [
    { data: stepOneData, step: "first-step", key: "step1" },
    { data: stepTwoData, step: "second-step", key: "step2" },
    { data: stepThreeData, step: "third-step", key: "step3" },
    { data: stepFourData, step: "fourth-step", key: "step4" },
    { data: stepFiveData, step: "fifth-step", key: "step5" },
    { data: stepSixData, step: "sixth-step", key: "step6" },
  ];

  const [invitedMembers, setInvitedMembers] = useState([]);
  useEffect(() => {
    if (!token) {
      Navigate("/signin");
    }
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message (some browsers may not display the custom message)
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // Required for some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (stepFiveData.onBoardingComplete) {
      investorStepClick(6, formData.investorStepClick);
    }
  }, [stepFiveData]);
  const [data, setData] = useState(stepOneData.investmentDetails);

  const [memberData, setMemberData] = useState({
    name: "",
    title: "",
    companyName: "",
    organizationId: "",
    phoneNumber: "",
    email: "",
    biography: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const fetchDetails = async () => {
      if (!token) return;
      let response;
      try {
        loader.start("Fetching investor details...");
        response = await fetchInvestorDetails(token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
      if (response?.data !== null) {
        const responseData = response.data;
        const { data } = responseData;
        const {
          investorLogo,
          companyName,
          headquarters,
          foundedYear,
          numEmployees,
          investorType,
          keyInvestments,
          investorWebsite,
          investmentThesis,
          foundingMember,
          ticketSizevalue,
          totalExposure,
          stakeAcquired,
          investmentStage,
          assetClass,
          coInvestment,
          financialCriteria,
          industryVerticalOptions,
          idealFocusOptions,
          moderatelySuitableOptions,
          negativeListOptions,
          endMarketFocus,
          acceptTC,
          investmentDetails,
          onBoardingComplete,
          isApplicationReviewed,
          investorID,
          profileCompleted,
        } = data;
        const parsedDetails = investmentDetails.map((detail) =>
          JSON.parse(detail)
        );
        if (data) {
          // setFormData(data);
          setStepOneData({
            investorLogo,
            companyName,
            headquarters,
            foundedYear,
            numEmployees,
            investorType,
            investorWebsite,
            investmentThesis,
            keyInvestments,
            investmentDetails: parsedDetails
              ? parsedDetails[0]
              : [
                  {
                    fund: "",
                    vintage: "",
                    investments: "",
                    aum: "",
                    dryPowder: "",
                  },
                ],
          });
          if (parsedDetails.length > 0) {
            setData(parsedDetails[0]);
          }
          const parsedMemberDetails =
            foundingMember.length > 0 &&
            foundingMember.map((detail) => JSON.parse(detail));
          setStepTwoData({
            foundingMember: parsedMemberDetails
              ? parsedMemberDetails[0]
              : [
                  {
                    foundingTeamProfile: "",
                    name: "",
                    title: "",
                    email: "",
                    linkedIn: "",
                    phoneNumber: "",
                  },
                ],
          });
          setStepThreeData({
            ticketSizevalue: ticketSizevalue.map((detail) =>
              JSON.parse(detail)
            )[0],
            totalExposure: totalExposure?.map((detail) =>
              JSON.parse(detail)
            )[0],
            stakeAcquired: stakeAcquired.map((detail) => JSON.parse(detail))[0],
            investmentStage: investmentStage.map((detail) =>
              JSON.parse(detail)
            )[0],
            assetClass: assetClass.map((detail) => JSON.parse(detail))[0],
            coInvestment,
            financialCriteria: financialCriteria.map((detail) =>
              JSON.parse(detail)
            )[0],
          });
          setInvestmentStage(investmentStage);
          setAssetClass(assetClass);
          setFinancialCriteria(financialCriteria);
          if (industryVerticalOptions.length > 0) {
            setIndustryVerticalOptions(
              industryVerticalOptions.map((detail) => JSON.parse(detail))[0]
            );
          }
          setStepFourData({
            industryVerticalOptions: industryVerticalOptions?.map((detail) =>
              JSON.parse(detail)
            )[0],
            idealFocusOptions: idealFocusOptions.map((detail) =>
              JSON.parse(detail)
            )[0],
            moderatelySuitableOptions: moderatelySuitableOptions.map((detail) =>
              JSON.parse(detail)
            )[0],
            negativeListOptions: negativeListOptions.map((detail) =>
              JSON.parse(detail)
            )[0],
            endMarketFocus:
              endMarketFocus.length > 0
                ? endMarketFocus.map((detail) => JSON.parse(detail))[0]
                : [],
          });
          if (acceptTC) setStepFiveData();
          setStepFiveData({ acceptTC, onBoardingComplete });
          setStepSixData({ isApplicationReviewed });
          setInvestorID(investorID);
          if (idealFocusOptions.length > 0)
            setIdealFocusOptions(
              JSON.parse(idealFocusOptions).map((option) => ({
                value: option,
                label: option,
              }))
            );
          if (moderatelySuitableOptions.length > 0)
            setModeratelySuitableOptions(
              JSON.parse(moderatelySuitableOptions).map((option) => ({
                value: option,
                label: option,
              }))
            );
        }
      } else {
      }
    };
    fetchDetails();
  }, [token]); // Removed formData from dependencies to avoid unnecessary refetching
  useEffect(() => {
    setFormData({
      ...stepOneData,
      ...stepTwoData,
      ...stepThreeData,
      ...stepFourData,
      ...stepFiveData,
    });
  }, [stepOneData, stepTwoData, stepThreeData, stepFourData, stepFiveData]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Fund",
        accessor: "fund",
        placeholder: "fund",
        Cell: InputCell,
        width: 220, // Customize the width for this column
      },
      {
        Header: "Vintage",
        accessor: "vintage",
        placeholder: "vintage",
        Cell: InputCell,
        width: 137.5, // Customize the width for this column
      },
      {
        Header: "Investments",
        accessor: "investments",
        placeholder: "investments",
        Cell: InputCell,
        width: 272.5, // Customize the width for this column
      },
      {
        Header: "AUM (₹Cr)",
        accessor: "aum",
        placeholder: "aum",
        Cell: InputCell,
        width: 190, // Customize the width for this column
      },
      {
        Header: "Dry Powder (₹Cr)",
        accessor: "dryPowder",
        placeholder: "dryPowder",
        Cell: InputCell,
        width: 190, // Customize the width for this column
      },
      {
        id: "delete",
        Cell: ({ row }) =>
          row.index > 0 && (
            <RiDeleteBin6Line
              onClick={() => deleteRow(row.index)}
              className="red-bin"
            />
          ),
        width: 100,
      },
    ],
    []
  );

  const [InvestorTypeOptions, setInvestorTypeOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [stageOptions, setStageOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [AssetOptions, setAssetOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [CriteriaOptions, setCriteriaOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [industryFocusOptions, setIndustryFocusOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [endMarketFocusOptions, setEndMarketFocusOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (!token) return;
      try {
        loader.start("Fetching investor member details...");
        const response = await fetchInvestorMemberDetails(token);
        loader.stop();
        if (response.data !== null) {
          const responseData = response.data;
          const { data } = responseData;
          setMemberData(data);
        }
      } catch (error) {
        loader.stop();
      }
    };
    fetchMemberDetails();
  }, []);
  const fetchMembersData = async () => {
    try {
      if (investorID && token) {
        const response = await fetchMembers(investorID, token);
        if (response && response.data) {
          const membersData = response.data.data; // Adjust according to the actual structure of the response
          setInvitedMembers(membersData);
        }
      }
    } catch (error) {
      console.error("Failed to fetch members:", error);
      // Optionally, handle errors by setting some state or showing a message
    }
  };

  useEffect(() => {
    fetchMembersData();
  }, [investorID]);
  useEffect(() => {
    const filteredOptions1 = industryFocusOptions.filter(
      (option) =>
        !moderatelySuitableOptions.find(
          (selected) => selected.value === option.value
        )
    );
    const filteredOptions2 = industryFocusOptions.filter(
      (option) =>
        !idealFocusOptions.find((selected) => selected.value === option.value)
    );
    setFilteredOptions1(filteredOptions1);
    setFilteredOptions2(filteredOptions2);
  }, [
    idealFocusOptions,
    moderatelySuitableOptions,
    stepFourData,
    industryFocusOptions,
  ]);
  useEffect(() => {
    const loadData = async () => {
      try {
        loader.start("Loading data...");
        const result = await getValues(); // Assuming this fetches all data
        loader.stop();
        if (result.error) {
          console.error("Error fetching data:", result.error);
        } else {
          // Process each options set
          if (result.data[0]) {
            if (result.data[0].InvestorTypeOptions) {
              const formattedInvestorTypeOptions =
                result.data[0].InvestorTypeOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setInvestorTypeOptions(formattedInvestorTypeOptions);
            }

            if (result.data[0].StageOptions) {
              const formattedStageOptions = result.data[0].StageOptions.map(
                (item) => ({
                  value: item,
                  label: item,
                })
              );
              setStageOptions(formattedStageOptions);
            }

            if (result.data[0].AssetOptions) {
              const formattedAssetOptions = result.data[0].AssetOptions.map(
                (item) => ({
                  value: item,
                  label: item,
                })
              );
              setAssetOptions(formattedAssetOptions);
            }

            if (result.data[0].CriteriaOptions) {
              const formattedCriteriaOptions =
                result.data[0].CriteriaOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setCriteriaOptions(formattedCriteriaOptions);
            }

            if (result.data[0].IndustryFocusOptions) {
              const formattedIndustryFocusOptions =
                result.data[0].IndustryFocusOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              if (
                stepFourData?.industryVerticalOptions?.length === 0 ||
                !stepFourData?.industryVerticalOptions
              ) {
                setStepFourData((prevData) => ({
                  ...prevData,
                  industryVerticalOptions: result.data[0].IndustryFocusOptions,
                }));
                setIndustryVerticalOptions(result.data[0].IndustryFocusOptions);
              }
              setIndustryFocusOptions(formattedIndustryFocusOptions);
            }

            if (result.data[0].EndMarketFocusOptions) {
              const formattedEndMarketFocusOptions =
                result.data[0].EndMarketFocusOptions.map((item) => ({
                  value: item,
                  label: item,
                }));
              setEndMarketFocusOptions(formattedEndMarketFocusOptions);
            }
          }
        }
      } catch (error) {
        console.error("Unexpected error fetching data:", error);
      }
    };

    loadData();
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const updateData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const addNew = () => {
    setData((oldData) => {
      const lastRow = oldData[oldData.length - 1];
      if (
        !lastRow.fund ||
        !lastRow.vintage ||
        !lastRow.investments ||
        !lastRow.aum ||
        !lastRow.dryPowder
      ) {
        Toast(
          "Please fill in all fields of the last row before adding a new one.",
          "info",
          "investorOnBoarding"
        );
        return oldData;
      }

      return [
        ...oldData,
        { fund: "", vintage: "", investments: "", aum: "", dryPowder: "" },
      ];
    });
  };

  useEffect(() => {
    const updatedInvestmentDetails = data.map((row) => {
      return row;
    });

    setStepOneData((prev) => ({
      ...prev,
      investmentDetails: updatedInvestmentDetails,
    }));
  }, [data]);

  const deleteRow = (rowIndex) => {
    setData((currentData) => {
      if (currentData.length > 1) {
        setStepOneData((prevStepOneData) => {
          const updatedInvestmentDetails =
            prevStepOneData.investmentDetails.filter(
              (_, index) => index !== rowIndex
            );
          return {
            ...prevStepOneData,
            investmentDetails: updatedInvestmentDetails,
          };
        });

        return currentData.filter((_, index) => index !== rowIndex);
      }
      return currentData;
    });
  };

  function handleFounderImageUpload(index, e) {
    const file = e.target.files[0];
    const newFoundingMembers = [...stepTwoData.foundingMember];
    const fileURL = URL.createObjectURL(file);
    newFoundingMembers[index].foundingTeamProfile = file;

    const newFoundingTeamProfileFile = [...foundingTeamProfileFile];
    newFoundingTeamProfileFile[index] = fileURL;
    setFoundingTeamProfileFile(newFoundingTeamProfileFile);
    setStepTwoData((prevState) => ({
      ...prevState,
      foundingMember: newFoundingMembers,
    }));
  }
  const sendInvite = async (emails) => {
    try {
      if (emails.length === 0) {
        if (inputValue.trim() !== "") {
          if (isValidEmail(inputValue) === false) {
            Toast(
              "Please enter a valid email address.",
              "error",
              "investorOnBoarding"
            );
            setEmails([]);
            setInputValue("");
            return;
          }
          emails.push(inputValue);
        } else {
          Toast(
            "Please enter atlease one email address.",
            "error",
            "investorOnBoarding"
          );
          setEmails([]);
          setInputValue("");
          return;
        }
      }
      let response;
      try {
        loader.start("Sending invites...");
        response = await investorAddMember(emails, token);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
      if (response?.data !== null) {
        setOpenSaveModal(true);
        setEmails([]);
      } else {
        Toast(
          "Member is already registered with Fundrev.",
          "error",
          "investorOnBoarding"
        );
      }
    } catch (error) {
      Toast("Error in inviting member", "error", "investorOnBoarding");
    }
    fetchMembersData();
  };
  const addFoundingMember = () => {
    const requiredFields = [
      "name",
      "title",
      "email",
      "linkedIn",
      "phoneNumber",
    ];
    const isAllFieldsFilled = (member) => {
      return requiredFields.every(
        (field) => member[field] && member[field].trim() !== ""
      );
    };

    setStepTwoData((prevState) => {
      const unfilledMembers = prevState.foundingMember.filter(
        (member) => !isAllFieldsFilled(member)
      );
      if (unfilledMembers.length > 0) {
        // You can display an error message here if needed
        console.error(
          "Please fill out all required fields for existing members before adding a new one."
        );
        Toast(
          "Please fill out all required fields for existing members before adding a new one.",
          "error",
          "investorOnBoarding"
        );
        return prevState; // Do not add a new member
      }

      // All previous members have their details filled, add a new member
      return {
        ...prevState,
        foundingMember: [
          ...prevState.foundingMember,
          {
            name: "",
            title: "",
            email: "",
            linkedIn: "",
            phoneNumber: "",
            foundingTeamProfile: "",
          },
        ],
      };
    });
  };
  const inviteFounderMember = async (index, email) => {
    const member = stepTwoData.foundingMember[index];
    const requiredFields = [
      "name",
      "title",
      "email",
      "linkedIn",
      "phoneNumber",
    ];

    const allFieldsFilled = requiredFields.every(
      (field) => member[field] && member[field].trim() !== ""
    );

    if (!allFieldsFilled) {
      Toast(
        "Please fill all the required fields before inviting the member.",
        "error",
        "investorOnBoarding"
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Toast(
        "Please enter a valid email address.",
        "error",
        "investorOnBoarding"
      );
      return;
    }

    const mem = invitedMembers.find((member) => member.email === email);
    if (mem) {
      Toast("Member already invited", "info", "investorOnBoarding");
      const newFoundingMembers = [...stepTwoData.foundingMember];
      newFoundingMembers[index] = {
        name: "",
        title: "",
        email: "",
        linkedIn: "",
        phoneNumber: "",
        foundingTeamProfile: "",
      };
      const newProfileImage = [...foundingTeamProfileFile];
      newProfileImage[index] = "";
      setFoundingTeamProfileFile(newProfileImage);

      setStepTwoData((prevState) => ({
        ...prevState,
        foundingMember: newFoundingMembers,
      }));
      return;
    }

    try {
      loader.start("Sending invite...");
      const response = await investorAddMember([email], token);
      loader.stop();
      if (response.data !== null) {
        await postFormData(stepTwoData);
        fetchMembersData();
        setOpenSaveModal(true);
      } else {
        Toast("Error in inviting member", "error", "investorOnBoarding");
      }
    } catch (error) {
      loader.stop();
      Toast("Error in inviting member", "error", "investorOnBoarding");
    }
  };

  const deleteFounderMember = async (index, email) => {
    const member = invitedMembers.find((member) => member.email === email);

    try {
      const response = await deleteMember(member._id, member.investorID, token);
      if (response) {
        Toast("Member deleted successfully", "info", "investorOnBoarding");
        fetchMembersData();
      } else {
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };
  const handleBlur = (index) => {
    const errors = [...linkedinUrlError];
    if (!isValidLinkedInUrl(stepTwoData.foundingMember[index].linkedIn)) {
      errors[index] = "Please enter a valid LinkedIn URL.";
    } else {
      errors[index] = "";
    }
    setLinkedinUrlError(errors);
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of the space bar
      const trimmedValue = inputValue.trim(); // Trim the current input value
      if (trimmedValue) {
        if (isValidEmail(trimmedValue) === false) {
          Toast(
            "Please enter a valid email address.",
            "error",
            "investorOnBoarding"
          );
          return;
        }
        handleChange([...emails, trimmedValue]);
        setInputValue(""); // Clear the input field after adding the tag
      }
    }
  };
  const handleInputChangeStep1 = (event) => {
    let { name, value } = event.target;
    if (name === "investorWebsite") {
      if (!isValidUrl(value)) {
        setInvestorWebsiteError("Please enter a valid URL.");
      } else {
        setInvestorWebsiteError(""); // Clear the error message if the URL is valid
      }
    }
    if (name === "foundedYear") {
      const currentYear = new Date().getFullYear();
      if (value > currentYear) {
        value = currentYear;
      }
    }
    setStepOneData({
      ...stepOneData,
      [name]: value,
    });
  };

  const handleInputChangeStep2 = (index, event) => {
    const { name, value } = event.target;
    if (name === "linkedIn") {
      const errors = [...linkedinUrlError];
      if (!isValidLinkedInUrl(value)) {
        errors[index] = "Please enter a valid LinkedIn URL.";
      } else {
        errors[index] = "";
      }
      setLinkedinUrlError(errors);
    }
    const newFoundingMembers = [...stepTwoData.foundingMember];
    newFoundingMembers[index][name] = value;

    setStepTwoData({ ...stepTwoData, foundingMember: newFoundingMembers });
  };

  const handleInputChangeStep3 = (event) => {
    const { name, value } = event.target;
    setStepThreeData({
      ...stepThreeData,
      [name]: value,
    });
  };

  const handleInvestorTypeChange = (selected) => {
    setinvestorType(selected);
    setStepOneData((prevStepOneData) => ({
      ...prevStepOneData,
      investorType: selected.value, // Assuming `selected` has a `value` property with the investor type
    }));
  };
  const handleTicketChange = (event, index) => {
    const newValue = [...ticketSizevalue];
    newValue[index] = parseFloat(event.target.value, 10);
    setTicketSizeValue(newValue);
    setStepThreeData({
      ...stepThreeData,
      ticketSizevalue: newValue,
    });
  };

  const handleExposureChange = (event, index) => {
    const newValue = [...totalExposure];
    newValue[index] = parseFloat(event.target.value, 10);
    setTotalExposure(newValue);
    setStepThreeData({
      ...stepThreeData,
      totalExposure: newValue,
    });
  };
  const handleStakeChangeInput = (event, index) => {
    const value = parseFloat(event.target.value);
    if (value < 0 || value > 100) {
      Toast(
        "Please enter a value between 0 and 100.",
        "info",
        "investorOnBoarding"
      );
      return; // Optionally reset the value or handle as needed
    }
    // const newValue = event.target.value;
    const newValue = [...stakeAcquired];
    newValue[index] = parseFloat(event.target.value, 10);
    // if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= 100 && newValue.match(/^\d+$/))) {
    //   // setValue(newValue);
    // }
    setStakeAcquired(newValue);
    setStepThreeData({
      ...stepThreeData,
      stakeAcquired: newValue,
    });
  };

  const handleCriteriaChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setFinancialCriteria(selected);
    setStepThreeData((prevState) => ({
      ...prevState,
      financialCriteria: selectedValues,
    }));
  };
  const handleIdealFocusChange = (selected) => {
    setIdealFocusOptions(selected);
    const selectedValues = selected.map((option) => option.value);
    setStepFourData({
      ...stepFourData,
      idealFocusOptions: selectedValues,
    });
  };
  const handleModeratelySuitableChange = (selected) => {
    setModeratelySuitableOptions(selected);
    const selectedValues = selected.map((option) => option.value);
    setStepFourData({
      ...stepFourData,
      moderatelySuitableOptions: selectedValues,
    });
  };

  const handleNegativeListChange = () => {
    const selectedValuesSet = new Set([
      ...idealFocusOptions.map((opt) => opt.value),
      ...moderatelySuitableOptions.map((opt) => opt.value),
    ]);

    const negativeOptions = industryFocusOptions.filter(
      (opt) => !selectedValuesSet.has(opt.value)
    );
    setNegativeListOptions(negativeOptions);
    setStepFourData({
      ...stepFourData,
      negativeListOptions: negativeOptions,
    });
  };

  useEffect(() => {
    handleNegativeListChange();
  }, [idealFocusOptions, moderatelySuitableOptions, industryFocusOptions]);

  const handleEndMarketChange = (selected) => {
    setEndMarketFocus(selected);
    const selectedValues = selected.map((option) => option.value);
    setStepFourData({
      ...stepFourData,
      endMarketFocus: selectedValues,
    });
  };
  const handleAssetClassChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setAssetClass(selectedOption);
    setStepThreeData({
      ...stepThreeData,
      assetClass: selectedValues,
    });
  };
  const handleStageChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setInvestmentStage(selectedOption);
    setStepThreeData({
      ...stepThreeData,
      investmentStage: selectedValues,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (emails) => {
    if (emails.length === 0) {
      setEmails(emails);
      return;
    }
    if (isValidEmail(emails[emails.length - 1])) {
      setEmails(emails);
    } else {
      Toast(
        "Please enter a valid email address.",
        "info",
        "investorOnBoarding"
      );
    }
  };
  function handleFileUpload(e) {
    fileUploads["investorLogo"] = 1;
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      fileUploads["investorLogo"] = 1;
      const fileURL = URL.createObjectURL(file);
      setInvestorLogoFile(fileURL);
      setStepOneData((prevStepOneData) => ({
        ...prevStepOneData,
        investorLogo: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only.",
        "info",
        "investorOnBoarding"
      );
    }
  }
  function handleProfileImageUpload(e) {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      fileUploads["investorImageFile"] = 1;
      const fileURL = URL.createObjectURL(file);
      setInvestorImageFile(fileURL);
      setStepTwoData((prevStepTwoData) => ({
        ...prevStepTwoData,
        investorProfile: file,
      }));
    } else {
      Toast(
        "Please select an image file (PNG or JPG format only.",
        "info",
        "investorOnBoarding"
      );
    }
  }
  const handleCoInvestment = (option) => {
    if (option === 1) {
      setCoInvestment(true);
      setStepThreeData({
        ...stepThreeData,
        coInvestment: true,
      });
    } else {
      setCoInvestment(false);
      setStepThreeData({
        ...stepThreeData,
        coInvestment: false,
      });
    }
  };

  const handlePhoneChange = (index, phoneNumber) => {
    const newFoundingMembers = [...stepTwoData.foundingMember];
    newFoundingMembers[index].phoneNumber = phoneNumber;
    // setFoundingMembers(newFoundingMembers);
    setStepTwoData({
      ...stepTwoData,
      foundingMember: newFoundingMembers,
    });
  };
  useEffect(() => {}, [industryVerticalOptions]);

  const validateStep = (currentStepData, step) => {
    let isValid = true;

    switch (step) {
      case "first-step":
        isValid =
          currentStepData.companyName &&
          currentStepData.companyName.trim() !== "" &&
          currentStepData.headquarters &&
          currentStepData.headquarters.trim() !== "" &&
          currentStepData.foundedYear !== undefined &&
          currentStepData.numEmployees > 0 &&
          currentStepData.investorType &&
          currentStepData.investorType.trim() !== "" &&
          currentStepData.investorWebsite &&
          currentStepData.investorWebsite.trim() !== "" &&
          currentStepData.investmentThesis &&
          currentStepData.investmentThesis.trim() !== "" &&
          currentStepData.keyInvestments &&
          currentStepData.keyInvestments.trim() !== "";
        break;
      case "second-step":
        isValid =
          Array.isArray(currentStepData.foundingMember) &&
          currentStepData.foundingMember.length > 0 &&
          currentStepData.foundingMember[0].name &&
          currentStepData.foundingMember[0].name.trim() !== "" &&
          currentStepData.foundingMember[0].title &&
          currentStepData.foundingMember[0].title.trim() !== "" &&
          currentStepData.foundingMember[0].phoneNumber &&
          currentStepData.foundingMember[0].phoneNumber.trim() !== "";
        // currentStepData.foundingMember[0].foundingTeamProfile;
        break;
      case "third-step":
        isValid =
          Array.isArray(currentStepData.investmentStage) &&
          currentStepData.investmentStage.length > 0 &&
          currentStepData.investmentStage.every((v) => v && v.trim() !== "") &&
          Array.isArray(currentStepData.assetClass) &&
          currentStepData.assetClass.length > 0 &&
          currentStepData.assetClass.every((v) => v && v.trim() !== "") &&
          // Array.isArray(currentStepData.financialCriteria) &&
          // currentStepData.financialCriteria.length > 0 &&
          // currentStepData.financialCriteria.every(
          //   (v) => v && v.trim() !== ""
          // ) &&
          Array.isArray(currentStepData.ticketSizevalue) &&
          currentStepData.ticketSizevalue.length > 0 &&
          currentStepData.ticketSizevalue.every((v) => v > 0) &&
          Array.isArray(currentStepData.totalExposure) &&
          currentStepData.totalExposure.length > 0 &&
          currentStepData.totalExposure.every((v) => v > 0) &&
          Array.isArray(currentStepData.stakeAcquired) &&
          currentStepData.stakeAcquired.length > 0 &&
          currentStepData.stakeAcquired.every((v) => v >= 0 && v <= 100);
        break;

      case "fourth-step":
        isValid =
          Array.isArray(currentStepData.industryVerticalOptions) &&
          currentStepData.industryVerticalOptions.length > 0 &&
          currentStepData.industryVerticalOptions.every(
            (v) => v && v.trim() !== ""
          ) &&
          // Array.isArray(currentStepData.idealFocusOptions) &&
          // currentStepData.idealFocusOptions.length > 0 &&
          // currentStepData.idealFocusOptions.every(
          //   (v) => v && v.trim() !== ""
          // ) &&
          // Array.isArray(currentStepData.moderatelySuitableOptions) &&
          // currentStepData.moderatelySuitableOptions.length > 0 &&
          // currentStepData.moderatelySuitableOptions.every(
          //   (v) => v && v.trim() !== ""
          // ) &&
          Array.isArray(currentStepData.endMarketFocus) &&
          currentStepData.endMarketFocus.length > 0 &&
          currentStepData.endMarketFocus.every((v) => v && v.trim() !== "");
        break;

      case "fifth-step":
        isValid = currentStepData.acceptTC === true;
        break;
      case "sixth-step":
        isValid =
          currentStepData.isApplicationReviewed === true &&
          currentStepData.onBoardingComplete;
        break;
      default:
        isValid = true;
        break;
    }

    return isValid;
  };

  const [currStep, setCurrStep] = useState("");

  useEffect(() => {
    const validateAllSteps = () => {
      const updatedCompletionStatus = {};

      allSteps.forEach(({ data, step, key }) => {
        if (validateStep(data, step)) {
          updatedCompletionStatus[key] = true;
        } else {
          updatedCompletionStatus[key] = false;
        }
      });

      setStepsCompleted(updatedCompletionStatus);
    };

    validateAllSteps();
  }, [stepOneData, stepTwoData, stepThreeData, stepFourData, stepFiveData]);

  function nextSection(step1, step2, currentStepData) {
    if (step1 === "fourth-step" && step2 === "fifth-step") {
      handleNegativeListChange();
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...currentStepData, // Properly spread currentStepData's properties
    }));
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  }

  const prevSection = (step1, step2, currentStepData) => {
    document.getElementsByClassName(step1)[0].style.display = "none";
    document.getElementsByClassName(step2)[0].style.display = "flex";
    window.scrollTo(0, 0);
  };

  const handleSave = async (currentStepData, currentStep) => {
    try {
      loader.start();
      await postFormData(currentStepData); // Assuming postFormData is properly handling API requests
      loader.stop();
      // setOpenSaveModal(true);
    } catch (error) {
      loader.stop();
      console.error("Failed to save data:", error);
      Toast(
        "Failed to save data, please try again.",
        "error",
        "investorOnBoarding"
      ); // User-friendly error message
    }
  };
  const handleSkip = async (currentStep) => {
    try {
      localStorage.setItem("skipSteps", true);
      document.getElementsByClassName(currentStep)[0].style.display = "none";
      document.getElementsByClassName("fifth-step")[0].style.display = "flex";
    } catch (error) {
      console.error("Failed to skip data:", error);
    }
  };

  const checkField = (currentStepData, step) => {
    let allFieldsFilled = true;
    let minMax = true;
    let minMax2 = true;
    let unfilledFields = [];

    const addUnfilledField = (fieldName) => {
      unfilledFields.push(fieldName);
      allFieldsFilled = false;
    };
    let stepName = "";
    switch (step) {
      case "first-step":
        if (
          !currentStepData.companyName ||
          currentStepData.companyName.trim() === ""
        ) {
          addUnfilledField("Company Name");
        }
        if (
          !currentStepData.headquarters ||
          currentStepData.headquarters.trim() === ""
        ) {
          addUnfilledField("Headquarter");
        }
        if (currentStepData.foundedYear === undefined) {
          addUnfilledField("Founded Year");
        }
        if (
          !currentStepData.numEmployees ||
          currentStepData.numEmployees <= 0
        ) {
          addUnfilledField("Number of Employees");
        }
        if (
          !currentStepData.investorType ||
          currentStepData.investorType.trim() === ""
        ) {
          addUnfilledField("Investor Type");
        }
        if (
          !currentStepData.investorWebsite ||
          currentStepData.investorWebsite.trim() === ""
        ) {
          addUnfilledField("Investor Website");
        }
        if (
          !currentStepData.keyInvestments ||
          currentStepData.keyInvestments.trim() === ""
        ) {
          addUnfilledField("Key Investments");
        }
        if (
          !currentStepData.investmentThesis ||
          currentStepData.investmentThesis.trim() === ""
        ) {
          addUnfilledField("Investment Thesis");
        }
        stepName = "Investor Details";
        break;

      case "second-step":
        if (
          currentStepData.foundingMember &&
          currentStepData.foundingMember.length > 0
        ) {
          const firstMember = currentStepData.foundingMember[0];
          const requiredFields = ["name", "title", "phoneNumber"];

          requiredFields.forEach((field) => {
            if (!firstMember[field] || firstMember[field].trim() === "") {
              addUnfilledField(field.replace(/([A-Z])/g, " $1"));
              // isValid = false;
            }
          });

          // if (!firstMember.foundingTeamProfile) {
          //   addUnfilledField("Founding Team Profile");
          //   // isValid = false;
          // }
        } else {
          // isValid = false;
          addUnfilledField("Founding Member Details");
        }
        stepName = "Investment Team";
        break;
      case "third-step":
        if (
          !Array.isArray(currentStepData.investmentStage) ||
          currentStepData.investmentStage.length === 0 ||
          !currentStepData.investmentStage.every((v) => v && v.trim() !== "")
        ) {
          addUnfilledField("Stage of Investement");
        }
        if (
          !Array.isArray(currentStepData.assetClass) ||
          currentStepData.assetClass.length === 0 ||
          !currentStepData.assetClass.every((v) => v && v.trim() !== "")
        ) {
          addUnfilledField("Asset Class");
        }
        // if (
        //   !Array.isArray(currentStepData.financialCriteria) ||
        //   currentStepData.financialCriteria.length === 0 ||
        //   !currentStepData.financialCriteria.every((v) => v && v.trim() !== "")
        // ) {
        //   addUnfilledField("Financial Criteria");
        // }
        if (
          !Array.isArray(currentStepData.ticketSizevalue) ||
          currentStepData.ticketSizevalue.length === 0 ||
          !currentStepData.ticketSizevalue.every((v) => v > 0)
        ) {
          addUnfilledField("Initial Check size");
        }
        if (
          !Array.isArray(currentStepData.ticketSizevalue) ||
          currentStepData.ticketSizevalue.length === 0 ||
          !currentStepData.ticketSizevalue.every((v) => v > 0)
        ) {
          addUnfilledField(`Total Exposure`);
        }
        if (
          !Array.isArray(currentStepData.stakeAcquired) ||
          currentStepData.stakeAcquired.length === 0 ||
          !currentStepData.stakeAcquired.every((v) => v >= 0 && v <= 100)
        ) {
          addUnfilledField("Stake Acquired");
        }
        if (
          Number(currentStepData.ticketSizevalue[0]) >
          Number(currentStepData.ticketSizevalue[1])
        ) {
          Toast(
            `Please check min and max values of Initial Check size field in Investment Focus step.
            `,
            "info",
            "investorOnBoarding"
          );
          minMax = false;
        }

        if (
          Number(currentStepData.totalExposure[0]) >
          Number(currentStepData.totalExposure[1])
        ) {
          Toast(
            `Please check min and max values of Total Exposure field in Investment Focus step.
            `,
            "info",
            "investorOnBoarding"
          );
          minMax2 = false;
        }

        stepName = "Investment Focus";
        break;

      case "fourth-step":
        if (
          !Array.isArray(currentStepData.industryVerticalOptions) ||
          currentStepData.industryVerticalOptions.length === 0 ||
          !currentStepData.industryVerticalOptions.every(
            (v) => v && v.trim() !== ""
          )
        ) {
          addUnfilledField("Industry Vertical");
        }
        // if (
        //   !Array.isArray(currentStepData.idealFocusOptions) ||
        //   currentStepData.idealFocusOptions.length === 0 ||
        //   !currentStepData.idealFocusOptions.every((v) => v && v.trim() !== "")
        // ) {
        //   addUnfilledField("Ideal Fit");
        // }

        // if (
        //   !Array.isArray(currentStepData.moderatelySuitableOptions) ||
        //   currentStepData.moderatelySuitableOptions.length === 0 ||
        //   !currentStepData.moderatelySuitableOptions.every(
        //     (v) => v && v.trim() !== ""
        //   )
        // ) {
        //   addUnfilledField("Moderate Fit");
        // }
        if (
          !Array.isArray(currentStepData.endMarketFocus) ||
          currentStepData.endMarketFocus.length === 0 ||
          !currentStepData.endMarketFocus.every((v) => v && v.trim() !== "")
        ) {
          addUnfilledField("End Market Focus");
        }
        stepName = "Industry Focus";
        break;
      case "fifth-step":
        if (
          currentStepData.acceptTC === false ||
          currentStepData.acceptTC === undefined
        ) {
          addUnfilledField("NDA Agreement");
        }
        stepName = "Accept T&C";
        break;

      default:
        break;
    }
    if (!allFieldsFilled) {
      Toast(
        `Please fill in all fields in ${stepName}: ${unfilledFields.join(
          ", "
        )}`,
        "info",
        "investorOnBoarding"
      );
    }

    return allFieldsFilled && minMax && minMax2;
  };

  const saveAndFinish = async (step1, step2, currentStepData) => {
    const skipSteps = localStorage.getItem("skipSteps");
    if (skipSteps) {
      if (!stepOneData.companyName) {
        investorStepClick(1, stepSixData.isApplicationReviewed);
        Toast(
          "Please add your investment firm name",
          "info",
          "investorOnBoarding"
        );

        return;
      } else if (!stepTwoData.foundingMember[0]["name"]) {
        investorStepClick(2, stepSixData.isApplicationReviewed);
        Toast("Please add member name", "info", "investorOnBoarding");
        return;
      } else if (!stepFiveData.acceptTC) {
        Toast("Please accept Terms & conditions", "info", "investorOnBoarding");
        return;
      }
    } else if (
      !checkField(stepOneData, "first-step") ||
      !checkField(stepTwoData, "second-step") ||
      !checkField(stepThreeData, "third-step") ||
      !checkField(stepFourData, "fourth-step") ||
      !checkField(stepFiveData, "fifth-step")
    ) {
      return; // Exit or perform other logic if any of the steps fail validation
    }
    const stepsToValidate = allSteps.slice(0, -1);

    const allStepsValid = stepsToValidate.every(({ data, step }) =>
      validateStep(data, step)
    );
    formData.profileCompleted = allStepsValid;
    const updatedFormData = {
      ...formData,
      ...currentStepData,
    };
    try {
      await postFormData(updatedFormData);

      // Update formData state with updated data
      setFormData(updatedFormData);

      // UI updates should only be done if data posting is successful
      document.getElementsByClassName(step1)[0].style.display = "none";
      document.getElementsByClassName(step2)[0].style.display = "flex";
      window.scrollTo(0, 0);

      await postFormData({
        ...formData,
        onBoardingComplete: true,
      });
    } catch (error) {
      console.error("Failed to post data:", error);
      Toast(
        "Failed to save data, please try again.",
        "error",
        "investorOnBoarding"
      );
    }
  };

  const postFormData = async (data) => {
    const formDataInput = new FormData();
    const changedIndices = [];
    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Check if the property is an array or an object that needs to be stringified

          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "foundingMember") {
              data[key].map((item, index) => {
                if (item.foundingTeamProfile instanceof File) {
                  formDataInput.append(
                    "foundingTeamProfile",
                    item.foundingTeamProfile
                  );
                  changedIndices.push(index);
                }
              });
            }
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (changedIndices.length > 0) {
      formDataInput.append("changedIndices", JSON.stringify(changedIndices));
    }
    if (!data.investorID) {
      formDataInput.append("investorID", investorID);
    }
    formDataInput.append("email", investorEmail);
    const response = await PostDetails(formDataInput, token);
  };
  const navigateToInvestorDashboard = () => {
    localStorage.setItem("token", token);
    localStorage.setItem("role", "investor");
    localStorage.setItem("onBoardingComplete", true);
    localStorage.setItem("isApplicationReviewed", true);
    localStorage.removeItem("skipSteps");
    Navigate("/investor/newDeals", {
      state: {
        token: token,
      },
    });
  };

  useEffect(() => {
    let timer;
    if (openSaveModal) {
      timer = setTimeout(() => {
        setOpenSaveModal(false);
      }, 1000); // Close after 3 seconds
    }
    return () => clearTimeout(timer); // Clean up the timer
  }, [openSaveModal]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width > 991) {
      setRows(3);
    } else {
      setRows(1);
    }
  }, [width]);

  const handleCheckboxChange = (option) => {
    if (industryVerticalOptions.includes(option)) {
      // Remove the option if it already exists in the array
      setIndustryVerticalOptions(
        industryVerticalOptions.filter((item) => item !== option)
      );
      setStepFourData({
        ...stepFourData,
        industryVerticalOptions: industryVerticalOptions.filter(
          (item) => item !== option
        ),
      });
    } else {
      // Add the option if it doesn't exist
      setIndustryVerticalOptions([...industryVerticalOptions, option]);
      setStepFourData({
        ...stepFourData,
        industryVerticalOptions: [
          ...stepFourData.industryVerticalOptions,
          option,
        ],
      });
    }
  };

  const handleCheckboxChangeEndMarket = (selected) => {
    if (stepFourData.endMarketFocus.includes(selected)) {
      setStepFourData({
        ...stepFourData,
        endMarketFocus: stepFourData.endMarketFocus.filter(
          (item) => item !== selected
        ),
      });
    } else {
      setStepFourData({
        ...stepFourData,
        endMarketFocus: [...stepFourData.endMarketFocus, selected],
      });
    }
  };
  return (
    <section className="investorDetailsPage">
      <Helmet>
        <title> Fundrev | Investor Onboarding</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="investorOnBoarding" />
      <Header
        color="#193946"
        token="Investortoken"
        type="Investor"
        data={formData}
        investorID={investorID}
        email={investorEmail}
        postData={postFormData}
      />
      <div className="investorDetails first-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"1"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <div className="add-member-div">
              <p className="investorDetails-heading">Investor Details</p>
              <button className="add-row OrgId">
                Investor. ID: {investorID}
              </button>
            </div>
            <div class="upload-btn-wrapper">
              <button class="upload-img-btn investor-logo-btn">
                <img
                  src={
                    !fileUploads["investorLogo"]
                      ? stepOneData.investorLogo
                        ? stepOneData.investorLogo
                        : uploadImg
                      : investorLogoFile
                  }
                  className="InvestorLogo"
                  alt="Upload Image"
                  style={{ cursor: "pointer" }}
                />
              </button>
              {(fileUploads["investorLogo"] || stepOneData.investorLogo) && (
                <label htmlFor="investor-logo" className="edit-img-btn">
                  <img src={editImg} className="editImg" alt="Edit" />
                </label>
              )}

              <input
                id="investor-logo"
                type="file"
                name="image"
                accept="image/png, image/jpeg" // Accept only JPEG and PNG
                onChange={handleFileUpload}
                style={{ cursor: "pointer" }}
              />
            </div>
            <form className="input-section">
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Investment Firm Name</FormLabel>
                  <Input
                    className="input"
                    placeholder="Investment Co."
                    color="neutral"
                    name="companyName"
                    type="text"
                    value={stepOneData.companyName}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Headquarter</FormLabel>
                  <Input
                    className="input"
                    placeholder="Bengaluru, India"
                    color="neutral"
                    name="headquarters"
                    type="text"
                    value={stepOneData.headquarters}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Founded Year</FormLabel>
                  <Input
                    className="input"
                    placeholder="2018"
                    color="neutral"
                    name="foundedYear"
                    type="number"
                    value={stepOneData.foundedYear}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
              </div>
              <div className="input-div">
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Number of Employees</FormLabel>
                  <Input
                    className="input"
                    placeholder="10"
                    color="neutral"
                    name="numEmployees"
                    type="number"
                    value={stepOneData.numEmployees}
                    onChange={handleInputChangeStep1}
                    required
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Investor Type</FormLabel>

                  <Select
                    // className="input"
                    className="react-select-input"
                    options={InvestorTypeOptions}
                    name="investorType"
                    value={stepOneData.investorType}
                    onChange={handleInvestorTypeChange}
                    placeholder={stepOneData.investorType}
                    required
                    styles={inputCustomStyles}
                  />
                </FormControl>
                <FormControl
                  className="form-control investorDetails-row-input"
                  id="Id"
                  required
                  size="lg"
                  color="primary"
                >
                  <FormLabel className="label">Investor Website</FormLabel>
                  <Input
                    className="input"
                    placeholder="www.investment.com"
                    color="neutral"
                    name="investorWebsite"
                    type="url"
                    value={stepOneData.investorWebsite}
                    required
                    onChange={handleInputChangeStep1}
                  />
                  {investorWebsiteError && (
                    <div style={{ color: "red" }}>{investorWebsiteError}</div>
                  )}
                </FormControl>
              </div>

              <div className="input-div">
                <FormControl
                  className="key-investment-form-control"
                  id="Id"
                  required
                  size="lg"
                >
                  <FormLabel className="label">Key Investments</FormLabel>
                  <TextField
                    className="key-investment-input"
                    placeholder="Flipkart, Rupee, TATA"
                    name="keyInvestments"
                    type="text"
                    value={stepOneData.keyInvestments}
                    onChange={handleInputChangeStep1}
                    required
                    multiline
                    minRows={rows}
                    sx={{ border: "1px solid #8692a6" }}
                  />
                </FormControl>

                <FormControl
                  className="investment-thesis-form-control"
                  id="Id"
                  required
                  size="lg"
                >
                  <FormLabel className="label">Investment Thesis</FormLabel>
                  <TextField
                    className="key-investment-input"
                    placeholder="The more detailed your investment thesis, the fewer deals you will need to reject later, as they will be more accurately aligned with your specific requirements"
                    name="investmentThesis"
                    type="text"
                    value={stepOneData.investmentThesis}
                    onChange={handleInputChangeStep1}
                    multiline
                    minRows={3}
                    variant="outlined"
                    sx={{ border: "1px solid #8692a6" }}
                  />
                </FormControl>
              </div>
              <div className="investmentDetails-table">
                <FormControl>
                  <FormLabel className="label">Investment Details</FormLabel>
                </FormControl>

                <div className="table-details">
                  <Editable
                    columns={columns}
                    data={data}
                    updateData={updateData}
                    deleteRow={deleteRow}
                    isCompany={false}
                  />
                  <div className="table-edit-buttons">
                    <button
                      onClick={(event) => {
                        event.preventDefault(); // This line prevents the form from being submitted
                        addNew();
                      }}
                      className="add-row"
                    >
                      <AiOutlinePlus />
                      &nbsp;&nbsp;Add row
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              style={{ visibility: "hidden" }}
              onClick={nextSection}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="skip-button"
                onClick={() => handleSkip("first-step")}
              >
                Skip steps
                <IoMdFastforward />
              </button>
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("first-step", "second-step", stepOneData);
                  handleSave(stepOneData, "first-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "600px", height: "398px" }}>
          <img src={Verify} alt="verify" className="verify-image" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Hang on tight, your team members will join soon!
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body"
            component="h2"
          >
            We have shared an invitation email to your team member.
          </Typography>
        </Box>
      </Modal>
      <div className="investorDetails second-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"2"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="details-section-div">
            <div className="investorDetails-first-section">
              <div className="add-member-div">
                <p className="investorDetails-heading">Investment Team</p>
                {/* <button onClick={addSection} className='add-row'><AiOutlinePlus />&nbsp;&nbsp; Add Member</button> */}
                <button
                  className="invite-members-div"
                  onClick={handleClickOpen}
                >
                  <img src={members} />
                  &nbsp;&nbsp; Invite Members
                </button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    component: "form",
                    onSubmit: (event) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const formJson = Object.fromEntries(formData.entries());
                      const email = formJson.email;
                      handleClose();
                    },
                    sx: {
                      width: "700px", // Adjust width as needed
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle>Invite Team members</DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent
                    sx={{
                      backgroundColor: "rgba(0,0,0,0.05)",
                    }}
                  >
                    <div className="dialogcontent">
                      <DialogContentText className="dialogcontenttext">
                        Invite by email
                      </DialogContentText>
                      <div className="select-peers-input">
                        <TagsInput
                          value={emails}
                          onChange={handleChange}
                          inputValue={inputValue}
                          onChangeInput={handleInputChange}
                          onlyUnique
                          inputProps={{
                            placeholder: "Select peers",
                            onKeyDown: handleKeyDown,
                          }}
                        />
                        <button
                          className="send-invite"
                          style={{ cursor: "pointer" }}
                          onClick={() => sendInvite(emails)}
                        >
                          <p>Send Invite</p>
                          <img src={invite} />
                        </button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              {stepTwoData.foundingMember?.slice(0, 1).map((member, index) => (
                <div key={index} className="member-form">
                  {index > 0 && <hr style={{ marginTop: "30px" }} />}
                  <div className="founding-member">
                    <div className="upload-btn-wrapper">
                      <button className="upload-img-btn">
                        <img
                          src={
                            !foundingTeamProfileFile[index]
                              ? member.foundingTeamProfile
                                ? member.foundingTeamProfile
                                : uploadProfile
                              : foundingTeamProfileFile[index]
                          }
                          className="InvestorLogo"
                          alt="Upload Image"
                          style={{ cursor: "pointer" }}
                        />
                      </button>

                      {member.foundingTeamProfile && (
                        <label
                          htmlFor={`founding-profile-${index}`}
                          className="edit-img-btn"
                        >
                          <img src={editImg} className="editImg" alt="Edit" />
                        </label>
                      )}
                      <input
                        id={`founding-profile-${index}`}
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg" // Accept only JPEG and PNG
                        onChange={(e) => handleFounderImageUpload(index, e)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <form className="input-section">
                    <div className="input-div">
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Full Name</FormLabel>
                        <Input
                          className="input"
                          placeholder="Full Name"
                          color="neutral"
                          name="name"
                          type="text"
                          value={member.name}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Title</FormLabel>
                        <Input
                          className="input"
                          placeholder="Title"
                          color="neutral"
                          name="title"
                          type="text"
                          value={member.title}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">
                          Official Email Address
                        </FormLabel>
                        <Input
                          className="input"
                          placeholder="Email"
                          color="neutral"
                          name="email"
                          type="email"
                          disabled
                          value={member.email}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                    </div>
                    <div className="input-div">
                      <FormControl
                        className="form-control investorDetails-row-input"
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">
                          LinkedIn Profile
                        </FormLabel>
                        <Input
                          className="input"
                          placeholder="LinkedIn Profile"
                          color="neutral"
                          name="linkedIn"
                          type="url"
                          value={member.linkedIn}
                          onChange={(e) => {
                            handleInputChangeStep2(index, e);
                          }}
                          onBlur={() => handleBlur(index)}
                        />
                        {linkedinUrlError[index] && (
                          <div style={{ color: "red" }}>
                            {linkedinUrlError[index]}
                          </div>
                        )}
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Phone Number</FormLabel>
                        <MuiTelInput
                          defaultCountry="IN"
                          className="input"
                          name="phoneNumber"
                          value={member.phoneNumber}
                          onChange={(phoneNumber) =>
                            handlePhoneChange(index, phoneNumber)
                          }
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        style={{ visibility: "hidden" }}
                      >
                        <FormLabel className="label">Random</FormLabel>
                        <Input className="input random-input" />
                      </FormControl>
                    </div>
                    <div className="input-div companyDetails-business-desc-div">
                      <FormControl
                        className="business-desc-form-control"
                        id="Id"
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Biography</FormLabel>
                        <TextField
                          className="large-input"
                          placeholder="Your biography"
                          name="biography"
                          type="text"
                          value={member.biography}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          multiline
                          minRows={3}
                        />
                      </FormControl>
                    </div>
                  </form>
                </div>
              ))}
              <div className="invited-investment-team">
                <p className="normal-small-text">Members</p>
                <div className="all-invited-email-div">
                  {invitedMembers &&
                    invitedMembers.map((member, index) => (
                      <div className="invited-email-div" key={index}>
                        <div className="invited-email-div-2">
                          {/* You can replace these with actual member data */}
                          {member.name && (
                            <div className="invited-email-div-3">
                              {member.name}
                            </div>
                          )}
                          <div className="invited-email-div-4">
                            {member.email}
                          </div>
                        </div>
                        <div className="invited-email-div-5">
                          <div
                            className={`invited-email-div-6 ${
                              member.isActive && "active-email-div"
                            }`}
                          >
                            {member.isAdmin
                              ? "Admin"
                              : member.isActive
                              ? "Active"
                              : "Pending"}
                          </div>
                          {memberData.isAdmin &&
                            member.email !== memberData.email && (
                              <img
                                loading="lazy"
                                src={Bin}
                                className="invited-email-img"
                                alt="Delete"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteFounderMember(member._id, member.email)
                                }
                              />
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("second-step", "first-step", stepOneData);
                // handleSave(stepTwoData, "second-step")
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="skip-button"
                onClick={() => handleSkip("second-step")}
              >
                Skip steps
                <IoMdFastforward />
              </button>
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("second-step", "third-step", stepTwoData);
                  handleSave(stepTwoData, "second-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails third-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"3"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Investment Focus</p>
            <div className="third-step-first-row">
              <div className="ticket-size-text-div">
                <FormControl required>
                  <FormLabel className="label">
                    Initial Check size (Cr)
                  </FormLabel>
                  <div className="ticket-size-div">
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        startDecorator="₹"
                        endDecorator="min"
                        className="input ticket-size-min"
                        placeholder="in crores"
                        color="neutral"
                        name="ticketSizeLowerLimit"
                        type="number"
                        required
                        value={
                          stepThreeData.ticketSizevalue
                            ? stepThreeData.ticketSizevalue[0]
                            : ""
                        }
                        onChange={(event) => handleTicketChange(event, 0)}
                      />
                    </FormControl>
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        startDecorator="₹"
                        endDecorator="max"
                        className="input ticket-size-max"
                        placeholder="in crores"
                        color="neutral"
                        name="ticketSizeUpperLimit"
                        type="number"
                        value={
                          stepThreeData.ticketSizevalue
                            ? stepThreeData.ticketSizevalue[1]
                            : ""
                        }
                        required
                        onChange={(event) => handleTicketChange(event, 1)}
                      />
                    </FormControl>
                  </div>
                </FormControl>
              </div>
              <div className="ticket-size-text-div">
                <FormControl required>
                  <FormLabel className="label">
                    Total Portfolio Exposure (Cr)
                  </FormLabel>
                  <div className="ticket-size-div">
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        startDecorator="₹"
                        endDecorator="min"
                        className="input ticket-size-min"
                        placeholder="in crores"
                        color="neutral"
                        name="totalExposureLowerLimit"
                        type="number"
                        required
                        value={
                          stepThreeData.totalExposure
                            ? stepThreeData.totalExposure[0]
                            : ""
                        }
                        onChange={(event) => handleExposureChange(event, 0)}
                      />
                    </FormControl>
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        startDecorator="₹"
                        endDecorator="max"
                        className="input ticket-size-max"
                        placeholder="in crores"
                        color="neutral"
                        name="ticketSizeUpperLimit"
                        type="number"
                        value={
                          stepThreeData.totalExposure
                            ? stepThreeData.totalExposure[1]
                            : ""
                        }
                        required
                        onChange={(event) => handleExposureChange(event, 1)}
                      />
                    </FormControl>
                  </div>
                </FormControl>
              </div>
            </div>
            <div
              className="third-step-second-row"
              style={{
                justifyContent: "normal",
              }}
            >
              <div className="ticket-size-text-div">
                <FormControl required>
                  <FormLabel className="label">Stake Acquired (%)</FormLabel>
                  <div className="ticket-size-div">
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        endDecorator="min"
                        className="input stake-size-min"
                        placeholder="In percentage"
                        color="neutral"
                        name="stakeAcquiredLowerLimit"
                        type="number"
                        value={
                          stepThreeData.stakeAcquired
                            ? stepThreeData.stakeAcquired[0]
                            : ""
                        }
                        required
                        maxLength="2"
                        onChange={(event) => handleStakeChangeInput(event, 0)}
                      />
                    </FormControl>
                    <FormControl
                      className="form-control investorDetails-row-input"
                      id="Id"
                      required
                      size="lg"
                      color="primary"
                    >
                      <Input
                        endDecorator="max"
                        className="input stake-size-max"
                        placeholder="In percentage"
                        color="neutral"
                        name="stakeAcquiredUpperLimit"
                        type="number"
                        value={
                          stepThreeData.stakeAcquired
                            ? stepThreeData.stakeAcquired[1]
                            : ""
                        }
                        required
                        min="0"
                        max="100"
                        onChange={(event) => handleStakeChangeInput(event, 1)}
                      />
                    </FormControl>
                  </div>
                </FormControl>
              </div>
              <FormControl
                className="form-control stage-of-investment-input"
                id="Id"
                required
                size="lg"
                color="primary"
                sx={{
                  marginTop: "5px",
                  width: {
                    md: "50% !important",
                  },
                }}
              >
                <FormLabel className="label">Asset Class</FormLabel>
                <Select
                  isMulti
                  className="react-select-input"
                  options={AssetOptions}
                  placeholder="Please Select"
                  required
                  styles={inputCustomStylesAssestClass}
                  value={
                    stepThreeData.assetClass
                      ? AssetOptions.filter((option) =>
                          stepThreeData.assetClass.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleAssetClassChange}
                  isClearable={true}
                  isSearchable={true}
                />
              </FormControl>
            </div>

            <Box
              className="third-step-third-row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <FormControl
                className="form-control stage-of-investment-input"
                id="Id"
                required
                size="lg"
                color="primary"
                sx={{
                  width: { md: "35% !important" },
                }}
              >
                <FormLabel className="label">Stage of Investment</FormLabel>
                <Select
                  isMulti
                  className="react-select-input"
                  options={stageOptions}
                  placeholder="Please Select"
                  required
                  styles={inputCustomStyles}
                  value={
                    stepThreeData.investmentStage
                      ? stageOptions.filter((option) =>
                          stepThreeData.investmentStage.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleStageChange}
                />
              </FormControl>
              <FormControl
                className="form-control stage-of-investment-input"
                id="Id"
                required
                size="lg"
                color="primary"
                sx={{
                  width: { md: "35% !important" },
                }}
              >
                <FormLabel className="label">Financial Criteria</FormLabel>
                <Select
                  isMulti
                  className="react-select-input"
                  options={CriteriaOptions}
                  value={
                    stepThreeData.financialCriteria
                      ? CriteriaOptions.filter((option) =>
                          stepThreeData.financialCriteria.includes(option.value)
                        )
                      : []
                  }
                  onChange={handleCriteriaChange}
                  placeholder="Please Select"
                  required
                  styles={inputCustomStyles}
                  isClearable={true}
                  isSearchable={true}
                />
              </FormControl>
              <Box
                className="coinvestment-div"
                sx={{
                  width: { md: "23% !important" },
                }}
              >
                <FormControl required color="primary">
                  <FormLabel className="label" required>
                    Co-Investment?
                  </FormLabel>
                  <div className="yes-or-no-button-div">
                    <button
                      className={`yes-or-no-button yes-button ${
                        stepThreeData.coInvestment ? "selected-button" : ""
                      }`}
                      onClick={() => handleCoInvestment(1)}
                    >
                      YES
                    </button>
                    <button
                      className={`yes-or-no-button ${
                        !stepThreeData.coInvestment ? "selected-button" : ""
                      }`}
                      onClick={() => handleCoInvestment(0)}
                    >
                      NO
                    </button>
                  </div>
                </FormControl>
              </Box>
            </Box>
            <div className="third-step-third-row">
              <FormControl
                className="investment-thesis-form-control"
                id="Id"
                size="lg"
                color="primary"
                sx={{
                  width: "100%",
                }}
              >
                <FormLabel className="label">Other Criteria</FormLabel>
                <TextField
                  className="key-investment-input2"
                  placeholder="Describe any other criteria"
                  name="otherCriteria"
                  type="text"
                  multiline
                  minRows={3}
                  value={stepThreeData.otherCriteria}
                  onChange={handleInputChangeStep3}
                />
              </FormControl>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("third-step", "second-step", stepTwoData);
                handleSave(stepThreeData, "third-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="skip-button"
                onClick={() => handleSkip("third-step")}
              >
                Skip steps
                <IoMdFastforward />
              </button>
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("third-step", "fourth-step", stepThreeData);
                  handleSave(stepThreeData, "third-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails fourth-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"4"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <p className="investorDetails-heading">Industry Focus</p>
            <p
              className="investorDetails-sub-heading"
              style={{
                marginTop: "10px",
              }}
            >
              Please select the industry verticals in which your company
              actively invests.
            </p>
            <div className="industry-focus-first-row">
              <Box
                className="form-control stage-of-investment-input"
                id="Id"
                required
                size="lg"
                color="primary"
                sx={{
                  width: "100% !important",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <Typography
                    className="label"
                    sx={{
                      display: "flex",
                    }}
                  >
                    Industry Verticals{" "}
                    <p
                      style={{
                        color: "#B22234",
                      }}
                    >
                      &nbsp;*
                    </p>{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setIndustryVerticalOptions(
                          industryFocusOptions.map((option) => option.label)
                        );
                        setStepFourData({
                          ...stepFourData,
                          industryVerticalOptions: industryFocusOptions.map(
                            (option) => option.label
                          ),
                        });
                      }}
                      sx={{
                        textTransform: "capitalize",
                        color: "#00a6fb",
                      }}
                    >
                      Select All
                    </Button>
                    <Button
                      onClick={() => {
                        setIndustryVerticalOptions([]);
                        setStepFourData({
                          ...stepFourData,
                          industryVerticalOptions: [],
                        });
                      }}
                      sx={{
                        textTransform: "capitalize",
                        color: "#00a6fb",
                      }}
                    >
                      <FaFilterCircleXmark />
                      Clear All
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <Grid container spacing={2} className="deals-filter-option">
                    {industryFocusOptions?.length > 0 &&
                      industryFocusOptions.map((option, index) => (
                        <Grid item xs="6" md="4" lg="2" key={index}>
                          <Checkbox
                            key={index}
                            label={option.label}
                            className="deal-checkbox"
                            checked={industryVerticalOptions?.includes(
                              option.label
                            )}
                            onChange={(e) => handleCheckboxChange(option.label)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </div>
            <div className="industry-focus-first-row">
              <Box
                className="form-control stage-of-investment-input industry-focus-input"
                id="Id"
                required
                size="lg"
                color="primary"
              >
                <Typography
                  className="label"
                  sx={{
                    display: "flex",
                    marginBottom: "5px",
                  }}
                >
                  End Market Focus{" "}
                  <p
                    style={{
                      color: "#B22234",
                    }}
                  >
                    &nbsp;*
                  </p>{" "}
                </Typography>
                <div className={`deals-filter-option `}>
                  <div className={`deals-checkboxes`}>
                    {endMarketFocusOptions?.length > 0 &&
                      endMarketFocusOptions.map((option, index) => (
                        <Checkbox
                          key={index}
                          label={option.label}
                          className="deal-checkbox"
                          checked={
                            stepFourData.endMarketFocus
                              ? stepFourData.endMarketFocus.includes(
                                  option.value
                                )
                              : false
                          }
                          onChange={(e) =>
                            handleCheckboxChangeEndMarket(option.label)
                          }
                        />
                      ))}
                  </div>
                </div>
              </Box>
              <FormControl
                className="form-control stage-of-investment-input industry-focus-input random-input"
                style={{ visibility: "hidden" }}
              >
                <FormLabel className="label">
                  Hidden input for styling
                </FormLabel>
                <Select className="react-select-input" />
              </FormControl>
            </div>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("fourth-step", "third-step", stepThreeData);
                handleSave(stepFourData, "fourth-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="skip-button"
                onClick={() => handleSkip("fourth-step")}
              >
                Skip steps
                <IoMdFastforward />
              </button>
              <button
                className="nextStep"
                onClick={() => {
                  nextSection("fourth-step", "fifth-step", stepFourData);
                  handleSave(stepFourData, "fourth-step");
                }}
              >
                Next Step&nbsp;&nbsp;{" "}
                <img src={Next} className="next" alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails fifth-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"5"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="investorDetails-first-section">
            <TnC />
            <p className="terms-condition-text">
              <Checkbox
                className="checkbox"
                {...label}
                sx={{
                  color: "#00a6fb",
                  "&.Mui-checked": {
                    color: "#00a6fb",
                  },
                }}
                checked={stepFiveData.acceptTC}
                onChange={(event) =>
                  setStepFiveData({
                    acceptTC: event.target.checked,
                  })
                }
              />
              <p className="AcceptTC-checkbox-text">
                By clicking this, I hereby acknowledge and confirm that I have
                read, understood, and fully accept the terms and conditions set
                forth in the Master Service Agreement and the Terms of Use
              </p>
            </p>
          </div>
          <div className="navigation-buttons">
            <button
              className="previousStep"
              onClick={() => {
                prevSection("fifth-step", "fourth-step", stepFiveData);
                handleSave(stepFiveData, "fifth-step");
              }}
            >
              <img src={previous} className="previous" alt="back-arrow" />
              &nbsp;&nbsp; Previous Step
            </button>
            <div className="next-buttons">
              <button
                className="nextStep"
                onClick={() => {
                  saveAndFinish("fifth-step", "sixth-step", stepFiveData);
                  handleSave(stepFiveData, "fifth-step");
                }}
              >
                Save & Finish
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="investorDetails sixth-step">
        <Step
          stepsCompleted={stepsCompleted}
          activeStep={"6"}
          isApplicationReviewed={stepSixData.isApplicationReviewed}
        />
        <div className="details-section">
          <div className="investorDetails-first-section sixth">
            {stepSixData.isApplicationReviewed ? (
              <div className="div-sixth">
                <img loading="lazy" src={Illustration} className="sixth-img" />
                <div className="text-3">Completed successfully</div>
                <div className="text-4">
                  Profile completed! Ready to shine and make meaningful
                  investments. Bring on the opportunities!
                </div>
                <button
                  className="exploreDeals"
                  onClick={navigateToInvestorDashboard}
                >
                  <img src={exploreDeals} />
                  &nbsp;&nbsp;Explore Deals
                </button>
              </div>
            ) : (
              <div className="eighth-step-div">
                <p className="eighth-step-heading">
                  Your application is under fundrev review
                </p>
                <p className="eighth-step-subheading">
                  Your Investor Profile is under review. Your application to
                  discover valuable investment opportunities has been launched
                  into the digital world. Our team of experts is now hard at
                  work, sifting through investment opportunities to find those
                  perfectly suited to your interests. Sit back and relax—we’ll
                  get back to you within{" "}
                  <span className="blue-text">24 hours</span>. In the meantime,
                  if you haven’t completed your profile yet, now is the perfect
                  time to do so.
                </p>
                <img
                  src={investorOnboardingProcessImg}
                  alt="Application review stage"
                ></img>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Investordetails;
