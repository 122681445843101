import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(({ className, position, ...props }) => (
  <Tooltip
    {...props}
    placement={position}
    classes={{ popper: className }}
    arrow
  />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme === "black" ? "#002132" : "#fff",
    color: theme === "black" ? "#fff" : "#002132",
    fontFamily: "Roboto",
    fontSize: "14px",
    padding: "10px",
    borderRadius: "4px",
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme === "black" ? "#002132" : "#fff",
  },
}));

export default CustomTooltip;
