import React, { useState, useEffect } from "react";
import Arrow from "../../Assets/Images/LandingPage/arrow-small-right.svg";
import frame9 from "../../Assets/Images/LandingPage/frame9.svg";
import { useNavigate } from "react-router-dom";
import frame9small from "../../Assets/Images/LandingPage/frame9small.svg";
const LandingPageMid1 = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth > 991 ? frame9 : frame9small;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className="LandingPageMid first-landingPageMid"
      style={{ marginBottom: "50px" }}
    >
      <div className="frame-73">
        <div className="frame-74">
          <div className="frame-75">
            <div className="frame-48">
              <div className="text-wrapper-7">FOR FOUNDERS</div>
            </div>
            <div className="frame-76">
              <h2 className="typography-23">Fundraise?</h2>
              <div className="LetsFundrev">
                <span className="Lets">Let's</span>{" "}
                <span className="Fundrev">&nbsp;Fundrev</span>
              </div>
            </div>
            <h3 className="text-wrapper-10">
              Traditional processes take 8-10 months, demand immense founder
              capacity, and often lead to no success. Enter fundrev - our
              digitally advanced platform with AI prowess accelerates deal
              closures, safeguards founder mind space, allowing you to
              concentrate on what truly matters, and finds your perfect partner
              at the right price at the right time!
            </h3>
          </div>
          <button className="button-3" onClick={() => navigate("/founder")}>
            <div className="content">
              <div className="text-wrapper-6">Learn More</div>
            </div>
            <img className="img-5" alt="Heroicons outline" src={Arrow} />
          </button>
        </div>
      </div>
      <div className="frame-37">
        <img
          style={{ height: "100%", width: "100%" }}
          className="img-frame-9"
          src={imageUrl}
          alt="frame9"
        ></img>
      </div>
    </section>
  );
};

export default LandingPageMid1;
