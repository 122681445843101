import React from "react";
import { FormLabel, Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { postStageData, referInvestor } from "../../../endpoints/deal";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";

import { StyledFormControl, StyledFormLabel } from "../functions";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import "../../../CSS/Signup/modal.css";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomInput from "../components/custom/customInput";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { ToastContainer } from "react-toastify";

const ReferInvestorModal = ({ openReferModal, setOpenReferModal, DealID }) => {
  const token = localStorage.getItem("token");
  const loader = useLoader();

  const handleReferInvestor = async (values) => {
    if (values.email !== "") {
      loader.start("Sending mail");
      const formData = {
        email: values.email,
        investorMessage:
          values.investorMessage === "" ? undefined : values.investorMessage,
        DealID: DealID,
      };
      const response = await referInvestor(formData, token);
      console.log(response);
      loader.stop();
      setOpenReferModal(false);
      if (response.response.status === 200) {
        Toast("Successfully referred the investor", "success", "investorRefer");
      } else {
        Toast(
          "Error sending mail to investor. Please try again later",
          "error",
          "investorRefer"
        );
      }
    } else {
      Toast("Please enter the email", "error", "referInvestor");
    }
  };

  return (
    <>
      <ToastContainer position="top-center" containerId="investorRefer" />
      <Modal
        open={openReferModal}
        onClose={() => setOpenReferModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box reason-box" style={{ width: "550px" }}>
          <div className="modal-content">
            <div className="modal-right-section">
              <div className="reason-box-first-div">
                <p className="modal-heading">
                  Enter email to refer the investor:
                </p>
                <img
                  className="reason-box-closeicon"
                  src={closeIcon}
                  onClick={() => setOpenReferModal(false)}
                  alt="close"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                  }}
                />
              </div>
              <div className="reasons-box">
                <Formik
                  initialValues={{ email: "", investorMessage: "" }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Email is required"),
                  })}
                  onSubmit={handleReferInvestor}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "36px",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="input-fields"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <StyledFormControl fullWidth>
                          <StyledFormLabel>Investor Email</StyledFormLabel>
                          <CustomInput
                            placeholder="Please enter the email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </StyledFormControl>
                        <StyledFormControl fullWidth>
                          <FormLabel
                            sx={{
                              color: "var(--Primary-Text-Color)",
                              fontFamily: "Karla",
                              fontWeight: 500,
                            }}
                          >
                            Message to investor
                          </FormLabel>
                          <CustomInput
                            placeholder="investorMessage"
                            name="investorMessage"
                            value={values.investorMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.investorMessage && errors.investorMessage
                            )}
                            helperText={
                              touched.investorMessage && errors.investorMessage
                            }
                          />
                        </StyledFormControl>
                      </div>

                      <div className="submit-btn-section">
                        <CustomBlackBtn type="submit" text="Refer" />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReferInvestorModal;
