import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../Company/Deal/Sidebar";
import InvestorSidebar from "./investorSidebar";
import InvestorOverview from "../../../Common/components/investorOverview";
import ProfileCard from "../../../Common/components/profileCard";

import { getInvestmentTeamDetails } from "../../../../endpoints/investor";
import { useLoader } from "../../../Common/LoaderProvider";

import { useLocation } from "react-router-dom";
import Topbar from "../../Company/Deal/topbar";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  Chip,
} from "@mui/material";
import {
  Mail as MailIcon,
  Phone as PhoneIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";

const InvestorPreview = () => {
  const location = useLocation();
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const { dealID, investor, startupID, state } = location.state;
  const [teamDetails, setTeamDetails] = useState([]);
  const getTeamDetails = async () => {
    try {
      if (!investor?.investorID) return;
      const response = await getInvestmentTeamDetails(
        investor?.investorID,
        token
      );
      console.log(response.data.data);
      if (response.data !== null) {
        setTeamDetails(response.data.data);
      }
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    getTeamDetails();
  }, [investor]);
  return (
    <>
      <div className="adminSide InvestorSide" style={{ overflowY: "hidden" }}>
        <ToastContainer position="top-center" />
        {state === "companies" ? (
          <Sidebar
            dealID={dealID}
            active={"investorPanel"}
            startupID={startupID}
          />
        ) : (
          <InvestorSidebar active="investorPreview" investor={investor} />
        )}

        <Topbar title="Investor Preview" />
        <div className="InvestorSide-box">
          <div className="InvestorSide-content">
            <div className="cdetails-div" style={{ paddingTop: "16px" }}>
              <InvestorOverview investor={investor} mode={"admin"} />
              <Box
                sx={{
                  padding: 2,
                  display: "grid",
                  gap: "16px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                {teamDetails &&
                  teamDetails.map((member, index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{
                        marginBottom: 2,
                        borderRadius: 2,
                        position: "relative",
                      }}
                    >
                      <CardContent>
                        <Box
                          display="flex"
                          alignItems="center"
                          marginBottom={2}
                        >
                          <Avatar
                            src={member.memberImg}
                            alt={`${member.name} Profile Picture`}
                            sx={{ width: 72, height: 72, marginRight: 2 }}
                          />
                          <Box>
                            <Typography variant="h6">{member.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              Investor Member ID: {member.investorMemberID}
                            </Typography>
                          </Box>
                          {member.isAdmin && (
                            <Chip
                              label="Admin"
                              color="primary"
                              size="small"
                              sx={{ position: "absolute", top: 8, right: 8 }}
                            />
                          )}
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="column"
                          marginBottom={2}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={1}
                          >
                            <MailIcon
                              fontSize="small"
                              sx={{ marginRight: 1 }}
                            />
                            <Link
                              href={`mailto:${member.email}`}
                              underline="hover"
                            >
                              {member.email}
                            </Link>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={1}
                          >
                            <PhoneIcon
                              fontSize="small"
                              sx={{ marginRight: 1 }}
                            />
                            <Link
                              href={`tel:${member.phoneNumber}`}
                              underline="hover"
                            >
                              {member.phoneNumber}
                            </Link>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={1}
                          >
                            <LinkedInIcon
                              fontSize="small"
                              sx={{ marginRight: 1 }}
                            />
                            <Link
                              href={member.linkedIn}
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="hover"
                            >
                              LinkedIn Profile
                            </Link>
                          </Box>

                          <Box marginBottom={1}>
                            <Typography>Bio: {member.biography}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorPreview;
