import React from "react";

const CalenderSetupSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">Here are few things to keep in mind</p>
      <p className="subheading">
        <div>
          <b>Block Unavailable Hours: </b>Update your connected calendar when
          you're busy to avoid conflicts.
        </div>
      </p>
      <p className="subheading">
        <div>
          <b>Avoid Peak Hours: </b>
          Choose times outside your busiest periods for focused conversations.
        </div>
      </p>
      <p className="subheading">
        <div>
          <b>Keep Calendar Synced: </b>
          Ensure your calendar is connected on the platform to reflect real-time
          availability.
        </div>
      </p>
    </div>
  );
};

export default CalenderSetupSuggested;
