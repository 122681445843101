import { CurrencyRupee } from "@mui/icons-material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CustomTooltip from "../../Common/components/custom/customToolTip";

import { FaRegCalendar, FaRegCalendarCheck } from "react-icons/fa";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";

import qna from "../../../Assets/Images/icons/qna.svg";
import deniedAccessDataroom from "../../../Assets/Images/icons/deniedAccessDataroom.svg";
import requestedAccessDataroom from "../../../Assets/Images/icons/requestAccessDataroom.svg";
import completedMeeting from "../../../Assets/Images/icons/completedMeeting.svg";
import scheduledMeeting from "../../../Assets/Images/icons/scheduledMeeting.svg";
import grantedAccessDataroom from "../../../Assets/Images/icons/grantedAccessDataroom.svg";
import notScheduledMeeting from "../../../Assets/Images/icons/notScheduledMeeting.svg";
dayjs.extend(timezone);

const ActiveInvestorTable = ({ data }) => {
  const getType = (investor) => {
    let result;
    if (investor.allLOI_Agreements.length > 0) {
      result = investor.allLOI_Agreements[0].investmentType;
    } else if (investor.allIOI_Bids.length > 0) {
      result = investor.allIOI_Bids[0].investmentType;
    }

    switch (result) {
      case "Co-Invest":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#FEF8C2",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#AA6E2D",
              fontSize: "12px",
              padding: "8px",
            }}
          >
            Co-Invest
          </Box>
        );
      case "Lead Round":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content",
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "12px",
              padding: "8px",
            }}
          >
            Lead
          </Box>
        );
      case "Sole Investor":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content",
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "12px",
              padding: "8px",
            }}
          >
            Solo
          </Box>
        );
      default:
        return "-";
    }
  };

  const getBidAmount = (investor) => {
    let result;
    if (investor.allLOI_Agreements.length > 0) {
      result = investor.allLOI_Agreements[0].investmentAmount;
    } else if (investor.allIOI_Bids.length > 0) {
      result = investor.allIOI_Bids[0].investmentAmount;
    }
    return result;
  };

  const getStage = (stage) => {
    switch (stage) {
      case "requestSent":
      case "requestAccepted": // Handles both "requestSent" and "requestAccepted"
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Screening
          </Box>
        );
      case "ioiSubmitted":
      case "ioiAccepted":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#F0F7F9",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Term Sheet
          </Box>
        );
      case "loiSubmitted":
      case "loiAccepted":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Due Diligence
          </Box>
        );
      case "spaNegotiation":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            SPA
          </Box>
        );
      case "dealClosed":
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Close
          </Box>
        );
      default:
        return String(stage).toUpperCase();
    }
  };

  const getIntent = (intent) => {
    switch (intent) {
      case 100:
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#00A6FB20",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#00A6FB",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Highest
          </Box>
        );
      case intent > 75:
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#E9FEEA",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#4FB24A",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            High
          </Box>
        );
      case intent > 50:
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#FEF8C2",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#AA6E2D",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Medium
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              borderRadius: "6px",
              backgroundColor: "#FDEED7",
              height: "fit-content",
              width: "fit-content", // Adjust width to fit "Screening" text with padding
              fontFamily: "Karla, sans-serif",
              fontWeight: 500,
              color: "#CD6035",
              fontSize: "12px",
              display: "flex", // Center content
              alignItems: "center", // Vertical center
              justifyContent: "center", // Horizontal center
              padding: "8px", // Horizontal padding
            }}
          >
            Low
          </Box>
        );
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          height: "100%",
          padding: "16px",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <div className="text-base text-[#696F79]">Active Investors</div>
        </Box> */}
        <Paper
          sx={{
            boxShadow: 0,
          }}
        >
          <TableContainer
            sx={{
              borderRadius: "4px",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      height: "40px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Investor Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Stage
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Intent
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Bid Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Dataroom Access
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    Meeting
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: "300",
                      fontSize: "14px",
                    }}
                  >
                    QnA
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.activeInvestorTable?.length > 0 &&
                  data?.activeInvestorTable?.map((investor) => (
                    <TableRow>
                      <TableCell
                        sx={{
                          maxWidth: "200px",
                          fontFamily: "Karla, sans-serif",
                          height: "50px",
                          fontSize: "14px",
                        }}
                      >
                        {investor?.firmName || `-`}
                      </TableCell>
                      <TableCell>{getType(investor)}</TableCell>
                      <TableCell>{getStage(investor?.Stage)}</TableCell>
                      <TableCell>
                        {getIntent(investor?.investorIntentPercentile)}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Karla, sans-serif",
                          fontSize: "14px",
                        }}
                      >
                        {getBidAmount(investor) ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CurrencyRupee fontSize="12px" />{" "}
                            {getBidAmount(investor) + `Cr`}{" "}
                          </Box>
                        ) : (
                          `-`
                        )}
                      </TableCell>
                      <TableCell>
                        {investor?.dataRoomStatus === "notAsked" && (
                          <div>No Action</div>
                        )}
                        {investor?.dataRoomStatus === "pending" && (
                          <CustomTooltip
                            title="Requested Dataroom Access"
                            position="bottom"
                            theme="black"
                          >
                            <div>
                              <img
                                src={requestedAccessDataroom}
                                alt="requested access"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                        {investor?.dataRoomStatus === "denied" && (
                          <CustomTooltip
                            title="Dataroom Access Denied"
                            position="bottom"
                            theme="black"
                          >
                            <div>
                              <img
                                src={deniedAccessDataroom}
                                alt="denied access"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                        {investor?.dataRoomStatus === "granted" && (
                          <CustomTooltip
                            title="Dataroom Access Granted"
                            position="bottom"
                            theme="black"
                          >
                            <div>
                              <img
                                src={grantedAccessDataroom}
                                alt="dataroom access denied"
                                className="w-[24px]"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        {investor?.meetingStatus === "scheduled" && (
                          <CustomTooltip
                            title={`Meeting Scheduled on ${dayjs(
                              investor?.meetingDetails?.length > 0 &&
                                investor?.meetingDetails?.slice(-1)[0].timings
                                  .start
                            )
                              .tz(`Asia/Kolkata`)
                              .format("D MMM hh:mm a")}`}
                            position="bottom"
                            theme="black"
                          >
                            <div>
                              <img
                                src={scheduledMeeting}
                                alt="Scheduled meeting"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                        {investor?.meetingStatus === "done" && (
                          <CustomTooltip
                            title="Meeting Completed"
                            position="bottom"
                            theme="black"
                          >
                            <div>
                              <img
                                src={completedMeeting}
                                alt="Completed meeting"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                        {investor?.meetingStatus === "not scheduled" && (
                          <Box>Not Scheduled</Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {investor?.countUnansweredQnAs === 0 && (
                          <CustomTooltip
                            title={`No Questions Pending`}
                            position="bottom"
                            theme="black"
                          >
                            <div className="relative">
                              <img
                                src={qna}
                                alt="qna"
                                className="w-[24px] mt-[3px]"
                              />
                            </div>
                          </CustomTooltip>
                        )}
                        {investor?.countUnansweredQnAs > 0 && (
                          <CustomTooltip
                            title={`${investor?.countUnansweredQnAs} Questions Pending`}
                            position="bottom-start"
                            theme="black"
                          >
                            <div className="relative">
                              <img
                                src={qna}
                                alt="qna"
                                className="w-[24px] mt-[3px]"
                              />
                              <p className="absolute top-[-5px] right-[26px] bg-red-500 text-[#fff] w-[14px] h-[14px] text-[12px] rounded-[50%] flex justify-center items-center">
                                {investor?.countUnansweredQnAs}
                              </p>
                            </div>
                          </CustomTooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default ActiveInvestorTable;
