import React from "react";

const DealInterestSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">
        Your existing investors can complete due diligence for free!
      </p>
      <p className="subheading">
        For ongoing rounds, mention the amount covered by term sheets and the
        lead/co-investor names.
      </p>
      <p className="subheading">
        If you're in early talks with investors, list their names to avoid
        duplicate outreach.{" "}
        <span
          style={{
            textDecoration: "underline",
            color: "var(--Primary-Text-Color)",
          }}
        >
          <strong>
            These investors will be excluded from our outreach but can be
            invited by you later.
          </strong>
        </span>
      </p>
      <p className="subheading">
        You will not be charged any success fee on the names you write here!
      </p>
    </div>
  );
};

export default DealInterestSuggested;
