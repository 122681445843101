import React, { useEffect, useState } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Overview from "./overview";
import DataRoom from "./dataroom";
import Activity from "./activity";
import Analytics from "./analytics/analytics";
import PassDealModal from "../../../Common/modals/passDealModal";
import IOIBidModal from "../../../Common/modals/IOIBidModal";
import LOIBidModal from "../../../Common/modals/LOIBidModal";
import { investorState } from "../investorState";
import { getAllDealInteractions } from "../../../../endpoints/deal";
import AskQuestionModal from "../../../Common/modals/askQuestionModal";
import CompanyDetailsSubheader from "../Components/companyDetailsSubheader";

import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ToastContainer } from "react-toastify";
import { useLoader } from "../../../Common/LoaderProvider";
import AnalyticsHomble from "./analyticsHomble/AnalyticsHomble";
import { Tooltip } from "@mui/material";
import CustomRedBtn from "../../../Common/components/custom/customRedBtn";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";
import ScheduleMeeting from "./scheduleMeeting";

const CompanyDetailsPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const { deal, passedStage } = location.state || {};
  const [title, setTitle] = useState(
    deal && deal?.CompanyName ? deal?.CompanyName : "Company Overview"
  );
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const token = localStorage.getItem("token");
  const [stage, setStage] = useState(passedStage);
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [askQuestionModal, setAskQuestionModal] = useState(false);
  const [IOIBidModalOpen, setIOIBidModalOpen] = useState(false);
  const [LOIBidModalOpen, setLOIBidModalOpen] = useState(false);
  const [analytics, setAnalytics] = useState("summary");
  const { name, investorId, activeBtn } = useRecoilValue(investorState);
  const [scheduleMeetingModal, setScheduleMeetingModal] = useState(false);

  const redirectToIOIBid = () => {
    Navigate("/investor/TermSheet", {
      state: {
        stage: "ioiSubmitted",
        dealId: deal?.DealID,
        companyName: deal?.CompanyName,
        fundingRequired: deal?.FundingRequired,
        minPostMoneyValuation: deal?.MinPostMoneyValuation,
        deal: deal,
      },
    });
  };
  const redirectToLOIBid = () => {
    Navigate("/investor/dueDiligence", {
      state: {
        stage: "loiSubmitted",
        dealId: deal?.DealID,
        companyName: deal?.CompanyName,
        fundingRequired: deal?.FundingRequired,
        minPostMoneyValuation: deal?.MinPostMoneyValuation,
        deal: deal,
      },
    });
  };
  const redirectToSPANegotiation = () => {
    Navigate("/investor/spaNegotiation", {
      state: {
        stage: "spaNegotiation",
        dealId: deal?.DealID,
        companyName: deal?.CompanyName,
        deal: deal,
      },
    });
  };
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        deal?.DealID,
        investorId,
        token
      );
      loader.stop();
      setStage(response.data[response.data.length - 1].Stage);
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    if (deal && investorId) fetchAllInteractions();
  }, [deal, investorId]);
  const btnTitleMap = {
    1: "Overview",
    2: "Analytics",
    3: "Dataroom",
    4: "Activity",
  };
  useEffect(() => {
    setTitle(`${deal?.CompanyName} - ${btnTitleMap[activeBtn]}`);
  }, [activeBtn]);
  return (
    <div
      className="InvestorSide companyOverview-page"
      style={{ overflowX: "hidden" }}
    >
      <ScheduleMeeting
        deal={deal}
        modal={scheduleMeetingModal}
        setModal={setScheduleMeetingModal}
        stage={stage}
      />
      <ToastContainer position="top-center" containerId="companyDetails" />

      <Sidebar active="ongoingDeals" />
      <Topbar title={title} />
      <PassDealModal
        setOpenReasonModal={setOpenReasonModal}
        openReasonModal={openReasonModal}
        stage={stage}
        DealID={deal ? deal?.DealID : ""}
        investorID={investorId}
      />
      <AskQuestionModal
        askQuestionModal={askQuestionModal}
        setAskQuestionModal={setAskQuestionModal}
        token={token}
        dealID={deal ? deal?.DealID : ""}
        investorID={investorId}
        roundType={stage}
        setNewQuestionAsked={setNewQuestionAsked}
      />
      <IOIBidModal
        IOIBidModalOpen={IOIBidModalOpen}
        setIOIBidModalOpen={setIOIBidModalOpen}
        deal={deal}
        investorID={investorId}
        sentBy={name}
        setStage={setStage}
        page="companyDetailsPage"
      />
      <LOIBidModal
        LOIBidModalOpen={LOIBidModalOpen}
        setLOIBidModalOpen={setLOIBidModalOpen}
        deal={deal}
        investorID={investorId}
        sentBy={name}
        setStage={setStage}
        page="companyDetailsPage"
      />
      <div className="InvestorSide-box" style={{ overflowX: "hidden" }}>
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            <CompanyDetailsSubheader
              activeButton={1}
              stage={stage}
              setAskQuestionModal={setAskQuestionModal}
              deal={deal}
            />
            {activeBtn === 1 && <Overview deal={deal} />}
            {activeBtn === 2 &&
              (deal?.DealID !== "D787569" ? (
                <Analytics
                  deal={deal}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                  stage={stage}
                />
              ) : (
                <AnalyticsHomble
                  deal={deal}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                />
              ))}
            {activeBtn === 3 && (
              <DataRoom stage={stage} deal={deal} role="investor" />
            )}
            {activeBtn === 4 && (
              <Activity
                deal={deal}
                newQuestionAsked={newQuestionAsked}
                setNewQuestionAsked={setNewQuestionAsked}
              />
            )}
          </div>
          {/* watchlist investor can't take any actions */}
          {stage !== "watchlist" && stage !== "dealClosed" && (
            <div className="showInterestButtons-div">
              <div className="btns-insideContent">
                {stage !== "dealClosed" && (
                  <div onClick={() => setOpenReasonModal(true)}>
                    <CustomRedBtn text="Pass Deal" type="button" />
                  </div>
                )}

                <div className="showInterestButtons-right">
                  {stage === "requestAccepted" && (
                    <div onClick={() => setIOIBidModalOpen(true)}>
                      <CustomBlackBtn type="button" text="Submit Term Sheet" />
                    </div>
                  )}
                  {stage === "ioiSubmitted" && (
                    <div onClick={redirectToIOIBid}>
                      <CustomBlackBtn type="button" text="View Term Sheet" />
                    </div>
                  )}
                  {stage === "ioiAccepted" && (
                    <div onClick={() => setLOIBidModalOpen(true)}>
                      <CustomBlackBtn
                        type="button"
                        text="Submit Due Diligence"
                      />
                    </div>
                  )}
                  {stage === "loiSubmitted" && (
                    <div onClick={redirectToLOIBid}>
                      <CustomBlackBtn type="button" text="View Due Diligence" />
                    </div>
                  )}
                  {stage === "spaNegotiation" && (
                    <div onClick={redirectToSPANegotiation}>
                      <CustomBlackBtn
                        type="button"
                        text="View SPA Negotiation"
                      />
                    </div>
                  )}
                  <div className="schedule-meeting-btn">
                    <Tooltip
                      title={
                        "Click here to schedule a meeting with the startup."
                      }
                    >
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            color: "inherit",
                          }}
                          onClick={() => setScheduleMeetingModal(true)}
                        >
                          <p>Schedule Meeting</p>
                        </div>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
