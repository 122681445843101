import { useState, useEffect } from "react";
import { cn } from "../../../lib/utils";

import { Link } from "react-router-dom";

import MidCircle from "../../../Assets/Images/LandingPage/elevateExperience/midCircle.svg";
import GreenDot from "../../../Assets/Images/LandingPage/elevateExperience/greenDot.svg";
import BlueDot from "../../../Assets/Images/LandingPage/elevateExperience/blueDot.svg";
import feature1 from "../../../Assets/Images/LandingPage/elevateExperience/feature1.svg";
import feature2 from "../../../Assets/Images/LandingPage/elevateExperience/feature2.svg";
import feature3 from "../../../Assets/Images/LandingPage/elevateExperience/feature3.svg";
import feature4 from "../../../Assets/Images/LandingPage/elevateExperience/feature4.svg";
import feature5 from "../../../Assets/Images/LandingPage/elevateExperience/feature5.svg";
import feature6 from "../../../Assets/Images/LandingPage/elevateExperience/feature6.svg";
import feature7 from "../../../Assets/Images/LandingPage/elevateExperience/feature7.svg";
import arrowRight from "../../../Assets/Images/LandingPage/arrow-small-right.svg";
import arrowRightWhite from "../../../Assets/Images/LandingPage/arrow-small-right-white.svg";

import AnimatedShinyText from "../../Common/animations/animatedButton";
import { Box } from "@mui/material";

export function OrbitingCircles({
  className,
  children,
  reverse,
  duration = 20,
  delay = 10,
  radius = 50,
  path = true,
  circleColor = "rgb(255 255 255 / 0.1)",
}) {
  return (
    <>
      {path && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className="pointer-events-none absolute inset-0 size-full"
        >
          <circle
            className="stroke-1 dark:stroke-white/10"
            cx="50%"
            cy="50%"
            r={radius}
            fill="none"
            stroke={circleColor} // dynamically set the stroke color
          />
        </svg>
      )}

      <div
        style={{
          "--duration": duration,
          "--radius": radius,
          "--delay": -delay,
        }}
        className={cn(
          "absolute flex size-full transform-gpu animate-orbit items-center justify-center [animation-delay:calc(var(--delay)*1000ms)] ",
          { "[animation-direction:reverse]": reverse },
          className
        )}
      >
        {children}
      </div>
    </>
  );
}

export function OrbitingCirclesDemo(props) {
  const headingtext = props.headingtext
    ? props.headingtext
    : "Elevate your experience with Fundrev";
  const text = props.text
    ? props.text
    : "Participate in the cutting-edge private capital investment opportunities available on Fundrev";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const frameWidth = windowWidth > 1700 ? 300 : 70;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="relative flex h-[900px] w-full flex-col items-center justify-center overflow-hidden bg-[#002132] md:shadow-xl">
      <div className="elevate-experience-text-div laptop-stats">
        <p className="elevate-experience-text">{headingtext}</p>
      </div>
      <Box className="laptop-stats elevate-experience-lower-div">
        <p className="elevate-experience-small-text" style={{ width: "75%" }}>
          {text}
        </p>
        <AnimatedShinyText type="elevateExperience" />
      </Box>
      <section className="ElevateExperience-section mobile-stats">
        <div className="elevate-experience-text-div">
          <p className="elevate-experience-text">{headingtext}</p>
          <p className="elevate-experience-small-text">{text}</p>
          <button className="button">
            <div className="content">
              <div className="text-wrapper">
                <Link to="/signup" className="elevate-experience-link">
                  Get Started
                </Link>
              </div>
            </div>
            <img
              className="heroicons-outline laptop-arrow"
              alt="Heroicons outline"
              src={arrowRight}
            />
            <img
              className="heroicons-outline mobile-arrow"
              alt="Heroicons outline"
              src={arrowRightWhite}
            />
          </button>
        </div>
      </section>

      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300 bg-clip-text text-center text-8xl font-semibold leading-none text-transparent dark:from-white dark:to-black position: absolute">
        <img src={MidCircle} />
      </span>

      {/* Inner Circles */}
      <OrbitingCircles
        className="size-[0px] border-none"
        duration={20}
        delay={70}
        radius={100}
      ></OrbitingCircles>
      <OrbitingCircles
        className="size-[20px] border-none"
        duration={20}
        delay={80}
        radius={160}
      >
        <img src={GreenDot} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[40px] border-none"
        duration={30}
        delay={40}
        radius={220}
      >
        <img src={feature5} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[20px] border-none"
        duration={30}
        delay={150}
        radius={220}
      >
        <img src={BlueDot} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[60px] border-none"
        duration={60}
        delay={0}
        radius={280}
      >
        <img src={feature1} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[20px] border-none"
        duration={60}
        delay={140}
        radius={280}
      >
        <img src={BlueDot} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[20px] border-none"
        duration={60}
        delay={280}
        radius={280}
      >
        <img src={GreenDot} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[60px] border-none"
        duration={80}
        delay={70}
        radius={340}
      >
        <img src={feature2} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[50px] border-none"
        duration={80}
        delay={100}
        radius={340}
      >
        <img src={feature7} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[20px] border-none"
        duration={80}
        delay={200}
        radius={340}
      >
        <img src={BlueDot} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[80px] border-none"
        duration={100}
        delay={103}
        radius={400}
      >
        <img src={feature3} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[80px] border-none"
        duration={100}
        delay={228}
        radius={400}
      >
        <img src={feature4} />
      </OrbitingCircles>
      <OrbitingCircles
        className="size-[60px] border-none"
        duration={100}
        delay={374}
        radius={400}
      >
        <img src={feature6} />
      </OrbitingCircles>
    </div>
  );
}

export default OrbitingCirclesDemo;
