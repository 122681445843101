import React, { useState, useEffect } from "react";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Account from "./account";
import Profile from "./profile";
import Members from "./members";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "../../../../CSS/InvestorPage/Dashboard/account.css";
import EditInvestorProfileSubHeader from "./editInvestorProfileSubHeader";
const AccountPage = () => {
  const location = useLocation();
  const { activeButton } = location.state || { activeButton: 1 };
  const [activeBtn, setActiveBtn] = useState(activeButton);

  return (
    <div className="InvestorSide">
      <ToastContainer position="top-center" containerId="investorAccount" />
      <Sidebar active="profile" />
      <Topbar title="Settings" />
      <div className="InvestorSide-box">
        <div className="InvestorSide-content">
          <div className="cdetails-div">
            <EditInvestorProfileSubHeader
              activeButton={activeBtn}
              setActiveButton={setActiveBtn}
            />
            {activeBtn === 1 && <Account />}
            {activeBtn === 2 && <Profile />}
            {activeBtn === 3 && <Members />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
