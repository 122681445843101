import React from "react";
import { useFormikContext, Form } from "formik";
import {
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  equityTypeOptions,
  pricedEquityOptions,
  nonPricedEquityOptions,
} from "../../../../Common/functions";
import CustomToolTip from "../../../../Common/components/custom/customToolTip";
import CustomSelect from "../../../../Common/components/custom/customSelect";
import CustomInput from "../../../../Common/components/custom/customInput";

import { CiCircleQuestion } from "react-icons/ci";
const DealInterest = () => {
  const { values, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  return (
    <div className="DealInterest fundrev-card onboardingForm-card">
      <p className="heading">What are your valuation expectations?</p>
      <p className="subheading">
        Enter details relating to the amount of funding required
      </p>
      <Form>
        <div className="companyOnboarding-form-section">
          {/* Show "Equity Round" only if dealType is "Equity" */}
          {(values.dealType === "Equity" ||
            values.dealType === "Equity + Debt") && (
            <StyledFormControl fullWidth>
              <StyledFormLabel>Select Equity Round</StyledFormLabel>
              <CustomSelect
                label="Select equity round"
                name="equityType"
                value={values.equityType}
                onChange={handleChange}
                onBlur={handleBlur}
                options={equityTypeOptions}
                placeholder="Select Equity Round"
                error={Boolean(touched.equityType && errors.equityType)}
                helperText={touched.equityType && errors.equityType}
              />
            </StyledFormControl>
          )}

          {/* Show "Priced Equity Options" if dealType is "Equity" and equityType is "Priced" */}
          {(values.dealType === "Equity" ||
            values.dealType === "Equity + Debt") &&
            values.equityType === "Priced" && (
              <>
                <StyledFormControl fullWidth>
                  <StyledFormLabel>Instrument Type</StyledFormLabel>
                  <CustomSelect
                    label="Select equity Type"
                    name="pricedEquity"
                    value={values.pricedEquity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={pricedEquityOptions}
                    placeholder="Select Instrument Type"
                    error={Boolean(touched.pricedEquity && errors.pricedEquity)}
                    helperText={touched.pricedEquity && errors.pricedEquity}
                  />
                </StyledFormControl>

                {/* Stake Offered and Min Valuation fields for Priced Equity */}
                {(values.pricedEquity === "Equity" ||
                  values.pricedEquity === "Preferred Stock") && (
                  <>
                    <div className="relative">
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Stake Offered%</StyledFormLabel>
                        <CustomInput
                          placeholder="Enter Stake offered"
                          name="stakeOffered"
                          type="number"
                          value={values.stakeOffered}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.stakeOffered && errors.stakeOffered
                          )}
                          helperText={
                            touched.stakeOffered && errors.stakeOffered
                          }
                        />
                      </StyledFormControl>
                      <div
                        style={{
                          position: "absolute",
                          color: "var(--Secondary-Text-Color)",
                          fontSize: "14px",
                        }}
                      >
                        {values.fundingRequired && values.stakeOffered && (
                          <p style={{ marginTop: "10px" }}>
                            Post Money Valuation : ₹
                            {(
                              (values.fundingRequired / values.stakeOffered) *
                              100
                            ).toFixed(2)}
                            Cr
                          </p>
                        )}
                      </div>
                    </div>
                    <StyledFormControl fullWidth>
                      <div className="flex gap-[8px] items-center">
                        <StyledFormLabel>
                          Min Valuation Expectation (Rs Cr.)
                        </StyledFormLabel>
                        <CustomToolTip
                          title="This is the lowest valuation that the company will expect as a bid"
                          position="right"
                          theme="black"
                        >
                          <div>
                            <CiCircleQuestion />
                          </div>
                        </CustomToolTip>
                      </div>
                      <CustomInput
                        placeholder="Enter minimum valuation"
                        name="minPostMoneyValuation"
                        type="number"
                        value={values.minPostMoneyValuation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.minPostMoneyValuation &&
                            errors.minPostMoneyValuation
                        )}
                        helperText={
                          touched.minPostMoneyValuation &&
                          errors.minPostMoneyValuation
                        }
                      />
                    </StyledFormControl>
                  </>
                )}
              </>
            )}

          {/* Show "Non-Priced Equity Options" if equityType is "Non-Priced" */}
          {values.equityType === "Non-Priced" && (
            <>
              <StyledFormControl fullWidth>
                <StyledFormLabel>Select Instrument Type</StyledFormLabel>
                <CustomSelect
                  label="Select Instrument Type"
                  name="nonPricedEquity"
                  value={values.nonPricedEquity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={nonPricedEquityOptions}
                  placeholder="Select Instrument Type"
                  error={Boolean(
                    touched.nonPricedEquity && errors.nonPricedEquity
                  )}
                  helperText={touched.nonPricedEquity && errors.nonPricedEquity}
                />
              </StyledFormControl>

              {/* Interest Rate field for Convertible Debt */}
              {values.nonPricedEquity === "Convertible Debt" && (
                <StyledFormControl fullWidth>
                  <StyledFormLabelOptional>
                    Interest Rate (%)
                  </StyledFormLabelOptional>
                  <CustomInput
                    placeholder="Enter interest rate"
                    name="interestRate"
                    type="number"
                    value={values.interestRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.interestRate && errors.interestRate)}
                    helperText={touched.interestRate && errors.interestRate}
                  />
                </StyledFormControl>
              )}

              {/* Valuation Cap and Discount Rate fields for Convertible Debt and iSAFE */}
              {(values.nonPricedEquity === "Convertible Debt" ||
                values.nonPricedEquity === "iSAFE") && (
                <>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Valuation Cap (Rs Cr.)
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter valuation cap"
                      name="valuationCap"
                      type="number"
                      value={values.valuationCap}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.valuationCap && errors.valuationCap
                      )}
                      helperText={touched.valuationCap && errors.valuationCap}
                    />
                  </StyledFormControl>
                  <StyledFormControl fullWidth>
                    <StyledFormLabelOptional>
                      Discount Rate (%)
                    </StyledFormLabelOptional>
                    <CustomInput
                      placeholder="Enter discount rate"
                      name="discountRate"
                      type="number"
                      value={values.discountRate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.discountRate && errors.discountRate
                      )}
                      helperText={touched.discountRate && errors.discountRate}
                    />
                  </StyledFormControl>
                </>
              )}
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default DealInterest;
