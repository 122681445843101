import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidebar from "../Components/sidebar";
import Topbar from "../Components/topbar";
import Timeline from "../Components/Timeline";
import TimelineComponent from "../../../Common/components/timeline";
import Overview from "./overview";
import Activity from "./activity";
import { companyState } from "../companyState";
import PassDealModal from "../../../Common/modals/passDealModal";
import { postStageData } from "../../../../endpoints/deal";
import UploadDocumentModal from "../../../Common/modals/uploadDocumentModal";
import Toast from "../../../Common/Toast";
import { getAllDealInteractions } from "../../../../endpoints/deal";

import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { useLoader } from "../../../Common/LoaderProvider";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

const InvestorDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { activeBtn } = useRecoilValue(companyState);
  const loader = useLoader();
  const location = useLocation();
  const Navigate = useNavigate();
  const { investor, passedStage, dealId, sentBy, investorID } =
    location.state || {};
  const [title, setTitle] = useState(
    investor && investor?.companyName
      ? investor?.companyName
      : "Company Overview"
  );
  const companyName = investor ? investor?.companyName : "";
  const [stage, setStage] = useState(passedStage ? passedStage : "");
  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [SPAModalOpen, setSPAModalOpen] = useState(false);
  const [spaFile, setspaFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({});
  const spaFileChange = (file) => {
    setspaFile(file);
  };
  const handleCancelSPA = () => {
    setSPAModalOpen(false);
    setFileInfo({});
    setspaFile();
  };
  const handleSPASubmit = async () => {
    try {
      if (!spaFile) {
        Toast("Please upload a file", "error", "investorDetails");
        return;
      }
      const formData = new FormData();
      formData.append("DealID", dealId);
      formData.append("Stage", "spaNegotiation");
      formData.append("IsInterested", true);
      formData.append("investorID", investor.investorID);
      formData.append("SPA", spaFile[0]);
      formData.append("sentBy", investor.sentBy);
      loader.start("Uploading SPA...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setSPAModalOpen(false);
        setFileInfo({});
        setspaFile();
        Navigate("/company/spaNegotiation", {
          state: {
            stage: "spaNegotiation",
            dealId: dealId,
            investorID: investor.investorID,
            companyName: investor.companyName,
            sentBy: sentBy,
          },
        });
      }
    } catch (error) {
      loader.stop();
      Toast(
        "Failed to upload SPA due to some internal errors",
        "error",
        "investorDetails"
      );
    }
  };
  const handleAccept = async () => {
    let stageAccept;
    if (stage === "requestSent") {
      stageAccept = "requestAccepted";
    } else if (stage === "ioiSubmitted") {
      stageAccept = "ioiAccepted";
    } else if (stage === "loiSubmitted") {
      stageAccept = "loiAccepted";
    }
    const updatedFormData = {
      DealID: dealId,
      investorID: investorID,
      IsInterested: true,
      Stage: stageAccept,
      sentBy: sentBy,
    };
    try {
      loader.start();
      const response = await postStageData(updatedFormData, token);
      loader.stop();
      if (response.data !== null) {
        setStage(stageAccept);
      }
    } catch (error) {
      loader.stop();
    }
  };

  const redirectToSPA = () => {
    Navigate("/company/spaNegotiation", {
      state: {
        stage: "spaNegotiation",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
        sentBy: sentBy,
      },
    });
  };

  const redirectToIOIBid = () => {
    Navigate("/company/termSheet", {
      state: {
        stage: "ioiSubmitted",
        dealId: dealId,
        investorID: investor?.investorID,
        companyName: investor?.companyName,
      },
    });
  };
  const redirectToLOIAgreement = () => {
    Navigate("/company/dueDiligence", {
      state: {
        stage: "loiSubmitted",
        dealId: dealId,
        investorID: investor.investorID,
        companyName: investor.companyName,
      },
    });
  };
  // const getTeamDetails = async () => {
  //   if (!investor) return;
  //   try {
  //     loader.start("Fetching details...");
  //     const response = await getInvestmentTeamDetails(
  //       investor.investorID,
  //       token
  //     );
  //     if (response.data !== null) {
  //       setTeamDetails(response.data.data);
  //     } else {
  //       loader.stop();
  //     }
  //   } catch (error) {
  //     loader.stop();
  //   }
  // };
  const fetchAllInteractions = async () => {
    try {
      const response = await getAllDealInteractions(
        dealId,
        investor.investorID,
        token
      );
      setStage(response.data[response.data.length - 1].Stage);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
  };
  useEffect(() => {
    fetchAllInteractions();
  }, [dealId, investor]);
  // useEffect(() => {
  //   getTeamDetails();
  // }, [investor]);
  useEffect(() => {
    if (!investor || !passedStage || !dealId) {
      Navigate("/company/fundraising");
    }
  }, [investor, passedStage, dealId]);
  const btnTitleMap = {
    1: "Overview",
    2: "Activity",
  };
  useEffect(() => {
    setTitle(`${investor?.companyName} - ${btnTitleMap[activeBtn]}`);
  }, [activeBtn]);
  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div className="InvestorSide">
          <ToastContainer position="top-center" containerId="investorDetails" />
          <Sidebar active="ongoingDeals" />
          <Topbar title={title} isBack={true} />
          <Helmet>
            <title>{title}</title>
          </Helmet>

          <PassDealModal
            openReasonModal={openReasonModal}
            setOpenReasonModal={setOpenReasonModal}
            stage={stage}
            isCompany={true}
            DealID={dealId}
            investorID={investorID}
          />
          <UploadDocumentModal
            DocumentUploadModalOpen={SPAModalOpen}
            setDocumentUploadModalOpen={setSPAModalOpen}
            DocumentUploadFile={spaFile}
            DocumentUploadFileChange={spaFileChange}
            handleCancelDocumentUpload={handleCancelSPA}
            handleDocumentUploadSubmit={handleSPASubmit}
          />
          <div
            className="InvestorSide-box"
            style={{
              overflowX: "hidden",
              display: "flex",
              flexDirection: "row-reverse",
              overflowY: "scroll",
            }}
          >
            <div className="InvestorSide-content" style={{ flex: 5 }}>
              <div className="cdetails-div">
                {stage !== "requestSent" && stage !== "requestAccepted" && (
                  <Header activeButton={1} stage={stage} />
                )}
                <div className="flex gap-3 mt-4">
                  <TimelineComponent
                    passedStage={stage}
                    dealId={dealId}
                    investorID={investor?.investorID}
                    companyName={investor?.companyName}
                    sentBy={sentBy}
                    role="company"
                  />
                  {activeBtn === 1 && <Overview investor={investor} />}
                  {activeBtn === 2 && (
                    <Activity
                      dealId={dealId}
                      investorID={investor?.investorID}
                    />
                  )}
                </div>
              </div>

              {stage === "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    <div className="showInterestButtons-right">
                      <CustomBlackBtn
                        mode="danger"
                        onClick={() => setOpenReasonModal(true)}
                        text="Decline Offer"
                        filled={false}
                      />
                    </div>
                    <div className="showInterestButtons-right">
                      <CustomBlackBtn
                        onClick={handleAccept}
                        text="Accept Request"
                      />
                    </div>
                  </div>
                </div>
              )}

              {stage !== "requestSent" && (
                <div className="showInterestButtons-div">
                  <div className="btns-insideContent">
                    {stage !== "dealClosed" && stage !== "requestSent" && (
                      <CustomBlackBtn
                        mode="danger"
                        onClick={() => setOpenReasonModal(true)}
                        text="Pass Deal"
                        filled={false}
                      />
                    )}
                    <div className="showInterestButtons-right">
                      {stage === "requestAccepted" ||
                        stage === "ioiSubmitted" ||
                        stage === "ioiAccepted" ||
                        stage === "loiSubmitted" ||
                        stage === "spaNegotiation" ||
                        stage === "loiAccepted"}

                      {stage === "ioiSubmitted" && (
                        <CustomBlackBtn
                          onClick={redirectToIOIBid}
                          text="View Term Sheet"
                        />
                      )}
                      {stage === "ioiAccepted" && (
                        <CustomBlackBtn
                          onClick={redirectToIOIBid}
                          text="View Term Sheet"
                        />
                      )}
                      {stage === "loiSubmitted" && (
                        <CustomBlackBtn
                          onClick={redirectToLOIAgreement}
                          text="View Due Diligence"
                        />
                      )}
                      {stage === "loiAccepted" && (
                        <CustomBlackBtn
                          onClick={() => setSPAModalOpen(true)}
                          text="Attach SPA"
                        />
                      )}
                      {stage === "spaNegotiation" && (
                        <CustomBlackBtn
                          onClick={redirectToSPA}
                          text="View SPA Negotiation"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorDetails;
