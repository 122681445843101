import React from "react";
import { motion } from "framer-motion";

import { cn } from "../../../lib/utils";

const animationProps = {
  initial: { "--x": "100%", scale: 0.8 },
  animate: { "--x": "-100%", scale: 1 },
  whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 1,
    type: "spring",
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: "spring",
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
};

const ShinyButton = ({ children, className, front, ...props }) => {
  return (
    <motion.button
      {...animationProps}
      {...props}
      className={cn(
        "relative rounded-lg px-6 py-2 font-medium backdrop-blur-xl transition-shadow duration-300 ease-in-out hover:shadow",
        className
      )}
      style={{
        backgroundColor: front ? "white" : "#002132", // White background when 'front' is true
        border: front
          ? "1px solid rgba(0, 0, 0, 0.1)"
          : "1px solid rgba(255, 255, 255, 0.3)", // Adjust border for contrast
        position: "relative",
        zIndex: 1, // Ensure it is above the parent
      }}
    >
      <span
        className="relative block size-full text-sm uppercase tracking-wide"
        style={{
          color: front ? "black" : "white", // Black text when 'front' is true
          maskImage: front
            ? "linear-gradient(-75deg, black calc(var(--x) + 20%), transparent calc(var(--x) + 30%), black calc(var(--x) + 100%))" // Animation for 'front'
            : "linear-gradient(-75deg, white calc(var(--x) + 20%), transparent calc(var(--x) + 30%), white calc(var(--x) + 100%))", // Animation for dark background
        }}
      >
        {children}
      </span>
      <span
        style={{
          mask: "linear-gradient(rgb(0,0,0), rgb(0,0,0)) content-box, linear-gradient(rgb(0,0,0), rgb(0,0,0))",
          maskComposite: "exclude",
        }}
        className={`absolute inset-0 z-0 block rounded-[inherit] ${
          front
            ? "bg-[linear-gradient(-75deg, black calc(var(--x) + 20%), transparent calc(var(--x) + 30%), black calc(var(--x) + 100%))]" // Animation with black for front
            : "bg-[linear-gradient(-75deg, white calc(var(--x) + 20%), transparent calc(var(--x) + 30%), white calc(var(--x) + 100%))]" // Animation with white for dark background
        } p-px`}
      ></span>
    </motion.button>
  );
};

export { ShinyButton };
