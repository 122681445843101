import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SmallAreaPlot from "./SmallAreaPlot";
import { TrendingUp, TrendingDown } from "@mui/icons-material";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";
import AnalyticsPageTitle from "./AnalyticsPageTitle";
const SmallCardGrid = ({ data, title }) => {
  function calculateRelative(x, y) {
    return ((x - y) / Math.abs(y)) * 100 || 0;
  }
  return (
    <>
      <AnalyticsPageTitle title={title} />
      <Grid container marginBottom={`24px`} spacing={`8px`} columns={20}>
        {data?.map((item, index) => {
          const comparedPercent = !item.percentage
            ? calculateRelative(item?.value, item?.compareValue)
            : null;
          const flag = item.type === "text";
          return (
            <Grid item xs="4" md="4" lg="4" key={index}>
              <Box
                sx={{
                  width: "100%",
                  height: "176px",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: !flag && "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Karla, sans-serif",
                        color: "var(--Secondary-Text-Color)",
                        fontSize: "14px",
                        fontWeight: 300,
                      }}
                    >
                      {item?.name}
                    </Typography>
                    {!flag && (
                      <Typography
                        sx={{
                          fontFamily: "Karla, sans-serif",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "var(--Primary-Text-Color)",
                        }}
                      >
                        {formatToLakhs(
                          item?.value,
                          item.format,
                          item.currency,
                          item.currency && item.format,
                          item.percentage
                        )}
                      </Typography>
                    )}
                  </Box>
                  {flag && (
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        marginTop: "24px",
                        borderRadius: "4px",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          fontFamily: "Karla, sans-serif",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "var(--Primary-Text-Color)",
                        }}
                      >
                        {formatToLakhs(
                          item?.value,
                          item.format,
                          item.currency,
                          item.currency && item.format,
                          item.percentage
                        )}
                      </Box>
                      <Box
                        sx={{
                          textAlign: "center",
                          fontFamily: "Karla, sans-serif",
                          color: "var(--Secondary-Text-Color)",
                          fontSize: "14px",
                          fontWeight: 300,
                        }}
                      >
                        {item?.compareValue}
                      </Box>
                    </Box>
                  )}

                  {!flag && (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {comparedPercent ? (
                          comparedPercent > 0 ? (
                            <TrendingUp
                              sx={{
                                color: "#05C168",
                                fontSize: "20px",
                              }}
                            />
                          ) : (
                            <TrendingDown
                              sx={{
                                color: "#D32F2F",
                                fontSize: "20px",
                              }}
                            />
                          )
                        ) : (
                          <p></p>
                        )}
                        <Typography
                          sx={{
                            font: "Karla, sans-serif",
                            fontWeight: 300,
                            color: comparedPercent > 0 ? "#05C168" : "#D32F2F",
                            fontSize: "14px",
                          }}
                        >
                          {comparedPercent
                            ? formatToLakhs(
                                comparedPercent,
                                false,
                                false,
                                false,
                                true
                              )
                            : `-`}
                          &nbsp;
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          font: "Karla, sans-serif",
                          fontWeight: 300,
                          color: `var(--Secondary-Text-Color)`,
                          fontSize: "14px",
                        }}
                      >
                        vs{" "}
                        {["-", "-%", "NaN%", "0.0%"].includes(
                          formatToLakhs(
                            item?.compareValue,
                            item.format,
                            item.currency,
                            item.currency && item.format,
                            item.percentage
                          )
                        )
                          ? `NA`
                          : formatToLakhs(
                              item?.compareValue,
                              item.format,
                              item.currency,
                              item.currency && item.format,
                              item.percentage
                            )}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {!flag && (
                  <SmallAreaPlot
                    data={item?.plotData}
                    compareData={item?.compareData}
                  />
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SmallCardGrid;
