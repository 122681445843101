import React, { useState } from "react";
import { StyledFormLabel } from "../../../../Common/functions";

import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Typography from "@mui/joy/Typography";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import gmail from "../../../../../Assets/Images/companiesIcons/gmail.svg";
import outlook from "../../../../../Assets/Images/companiesIcons/outlook.svg";
import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { googleAuth, microsoftAuth } from "../../../../../endpoints/startup";
import dayjs from "dayjs";
const CalenderSetup = ({ startupID }) => {
  const token = localStorage.getItem(`token`);
  const { values, setFieldValue, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  const handleGoogleClick = async () => {
    if (startupID) {
      await googleAuth(token);
    }
  };
  const handleMicrosoftClick = async () => {
    if (startupID) {
      await microsoftAuth(token);
    }
  };

  const handleDayChange = (day) => {
    const updatedDays = (values.availableDays || []).includes(day) // Ensure availableDays is an array
      ? values.availableDays.filter((d) => d !== day) // Remove if already selected
      : [...(values.availableDays || []), day]; // Add if not selected

    setFieldValue("availableDays", updatedDays); // Update Formik field
  };

  return (
    <div className="CalenderSetup fundrev-card onboardingForm-card">
      <p className="heading">Connect Your Calender</p>
      <p className="subheading">
        Connect your calender to help investors determine on which time you are
        available for meeting
      </p>
      <div className="flex flex-row gap-[30px] w-[100%] justify-center pt-[32px] pb-[48px] items-center">
        <div
          className="p-[16px] flex flex-col justify-center items-center gap-[8px] shadow-md w-[160px] cursor-pointer rounded-[4px]"
          onClick={handleGoogleClick}
        >
          <img
            src={gmail}
            alt="connect gmail"
            style={{
              height: "32px",
              width: "32px",
            }}
          />
          <p className="text-[--Secondary-Text-Color]">Gmail</p>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Karla, sans-serif",
              fontSize: "12px",
            }}
          >
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: values.googleAuthToken ? "#00A6FB" : "#D32F2F",
                marginRight: "4px",
              }}
            />
            {values.googleAuthToken ? "Connected" : "Disconnected"}
          </Box>
        </div>
        <p className="text-sm text-[var(--Primary-Text-Color)]">Or</p>
        <div
          className="p-[16px] flex flex-col justify-center items-center gap-[8px] shadow-md w-[160px] cursor-pointer rounded-[4px]"
          onClick={handleMicrosoftClick}
        >
          <img src={outlook} alt="connect gmail" />
          <p className="text-[--Secondary-Text-Color]">Outlook</p>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Karla, sans-serif",
              fontSize: "12px",
            }}
          >
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "50%",
                backgroundColor: values.microsoftAuthToken
                  ? "#00A6FB"
                  : "#D32F2F",
                marginRight: "4px",
              }}
            />
            {values.microsoftAuthToken ? "Connected" : "Disconnected"}
          </Box>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "40px",
          alignItems: "center",
        }}
      >
        <Box>
          <StyledFormLabel>Select Days</StyledFormLabel>
          <div
            role="group"
            aria-labelledby="days"
            style={{
              marginTop: "8px",
            }}
          >
            <List
              orientation="horizontal"
              wrap
              sx={{
                "--List-gap": "8px",
                "--ListItem-radius": "4px",
              }}
            >
              {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => {
                const isSelected =
                  values &&
                  values.availableDays &&
                  values.availableDays.includes(day + index);

                return (
                  <ListItem
                    key={day + index}
                    sx={{
                      backgroundColor: isSelected ? "red" : "transparent",
                      fontWeight: isSelected ? "bold" : "normal",
                      borderRadius: "4px",
                      width: "42px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      overlay
                      disableIcon
                      label={day}
                      checked={isSelected}
                      onChange={() => handleDayChange(day + index)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Box>
        <Box>
          <div>
            <StyledFormLabel>Select Time</StyledFormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  value={values.availableTimeStart}
                  onChange={(newValue) =>
                    setFieldValue("availableTimeStart", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      className="calender-time-picker"
                      {...params}
                      error={
                        touched.availableTimeStart &&
                        Boolean(errors.availableTimeStart)
                      }
                      helperText={
                        touched.availableTimeStart && errors.availableTimeStart
                      }
                    />
                  )}
                  ampm={false}
                />
                <TimePicker
                  value={values.availableTimeEnd}
                  onChange={(newValue) =>
                    setFieldValue("availableTimeEnd", newValue)
                  }
                  minTime={
                    values.availableTimeStart
                      ? dayjs(values.availableTimeStart).add(2, "hours")
                      : dayjs().set("hour", 0).set("minute", 0)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        touched.availableTimeEnd &&
                        Boolean(errors.availableTimeEnd)
                      }
                      helperText={
                        touched.availableTimeEnd && errors.availableTimeEnd
                      }
                    />
                  )}
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default CalenderSetup;
