import React, { useState, useEffect } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Icon from "react-icons-kit";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";

// Custom Password Input Component
const CustomPasswordInput = ({
  placeholder,
  name,
  value,
  onChange,
  error,
  helperText,
  sendStatesToParent,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Toggle the password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Prevent the mouse event when the user clicks the visibility icon
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Password validation handler
  const handlePasswordChange = (event) => {
    const value = event.target.value;

    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");

    setLowerValidated(lower.test(value));
    setUpperValidated(upper.test(value));
    setNumberValidated(number.test(value));
    setSpecialValidated(special.test(value));
    setLengthValidated(length.test(value));
    onChange(event);
  };

  useEffect(() => {
    const sendStates = () => {
      sendStatesToParent({
        lowerValidated,
        upperValidated,
        numberValidated,
        specialValidated,
        lengthValidated,
      });
    };
  }, [
    lowerValidated,
    upperValidated,
    numberValidated,
    specialValidated,
    lengthValidated,
  ]);
  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        name={name}
        type={showPassword ? "text" : "password"} // Toggle between text and password
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handlePasswordChange}
        error={error}
        helperText={helperText}
        {...rest}
        InputProps={{
          // Add the eye icon inside the input field
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "6px",
            borderWidth: "0.5px",
            "& fieldset": {
              borderColor: "#8692A6", // Custom border color
            },
            "&:hover fieldset": {
              borderColor: "#3C82F6", // Color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#3C82F6", // Color when focused
            },
            "& input": {
              padding: "12px 16px", // Custom padding for the input
              fontFamily: "'Karla', sans-serif",
            },
            "& input::placeholder": {
              fontFamily: "'Karla', sans-serif",
              fontSize: "14px",
              color: "#696F79",
              opacity: 1,
              fontWeight: 300,
            },
          },
        }}
      />

      {/* Password validation tracker */}
      {isFocused && name === "password" && (
        <main className="tracker-box">
          <p
            className="tracker-box-heading"
            style={{ fontSize: "14px", fontFamily: "Karla" }}
          >
            Your password must contain:
          </p>
          <div className={lowerValidated ? "validated" : "not-validated"}>
            {lowerValidated ? (
              <span className="list-icon green">
                <Icon icon={arrows_circle_check} />
              </span>
            ) : (
              <span className="list-icon">
                <Icon icon={arrows_exclamation} />
              </span>
            )}
            At least one lowercase letter
          </div>
          <div className={upperValidated ? "validated" : "not-validated"}>
            {upperValidated ? (
              <span className="list-icon green">
                <Icon icon={arrows_circle_check} />
              </span>
            ) : (
              <span className="list-icon">
                <Icon icon={arrows_exclamation} />
              </span>
            )}
            At least one uppercase letter
          </div>
          <div className={numberValidated ? "validated" : "not-validated"}>
            {numberValidated ? (
              <span className="list-icon green">
                <Icon icon={arrows_circle_check} />
              </span>
            ) : (
              <span className="list-icon">
                <Icon icon={arrows_exclamation} />
              </span>
            )}
            At least one number
          </div>
          <div className={specialValidated ? "validated" : "not-validated"}>
            {specialValidated ? (
              <span className="list-icon green">
                <Icon icon={arrows_circle_check} />
              </span>
            ) : (
              <span className="list-icon">
                <Icon icon={arrows_exclamation} />
              </span>
            )}
            At least one special character
          </div>
          <div className={lengthValidated ? "validated" : "not-validated"}>
            {lengthValidated ? (
              <span className="list-icon green">
                <Icon icon={arrows_circle_check} />
              </span>
            ) : (
              <span className="list-icon">
                <Icon icon={arrows_exclamation} />
              </span>
            )}
            At least 8 characters
          </div>
        </main>
      )}
    </div>
  );
};

export default CustomPasswordInput;
