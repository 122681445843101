import React from "react";

import InvestorOverview from "../../../Common/components/investorOverview";
const Overview = ({ investor, teamDetails }) => {
  return (
    <>
      <InvestorOverview investor={investor} teamDetails={teamDetails} />
    </>
  );
};

export default Overview;
