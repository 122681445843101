import React, { useEffect, useState } from "react";
import {
  dbHealthCheck,
  devVMHealthCheck,
  prodVMHealthCheck,
} from "../../endpoints/admin";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Chip,
} from "@mui/material";

const SystemStatus = () => {
  const [prodStatus, setProdStatus] = useState(null);
  const [devStatus, setDevStatus] = useState(null);
  const [dbStatus, setDbStatus] = useState(null);

  const prodVMHealth = async () => {
    try {
      const response = await prodVMHealthCheck();
      setProdStatus(response.data); // Assuming response has a 'status' property
    } catch (error) {
      setProdStatus("Error fetching production VM status");
    }
  };

  const dbHealth = async () => {
    try {
      const response = await dbHealthCheck();
      setDbStatus(response.data); // Placeholder status
    } catch (error) {
      setDbStatus("Error fetching database status");
    }
  };

  const devVMHealth = async () => {
    try {
      const response = await devVMHealthCheck();
      setDevStatus(response.data); // Assuming response has a 'status' property
    } catch (error) {
      setDevStatus("Error fetching development VM status");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([prodVMHealth(), devVMHealth(), dbHealth()]);
    };
    fetchData();
  }, []);

  // Conditional dot color based on the status
  const getDotColor = (status) => {
    return status === "OK" || status === "Operational" ? "#00A6FB" : "#D32F2F";
  };

  // Check if everything is operational
  const isEverythingOperational =
    prodStatus === "OK" && devStatus === "OK" && dbStatus?.status !== "Error";

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        System Status{" "}
        {isEverythingOperational && (
          <>
            <Chip
              label=""
              sx={{
                width: 10,
                height: 10,
                backgroundColor: "#00A6FB",
                borderRadius: "50%",
                display: "inline-block",
                marginLeft: 1,
              }}
            />
            <span
              style={{
                fontSize: "16px",
              }}
            >
              All systems normal
            </span>
          </>
        )}
      </Typography>
      <List>
        {/* Production VM Status */}
        <ListItem>
          <ListItemText
            primary="Production VM"
            secondary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: getDotColor(prodStatus),
                    marginRight: 1,
                  }}
                />
                {prodStatus === "OK" ? "Operational" : "Not Operational"}
              </Box>
            }
          />
        </ListItem>

        {/* Development VM Status */}
        <ListItem>
          <ListItemText
            primary="Development VM"
            secondary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: getDotColor(devStatus),
                    marginRight: 1,
                  }}
                />
                {devStatus === "OK" ? "Operational" : "Not Operational"}
              </Box>
            }
          />
        </ListItem>
        {/* Database Status */}
        <ListItem>
          <ListItemText
            primary="Database"
            secondary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor:
                      dbStatus?.status !== "Error" ? "#00A6FB" : "#D32F2F",
                    marginRight: 1,
                  }}
                />
                {dbStatus?.message}
              </Box>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default SystemStatus;
