import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

const DashboardGrid3 = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Investors with Dataroom Access
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Watchlisted Investors
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                background: "white",
                borderRadius: "4px",
                height: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Past Deals
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardGrid3;
