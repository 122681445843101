import React, { useEffect, useState } from "react";
import Sidebar2 from "../Components/sidebar2";
import Topbar from "../Components/topbar";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import { companyState } from "../companyState";
import { getDealDetailsByDealId } from "../../../../endpoints/deal";
import Analytics from "../../../Investor/Dashboard/companyDetails/analytics/analytics";
import CompanySidebarHomble from "../../../Investor/Dashboard/companyDetails/analyticsHomble/CompanySidebarHomble";
import AnalyticsHomble from "../../../Investor/Dashboard/companyDetails/analyticsHomble/AnalyticsHomble";

const CompanyAnalytics = () => {
  const { dealId } = useRecoilValue(companyState);
  const [deal, setDeal] = useState(null);
  const token = localStorage.getItem("token");
  const [analytics, setAnalytics] = useState("summary");

  useEffect(() => {
    const fetchDeal = async () => {
      const response = await getDealDetailsByDealId(dealId, token);
      setDeal(response.data);
    };
    fetchDeal();
  }, [dealId, token]);
  useEffect(() => {}, [deal]);
  return (
    <>
      <div className="InvestorSide">
        {dealId === "D787569" ? (
          <CompanySidebarHomble
            active={`myProfile`}
            analytics={analytics}
            setAnalytics={setAnalytics}
          />
        ) : (
          <Sidebar2
            active="myProfile"
            analytics={analytics}
            setAnalytics={setAnalytics}
          />
        )}
        <Topbar title="Analytics" />
        <Helmet>
          <title>{`${deal?.CompanyName} - Analytics`}</title>
        </Helmet>
        <div className="InvestorSide-box">
          {deal &&
            (dealId === "D787569" ? (
              <AnalyticsHomble
                deal={deal}
                analytics={analytics}
                setAnalytics={setAnalytics}
              />
            ) : (
              <Analytics
                deal={deal}
                analytics={analytics}
                setAnalytics={setAnalytics}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default CompanyAnalytics;
