import React, { useEffect, useState } from "react";

import { calculateProfileCompletion } from "../onBoarding/stepCompletion";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import "../../../../CSS/FounderPage/Dashboard/dashboardState.css";
import { ShinyButton } from "../../../Common/animations/shinyButton";

//We are showing details that are filled by company here as deal might not be created yet by FA.
const CompleteDealDetails = ({ dealID, details }) => {
  const Navigate = useNavigate();
  const [profileRemainingPercentage, setProfileRemainingPercentage] =
    useState(0);
  const [initialValues, setInitialValues] = useState({
    //deal details first stage
    dealType: "",
    timeline: null,
    fundingRequired: "",
    debtFundingRequired: "", // Only visible when the user has chosen equity + debt option
    roundType: [],
    debtType: "",
    term: "",
    preferredInvestors: [],

    //Expected Valuation second stage
    equityType: "",
    pricedEquity: "",
    stakeOffered: "",
    minPostMoneyValuation: "",
    nonPricedEquity: "",
    interestRate: "",
    valuationCap: "",
    discountRate: "",

    //Deal Interest third stage
    softCommitments: false,
    amountRaised: 0,
    debtAmountRaised: "", // Only visible when the user has chosen equity + debt option
    // leadInvestor: "",
    coInvestors: "",
    contactedInvestorNames: "",

    //Purpose of fundraising fourth step
    purpose: "",

    //Pitch Creation fifth stage
    pitchDeck: [],
    videoPitch: [],

    //Meeting Calender setup
    availableDays: ["M1", "T2", "W3", "T4", "F5"],
    availableTimeStart: dayjs().set("hour", 10).set("minute", 0),
    availableTimeEnd: dayjs().set("hour", 18).set("minute", 0),
    googleAuthToken: false,
    microsoftAuthToken: false,

    //Analytics preparation seventh stage
    financialDocuments: [],
    invoiceDetails: [],
    bankStatements: [],

    //Dataroom preparation eighth stage
    business: [],
    incorporation: [],
    teamDetails: [],
    legalDocuments: [],
    complianceDetails: [],
    productTechnology: [],

    //Terms and conditions ninth step
    acceptTC: false,

    pitchDeckFiles: "",
    videoPitchFiles: "",
    financialDocumentsFiles: "",
    invoiceDetailsFiles: "",
    bankStatementsFiles: "",

    corporateDocumentsFiles: "",
    teamDetailsFiles: "",
    productTechnologyFiles: "",
    marketClientInfoFiles: "",
    legalComplianceDetailsFiles: "",
    financialPlanningFiles: "",

    deletedFileNames: "",
    isApplicationReviewed: "",
    profileCompleted: "",
  });
  function returnRoundType(roundType) {
    try {
      const arr = JSON.parse(roundType);
      return arr[0];
    } catch {
      return "Debt";
    }
  }
  useEffect(() => {
    setProfileRemainingPercentage(
      100 - calculateProfileCompletion(initialValues)
    );
  }, [initialValues]);
  useEffect(() => {
    setInitialValues({
      // Deal details first stage
      dealType: details.dealType || "",
      timeline: details.timeline || null,
      fundingRequired: details.fundingRequired || "",
      debtFundingRequired: details.debtFundingRequired || "", // Visible only for equity + debt option
      roundType: (() => {
        try {
          return JSON.parse(details?.roundType);
        } catch (error) {
          return [];
        }
      })(),
      debtType: details.debtType || "",
      term: details.term || "",
      preferredInvestors: (() => {
        try {
          return JSON.parse(details?.preferredInvestors);
        } catch (error) {
          return [];
        }
      })(),

      // Expected Valuation second stage
      equityType: details.equityType || "",
      pricedEquity: details.pricedEquity || "",
      stakeOffered: details.stakeOffered || "",
      minPostMoneyValuation: details.minPostMoneyValuation || "",
      nonPricedEquity: details.nonPricedEquity || "",
      interestRate: details.interestRate || "",
      valuationCap: details.valuationCap || "",
      discountRate: details.discountRate || "",

      // Deal Interest third stage
      softCommitments: details.softCommitments || false,
      amountRaised: details.amountRaised || 0,
      debtAmountRaised: details.debtAmountRaised || "", // Visible only for equity + debt option
      // leadInvestor: details.leadInvestor || "",
      coInvestors: details.coInvestors || "",
      contactedInvestorNames: details.contactedInvestorNames || "",

      // Purpose of fundraising fourth step
      purpose: details.purpose || "",

      // Pitch Creation - Fifth Stage
      pitchDeck: details.pitchDeck || [],
      videoPitch: details.videoPitch || [],

      //Meeting Calender setup
      availableDays: (() => {
        try {
          return JSON.parse(details?.availableDays);
        } catch (error) {
          return ["M1", "T2", "W3", "T4", "F5"];
        }
      })(),
      availableTimeStart: details.availableTimeStart
        ? dayjs(details.availableTimeStart)
        : dayjs().set("hour", 10).set("minute", 0),
      availableTimeEnd: details.availableTimeEnd
        ? dayjs(details.availableTimeEnd)
        : dayjs().set("hour", 18).set("minute", 0),
      googleAuthToken: details.googleAuthToken || false,
      microsoftAuthToken: details.microsoftAuthToken || false,

      // Analytics Preparation - Sixth Stage
      financialDocuments: details.financialDocuments || [],
      invoiceDetails: details.invoiceDetails || [],
      bankStatements: details.bankStatements || [],

      // Data Room Preparation - Seventh Stage
      corporateDocuments: details.corporateDocuments || [],
      teamDetails: details.teamDetails || [],
      productTechnology: details.productTechnology || [],
      marketClientInfo: details.marketClientInfo || [],
      legalComplianceDetails: details.legalComplianceDetails || [],
      financialPlanning: details.financialPlanning || [],

      //Terms and conditions ninth step
      acceptTC: details.acceptTC || false,

      //Submit for review
      onBoardingComplete: details.onBoardingComplete || false,
      isApplicationReviewed: details.isApplicationReviewed || false,
    });
  }, [details]);

  return (
    <div className="dashBoard-state-card">
      <div className="inner-content-div">
        <div className="middle-div">
          <p className="roundType-text">{returnRoundType(details.roundType)}</p>
          <p className="roundSize-text">
            <span>Round Size:</span> ₹{details.fundingRequired}Cr
          </p>
          {details.timeline && (
            <p className="roundSize-text">
              <span>End Date:</span>{" "}
              {moment(details.timeline).format("D MMM YYYY")}
            </p>
          )}
        </div>
        <div className="button-div">
          <ShinyButton
            className={`rounded-[4px] px-6 py-2`}
            onClick={() => Navigate("/company/onboarding")}
          >
            Complete Deal Details
          </ShinyButton>
          <p>{`${profileRemainingPercentage}% remaining`}</p>
        </div>
      </div>
    </div>
  );
};

export default CompleteDealDetails;
