import * as React from "react";
import linkedin from "../../../../../Assets/Images/companiesIcons/linkedin.svg";

function LinkedinComponent({ name, designation, message, profilePhoto, link }) {
  return (
    <div className="flex gap-2 items-start p-2 bg-[#FCFBFC] rounded">
      <div className="flex flex-col">
        <div className="flex gap-2.5 justify-between items-start w-full">
          <div className="flex gap-2 items-center">
            <img
              loading="lazy"
              srcSet={profilePhoto}
              alt={`${name}'s profile`}
              className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square rounded-[80px]"
            />
            <div className="flex flex-col self-stretch my-auto min-h-[45px]">
              <div className="text-base font-semibold text-zinc-900">
                {name}
              </div>
              <div className="text-sm font-light text-gray-500">
                {designation}
              </div>
            </div>
          </div>
          <a href={link} target="_blank">
            <img
              loading="lazy"
              src={linkedin}
              alt="linkedin"
              className="object-contain shrink-0 w-4 aspect-[0.67]"
            />
          </a>
        </div>
        <div className="mt-2 text-xs leading-5 text-zinc-900">
          {message}{" "}
          <a href={link} target="_blank">
            <span className="text-[--link-color]">...read more</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default LinkedinComponent;
