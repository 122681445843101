import React from "react";
import { FaFileAlt } from "react-icons/fa";
import { ImBin2 } from "react-icons/im";

const File = ({ filename, onDelete }) => {
  const limit = 30;
  return (
    <div className="flex gap-10 justify-between items-center px-2 py-1 text-sm font-light text-center text-gray-500 whitespace-nowrap rounded border-[0.2px] bg-[#FCFBFC] border-[#8692A6]">
      <div className="flex flex-col self-stretch my-auto">
        <div className="flex gap-2 items-center">
          <FaFileAlt className="object-contain shrink-0 self-stretch my-auto w-6 h-5 aspect-square" />
          <div className="self-stretch my-auto">
            {filename.length > limit
              ? filename.slice(0, limit) + "..."
              : filename}
          </div>
        </div>
      </div>
      <ImBin2
        className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square cursor-pointer"
        onClick={(e) => {
          e.stopPropagation(); // Prevent the click from reaching the upload button
          onDelete(filename);
        }}
      />
    </div>
  );
};

export default File;
