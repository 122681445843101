import React, { useEffect, useState } from "react";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import { Typography, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";

import { sendJoiningRequestInvestor } from "../../../endpoints/investor";
import { sendAdminRequestMail } from "../../../endpoints/fundrevAnalyst";
import { useLoader } from "../LoaderProvider";
import Toast from "../Toast";
import { ToastContainer } from "react-toastify";
import { sendJoiningRequestStartup } from "../../../endpoints/startup";

const JoinRequestComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useLoader();
  const { companyName, name, email, token, values, type } =
    location.state || {};
  const [domain, setDomain] = useState("");

  useEffect(() => {
    setDomain(email.split("@")[1]);
  }, [email]);

  const sendRequestToFirm = async () => {
    try {
      loader.start("Sending request to admin");
      let response;
      if (type === "investor") {
        response = await sendJoiningRequestInvestor(values, token);
      } else {
        response = await sendJoiningRequestStartup(values, token);
      }
      loader.stop();
      if (response.data) {
        Toast(
          "Successfully sent the request to admin. Please wait till approval. Redirecting to home page",
          "success",
          "joinRequest"
        );
      }
      if (response.error) {
        Toast(
          "Error is sending the request to admin. Please try again later. Redirecting to home page",
          "error",
          "joinRequest"
        );
      }
      setTimeout(() => {
        navigate("/");
      }, [3000]);
    } catch (error) {
      loader.stop();
      Toast(
        "Error is sending the request to admin. Please try again later",
        "success",
        "joinRequest"
      );
    }
  };
  const requestAdminRights = async () => {
    const formData = {
      email: values.email,
      senderName: values.name,
      companyName: companyName,
    };
    const response = await sendAdminRequestMail(formData);
    if (response.data) {
      Toast(
        "Successfully sent the mail to Fundrev Analyst. You will get an email it we approve the request. Redirecting to home page",
        "success",
        "joinRequest"
      );
      setTimeout(() => {
        navigate("/");
      }, [3000]);
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "#f6f8ff",
        width: "100vw",
        minHeight: "100vh",
      }}
    >
      <ToastContainer position="top-center" containerId="joinRequest" />
      <div className="sign-in-page-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <Box
        className="joinRequestPage"
        sx={{
          minHeight: "calc(100vh - 200px)",
          display: "flex",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
          sx={{
            width: "500px",
            maxWidth: "90%",
            margin: "auto",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "#fff",
            padding: "24px",
            borderRadius: "24px",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ textAlign: "center", color: "var(--Primary-Text-Color)" }}
          >
            Looks like your team is already on Fundrev
          </Typography>

          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ textAlign: "center", color: "var(--Primary-Text-Color)" }}
          >
            {`${name} (${email}) is part of the team at ${companyName}`}.
          </Typography>
          <div onClick={sendRequestToFirm}>
            <CustomBlackBtn type="button" text="Request to join" />
          </div>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "var(--Secondary-Text-Color)" }}
          >
            Looks like a team with the domain{" "}
            <b style={{ color: "var(--Primary-Text-Color)" }}>{domain} </b>is
            already using Fundrev. Request to join now. Once approved, you will
            get an email or instead
          </Typography>
          <p
            style={{
              color: "var(--link-color)",
              cursor: "pointer",
            }}
          >
            <div onClick={requestAdminRights}>Request for admin rights</div>
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default JoinRequestComponent;
