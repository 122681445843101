import "./sentry-init";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { Navigate, Outlet } from "react-router-dom";
import "./index.css";
import "../src/CSS/common.css";
import Loader from "./Components/Common/Loader";
import mixpanel from "mixpanel-browser";

//common pages
import Home from "./Components/LandingPage/home";
import PrivacyPolicy from "./Components/LandingPage/privacyPolicy";
import TermsOfUse from "./Components/LandingPage/termsOfUse";
import InvestorPage from "./Components/Investor/LandingPage/InvestorPage";
import FoundersPage from "./Components/Founders/LandingPage/FoundersPage";
import SignUp from "./Components/SignUp/SignUp";
import SignIn from "./Components/SignIn/SignIn";
import VerificationPage from "./Components/Common/pages/VerificationPage";
import ResetPassword from "./Components/Common/pages/resetPassword";
import NoRoutesMatched from "./Components/Common/pages/noRoutesMatched";
import WithMobileRedirect from "./Components/Common/pages/mobileRedirect";
import MobileRedirectPage from "./Components/Common/pages/mobileRedirectPage";

//Investor Onboarding pages
import InvestorRegister from "./Components/Investor/RegisterPage/InvestorRegister";
import InvestorTnC from "./Components/Investor/RegisterPage/investorTnC";
import EmailVerification from "./Components/Common/pages/emailVerification";
import Investordetails from "./Components/Investor/OnboardingPage/investordetails";
// import InvestorMemberSignUp from "./Components/Investor/RegisterPage/investorMemberSignUp";
import InvestorMembersOnBoarding from "./Components/Members/onBoardingPage/investorMemberOnBoarding";
import InvestorRevoked from "./Components/Investor/RegisterPage/investorRevoked";
import NoNameDeal from "./Components/Investor/LandingPage/noNameDeal";
import NoNameDeals from "./Components/Investor/LandingPage/noNameDeals";
// import InvestorDealRegister from "./Components/Investor/RegisterPage/investorDealRegister";
import InvestorDealSignIn from "./Components/Investor/RegisterPage/investorDealSignIn";
import JoinRequestComponent from "./Components/Common/pages/joinRequestPage";

//Investor dashboard pages
// import Opportunities from "./Components/Investor/Dashboard/Opportunities/OpportunitiesPage";
import OngoingDeals from "./Components/Investor/Dashboard/ongoingDeals";
import Monitoring from "./Components/Investor/Dashboard/monitoring";
import InvestorSideCompanyDetails from "./Components/Investor/Dashboard/investorSideCompanyDetails";
import CompanyDetailsPage from "./Components/Investor/Dashboard/companyDetails/CompanyDetailsPage";
import InvestorAgreement from "./Components/Investor/Dashboard/agreement";
import AccountPage from "./Components/Investor/Dashboard/account/accountPage";
import InvestorNoRoutesMatched from "./Components/Investor/noRoutesMacthed/InvestorNoRoutesMatched";
import StartupNoRoutesMatched from "./Components/Founders/noRoutesMatched/StartupNoRoutesMatched";

//company onboarding pages
import CompanyRegister from "./Components/Founders/RegisterPage/CompanyRegister";
// import CompanyTnC from "./Components/Founders/RegisterPage/companyTnC";
import CompanyOnBoarding from "./Components/Founders/RegisterPage/CompanyOnboarding";
import CompanyDetails from "./Components/Founders/OnboardingPage/companyDetails";
import CompanyWaitlistPage from "./Components/Founders/RegisterPage/companyWaitlistPage";
// import CompanyMemberSignUp from "./Components/Founders/RegisterPage/CompanyMemberSignUp";
import StartupMembersOnBoarding from "./Components/Members/onBoardingPage/startupMembersOnBoarding";
import CompanyProfilePreview from "./Components/Founders/OnboardingPage/profilePreview";
// import RedirectToProfilePreview from "./Components/SignIn/redirectToProfilePreview";

//Company Dashboard pages
import Dashboard from "./Components/Founders/Dashboard/dashboard";
import CompanyOnboarding from "./Components/Founders/Dashboard/onBoarding/companyonboarding";
import Fundraising from "./Components/Founders/Dashboard/Fundraising/fundraising";
import InvestorDetails from "./Components/Founders/Dashboard/investorDetails/investorDetails";
import Dataroom from "./Components/Founders/Dashboard/MyProfile/dataroom";
import CompanyAgreemnt from "./Components/Founders/Dashboard/agreement";
import MyProfile from "./Components/Founders/Dashboard/MyProfile/myProfile";
import CompanyAccountPage from "./Components/Founders/Dashboard/account/accountPage";
import EditCompanyProfile from "./Components/Founders/Dashboard/editProfile.jsx/editCompanyProfile";

//Admin panel pages
import AdminPanel from "./Components/Admin/adminPanel";
import AdminSignIn from "./Components/Admin/adminSignIn";
import InvestorPanel from "./Components/Admin/Investor/Onboarding/InvestorPanel";
import InvestorPreview from "./Components/Admin/Investor/Onboarding/InvestorPreview";
import DataroomUpload from "./Components/Admin/Company/Deal/DataroomUpload";
import InvestorStages from "./Components/Admin/Company/investorStages";
import AnswerQnAs from "./Components/Admin/Company/answerQnAs";
import DealDetailsEdit from "./Components/Admin/Company/Deal/dealDetailsEdit";
import AnalyticsMapper from "./Components/Admin/Company/Deal/AnalyticsMapper";
import CompanyAnalytics from "./Components/Founders/Dashboard/MyProfile/CompanyAnalytics";
import { LoaderProvider } from "./Components/Common/LoaderProvider";
import AdminAnalytics from "./Components/Admin/Investor/Dashboard/AdminAnalytics";
import DownloadPdf from "./Components/Admin/Company/Deal/downloadPdf";
import UnifiedInvestorPreview from "./Components/Admin/UnifiedInvestor/unifiedInvestorPreview";
import RedirectURL from "./Components/Common/RedirectURL";
import NewDeals from "./Components/Investor/Dashboard/Opportunities/newDeals";
import InvestorToolkit from "./Components/Founders/investorToolkit/investorToolkit";
import AdminCompanyProfile from "./Components/Admin/Company/AdminCompanyProfile";
import InvestorEditProfile from "./Components/Admin/Investor/Onboarding/investorEditProfile";
import CompanySummaryPage from "./Components/Founders/Overview/companySummaryPage";
import DummyAnalytics from "./Components/Common/components/DummyAnalytics";

const RedirectRoute = ({ element: Component, redirectTo, ...rest }) => {
  const WrappedComponent = WithMobileRedirect(Component, redirectTo);
  return <WrappedComponent {...rest} />;
};

const PrivateRoutes = ({ role }) => {
  let auth = { token: localStorage.getItem("token") };
  let userRole = localStorage.getItem("role");
  return auth.token && userRole === role ? (
    <Outlet />
  ) : role === "admin" ? (
    <Navigate to="adminsignin" />
  ) : (
    <Navigate to="/signin" />
  );
};

ReactGA.initialize(process.env.REACT_APP_REACT_GA);

mixpanel.init(process.env.REACT_APP_MIXPANEL, {
  debug: true,
  track_pageview: "full-url",
  persistence: "localStorage",
});

export default function App() {
  useEffect(() => {
    // Track the initial page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const isHashRoute =
    window.location.hash && window.location.hash.startsWith("#");

  const RouterComponent = isHashRoute ? HashRouter : BrowserRouter;
  return (
    <RecoilRoot>
      <RouterComponent>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/emailVerification" element={<VerificationPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/loading" element={<Loader />} />
          {/* Investor Routes */}
          <Route path="/investor" element={<InvestorPage />} />
          <Route path="/investor/Register" element={<InvestorRegister />} />
          <Route path="/investor/acceptTC" element={<InvestorTnC />} />
          <Route
            path="/investor/emailVerification"
            element={<EmailVerification />}
          />
          <Route path="/investor/joinFirm" element={<JoinRequestComponent />} />
          <Route
            path="/investor/deal/signIn"
            element={<InvestorDealSignIn />}
          />
          <Route path="/investor/revoked" element={<InvestorRevoked />} />
          <Route
            path="/investor/*"
            element={
              <RedirectRoute
                element={InvestorNoRoutesMatched}
                redirectTo="/mobileRedirect"
              />
            }
          />
          <Route
            path="/investorMemberSignup/:id"
            element={<InvestorRegister />}
          />
          <Route
            path="/investorMembersOnBoarding"
            element={<InvestorMembersOnBoarding />}
          />
          <Route path="/investor/deals" element={<NoNameDeals />} />
          <Route path="/:shortURL" element={<RedirectURL />} />

          <Route element={<PrivateRoutes role="investor" />}>
            <Route path="/investor/deal" element={<NoNameDeal />} />
            <Route path="/investorDetails" element={<Investordetails />} />
            <Route
              path="/investor/ongoingDeals"
              element={
                <RedirectRoute
                  element={OngoingDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/ongoingDeals/:type"
              element={
                <RedirectRoute
                  element={OngoingDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/newDeals"
              element={
                <RedirectRoute
                  element={NewDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/newDeals/:type"
              element={
                <RedirectRoute
                  element={NewDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/newDeals/:type"
              element={
                <RedirectRoute
                  element={NewDeals}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/monitoring"
              element={
                <RedirectRoute
                  element={Monitoring}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/companyDetails"
              element={
                <RedirectRoute
                  element={InvestorSideCompanyDetails}
                  redirectTo="/mobileRedirect"
                />
              }
            />

            <Route
              path="/investor/TermSheet"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/dueDiligence"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/spaNegotiation"
              element={
                <RedirectRoute
                  element={InvestorAgreement}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/companyDetails/requestAccepted"
              element={
                <RedirectRoute
                  element={CompanyDetailsPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/investor/account"
              element={
                <RedirectRoute
                  element={AccountPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
          </Route>

          {/* Founder Routes */}
          <Route path="/founder" element={<FoundersPage />} />
          <Route path="/investor-toolkit" element={<InvestorToolkit />} />
          <Route path="/company/Register" element={<CompanyRegister />} />
          {/* <Route path="/company/acceptTC" element={<CompanyTnC />} /> */}
          <Route
            path="/company/emailVerification"
            element={<EmailVerification />}
          />
          {/* <Route
            path="/company/register"
            element={<RedirectToProfilePreview />}
          /> */}
          <Route path="/companyOnBoarding" element={<CompanyOnBoarding />} />
          <Route path="/company/sampleAnalytics" element={<DummyAnalytics />} />
          <Route
            path="/company/*"
            element={
              <RedirectRoute
                element={StartupNoRoutesMatched}
                redirectTo="/mobileRedirect"
              />
            }
          />
          <Route
            path="/companyMemberSignup/:id"
            element={<CompanyRegister />}
          />
          <Route path="/company/joinFirm" element={<JoinRequestComponent />} />
          <Route path="/company/revoked" element={<InvestorRevoked />} />
          <Route
            path="/companyMembersOnBoarding"
            element={<StartupMembersOnBoarding />}
          />
          <Route
            path="/companyWaitlistPage"
            element={<CompanyWaitlistPage />}
          />
          <Route element={<PrivateRoutes role="company" />}>
            <Route path="/companyDetails" element={<CompanyDetails />} />
            <Route
              path="/company/dashboard"
              element={
                <RedirectRoute
                  element={Dashboard}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/onBoarding"
              element={
                <RedirectRoute
                  element={CompanyOnboarding}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/overview"
              element={
                <RedirectRoute
                  element={CompanySummaryPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/ongoingDeals"
              element={
                <RedirectRoute
                  element={Fundraising}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/ongoingDeals/:type"
              element={
                <RedirectRoute
                  element={Fundraising}
                  redirectTo="/mobileRedirect"
                />
              }
            />

            <Route
              path="/company/dataroom"
              element={
                <RedirectRoute
                  element={Dataroom}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/profilePreview"
              element={
                <RedirectRoute
                  element={CompanyProfilePreview}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/myProfile"
              element={
                <RedirectRoute
                  element={MyProfile}
                  redirectTo="/mobileRedirect"
                />
              }
            />

            <Route
              path="/company/account"
              element={
                <RedirectRoute
                  element={CompanyAccountPage}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/editProfile"
              element={
                <RedirectRoute
                  element={EditCompanyProfile}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/investorDetails"
              element={
                <RedirectRoute
                  element={InvestorDetails}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/termSheet"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/dueDiligence"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/spaNegotiation"
              element={
                <RedirectRoute
                  element={CompanyAgreemnt}
                  redirectTo="/mobileRedirect"
                />
              }
            />
            <Route
              path="/company/analytics"
              element={
                <RedirectRoute
                  element={CompanyAnalytics}
                  redirectTo="/mobileRedirect"
                />
              }
            />
          </Route>

          {/* Admin/Analyst Routes */}
          <Route path="/adminSignIn" element={<AdminSignIn />} />
          <Route element={<PrivateRoutes role="admin" />}>
            <Route path="/adminPanel" element={<AdminPanel />} />
            <Route path="/admin/investorPanel" element={<InvestorPanel />} />
            <Route path="/admin/dataroom" element={<DataroomUpload />} />
            <Route
              path="/admin/companyProfile"
              element={<AdminCompanyProfile />}
            />
            <Route
              path="/admin/investor/preview"
              element={<InvestorPreview />}
            />
            <Route
              path="/admin/investor/editProfile"
              element={<InvestorEditProfile />}
            />
            <Route
              path="/admin/unifiedInvestorPreview"
              element={<UnifiedInvestorPreview />}
            />

            <Route path="/admin/investorStages" element={<InvestorStages />} />
            <Route
              path="/admin/profilePreview"
              element={<CompanyProfilePreview />}
            />
            <Route path="/admin/answerQnAs" element={<AnswerQnAs />} />
            <Route
              path="/admin/dealDetailsEdit"
              element={<DealDetailsEdit />}
            />
            <Route
              path="/admin/companyProfile"
              element={<AdminCompanyProfile />}
            />
            <Route
              path="/admin/analyticsMapper"
              element={<AnalyticsMapper />}
            />
            <Route path="/admin/analytics" element={<AdminAnalytics />} />
            <Route path="/admin/downloadPDF" element={<DownloadPdf />} />
          </Route>

          {/* General Route */}
          <Route path="*" element={<NoRoutesMatched />} />
          <Route path="/mobileRedirect" element={<MobileRedirectPage />} />
        </Routes>
      </RouterComponent>
    </RecoilRoot>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoaderProvider>
    <App />
  </LoaderProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
