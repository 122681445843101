import { Box, Typography } from "@mui/material";
import React from "react";

const AnalyticsPageTitle = ({ title, viewMore, handleClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
        height: "39px",
        padding: "8px",
        paddingRight: "16px",
        borderRadius: "4px",
        marginBottom: "4px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          font: "Roboto",
          fontWeight: 300,
          fontSize: "20px",
          color: "var(--Secondary-Text-Color)",
        }}
      >
        {title}
      </Typography>
      {viewMore && (
        <Typography
          sx={{
            fontFamily: "Karla, sans-serif",
            fontWeight: 500,
            textDecoration: "underline",
            cursor: "pointer",
            color: "var(--blue-color)",
            marginLeft: "16px",
          }}
          onClick={handleClick}
        >
          View more
        </Typography>
      )}
    </Box>
  );
};

export default AnalyticsPageTitle;
