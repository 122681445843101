import React from "react";
import MarqueeDemo from "./animatedCards";
import ShimmerButton from "../Common/animations/shimmerButton";
import { RainbowButton } from "../Common/animations/rainbowButton";
import { GoArrowRight } from "react-icons/go";
import "../../CSS/LandingPage/front.css";
import { Link } from "react-router-dom";
import { ShinyButton } from "../Common/animations/shinyButton";
export const Front = () => {
  return (
    <div className="header">
      <div className="overlap">
        <div className="frame-and-frame-4">
          <div className="frame">
            <div className="frame-2">
              <div className="frame-3">
                <h1 className="text-wrapper">LET'S FUNDREV</h1>
              </div>
              <h2 className="typography">
                Helping Companies Raise{" "}
                <span className="onetotwentymillion">$1-20 million</span>{" "}
                Institutional Capital
              </h2>
              <h2 className="p">
                Assisting entrepreneurs in securing institutional/strategic
                funding from verified investors, accelerating the process and
                obtaining vital capital swiftly than ever before!
              </h2>
            </div>
            <Link to="/investor-toolkit">
              <ShinyButton
                className="rounded-full h-12 px-6 py-3 max-[576px]:py-2.5 max-[576px]:h-10"
                front={true}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Find Active Investors
                  <GoArrowRight
                    style={{
                      color: "black",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                    size={`15px`}
                  />
                </span>
              </ShinyButton>
            </Link>
          </div>
          <div className="group-4">
            <MarqueeDemo />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Front;
