import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import PendingRequestsTable from "../../Overview/PendingRequestsTable";
import MeetingTable from "../../Overview/MeetingTable";

const DashboardGrid2 = ({ data, deal }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          <Grid xs={12}>
            <PendingRequestsTable data={data} deal={deal} />
          </Grid>
          <Grid xs={12}>
            <MeetingTable data={data} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardGrid2;
