import React, { useState } from "react";

const CustomBlackBtn = ({
  type = "button",
  text,
  mode = "dark",
  border = "md",
  filled = true,
  onClick,
  disabled,
  width = "fit-content",
  variant = "md",
  textWeight = "md",
}) => {
  const [hover, setHover] = useState(false);

  // Define color options based on mode
  const colorOptions = {
    dark: {
      backgroundColor: "#002132",
      hoverColor: "#00445E",
      textColor: "#fff",
      borderColor: "#002132",
    },
    light: {
      backgroundColor: "#fff",
      hoverColor: "#f0f0f0",
      textColor: "#002132",
      borderColor: "#002132",
    },
    danger: {
      backgroundColor: "#d9534f", // Red color
      hoverColor: "#c9302c",
      textColor: "#fff",
      borderColor: "#d9534f",
    },
    success: {
      backgroundColor: "#5cb85c", // Green color
      hoverColor: "#4cae4c",
      textColor: "#fff",
      borderColor: "#5cb85c",
    },
  };

  // Define border radius options
  const borderRadiusValues = {
    sm: "4px",
    md: "100px",
    lg: "24px",
  };

  // Define text weight options
  const textWeightValues = {
    sm: 300,
    md: 400,
    lg: 600,
  };

  // Choose color options based on mode
  const { backgroundColor, hoverColor, textColor, borderColor } =
    colorOptions[mode];

  return (
    <button
      type={type}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: width,
        display: "flex",
        height: variant === "sm" ? "36px" : "48px",
        padding: variant === "sm" ? "12px 18px" : "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: borderRadiusValues[border],
        background: filled
          ? hover
            ? hoverColor
            : backgroundColor
          : "transparent",
        color: filled ? textColor : borderColor,
        boxShadow: hover
          ? "0px 6px 12px rgba(0, 0, 0, 0.2)"
          : "0px 4px 8px rgba(0, 0, 0, 0.1)",
        border: `1px solid ${borderColor}`,
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        opacity: disabled ? 0.6 : 1, // Adjust opacity when disabled
        fontSize: variant === "sm" ? "14px" : undefined,
        fontWeight: textWeightValues[textWeight], // Set text weight based on the new prop
      }}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default CustomBlackBtn;
