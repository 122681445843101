import React, { useState, useEffect } from "react";
import Toast from "../Toast";
import { useLoader } from "../LoaderProvider";
import { StyledFormControl, StyledFormLabel } from "../functions";

import {
  sendOtpPasswordChange,
  updatePasswordRequest,
  verifyEmailPasswordChange,
} from "../../../endpoints/common";

import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomBlackBtnPhone from "../components/custom/customBlackBtnPhone";
import VerificationInput from "react-verification-input";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation, Link } from "react-router-dom";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import { FormControl, FormLabel } from "@mui/material";
import CustomInput from "../../Common/components/custom/customInput";
import CustomPasswordInput from "../components/custom/customPasswordInput";

import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";
import "../../../CSS/SignIn/resetPassword.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
const ResetPassword = ({}) => {
  const navigate = useNavigate();
  const loader = useLoader();
  const location = useLocation();

  const [page, setPage] = useState("resetPassword");

  const { role } = location.state || {};
  const [email, setEmail] = useState("");
  const [token, setToken] = useState();
  const [secondToken, setSecondToken] = useState();
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [time, setTime] = useState(5 * 60);
  const [timeUp, setTimeUp] = useState(false);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const [firstopen, setfirstOpen] = useState(false);
  const [secondopen, setsecondOpen] = useState(false);
  const [thirdopen, setthirdOpen] = useState(false);
  const [fourthopen, setfourthOpen] = useState(false);
  const [codesent, setCodeSent] = useState(false);
  //   useEffect(() => {
  //     if (time > 0) {
  //       const timer = setInterval(() => {
  //         setTime((prevTime) => prevTime - 1);
  //       }, 1000);

  //       return () => clearInterval(timer);
  //     }
  //   }, [time]);
  const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
  const sendOTP = async (values) => {
    try {
      loader.start("Sending OTP...");
      const responseData = await sendOtpPasswordChange(
        role,
        values.email || email
      );
      loader.stop();
      if (responseData.data !== null) {
        const response = responseData.data;
        if (response.ok) {
          const responseData = await response.json();
          setCodeSent(true);
          setToken(responseData.verificationToken);
          setEmail(values.email);
          setPage("enterOTP");
        }
        if (!response.ok) {
          Toast(
            "Did not find account associated with this email address",
            "error",
            "resetPassword"
          );
        }

        setTime(5 * 60);
        setTimeUp(false);
      } else {
        // Handle any errors, such as showing a message to the user
      }
    } catch (error) {
      loader.stop();
    }
  };
  const verifyCode = async () => {
    try {
      loader.start("Verifying OTP...");
      const responseData = await verifyEmailPasswordChange(
        token,
        email,
        verificationCode
      );
      loader.stop();
      if (responseData.data !== null) {
        const response = responseData.data;
        if (response.ok) {
          const responseData = await response.json();
          Toast("Verification Successful", "success", "resetPassword");
          setVerified(true);
          setSecondToken(responseData.verificationToken);
          setPage("newPassword");
        }
        if (!response.ok) {
          Toast("Incorrect otp", "error", "resetPassword");
        }
      } else {
        Toast("Internal server error", "error", "resetPassword");
        setVerificationCode("");
      }
    } catch (error) {
      loader.stop();
    }
  };
  const handlePasswordChange = async (values) => {
    try {
      loader.start("Updating Password...");
      const responseData = await updatePasswordRequest(
        secondToken,
        values.password
      );
      loader.stop();
      if (responseData.data !== null) {
        const response = responseData.data;
        if (response.ok) {
          Toast(
            "Password updated successfully redirecting to sign in page",
            "success",
            "resetPassword"
          );
          setTimeout(() => {
            navigate("/signin");
          }, [2000]);
        }
        if (!response.ok) {
          Toast("Failed to update password", "error", "resetPassword");
        }

        // const data = await response.json();
        // Handle successful verification
      } else {
        Toast("Failed to update password", "error", "resetPassword");
      }
    } catch (error) {
      loader.stop();
    }
  };
  const handleSendAgain = () => {
    sendOTP({ email: email });
    Toast("OTP sent again", "success", "resetPassword");
    setTime(5 * 60);
  };
  const updateSignInPassword = (newPassword) => {
    setNewPassword(newPassword);
  };
  const [allStates, setAllStates] = useState({
    lowerValidated: false,
    upperValidated: false,
    numberValidated: false,
    specialValidated: false,
    lengthValidated: false,
  });
  const receiveStatesFromChild = (states) => {
    setAllStates(states);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const initialValues = {
    email: "",
  };
  const validationSchema2 = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues2 = {
    password: "",
    confirmPassword: "",
  };
  const handleSubmit = (values) => {
    setPage("enterOTP");
    // navigate("/reset-confirmation");
  };
  return (
    <div className="reset-password">
      <ToastContainer position="top-center" containerId="resetPassword" />
      <div className="reset-password-header">
        <Link to="/">
          <img src={Logo} alt="fundrev_logo" />
        </Link>
      </div>
      <div
        className="reset-password-block"
        style={{ display: page === "resetPassword" ? "flex" : "none" }}
      >
        <div className="heading-block">
          <p className="fundrev-heading reset-password-heading">
            Reset your password
          </p>
        </div>
        <div className="middle-block">
          <div>
            <p>Please enter the email associated with your account</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendOTP}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  width: "100%",
                }}
              >
                <div className="reset-password-input-fields">
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Email Address</StyledFormLabel>
                    <CustomInput
                      placeholder="Enter your email address"
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </StyledFormControl>
                </div>

                <div className="reset-password-btn-section laptop-design">
                  <CustomBlackBtn type="submit" text="Send OTP" />
                </div>
                <div className="reset-password-btn-section phone-design">
                  <CustomBlackBtnPhone type="submit" text="Send OTP" />
                  <Link to="/signup">
                    <p>Cancel</p>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="enter-otp-block"
        style={{ display: page === "enterOTP" ? "flex" : "none" }}
      >
        <div className="heading-block">
          <p className="fundrev-heading reset-password-heading">Enter OTP</p>
        </div>
        <div className="middle-block">
          <div className="middle-block-header">
            <p>Please enter the OTP shared on your email</p>
          </div>
          <div className="otp-block">
            <div className="otp-block-first">
              <VerificationInput
                length={6}
                placeholder=""
                value={verificationCode}
                onChange={(code) => setVerificationCode(code)}
              />
              <div className="time-up-send-again laptop-design">
                <p className="timer">{timeUp ? "Time up" : formattedTime}</p>
              </div>
            </div>
            <div
              className="send-again-div laptop-design"
              onClick={handleSendAgain}
              style={{ cursor: "pointer" }}
            >
              <RefreshIcon style={{ color: "#3C82F6" }} />
              <p>Send again</p>
            </div>
            <div
              className="send-again-div phone-design"
              onClick={handleSendAgain}
              style={{ cursor: "pointer" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RefreshIcon style={{ color: "#3C82F6" }} />
                <p>Send again</p>
              </div>
              <div className="time-up-send-again">
                <p className="timer">{timeUp ? "Time up" : formattedTime}</p>
              </div>
            </div>
            <div className="btn-block laptop-design">
              <div onClick={verifyCode}>
                <CustomBlackBtn text="Proceed" type="submit" />
              </div>
            </div>
            <div className="btn-block phone-design">
              <CustomBlackBtnPhone type="submit" text="Proceed" />
              <Link to="/signup">
                <p>Cancel</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="new-password-block"
        style={{ display: page === "newPassword" ? "flex" : "none" }}
      >
        <div className="heading-block">
          <p className="fundrev-heading reset-password-heading">
            Enter your new password
          </p>
        </div>
        <div className="middle-block">
          <Formik
            initialValues={initialValues2}
            validationSchema={validationSchema2}
            onSubmit={handlePasswordChange}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "40px",
                  width: "100%",
                }}
              >
                <div className="reset-password-input-fields">
                  <StyledFormControl fullWidth>
                    <StyledFormLabel>New Password</StyledFormLabel>
                    <CustomPasswordInput
                      placeholder="Enter new password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </StyledFormControl>

                  <StyledFormControl fullWidth>
                    <StyledFormLabel>Confirm Password</StyledFormLabel>
                    <CustomPasswordInput
                      placeholder="Re-enter password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                  </StyledFormControl>
                </div>

                <div className="reset-password-btn-section laptop-design">
                  <CustomBlackBtn type="submit" text="Reset Password" />
                </div>
                <div className="reset-password-btn-section phone-design">
                  <CustomBlackBtnPhone type="submit" text="Reset Password" />
                  <Link to="/signup">
                    <p>Cancel</p>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
