import React from "react";
import TimePeriodButton from "../analytics/AnalyticsPlots/TimePeriodButton";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const CohortTable = ({ data }) => {
  const headers = data.data.headers;
  const rows = data.data.values;
  function getMinMax(arr) {
    // Convert all percentage strings or number strings to numbers and return min/max
    const numberValues = arr.map(
      (x) =>
        typeof x === "string" && x.slice(-1) === "%" // Handle percentage strings
          ? parseFloat(x.replace("%", ""))
          : typeof x === "string" && !isNaN(x) // Handle number strings like "1.1"
          ? parseFloat(x)
          : typeof x === "number" // Handle numbers directly
          ? x
          : 0 // Default to 0 for unrecognized values
    );

    return {
      min: Math.min(...numberValues),
      max: Math.max(...numberValues),
    };
  }

  // Example data
  const allValues = Object.values(data.data.values).flat(); // Flatten the data object
  const { min, max } = getMinMax(allValues); // Get min and max values from the array

  // Function to return a color as a single hex code based on the value, adjusting for min/max
  function getColor(value) {
    let percentage;

    // If value is a percentage string (ends with %), convert to number
    if (typeof value === "string" && value.slice(-1) === "%") {
      percentage = parseFloat(value.replace("%", ""));
    }
    // If value is a string that represents a number (like "1.1"), convert to float
    else if (typeof value === "string" && !isNaN(value)) {
      percentage = parseFloat(value);
    }
    // If value is already a number, treat it as a percentage-like value
    else if (typeof value === "number") {
      percentage = value;
    }
    // Return white if the value isn't recognized as a percentage or number
    else {
      return "#FFFFFF";
    }

    // Normalize the percentage between 0 and 1 based on the min and max values
    const normalized = (percentage - min) / (max - min);

    // Ensure normalized value is within 0-1 range to avoid outliers
    const clampedNormalized = Math.min(Math.max(normalized, 0), 1);

    // Calculate RGB values for a gradient from light (white) to dark (teal) as the percentage increases
    const r = Math.round(179 + (255 - 179) * (1 - clampedNormalized)); // Blend between 179 and 255
    const g = Math.round(242 + (255 - 242) * (1 - clampedNormalized)); // Blend between 242 and 255
    const b = Math.round(224 + (255 - 224) * (1 - clampedNormalized)); // Blend between 224 and 255

    // Convert the r, g, b values to a hex string
    const hexR = r.toString(16).padStart(2, "0");
    const hexG = g.toString(16).padStart(2, "0");
    const hexB = b.toString(16).padStart(2, "0");

    // Return the final hex color code
    return `#${hexR}${hexG}${hexB}`;
  }

  return (
    <Box>
      <TimePeriodButton
        title={data.name}
        year={false}
        q={false}
        type={`m`}
        m={false}
        paddingRight={`20px`}
      />
      <Paper
        sx={{
          width: "529px",
          overflow: "hidden",
          boxShadow: "0",
          marginTop: "10px",
          border: "1px solid #B8B8B875",
          borderRadius: "10px",
        }}
      >
        <TableContainer>
          <Table aria-label={data.name}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    sx={{
                      width: index === 0 ? "169px" : "125px",
                      paddingX: "5px",
                      paddingY: "10px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 700,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#eaeaea",
                      border: "1px solid #B8B8B850",
                      color: "#1a1a1a",
                      borderTop: 0,
                      borderRight: 0,
                      borderLeft: index === 0 && 0,
                    }}
                    align={index !== 0 ? "center" : "left"}
                    key={header}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(rows)?.map(([key, row], index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      // width: "169px",
                      paddingX: "5px",
                      paddingY: "8px",
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 400,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      border: "0.25px solid #D3D9EE50",
                      fontStyle: "normal",
                      borderLeft: 0,
                      borderBottom: index === Object.keys(rows).length - 1 && 0,
                    }}
                  >
                    {key}
                  </TableCell>
                  {row.map((cell, index) => (
                    <TableCell
                      sx={{
                        // width: "169px",
                        paddingX: "5px",
                        paddingY: "8px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        border:
                          cell === `-`
                            ? "0.25px dashed #D3D9EE50"
                            : "0.25px solid #D3D9EE50",
                        fontStyle: "normal",
                        borderBottom: 0,
                        borderRight: 0,
                        backgroundColor: getColor(cell),
                      }}
                      align="center"
                      key={index}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CohortTable;
