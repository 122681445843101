import {
  Box,
  Table,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "../analytics/AnalyticsPlots/TimePeriodButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const IncomeTable = ({ data, ltm = true }) => {
  const formatter = (x) => {
    if (x === undefined) return null;
    if (typeof x === "string") {
      if (x.includes(`%`)) return x;
      const numx = Number(x);
      if ([NaN, Infinity].includes(numx)) {
        return 0;
      }
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(numx);
    } else {
      return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 1, // Customize decimal places if needed
        minimumFractionDigits: 1,
      }).format(x);
    }
  };
  const [type, setType] = useState("m");
  const [open, setOpen] = useState(true);
  const percentageRows = [
    "Gross Profit %",
    "EBITDA %",
    "Net Profit Margin",
    "EBIT %",
  ];
  const IMPRows = [
    "Gross Profit",
    "EBITDA",
    "Net Profit",
    "EBIT",
    "Profit (Before Tax)",
  ];

  useEffect(() => {}, [type, data]);

  function CollapsibleRow(props) {
    const { row } = props;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell
            sx={{
              position: "sticky",
              left: 0,
              background: "white",
              zIndex: 1,
              paddingY: "0px",
              whiteSpace: "nowrap",
              fontFamily: "Karla, sans-serif",
              padding: 0,
              border: 0,
            }}
          >
            <div
              style={{
                border: "0.25px solid #D3D9EE50",
                height: "100%",
                padding: "3px 10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {row}

              <IconButton
                aria-label="expand row"
                size="small"
                sx={{
                  paddingY: 0,
                }}
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <KeyboardArrowUpIcon fontSize="3px" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="3px" />
                )}
              </IconButton>
            </div>
          </TableCell>
          {Object.values(data.data[type]["SG&A"]["Total SG&A Expenses"])?.map(
            (value, index) => (
              <TableCell
                key={index}
                sx={{
                  width: "123px",
                  paddingY: "0px",
                  whiteSpace: "nowrap",
                  fontFamily: "Karla, sans-serif",
                  border: "0.25px solid #D3D9EE50",
                }}
                align="center"
              >
                {formatter(value) || `-`}
              </TableCell>
            )
          )}
        </TableRow>
        {Object.keys(data.data[type][row]).map((inside_row, index) => {
          return (
            inside_row !== "Total SG&A Expenses" &&
            Object.values(data.data[type][row][inside_row]) && (
              <TableRow
                key={index}
                sx={{
                  display: open ? "table-row" : "none",
                  animation: open
                    ? "fadeIn 0.5s ease-in-out"
                    : "fadeOut 0.5s ease-in-out",
                  "@keyframes fadeIn": {
                    from: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                    to: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                  },
                  "@keyframes fadeOut": {
                    from: {
                      opacity: 1,
                      transform: "translateY(0)",
                    },
                    to: {
                      opacity: 0,
                      transform: "translateY(-10px)",
                    },
                  },
                }}
              >
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                    paddingY: "0px",
                    whiteSpace: "nowrap",
                    fontFamily: "Karla, sans-serif",
                    padding: 0,
                    border: 0,
                  }}
                >
                  <div
                    style={{
                      border: "0.25px solid #D3D9EE50",
                      height: "100%",
                      padding: "3px 10px",
                      paddingLeft: "30px",
                      borderBottom: 0,
                    }}
                  >
                    {inside_row}
                  </div>
                </TableCell>
                {Object.values(data.data[type][row][inside_row]).map(
                  (val, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        width: "123px",
                        paddingY: "0px",
                        fontFamily: "Karla, sans-serif",
                        border: "0.25px solid #D3D9EE50",
                      }}
                      align="center"
                    >
                      {formatter(val && val) || `-`}
                    </TableCell>
                  )
                )}
              </TableRow>
            )
          );
        })}
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          width: "1150px",
          height: open ? "425px" : "315px",
          padding: "15px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <TimePeriodButton
          type={type}
          setType={setType}
          ltm={ltm}
          title={data.name}
        />
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 0,
            paddingX: "5px",
          }}
        >
          <TableContainer
            sx={{
              border: "1px solid #B8B8B875",
              borderRadius: "10px",
            }}
          >
            <Table aria-label="Financial statement table" size="small">
              <TableHead>
                <TableRow>
                  {[
                    "Particulars",
                    ...Object.keys(
                      data.data[type][Object.keys(data.data[type])[0]]
                    ),
                  ].map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        position: "sticky",
                        left: index === 0 ? 0 : "auto",
                        background: index === 0 ? "white" : "none",
                        zIndex: index === 0 ? 1 : 0,
                        whiteSpace: "nowrap",
                        fontWeight: 700,
                        fontFamily: "Karla, sans-serif",
                        padding: index === 0 ? 0 : "3px 10px",
                        paddingY: "0px",
                        backgroundColor: "#eaeaea",
                        color: "#1a1a1a",
                        border: 0,
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          padding: index === 0 && "3px 10px",
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: index !== 0 && "center",
                          border: index === 0 && "0.25px solid #D3D9EE50",
                        }}
                      >
                        {column}
                        {index === 0 && (
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "700px",
                              color: "#1a1a1a",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data.data[type]).map((row, index) =>
                  row === "SG&A" ? (
                    <CollapsibleRow row={row} key={index} />
                  ) : (
                    <TableRow key={index}>
                      {row !== "SG&A" && (
                        <TableCell
                          sx={{
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 1,
                            paddingY: "0px",
                            whiteSpace: "nowrap",
                            fontFamily: "Karla, sans-serif",
                            padding: 0,
                            fontWeight: IMPRows.includes(row) ? "700" : "400",
                            border: 0,
                            borderTop:
                              IMPRows.includes(row) && "1.5px solid black",
                            fontStyle: percentageRows.includes(row)
                              ? "italic"
                              : "normal",
                          }}
                        >
                          <div
                            style={{
                              border: "0.25px solid #D3D9EE50",
                              height: "100%",
                              width: "100%",
                              padding: "3px 10px",
                            }}
                          >
                            {row}
                          </div>
                        </TableCell>
                      )}
                      {row !== "SG&A" &&
                        Object.keys(
                          data.data[type][Object.keys(data.data[type])[0]]
                        ).map((val, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              width: "123px",
                              padding: "3px 10px",
                              fontFamily: "Karla, sans-serif",
                              border: "0.25px solid #D3D9EE50",
                              fontWeight: IMPRows.includes(row) ? "700" : "400",
                              borderTop:
                                IMPRows.includes(row) && "1.5px solid #000",
                              fontStyle: percentageRows.includes(row)
                                ? "italic"
                                : "normal",
                              borderBottom: 0,
                              borderRight: 0,
                            }}
                            align="center"
                          >
                            {formatter(data.data[type][row][val]) || `-`}
                          </TableCell>
                        ))}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default IncomeTable;
