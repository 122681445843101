import React from "react";
import lightbulb from "../../../../../Assets/Images/FounderPage/Dashboard/lightbulb.png";
const ExpectedValuationSuggested = () => {
  return (
    <div className="suggested-section">
      <p className="heading">Understanding Priced vs. Non-Priced Rounds</p>
      <p className="subheading">
        <p>
          <b>Priced Round: </b>The company's valuation is set upfront, so
          investors know the exact percentage they're buying.
        </p>
        <p className="mt-2">
          <b>Non-Priced Round: </b>No fixed valuation initially; investors use
          instruments like convertible notes that convert to equity later, often
          with a discount or valuation cap.
        </p>
      </p>
      <div className="bg-gray-200 p-[8px] rounded-[4px] text-[var(--Primary-Text-Color)] text-sm">
        <p>
          <img
            src={lightbulb}
            alt="lightbulb"
            className="w-[24px] inline mr-[8px]"
          />
          Most seed-stage rounds are non-priced. If you're uncertain about your
          business's valuation or feel it's too early to determine one, opting
          for a non-priced round is often a better choice.
        </p>
      </div>
    </div>
  );
};

export default ExpectedValuationSuggested;
