import React, { useState, useEffect, useRef } from "react";
import { getAllNoNameDeals } from "../../../endpoints/deal";
import Card from "../../Common/components/Card";
import Header from "../../LandingPage/header";
import Footer from "../../LandingPage/Footer";
import NoDeal from "../../Investor/Dashboard/Opportunities/NoDeal";
import Toast from "../../Common/Toast";
import DealsFilterBox from "../../Common/components/DealsFilterBox";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "../../../CSS/InvestorPage/noNameDeals.css";
import { FiFilter } from "react-icons/fi";
import { useLoader } from "../../Common/LoaderProvider";
import { ShinyButton } from "../../Common/animations/shinyButton";

const NoNameDeals = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const elementRef = useRef(null);
  const [deals, setDeals] = useState([]);
  const token = localStorage.getItem("token");

  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filterBoxOpen, setFilterBoxOpen] = useState(false);
  const loader = useLoader();
  async function getNoNameDeals() {
    try {
      loader.start();
      const data = await getAllNoNameDeals(token);
      setDeals(data.data);
      loader.stop();
    } catch (error) {
      Toast("Error getting NoNameDeals", "error", "noNameDeals");
      loader.stop();
    }
  }
  useEffect(() => {
    getNoNameDeals();
  }, [token]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) setFilterBoxOpen(false);
  }, [windowWidth]);
  const getRequiredHeight = () => {
    const ele = document.getElementsByClassName("Financials");
    const ele2 = document.getElementsByClassName("Card-div");
    const box = document.getElementsByClassName("investor-noName-deals");
    const len = ele.length;

    if (len > 4) {
      const rect = ele[4].getBoundingClientRect();
      const rect2 = ele2[0].getBoundingClientRect();
      const height = rect.top - rect2.top;
      if (box.length > 0) {
        box[0].style.height = height + "px";
      }
    } else if (window.innerWidth < 768 && len > 0) {
      const cards = Array.from(document.getElementsByClassName("Card-div"));
      let totalHeight = 0;
      if (cards.length > 0) {
        cards.forEach((card) => {
          totalHeight += card.offsetHeight;
        });
      }
      if (box.length > 0) {
        box[0].style.height = totalHeight + 50 + "px";
      }
    } else if (window.innerWidth < 768 && len === 0) {
      if (box.length > 0) {
        box[0].style.height = 0 + "px";
      }
    }
  };

  // Execute when the document is fully loaded
  window.onload = () => {
    getRequiredHeight();
  };
  useEffect(() => {
    getRequiredHeight();
  });
  return (
    <section className="open-listing-page">
      <Helmet>
        <title>Investor | Deals</title>
      </Helmet>
      <ToastContainer position="top-center" containerId="noNameDeals" />
      <Header color="#193946" type="noNameDeals" />
      <div className="investor-noName-deals">
        <div>
          <div className="noNameDeal-first-section">
            <p className="noNameDeal-heading">
              Find highly qualified investments on Fundrev
            </p>
            <button
              className="InvestorSide-content-button-1 "
              onClick={() => setFilterBoxOpen(!filterBoxOpen)}
              style={{
                backgroundColor: filterBoxOpen ? "#114fee" : "#fff",
                color: filterBoxOpen ? "#fff" : "#114fee",
                transition: "all 0.3s ease",
              }}
            >
              <FiFilter />
            </button>
            {/* <div className="mobile-stats">
              <button
                className="InvestorSide-content-button-1 mobile-stats"
                onClick={() => setFilterBoxOpen(!filterBoxOpen)}
                style={{ padding: "10px 20px", borderRadius: "10px" }}
              >
                <CiFilter />
              </button>
            </div> */}
          </div>

          <DealsFilterBox
            deals={deals}
            setFilteredDeals={setFilteredDeals}
            filterBoxOpen={filterBoxOpen}
            shouldUpdateUrl={true}
          />
        </div>

        <div className="opportunites-display">
          {filteredDeals && filteredDeals.length > 0 ? (
            filteredDeals.map((deal) => (
              <Card
                startPoint="noNameDeals"
                key={deal.DealID}
                isNew={false}
                deal={deal}
                elementRef={elementRef}
              />
            ))
          ) : windowWidth > 768 ? (
            <NoDeal />
          ) : (
            <></>
          )}
        </div>
      </div>
      <section className="scroll-mt-[70px]" ref={elementRef}>
        <div className="noName-signup-box flex overflow-hidden justify-center items-center px-14 py-14 bg-white border-solid backdrop-blur-[200px] max-md:px-6 border-t-[2px] border-[#000] border-opacity-40 ">
          <div className="flex flex-col justify-center items-center self-stretch my-auto min-w-[240px] w-[1191px]">
            <div className="flex flex-col w-full max-w-[1191px] max-md:max-w-full">
              <div className="text-center self-center text-xl font-light leading-none text-zinc-900 max-md:max-w-full">
                Join leading investment firms in making data-driven investments!
              </div>
              <div className="flex flex-wrap gap-6 items-center mt-4 w-full max-md:max-w-full">
                <div className="noName-investorFirms-section flex flex-wrap gap-6 items-center self-stretch my-auto min-w-[240px] justify-center max-md:max-w-full">
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/trifecta.png"
                      className="object-contain max-w-full aspect-[3] w-[120px]"
                      alt="Trifecta"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-3.5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/firside.png"
                      className="object-contain max-w-full aspect-[2.79] w-[146px]"
                      alt="Fireside"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-6 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/adityaBirla.png"
                      className="object-contain max-w-full aspect-[3.53] w-[120px]"
                      alt="Aditya Birla"
                    />
                  </div>
                  <div className="noName-investorFirms-card flex overflow-hidden flex-col justify-center items-center self-stretch px-6 py-2.5 my-auto w-60 bg-white rounded-lg border-solid shadow-sm border-[0.5px] border-slate-400 min-h-[80px] max-md:px-6">
                    <img
                      loading="lazy"
                      srcSet="https://fundrevstorage2.blob.core.windows.net/website-images/rockstud.png"
                      className="object-contain max-w-full aspect-[1.88] w-[113px]"
                      alt="Rockstud"
                    />
                  </div>
                  <div className="more-companies-text self-stretch my-auto text-3xl font-light text-gray-500">
                    +24 more
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mt-16 max-w-full text-base font-medium tracking-tighter text-white w-[783px] max-md:mt-5">
              <Link to="/investor/Register">
                {/* <ShimmerButtonDemo text="Join Now" /> */}
                <ShinyButton
                  className="rounded-full h-12 px-6 py-3 max-[576px]:py-2.5 max-[576px]:h-10"
                  // front={true}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Join Now
                  </span>
                </ShinyButton>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="landing-page ">
        <Footer />
      </div>
    </section>
  );
};

export default NoNameDeals;
