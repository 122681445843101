import React, { useEffect, useState } from "react";
import "../../../../CSS/FounderPage/Dashboard/timeline.css";
import tick from "../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Tick.svg";
import circle from "../../../../Assets/Images/FounderPage/Dashboard/TimelineIcons/Circle.svg";

import { isStageCompleted, calculateProfileCompletion } from "./stepCompletion";
import { useFormikContext } from "formik";

const Steps = ({
  setActiveStep,
  values,
  setSchema,
  validationSchema,
  activeStep,
}) => {
  const { submitForm } = useFormikContext();
  const [steps, setSteps] = useState([
    "Deal Details",
    "Expected Valuation",
    "Deal Interest",
    "Deal Purpose",
    "Pitch Creation",
    "Meeting Calendar Setup",
    "Analytics Preparation",
    "Data Room Setup",
    "Terms & Conditions",
    "Submit for Review",
  ]);
  useEffect(() => {
    const elements = document.getElementsByClassName("div-3");
    if (values.dealType !== "Equity" && values.dealType !== "Equity + Debt") {
      // Set margin for each element if funding type is not "Equity"
      elements &&
        Array.from(elements).forEach((element) => {
          element.style.marginBottom = "25px";
        });
      elements[elements.length - 1].style.marginBottom = "25px";
      // Filter out "Expected Valuation" from steps
      setSteps(steps.filter((step) => step !== "Expected Valuation"));
    } else {
      // Set margin for each element if funding type is "Equity"
      elements &&
        Array.from(elements).forEach((element) => {
          element.style.marginBottom = "18px";
        });

      elements[elements.length - 1].style.marginBottom = "25px";

      // Add "Expected Valuation" at the correct position in steps
      setSteps([
        ...steps.slice(0, 1),
        "Expected Valuation",
        ...steps.slice(1).filter((step) => step !== "Expected Valuation"),
      ]);
    }
  }, [values.dealType]);
  const handleStepClick = async (index) => {
    console.log("d", values.dealType);
    if (values.onBoardingComplete === true) {
      return;
    } else if (
      ((values.dealType === "Debt" || values.dealType === "") &&
        index + 1 === 9) ||
      ((values.dealType === "Equity + Debt" || values.dealType === "Equity") &&
        index + 1 === 10) // Case when dealType is not selected
    ) {
      return;
    }
    setActiveStep(index + 1);
    await setSchema(null);
    await submitForm().then(() => {
      setSchema(validationSchema);
    });
  };
  return (
    <div className="comapnyOnboarding-steps">
      <div className={`timeline`}>
        <div className="div-2" style={{ marginBottom: "8px" }}>
          Create Deal
        </div>
        <div className="text-[12px] text-[#696F79]">Your Progress</div>
        <div
          className="text-[20px] text-[#00A6FB] font-semibold"
          style={{
            color: calculateProfileCompletion(values) === "100" && "#5db18a",
          }}
        >
          {`${calculateProfileCompletion(values)}% Completed`}
        </div>
        {steps.map((step, index) => (
          <div className="div-3" key={index}>
            {step === "Submit for Review" ? (
              <></>
            ) : (
              <div
                className="line"
                style={{
                  borderColor: isStageCompleted(step, values)
                    ? "#5db18a"
                    : "#B9BBBD",
                }}
              ></div>
            )}
            <div
              className={`details-div`}
              style={{
                cursor: !values.onBoardingComplete
                  ? values.dealType === "Equity + Debt" ||
                    values.dealType === "Equity"
                    ? index + 1 === 10
                      ? "not-allowed"
                      : "pointer"
                    : index + 1 === 9
                    ? "not-allowed"
                    : "pointer"
                  : "not-allowed",
              }}
              onClick={() => handleStepClick(index)}
            >
              <img
                loading="lazy"
                src={isStageCompleted(step, values) ? tick : circle}
                className="img"
                alt={step}
              />
              <div className="div-4">
                <div
                  className="div-6"
                  style={{
                    color:
                      index + 1 === activeStep && "var(--Primary-Text-Color)",
                    fontWeight: index + 1 === activeStep && 700,
                  }}
                >
                  {step}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
