import React from "react";
import { fetchBlobName, handleFileDownload } from "../../Common/functions";

import BlueFileIcon from "../../../Assets/Images/InvestorPage/Dashboard/blueFileIcon.svg";

const DocumentFile = ({ file, fileName, loader, containerId }) => (
  <div className="dealFile cursor-pointer">
    <img src={BlueFileIcon} alt="file" />
    <div className="dealFile-name-div">
      <p
        onClick={() => handleFileDownload(file, fileName, loader, containerId)}
        className="dealFile-link"
      >
        <div className="dealFile-name">{fetchBlobName(file)}</div>
      </p>
    </div>
  </div>
);

export default DocumentFile;
