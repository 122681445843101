import React from "react";
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/joy";
import { FileUploader } from "react-drag-drop-files";
import uploadFileBlue from "../../../Assets/Images/InvestorPage/Dashboard/uploadFile.svg";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { getIcon } from "../functions";
import CustomBlackBtn from "../components/custom/customBlackBtn";
const UploadDocumentModal = ({
  DocumentUploadModalOpen,
  setDocumentUploadModalOpen,
  DocumentUploadFile,
  DocumentUploadFileChange,
  handleCancelDocumentUpload,
  handleDocumentUploadSubmit,
}) => {
  return (
    <Modal
      open={DocumentUploadModalOpen}
      onClose={() => setDocumentUploadModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box reason-box" style={{ width: "750px" }}>
        <div className="reason-box-first-div">
          <p className="IOI-bid-title">Document Upload</p>
          <img
            className="reason-box-closeicon"
            src={closeIcon}
            style={{ cursor: "pointer" }}
            onClick={() => setDocumentUploadModalOpen(false)}
          ></img>
        </div>
        <div className="SPA-modal-upload">
          {DocumentUploadFile ? (
            <div className="upload-modal-spa-div">
              <img
                className="upload-modal-spa-img"
                src={getIcon(DocumentUploadFile[0].name.split(".").pop())}
                alt={DocumentUploadFile[0].name.split(".").pop()}
              />
              <p
                style={{
                  fontWeight: "600",
                  color: "var(--Primary-Text-Color)",
                  textAlign: "center",
                  margin: "10px 0px",
                }}
              >
                {DocumentUploadFile[0].name}
              </p>
            </div>
          ) : (
            <FileUploader
              multiple={true}
              handleChange={DocumentUploadFileChange}
              name="file"
            >
              <div className="upload-file-div">
                <div className="upload-file-div-2">
                  <img
                    loading="lazy"
                    src={uploadFileBlue}
                    className="upload-file-img"
                  />
                </div>
                <div className="upload-file-div-3">
                  <div className="upload-file-div-4">Click to Upload</div>
                  <div className="upload-file-div-5">or drag and drop</div>
                </div>
              </div>
            </FileUploader>
          )}
        </div>
        <div className="SPA-modal-btns">
          <CustomBlackBtn
            text="Cancel"
            filled={false}
            onClick={handleCancelDocumentUpload}
          />
          <CustomBlackBtn text="Upload" onClick={handleDocumentUploadSubmit} />
        </div>
      </Box>
    </Modal>
  );
};

export default UploadDocumentModal;
