import React from "react";

const CardTags = ({ deal, page }) => {
  const parseAndRender = (jsonString) => {
    try {
      const parsedArray = JSON.parse(jsonString);
      return parsedArray.map((field, index) => (
        <div key={index} className="cardTags-div">
          {field}
        </div>
      ));
    } catch (error) {
      console.error("Parsing error:", error);
      return null;
    }
  };
  return (
    <div className="cardTags-row-div">
      {deal && deal.RoundType.length > 0 && parseAndRender(deal.RoundType)}
      {deal &&
        deal.IndustryVerticals.length > 0 &&
        parseAndRender(deal.IndustryVerticals)}
      {deal &&
        deal.EndMarketFocus.length > 0 &&
        parseAndRender(deal.EndMarketFocus)}
      {deal && deal.FundingType.length > 0 && parseAndRender(deal.FundingType)}
    </div>
  );
};

export default CardTags;
