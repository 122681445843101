import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const SmallAreaPlot = ({ data, compareData }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getWidth = () => {};
  // Split data into positive and negative values
  const positiveData = data?.map((val) => (val >= 0 ? val : null));
  const negativeData = data?.map((val) => (val < 0 ? val : null));
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [0.85, 0.8, 1], // Increase the width for the dashed line
      dashArray: [0, 0, 4], // Keep the dash pattern for second series
      colors: ["#05C168", "#D32F2F", "#8692A6"], // Stroke colors
    },
    grid: {
      show: false,
    },
    markers: {
      size: [0, 0, 0],
    },
    fill: {
      colors: ["#05C16809", "#D32F2F44", "#8692A6"],
      type: ["gradient", "gradient", "solid"],
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#05C16844", "#D32F2F11", "#8692A6"],
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: Math.min(data)[0],
    },
  };

  const series = [
    {
      name: "+ve Area Data",
      type: "area",
      data: positiveData,
    },
    {
      name: "-ve Area Data",
      type: "area",
      data: negativeData,
    },
    compareData?.length > 0 && {
      name: "Compare Data",
      type: "line",
      data: compareData,
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      width={windowWidth > 1480 ? 178 : 210 - windowWidth * 0.03}
      height={`95`}
    />
  );
};

export default SmallAreaPlot;
