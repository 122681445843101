import { useEffect, useState } from "react";
import {
  addStartuptoWishlist,
  removeStartupFromWishlist,
} from "../../endpoints/investor";
import InputCell from "./components/inputCell";
import folder from "../../Assets/Images/InvestorPage/Dashboard/folder.svg";
import pdfIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pdfIcon.svg";
import docxIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/docxIcon.svg";
import pptIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/pptIcon.svg";
import jpgIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/jpgIcon.svg";
import mpIcon from "../../Assets/Images/InvestorPage/Dashboard/FileIcons/mpIcon.svg";
import FileImg from "../../Assets/Images/signup/file.svg";
import { FormControl, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { streamFile } from "../../endpoints/common";
import Toast from "./Toast";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { streamDataroomFile } from "../../endpoints/dataroom";
const fetchBlobName = (url) => {
  try {
    if (url) {
      const str = decodeURIComponent(url.split("/").pop());
      const lastIndex = str.lastIndexOf("_");
      if (lastIndex === -1 || str.indexOf("_") === lastIndex) {
        return str;
      }
      const result = str.replace(/_\d{8}_\d{6}/, "");
      return result;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};
const fetchFullFileName = (url) => {
  try {
    const str = decodeURIComponent(url.split("/").pop());
    return str;
  } catch (error) {
    console.error("Error extracting file name:", error);
    return "";
  }
};

export const handleVideoStream = async (fileURL) => {
  if (fileURL) {
    const response = await streamFile(fileURL);
    const url = URL.createObjectURL(response);
    return url;
  }
};
export const handleFileDownload = async (
  fileURL,
  fileName,
  loader,
  toastContainerId,
  isDataRoomRequest,
  dealID,
  token
) => {
  loader && loader.start("Downloading file...");
  if (fileURL && !fileURL.startsWith("blob:")) {
    if (isDataRoomRequest) {
      const formData = {
        fileURL: fileURL,
        dealID: dealID,
      };
      const response = await streamDataroomFile(formData, token);
      loader && loader.stop();
      if (response.status && response.status === 403) {
        Toast(
          "You don't have access to the following file",
          "error",
          toastContainerId
        );
        return;
      }
      if (response) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(response);
        downloadLink.download = fetchFullFileName(fileURL); // Extract filename
        downloadLink.click();

        // Clean up the URL object to release memory
        window.URL.revokeObjectURL(downloadLink.href);
        return;
      } else {
        Toast(
          `Some error occurred while downloading.`,
          `info`,
          toastContainerId
        );
      }
    } else {
      const response = await streamFile(fileURL);
      loader && loader.stop();
      if (response) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(response);
        downloadLink.download = fetchFullFileName(fileURL); // Extract filename
        downloadLink.click();

        // Clean up the URL object to release memory
        window.URL.revokeObjectURL(downloadLink.href);
      } else {
        loader && loader.stop();
        Toast(
          `Some error occurred while downloading.`,
          `info`,
          toastContainerId
        );
      }
    }
  } else if (fileURL.startsWith("blob:")) {
    const downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = fileName; // Extract filename
    downloadLink.click();
    // Clean up the URL object to release memory
    window.URL.revokeObjectURL(downloadLink.href);
    loader && loader.stop();
  } else {
    loader && loader.stop();
    Toast(`Some error occurred while downloading.`, `info`, toastContainerId);
  }
};

function isValidEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return false;
  } else {
    return true;
  }
}

function isValidUrl(url) {
  const regex =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regex.test(url);
}

function isValidLinkedInUrl(url) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
  return regex.test(url);
}
const timeDiff = (givenTime, hours) => {
  const givenDate = new Date(givenTime);
  const currentDate = new Date();
  const diffInMilliseconds = Math.abs(currentDate - givenDate);
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours < hours;
};
const useZoomEffect = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (windowWidth < 1300) {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "75%";
      const plots = document.getElementsByClassName("plot");
      for (let i = 0; i < plots.length; i++) {
        plots[i].style.zoom = "133.33%";
      }
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(133.33vh - 100px)";
    } else {
      document.getElementsByClassName("InvestorSide")[0].style.zoom = "100%";
      document.getElementsByClassName("InvestorSide-box")[0].style.height =
        "calc(100vh - 100px)";
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};
//Navigating user if he/she has token
export const handleNavigation = (navigate) => {
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  if (userRole === "investor") {
    navigate("/investor/newDeals", {
      state: {
        token: token,
      },
    });
    // } else {
    //   navigate("/investorDetails", {
    //     state: {
    //       tkn: token,
    //     },
    //   });
  } else if (userRole === "company") {
    navigate("/company/dashboard", {
      state: {
        tkn: token,
      },
    });
  }
  window.scrollTo(0, 0);
};

const addDealToWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await addStartuptoWishlist(startupId, investorId, token);
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Added to wishlist", "success");
    }
  } catch (error) {}
};

const removeDealFromWishlist = async (startupId, investorId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await removeStartupFromWishlist(
      startupId,
      investorId,
      token
    );
    if (!response) {
    } else if (response.data !== null) {
      // Toast("Removed from wishlist", "success");
    }
  } catch (error) {}
};

function calculateTotalAum(investmentDetails) {
  try {
    const totalAum = JSON.parse(investmentDetails).reduce((sum, investment) => {
      const aumValue = Number(investment.aum) || 0;
      return sum + aumValue;
    }, 0);
    return totalAum;
  } catch (error) {
    console.error("Error calculating total AUM:", error);
    return 0;
  }
}

function calculateInvestments(investmentDetails) {
  const totalInvestments = investmentDetails.reduce((sum, investment) => {
    const InvestmentValue = Number(investment.investments) || 0;
    return sum + InvestmentValue;
  }, 0);
  return totalInvestments;
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
const industryVerticalsShort = {
  AT: "AdTech",
  AM: "Advanced Manufacturing",
  AG: "AgTech",
  AI: "AI/ML",
  AU: "Audiotech",
  AR: "AR/VR",
  AC: "Autonomous cars",
  BP: "B2B payments",
  BE: "Beauty",
  BD: "Big Data",
  CA: "Cannabis",
  CS: "Carsharing",
  CL: "Cleantech",
  CT: "Climate tech",
  CC: "Cloudtech",
  CO: "ConstructionTech",
  CN: "Consumer",
  BC: "Blockchain",
  CY: "Cybersecurity",
  DH: "Digital health",
  EC: "Ecommerce",
  ED: "Edtech",
  EP: "Ephemeral content",
  ES: "eSports",
  FT: "Femtech",
  FI: "Fintech",
  FD: "Foodtech",
  GA: "Gaming",
  HT: "Healthtech",
  HR: "HRtech",
  IM: "Impact investing",
  IN: "Industrials",
  IF: "Infrastructure",
  IT: "Insurtech",
  IO: "IOT",
  LT: "Legal tech",
  LS: "Life sciences",
  MF: "Manufacturing",
  MT: "Marketing tech",
  MM: "Micro-mobility",
  MB: "Mobile",
  MC: "Mobile commerce",
  MG: "Mortgage tech",
  NT: "Nanotechnology",
  OG: "Oil and gas",
  ON: "Oncology",
  PT: "Pet tech",
  PR: "PropTech",
  RT: "Restaurant tech",
  RS: "Ridesharing",
  RD: "Robotics and drones",
  SA: "SaaS",
  ST: "Space tech",
  SC: "Supply chain technology",
  TM: "TMT",
  WE: "Wearables",
  WT: "WorkTech",
  "3D": "3D printing",
};

const stages = [
  "requestSent",
  "requestAccepted",
  "ioiSubmitted",
  "ioiAccepted",
  "loiSubmitted",
  "loiAccepted",
  "spaNegotiation",
  "dealClosed",
  "watchlist",
];
const reducedStages = [
  "requestSent",
  "ioiSubmitted",
  "loiSubmitted",
  "dealClosed",
  "watchlist",
];
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #cdd7e1",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "none",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const inputCustomStylesAssestClass = {
  control: (provided) => ({
    ...provided,
    minHeight: "64px",
    width: "100%",
    fontSize: "14px",
    background: "white",
    border: "1px solid #8692a6",
    borderRadius: "6px",
    padding: "15px",
    fontFamily: '"Karla", sans-serif',
    alignItems: "base-line",
  }),
};
const cols = [
  {
    Header: "Date",
    accessor: "date",
    placeholder: "date",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Round",
    accessor: "round",
    placeholder: "round",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Raised (₹Cr)",
    accessor: "raised",
    placeholder: "raised",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "% Stake",
    accessor: "stake",
    placeholder: "stake",
    Cell: InputCell,
    width: 180,
  },
  {
    Header: "Valuation (₹Cr)",
    accessor: "valuation",
    placeholder: "valuation",
    Cell: InputCell,
    width: 180,
  },
];
const folderIcons = {
  folder: folder,
  pdf: pdfIcon,
  docx: docxIcon,
  ppt: pptIcon,
  jpg: jpgIcon,
  mp3: mpIcon,
};
const getIcon = (fileType) => {
  const defaultIcon = FileImg; // Replace with your generic icon
  return folderIcons[fileType] || defaultIcon;
};

const plotColorPallete = [
  "#D2DCFF",
  "#A8B0CC",
  "#525664",
  "#2A2C33",
  "#86A1FF",
  "#FFD2DC",
  "#98A6D4",
  "#114FEE",
  "#98DBFD",
  "#00CD9E",
  "#D3D9EE",
  "#9747FF",
  "#F4BD50",
  "#FF9933",
  "#FF855F",
  "#D32F2F",
  "#B22234",
  "#DD2590",
  "#99005C",
  "#161616",
];

function validateFolderName(folderName) {
  const prohibitedCharacters = /[<>:"\/\\|?*]/;
  const reservedNames = [
    "CON",
    "PRN",
    "AUX",
    "NUL",
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "LPT1",
    "LPT2",
    "LPT3",
    "LPT4",
    "LPT5",
    "LPT6",
    "LPT7",
    "LPT8",
    "LPT9",
  ];
  const minLength = 3;
  const maxLength = 255;
  if (!folderName) {
    return "Folder name cannot be empty.";
  }
  if (prohibitedCharacters.test(folderName)) {
    return "Folder name contains prohibited characters.";
  }
  if (reservedNames.includes(folderName.toUpperCase())) {
    return "Folder name is reserved and cannot be used.";
  }
  if (folderName.length < minLength) {
    return `Folder name must be at least ${minLength} character long.`;
  }
  if (folderName.length > maxLength) {
    return `Folder name must not exceed ${maxLength} characters.`;
  }
  return null;
}
function formatSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  }
}
function investorStepClick(stepNumber, isApplicationReviewed) {
  if (isApplicationReviewed) {
    return;
  }
  document.getElementsByClassName("first-step")[0].style.display = "none";
  document.getElementsByClassName("second-step")[0].style.display = "none";
  document.getElementsByClassName("third-step")[0].style.display = "none";
  document.getElementsByClassName("fourth-step")[0].style.display = "none";
  document.getElementsByClassName("fifth-step")[0].style.display = "none";
  document.getElementsByClassName("sixth-step")[0].style.display = "none";

  switch (stepNumber) {
    case 1:
      document.getElementsByClassName("first-step")[0].style.display = "flex";
      break;
    case 2:
      document.getElementsByClassName("second-step")[0].style.display = "flex";
      break;
    case 3:
      document.getElementsByClassName("third-step")[0].style.display = "flex";
      break;
    case 4:
      document.getElementsByClassName("fourth-step")[0].style.display = "flex";
      break;
    case 5:
      document.getElementsByClassName("fifth-step")[0].style.display = "flex";
      break;
    case 6:
      document.getElementsByClassName("sixth-step")[0].style.display = "flex";
      break;
    default:
      break;
  }
}
const StyledFormLabel = styled(FormLabel)`
  font-family: Karla;
  color: var(--Primary-Text-Color);
  font-size: 16px;
  font-weight: 600;
  ::after {
    content: " *";
    color: #d32f2f;
  }
`;
const StyledFormLabelOptional = styled(FormLabel)`
  font-family: Karla;
  color: var(--Primary-Text-Color);
  font-size: 16px;
  font-weight: 600;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  gap: 8px;
`;
const financialCriteriaOptions = [
  {
    label: "Revenue+",
    value: "Revenue+",
  },
  {
    label: "EBITDA+",
    value: "EBITDA+",
  },
];
const investmentStageOptions = [
  {
    label: "Seed",
    value: "Seed",
  },
  {
    label: "Early Stage",
    value: "Early Stage",
  },
  {
    label: "Growth",
    value: "Growth",
  },
  {
    label: "Late Stage",
    value: "Late Stage",
  },
];
const assetClassOptions = [
  {
    label: "Equity",
    value: "Equity",
  },
  {
    label: "Venture Debt",
    value: "Venture Debt",
  },
  {
    label: "iSAFE",
    value: "iSAFE",
  },
  {
    label: "CCPs",
    value: "CCPs",
  },
  {
    label: "CCDs",
    value: "CCDs",
  },
  {
    label: "Conventional Debt",
    value: "Conventional Debt",
  },
  {
    label: "Warrants",
    value: "Warrants",
  },
];

const investorTypeOptions = [
  { label: "Private Equity", value: "Private Equity" },
  { label: "Venture Capital", value: "Venture Capital" },
  {
    label: "Family Office",
    value: "Family Office",
  },
  { label: "Corporate", value: "Corporate" },
  { label: "HNI/Angel Investor", value: "HNI/Angel Investor" },
  { label: "Others", value: "Others" },
];

const industryVerticalsOptions = [
  { label: "FinTech", value: "FinTech" },
  { label: "eCommerce", value: "eCommerce" },
  { label: "CleanTech", value: "CleanTech" },
  { label: "TravelTech", value: "TravelTech" },
  { label: "DeepTech", value: "DeepTech" },
  { label: "EdTech", value: "EdTech" },
  { label: "AgriTech", value: "AgriTech" },
  { label: "Consumer", value: "Consumer" },
  { label: "RealTech", value: "RealTech" },
  { label: "HealthTech", value: "HealthTech" },
  { label: "Logistics", value: "Logistics" },
  { label: "Media", value: "Media" },
  { label: "EnterpriseTech", value: "EnterpriseTech" },
];

const endMarketFocusOptions = [
  { label: "B2B", value: "B2B" },
  { label: "B2B2C", value: "B2B2C" },
  { label: "B2C", value: "B2C" },
  { label: "D2C", value: "D2C" },
];
const stageRenameMap = {
  requestSent: "Screening",
  ioiSubmitted: "Term Sheet",
  loiSubmitted: "Due Diligence",
  dealClosed: "Close",
  watchlist: "watchlist",
};
const stageMap = {
  requestSent: "requestSent",
  requestAccepted: "requestSent",
  ioiSubmitted: "ioiSubmitted",
  ioiAccepted: "ioiSubmitted",
  loiSubmitted: "loiSubmitted",
  loiAccepted: "loiSubmitted",
  spaNegotiation: "dealClosed",
  dealClosed: "dealClosed",
  watchlist: "watchlist",
};
const toggleButtonStyles = {
  textTransform: "capitalize !important",
  color: "#000",
  height: "32px",
  border: "none",
  padding: "0px 8px",
  fontSize: "14px",
  "&.Mui-selected": {
    backgroundColor: "#fff !important",
    borderRadius: "8px !important",
    color: "#000 !important",
    border: "1px solid #AEB3C1",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#115293",
  },
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00A6FB",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));
const BorderLinearProgressBlue = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF",
    border: "2px solid #DDF3FF",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00A6FB",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));
const dealTypeOptions = [
  { value: "Equity", label: "Equity" },
  { value: "Debt", label: "Debt" },
  { value: "Equity + Debt", label: "Equity + Debt" },
];
const preferredInvestorsOptions = [
  { value: "Private Equity", label: "Private Equity" },
  { value: "Venture Capital", label: "Venture Capital" },
  { value: "Family Office", label: "Family Office" },
  { value: "Corporate", label: "Corporate" },
  { value: "HNI/Angel Investor", label: "HNI/Angel Investor" },
  { value: "Others", label: "Others" },
];

const debtTypeOptions = [
  { value: "Conventional Debt", label: "Conventional Debt" },
  { value: "Revenue Based Financing", label: "Revenue Based Financing" },
];
const roundTypeOptions = [
  { value: "Pre Seed", label: "Pre Seed" },
  { value: "Seed", label: "Seed" },
  { value: "Pre-Series A", label: "Pre-Series A" },
  { value: "Series A", label: "Series A" },
  { value: "Series B", label: "Series B" },
  { value: "Series C", label: "Series C" },
  { value: "Series D", label: "Series D" },
  { value: "Series D+", label: "Series D+" },
];
const equityTypeOptions = [
  { value: "Priced", label: "Priced" },
  { value: "Non-Priced", label: "Non-Priced" },
];
const pricedEquityOptions = [
  { value: "Equity", label: "Equity" },
  { value: "Preferred Stock", label: "Preferred Stock" },
];
const nonPricedEquityOptions = [
  { value: "Convertible Debt", label: "Convertible Debt" },
  { value: "iSAFE", label: "iSAFE" },
];
export {
  fetchBlobName,
  fetchFullFileName,
  isValidEmail,
  isValidUrl,
  isValidLinkedInUrl,
  useZoomEffect,
  timeDiff,
  addDealToWishlist,
  removeDealFromWishlist,
  calculateTotalAum,
  truncateText,
  calculateInvestments,
  investorStepClick,
  industryVerticalsShort,
  stages,
  reducedStages,
  customStyles,
  inputCustomStyles,
  cols,
  getIcon,
  validateFolderName,
  formatSize,
  plotColorPallete,
  inputCustomStylesAssestClass,
  StyledFormControl,
  StyledFormLabel,
  StyledFormLabelOptional,
  industryVerticalsOptions,
  endMarketFocusOptions,
  stageRenameMap,
  stageMap,
  toggleButtonStyles,
  BorderLinearProgress,
  BorderLinearProgressBlue,
  investorTypeOptions,
  assetClassOptions,
  investmentStageOptions,
  financialCriteriaOptions,
  dealTypeOptions,
  preferredInvestorsOptions,
  debtTypeOptions,
  roundTypeOptions,
  equityTypeOptions,
  pricedEquityOptions,
  nonPricedEquityOptions,
};
