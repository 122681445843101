import React, { useEffect, useState } from "react";
import { fetchStartupMemberDetails } from "../../../../endpoints/startup";

import { useRecoilState, useRecoilValue } from "recoil";
import { companyState, saveState } from "../companyState";

import dropdownwhite from "../../../../Assets/Images/InvestorPage/dropdownwhite.svg";
import userProfile from "../../../../Assets/Images/InvestorPage/Dashboard/userProfile.jpg";

const CornerProfileBox = ({ setModal, modal }) => {
  const token = localStorage.getItem("token");
  const [state, setState] = useRecoilState(companyState);
  const [companyProfile, setCompanyProfile] = useState("");
  const [name, setName] = useState("");
  const { memberImg } = useRecoilValue(companyState);
  const handleProfileBoxOpen = () => {
    setModal(!modal);
  };
  useEffect(() => {
    const fetchDetails = async () => {
      if (token) {
        const response = await fetchStartupMemberDetails(token);
        if (response && response.data !== null) {
          const { name, memberImg, startupId, startupMemberID } =
            response.data.data;
          const newState = {
            ...state,
            name,
            memberImg,
            startupId,
            startupMemberId: startupMemberID,
          };
          setCompanyProfile(memberImg);
          setName(name);
          setState(newState);
          saveState(newState);
        }
      }
    };

    fetchDetails();
  }, [token]);
  return (
    <div className="investor-profile-div" onClick={handleProfileBoxOpen}>
      <img
        loading="lazy"
        srcSet={
          memberImg
            ? memberImg
            : companyProfile === undefined || companyProfile === ""
            ? userProfile
            : companyProfile
        }
        className="topbarimg-6"
      />
      <div className="topbardiv-9">
        <div className="topbardiv-10">
          {/* <div className="topbardiv-11">{state.name ? state.name : name}</div> */}
          <img
            loading="lazy"
            src={dropdownwhite}
            className="topbarimg-7"
            style={{
              transform: modal ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        {/* <div className="topbardiv-12">Company</div> */}
      </div>
    </div>
  );
};

export default React.memo(CornerProfileBox);
