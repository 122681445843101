import React from "react";

import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/LandingPage/FUNDREV_LOGO.svg";

import { Helmet } from "react-helmet";
import CustomBlackBtn from "../../Common/components/custom/customBlackBtn";
const InvestorRevoked = () => {
  const navigate = useNavigate();
  return (
    <div className="investorRevoked-screen">
      <Helmet>
        <title>Investor | Invalid Invite</title>
      </Helmet>
      <img
        className="investorRevoked-fundrev-logo"
        onClick={() => navigate("/")}
        style={{ width: "100px", margin: "20px 0px 0px 20px" }}
        src={Logo}
      />
      <div className="invalid-invite-div">
        <div className="invalid-invite-div-2">
          <div className="invalid-invite-div-3">Invite Invalid</div>
          <div className="invalid-invite-div-4">
            This invite may be revoked, or you might not have the permission to
            join.
          </div>
          <CustomBlackBtn
            onClick={() => navigate("/")}
            text="Continue to Fundrev"
          />
        </div>
      </div>
    </div>
  );
};

export default InvestorRevoked;
