import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimePeriodButton from "./TimePeriodButton";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const CashFlowSmallTable = ({ data }) => {
  const [type, setType] = useState("m");
  const [option, setOption] = useState(`previous${type}`);
  const keys = Object.keys(
    data?.data[type][Object.keys(data?.data[type])[0]].values
  );

  const [key, setKey] = useState(keys.slice(-1)[0]);
  const [ckey, setCKey] = useState(null);
  useEffect(() => {
    switch (option) {
      case "previousm":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastm":
        setCKey(keys.slice(-13)[0]);
        break;
      case "previousq":
        setCKey(keys.slice(-2)[0]);
        break;
      case "lastq":
        setCKey(keys.slice(-5)[0]);
        break;
      case "previousltm":
        setCKey(keys.slice(-13)[0]);
        break;
      default:
        setCKey(keys.slice(-1)[0]);
    }
  }, [data, key, ckey, option, keys]);
  useEffect(() => {
    setKey(
      Object.keys(
        data?.data[type][Object.keys(data?.data[type])[0]].values
      ).slice(-1)[0]
    );
  }, [type, data]);
  const rows = [
    "Operating Activity",
    "Investing Activity",
    "Financing Activity",
    "Net Change in Cash",
    "Beginning Cash",
    "Ending Cash",
  ];
  const IMPRows = ["Net Change in Cash", "Ending Cash"];
  function getVariance(row, last = false) {
    let a, b;
    if (last) {
      a = row[Object.keys(row)[0]][Object.keys(row)[0]][key];
      b = row[Object.keys(row)[0]][Object.keys(row)[0]][ckey];
    } else {
      a = data?.data[type][row].values[key];
      b = data?.data[type][row].values[ckey];
    }

    // Check if either 'a' or 'b' is zero to prevent division by zero
    if (a === 0 || b === 0) {
      return 0;
    }

    return ((a / b - 1) * 100).toFixed(1);
  }

  return (
    <>
      <Box>
        <TimePeriodButton
          title={data?.name.slice(0, 5)}
          type={type}
          setType={setType}
          select={true}
          ltm={true}
          year={false}
          option={option}
          setOption={setOption}
        />
        <Paper
          sx={{
            marginTop: "16px",
            boxShadow: 0,
          }}
        >
          <TableContainer
            sx={{
              borderRadius: "4px",
            }}
          >
            <Table aria-label="cash flow summary table">
              <TableHead>
                <TableRow>
                  {["Summary", ckey, key, "%Change"].map((column, index) => (
                    <TableCell
                      sx={{
                        width: index === 0 ? "169px" : "125px",
                        paddingY: "3px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: 300,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "#B9BBBD30",
                      }}
                      key={index}
                      align={index === 0 ? "left" : "right"}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          gap: "2px",
                          alignItems: "baseline",
                          justifyContent: index !== 0 && "end",
                          color: "#696F79",
                        }}
                      >
                        {column}
                        {index === 0 && (
                          <Typography
                            sx={{
                              fontFamily: `Karla, sans-serif`,
                              fontSize: "10px",
                              fontWeight: "300",
                              color: "#696F79",
                            }}
                          >
                            (in lakhs)
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(0, 3).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "7.5px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(rows[index]) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color:
                          IMPRows.includes(rows[index]) && "var(--brand-color)",
                        fontStyle: "normal",
                        borderTop:
                          IMPRows.includes(rows[index]) &&
                          "2px solid var(--brand-color)",
                      }}
                      align="left"
                    >
                      {row}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "7.5px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(rows[index]) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color:
                          IMPRows.includes(rows[index]) && "var(--brand-color)",
                        fontStyle: "normal",
                        borderTop:
                          IMPRows.includes(rows[index]) &&
                          "2px solid var(--brand-color)",
                      }}
                      align="right"
                    >
                      {formatToLakhs(
                        data?.data[type][Object.keys(data?.data[type])[index]]
                          .values[ckey]
                      ) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "7.5px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(rows[index]) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        background: "white",
                        color:
                          IMPRows.includes(rows[index]) && "var(--brand-color)",
                        fontStyle: "normal",
                        borderTop:
                          IMPRows.includes(rows[index]) &&
                          "2px solid var(--brand-color)",
                      }}
                      align="right"
                    >
                      {formatToLakhs(
                        data?.data[type][Object.keys(data?.data[type])[index]]
                          .values[key]
                      ) || `-`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "169px",
                        paddingY: "7.5px",
                        fontFamily: "Karla, sans-serif",
                        fontWeight: IMPRows.includes(rows[index]) ? 700 : 400,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        fontStyle: "italic",
                        borderTop:
                          IMPRows.includes(rows[index]) &&
                          "2px solid var(--brand-color)",
                        color:
                          getVariance(Object.keys(data?.data[type])[index]) > 0
                            ? "#00CD9E"
                            : "#D32F2F",
                      }}
                      align="right"
                    >
                      {getVariance(Object.keys(data?.data[type])[index])
                        ? getVariance(Object.keys(data?.data[type])[index]) +
                          `%`
                        : `-`}
                    </TableCell>
                  </TableRow>
                ))}
                {data?.data[type][Object.keys(data?.data[type])[3]].mappers.map(
                  (row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "7.5px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color:
                            IMPRows.includes(rows[index + 3]) &&
                            "var(--brand-color)",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid var(--brand-color)",
                        }}
                        align="left"
                      >
                        {rows[index + 3]}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "7.5px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color:
                            IMPRows.includes(rows[index + 3]) &&
                            "var(--brand-color)",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid var(--brand-color)",
                        }}
                        align="right"
                      >
                        {formatToLakhs(
                          row[Object.keys(row)[0]][Object.keys(row)[0]][ckey]
                        ) || `-`}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "7.5px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          background: "white",
                          color:
                            IMPRows.includes(rows[index + 3]) &&
                            "var(--brand-color)",
                          fontStyle: "normal",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid var(--brand-color)",
                        }}
                        align="right"
                      >
                        {formatToLakhs(
                          row[Object.keys(row)[0]][Object.keys(row)[0]][key]
                        ) || `-`}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "169px",
                          paddingY: "7.5px",
                          fontFamily: "Karla, sans-serif",
                          fontWeight: IMPRows.includes(rows[index + 3])
                            ? 700
                            : 400,
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          fontStyle: "italic",
                          borderTop:
                            IMPRows.includes(rows[index + 3]) &&
                            "2px solid var(--brand-color)",
                          color:
                            getVariance(Object.keys(data?.data[type])[index]) >
                            0
                              ? "#00CD9E"
                              : "#D32F2F",
                        }}
                        align="right"
                      >
                        {getVariance(row, true)
                          ? getVariance(row, true) + `%`
                          : `-`}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default CashFlowSmallTable;
