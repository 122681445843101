import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { fetchInvestorMemberDetails } from "../../../endpoints/investor";
import { postqnAs } from "../../../endpoints/qnA";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import CustomInput from "../components/custom/customInput";
import { StyledFormControl, StyledFormLabel } from "../functions";
import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";

const AskQuestionModal = ({
  askQuestionModal,
  setAskQuestionModal,
  token,
  dealID,
  investorID,
  roundType,
  setNewQuestionAsked,
  type,
  fundrevAnalystID,
  startupID,
}) => {
  const [investorMemberID, setInvestorMemberID] = useState();

  useEffect(() => {
    const fetchDetails = async () => {
      if (token) {
        const response = await fetchInvestorMemberDetails(token);
        if (response && response.data !== null) {
          setInvestorMemberID(response.data.data.investorMemberID);
        }
      }
    };
    fetchDetails();
  }, [token]);

  const handleSubmitQuestion = async (values, { resetForm }) => {
    const formData =
      type === "analystQuestion"
        ? {
            question: values.question,
            askedBy: startupID,
            fundrevAnalyst: fundrevAnalystID,
          }
        : {
            question: values.question,
            askedBy: investorMemberID,
            dealID,
            investorID,
            roundType,
          };

    const response = await postqnAs(formData);
    if (response) {
      setAskQuestionModal(false);
      setNewQuestionAsked(true);
      resetForm();
    }
  };

  return (
    <Modal
      open={askQuestionModal}
      onClose={() => setAskQuestionModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box reason-box" style={{ width: "550px" }}>
        <div className="modal-content">
          <div className="modal-right-section">
            <div className="reason-box-first-div">
              <p className="modal-heading">Ask A Question:</p>
              <img
                className="reason-box-closeicon"
                src={closeIcon}
                onClick={() => setAskQuestionModal(false)}
                alt="close"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "16px",
                  top: "16px",
                }}
              />
            </div>
            <div className="reasons-box">
              <Formik
                initialValues={{ question: "" }}
                validationSchema={Yup.object({
                  question: Yup.string().required("A question is required"),
                })}
                onSubmit={handleSubmitQuestion}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form
                    className="modal-form"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "36px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="input-fields" style={{ width: "100%" }}>
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Question</StyledFormLabel>
                        <CustomInput
                          name="question"
                          value={values.question}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter your question here"
                          error={Boolean(touched.question && errors.question)}
                          helperText={touched.question && errors.question}
                        />
                      </StyledFormControl>
                    </div>
                    <div className="submit-btn-section">
                      <CustomBlackBtn type="submit" text="Post" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AskQuestionModal;
