import * as React from "react";
import { useNavigate } from "react-router-dom";
import verificationIncomplete from "../../../../Assets/Images/FounderPage/Dashboard/verificationIncomplete.svg";
function VerificationIncompleteDiv() {
  const Navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center p-6 text-base bg-white rounded-3xl shadow-[4px_4px_16px_rgba(161,161,161,0.12)] max-md:px-5 max-w-[650px]  mx-auto mt-[28px]">
      <div className="flex flex-col items-center max-md:max-w-full">
        <div className="flex flex-col items-center text-center max-md:max-w-full">
          <div className="font-medium text-zinc-900 max-md:max-w-full">
            You’re on the Waitlist! 🎉
          </div>
        </div>
        <img
          loading="lazy"
          srcSet={verificationIncomplete}
          className="object-contain w-96 h-72 max-w-full aspect-square"
          alt="verificationIncomplete"
        />
        <div
          className=" mb-2 leading-7 text-gray-500 max-md:max-w-full px-8"
          style={{
            textAlign: "center",
          }}
        >
          Thanks for joining! We're experiencing high demand and will notify you
          as soon as you’re approved to start creating deals. Hang tight—funding
          opportunities are just around the corner!
        </div>
      </div>
    </div>
  );
}

export default VerificationIncompleteDiv;
