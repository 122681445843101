import React, { useState, useEffect, useRef } from "react";
import {
  startupAddMember,
  fetchMembers,
  deleteMember,
} from "../../../../endpoints/startup";
import { getDealDetailsByDealId, updateDeal } from "../../../../endpoints/deal";
import Toast from "../../../Common/Toast";
import { useLoader } from "../../../Common/LoaderProvider";
import { isValidLinkedInUrl } from "../../../Common/functions";

import { FormControl, FormLabel, Input } from "@mui/joy";
import { MuiTelInput } from "mui-tel-input";
import { TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import { AiOutlinePlus } from "react-icons/ai";
import uploadProfile from "../../../../Assets/Images/signup/upload-profile.svg";
import Bin from "../../../../Assets/Images/signup/bin.svg";
import editImg from "../../../../Assets/Images/signup/edit-img.svg";

const FoundingTeamDetailsForm = () => {
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const elementRef = useRef(null);
  const { startupID, dealID } = location.state || {};
  const [foundingTeamProfileFile, setFoundingTeamProfileFile] = useState([]);
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [linkedinUrlError, setLinkedinUrlError] = useState([]);
  const [formData, setFormData] = useState({
    foundingMember: [
      {
        foundingTeamProfile: "",
        name: "",
        title: "",
        email: "",
        linkedin: "",
        phoneNumber: "",
        biography: "",
      },
    ],
  });
  const scrollToElement = () => {
    elementRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const fetchDetails = async () => {
    if (!token) return;
    let response = null;
    try {
      loader.start();
      response = await getDealDetailsByDealId(dealID, token);
      loader.stop();
    } catch (error) {
      loader.stop();
    }
    if (response?.data !== null) {
      const data = response.data;
      const { FoundingTeamDetails } = data;
      if (data) {
        const parsedMemberDetails =
          FoundingTeamDetails.length > 0 &&
          FoundingTeamDetails.map((detail) => JSON.parse(detail));
        setFormData({
          foundingMember: parsedMemberDetails
            ? parsedMemberDetails[0]
            : [
                {
                  foundingTeamProfile: "",
                  name: "",
                  title: "",
                  email: "",
                  linkedin: "",
                  phoneNumber: "",
                  biography: "",
                },
              ],
        });
      }
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  const handleBlur = (index) => {
    const errors = [...linkedinUrlError];
    if (!isValidLinkedInUrl(formData.foundingMember[index].linkedin)) {
      errors[index] = "Please enter a valid LinkedIn URL.";
    } else {
      errors[index] = "";
    }
    setLinkedinUrlError(errors);
  };
  const fetchMembersData = async () => {
    try {
      const response = await fetchMembers(startupID, token);

      if (response && response.data) {
        const membersData = response.data.data; // Adjust according to the actual structure of the response
        setInvitedMembers(membersData);
      }
    } catch (error) {
      console.error("Failed to fetch members:", error);
      // Optionally, handle errors by setting some state or showing a message
    }
  };
  const handleAddClick = async () => {
    await addFoundingMember();
    await scrollToElement();
  };
  const addFoundingMember = () => {
    const requiredFields = [
      "name",
      "title",
      "email",
      // "linkedin",
      "phoneNumber",
      "biography",
    ];
    const isAllFieldsFilled = (member) => {
      return requiredFields.every(
        (field) => member[field] && member[field].trim() !== ""
      );
    };

    setFormData((prevState) => {
      const unfilledMembers = prevState.foundingMember.filter(
        (member) => !isAllFieldsFilled(member)
      );
      if (unfilledMembers.length > 0) {
        // You can display an error message here if needed
        console.error(
          "Please fill out all required fields for existing members before adding a new one."
        );
        Toast(
          "Please fill out all required fields for existing members before adding a new one.",
          "error",
          "foundingTeamDetailsForm"
        );
        return prevState; // Do not add a new member
      }

      // All previous members have their details filled, add a new member
      return {
        ...prevState,
        foundingMember: [
          ...prevState.foundingMember,
          {
            name: "",
            title: "",
            email: "",
            linkedin: "",
            phoneNumber: "",
            biography: "",
            foundingTeamProfile: "",
          },
        ],
      };
    });
    scrollToElement();
  };
  function handleFounderImageUpload(index, e) {
    const file = e.target.files[0];
    const newFoundingMembers = [...formData.foundingMember];
    const fileURL = URL.createObjectURL(file);
    newFoundingMembers[index].foundingTeamProfile = file;

    const newFoundingTeamProfileFile = [...foundingTeamProfileFile];
    newFoundingTeamProfileFile[index] = fileURL;
    setFoundingTeamProfileFile(newFoundingTeamProfileFile);
    setFormData((prevState) => ({
      ...prevState,
      foundingMember: newFoundingMembers,
    }));
  }
  const handlePhoneChange = (index, phoneNumber) => {
    const newFoundingMembers = [...formData.foundingMember];
    newFoundingMembers[index].phoneNumber = phoneNumber;
    // setFoundingMembers(newFoundingMembers);
    setFormData({
      ...formData,
      foundingMember: newFoundingMembers,
    });
  };

  const postFormData = async (data) => {
    const formDataInput = new FormData();
    const changedIndices = [];
    // Loop through each key in the data object and append it to FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          // Check if the property is an array or an object that needs to be stringified
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            if (key === "foundingMember") {
              data[key].map((item, index) => {
                if (item.foundingTeamProfile instanceof File) {
                  formDataInput.append(
                    "foundingTeamProfile",
                    item.foundingTeamProfile
                  );
                  changedIndices.push(index);
                }
              });
              formDataInput.append(key, JSON.stringify(data[key]));
            } else {
              formDataInput.append(key, JSON.stringify(data[key]));
            }
          } else {
            formDataInput.append(key, data[key]);
          }
        }
      }
    }
    if (changedIndices.length > 0) {
      formDataInput.append("changedIndices", JSON.stringify(changedIndices));
    }
    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }
    try {
      loader.start();
      formDataInput.append("DealID", dealID);
      const response = await updateDeal(formDataInput, token);
      if (response.data) {
        Toast(
          "Founding team details successfully updated",
          "success",
          "foundingTeamDetailsForm"
        );
      }
      loader.stop();
      if (response) {
      }
    } catch (error) {
      Toast("Error updating team details", "error", "foundingTeamDetailsForm");
      loader.stop();
      setUploading(false);
    }
  };
  const handleInputChangeStep2 = (index, event) => {
    const { name, value } = event.target;
    if (name === "linkedin") {
      const errors = [...linkedinUrlError];
      if (!isValidLinkedInUrl(value)) {
        errors[index] = "Please enter a valid LinkedIn URL.";
      } else {
        errors[index] = "";
      }
      setLinkedinUrlError(errors);
    }
    const newFoundingMembers = [...formData.foundingMember];
    newFoundingMembers[index][name] = value;

    setFormData({ ...formData, foundingMember: newFoundingMembers });
  };
  const deleteFounderMember = async (index, email) => {
    const newFoundingMembers = [...formData.foundingMember];
    newFoundingMembers.splice(index, 1);

    setFormData({
      ...formData,
      foundingMember: newFoundingMembers,
    });

    // const newProfileImage = [...foundingTeamProfileFile];
    // newProfileImage[index] = "";
    //   setFoundingTeamProfileFile(newProfileImage);

    const member = invitedMembers.find((member) => member.email === email);

    try {
      const response = await deleteMember(member._id, member.startupId, token);
      if (response) {
        Toast("Member deleted successfully", "info", "foundingTeamDetailsForm");
      } else {
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };
  const handleSave = async (currentStepData) => {
    try {
      loader.start();
      await postFormData(currentStepData);
      loader.stop();
    } catch (error) {
      loader.stop();
      console.error("Failed to save data:", error);
      Toast(
        "Failed to save data, please try again.",
        "error",
        "companyOnboarding"
      ); // User-friendly error message
    }
  };
  return (
    <>
      <div className="investorDetails foundingTeamDetailsForm">
        <ToastContainer
          position="top-center"
          containerId="foundingTeamDetailsForm"
        />
        <div className="details-section">
          <div className="details-section-div">
            <div className="investorDetails-first-section">
              <div className="add-member-div">
                <p className="investorDetails-heading">Founding Team</p>
                <button onClick={handleAddClick} className="add-row">
                  <AiOutlinePlus />
                  &nbsp;&nbsp; Add Member
                </button>
              </div>
              {formData.foundingMember?.map((member, index) => (
                <div
                  key={index}
                  className="member-form"
                  style={{
                    borderBottom:
                      index === formData.foundingMember.length - 1
                        ? "0px"
                        : "1px dashed #8692a6",
                  }}
                >
                  <div className="founding-member">
                    <div className="upload-btn-wrapper">
                      <button className="upload-img-btn">
                        <img
                          src={
                            !foundingTeamProfileFile[index]
                              ? member.foundingTeamProfile
                                ? member.foundingTeamProfile
                                : uploadProfile
                              : foundingTeamProfileFile[index]
                          }
                          className="InvestorLogo"
                          alt="Upload"
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                      {/* </div> */}

                      {member.foundingTeamProfile && (
                        <label
                          htmlFor={`founding-profile-${index}`}
                          className="edit-img-btn"
                        >
                          <img src={editImg} className="editImg" alt="Edit" />
                        </label>
                      )}
                      <input
                        id={`founding-profile-${index}`}
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg" // Accept only JPEG and PNG
                        onChange={(e) => handleFounderImageUpload(index, e)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {index > 0 && (
                      <div className="member-actions">
                        <button
                          className="delete-row"
                          onClick={() =>
                            deleteFounderMember(index, member.email)
                          }
                        >
                          <img src={Bin} />
                          &nbsp;&nbsp; Delete
                        </button>
                      </div>
                    )}
                  </div>
                  <form className="input-section">
                    <div className="input-div">
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Full Name</FormLabel>
                        <Input
                          className="input"
                          placeholder="Full Name"
                          color="neutral"
                          name="name"
                          type="text"
                          value={member.name}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Title</FormLabel>
                        <Input
                          className="input"
                          placeholder="Title"
                          color="neutral"
                          name="title"
                          type="text"
                          value={member.title}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">
                          Official Email Address
                        </FormLabel>
                        <Input
                          className="input"
                          placeholder="Email"
                          color="neutral"
                          name="email"
                          type="email"
                          value={member.email}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          required
                        />
                      </FormControl>
                    </div>
                    <div className="input-div">
                      <FormControl
                        className="form-control investorDetails-row-input"
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">
                          LinkedIn Profile
                        </FormLabel>
                        <Input
                          className="input"
                          placeholder="LinkedIn Profile"
                          color="neutral"
                          name="linkedin"
                          type="url"
                          value={member.linkedin}
                          onChange={(e) => {
                            handleInputChangeStep2(index, e);
                          }}
                          onBlur={() => handleBlur(index)}
                        />
                        {linkedinUrlError[index] && (
                          <div style={{ color: "red" }}>
                            {linkedinUrlError[index]}
                          </div>
                        )}
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input"
                        required
                        size="lg"
                        color="primary"
                      >
                        <FormLabel className="label">Phone Number</FormLabel>
                        <MuiTelInput
                          defaultCountry="IN"
                          className="input"
                          name="phoneNumber"
                          value={member.phoneNumber}
                          onChange={(phoneNumber) =>
                            handlePhoneChange(index, phoneNumber)
                          }
                          required
                        />
                      </FormControl>
                      <FormControl
                        className="form-control investorDetails-row-input random-input"
                        style={{ visibility: "hidden" }}
                      >
                        <FormLabel className="label">Biography</FormLabel>
                        <Input className="input" />
                      </FormControl>
                    </div>
                    <div className="input-div companyDetails-business-desc-div pof-div">
                      <FormControl
                        className="business-desc-form-control"
                        id="Id"
                        size="lg"
                        color="primary"
                        required
                      >
                        <FormLabel className="label">Biography</FormLabel>
                        <TextField
                          className="large-input"
                          placeholder="Your biography"
                          name="biography"
                          type="text"
                          value={member.biography}
                          onChange={(e) => handleInputChangeStep2(index, e)}
                          multiline
                          minRows={3}
                        />
                      </FormControl>
                    </div>
                  </form>
                </div>
              ))}
              <div ref={elementRef}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-buttons foundingTeamDetails-nav-btns">
        <button className="previousStep" style={{ visibility: "hidden" }}>
          &nbsp;&nbsp; Previous Step
        </button>
        <div className="next-buttons">
          <button
            className="save-button"
            onClick={() => handleSave(formData, "second-step")}
            style={{ cursor: "pointer" }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default FoundingTeamDetailsForm;
