import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CustomRedBtn from "../components/custom/customRedBtn";
import CustomBlackBtn from "../components/custom/customBlackBtn";
const DeleteAccountModal = ({
  deleteAccountModalOpen,
  setDeleteAccountModalOpen,
  deleteFounderMember,
  email,
  revoke,
}) => {
  return (
    <Dialog
      open={deleteAccountModalOpen}
      onClose={() => setDeleteAccountModalOpen(false)}
      PaperProps={{
        component: "form",
        sx: {
          width: "700px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle className="dialog-title">
        Are you sure you want to revoke your account?
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setDeleteAccountModalOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="dialogcontent dialogcontent-forget-password">
          <DialogContentText className="dialogcontenttext signIn-dialogcontenttext">
            <p className="dialog-box-enter-email-text">
              Are you sure you want to revoke your account. This action cannot
              be reversed.
            </p>
            <div className="flex w-full justify-center mt-4 gap-2">
              <div onClick={() => setDeleteAccountModalOpen(false)}>
                <CustomRedBtn type="button" text="cancel" />
              </div>
              <div onClick={() => deleteFounderMember(email)}>
                <CustomBlackBtn type="button" text="Revoke" />
              </div>
            </div>
          </DialogContentText>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAccountModal;
