import React, { useEffect, useState } from "react";
import { StyledFormControl, StyledFormLabel } from "../../../Common/functions";
import CustomInput from "../../../Common/components/custom/customInput";
import CustomSelect from "../../../Common/components/custom/customSelect";
import CustomBlackBtn from "../../../Common/components/custom/customBlackBtn";

import { isProfileComplete } from "./profileCompletion";

import {
  industryVerticalsOptions,
  endMarketFocusOptions,
} from "../../../Common/functions";
import { companyState } from "../companyState";

import {
  fetchStartupDetails,
  getProfileCompletionPercentage,
  PostDetails,
} from "../../../../endpoints/startup";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../../Common/LoaderProvider";
import Toast from "../../../Common/Toast";

import uploadImg from "../../../../Assets/Images/signup/upload-img.svg";
import { useRecoilState } from "recoil";

const EditCompanyDetails = () => {
  const token = localStorage.getItem("token");
  const loader = useLoader();
  const [state, setState] = useRecoilState(companyState);
  const [startupID, setStartupID] = useState("");
  const [companyLogoPreview, setCompanyLogoPreview] = useState(uploadImg);
  const [foundingMember, setFoundingMember] = useState([]);
  const [initialValues, setInitialValues] = useState({
    companyName: "",
    legalEntityName: "",
    headquarters: "",
    foundedYear: "",
    numEmployees: "",
    website: "",
    industryVerticals: [],
    cin: "",
    taxNumber: "",
    endMarket: [],
    erpPlatform: "",
    companyLogo: null,
    isApproved: false,
  });

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    legalEntityName: Yup.string().required("Legal Entity Name is required"),
    headquarters: Yup.string().required("Headquarters is required"),
    foundedYear: Yup.number()
      .required("Founded Year is required")
      .min(1800, "Year must be after 1800")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
    numEmployees: Yup.number()
      .required("Number of Employees is required")
      .min(1, "Must have at least 1 employee"),
    website: Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
        "Please enter a valid URL"
      )
      .required("Company Website is required"),
    industryVerticals: Yup.array().required("Industry Verticals is required"),
    cin: Yup.string().required("CIN/LLPIN is required"),
    taxNumber: Yup.string().required("Tax Identification Number is required"),
    endMarket: Yup.array().required("End Market Focus is required"),
    erpPlatform: Yup.string().required("ERP Platform Used is required"),
    companyLogo: Yup.mixed().required("Company Logo is required"),
  });

  const fetchDetails = async () => {
    if (!token) return;
    try {
      loader.start();
      const response = await fetchStartupDetails(token);
      loader.stop();
      if (response?.data !== null) {
        const data = response.data.data;

        setInitialValues({
          companyName: data.companyName || "",
          legalEntityName: data.legalEntityName || "",
          headquarters: data.headquarters || "",
          foundedYear: data.foundedYear || "",
          numEmployees: data.numEmployees || "",
          website: data.website || "",
          industryVerticals: (() => {
            try {
              return JSON.parse(data?.industryVerticals);
            } catch (error) {
              return [];
            }
          })(),

          endMarket: (() => {
            try {
              return JSON.parse(data?.endMarket);
            } catch (error) {
              return [];
            }
          })(),

          cin: data.cin || "",
          taxNumber: data.taxNumber || "",
          erpPlatform: data.erpPlatform || "",
          companyLogo: data.companyLogo || null, // Ensure this matches the type required
          isApproved: data.isApproved || false,
        });

        setFoundingMember(data.foundingMember);
        const foundingMembersJSON = data.foundingMember || "[]";
        isProfileComplete(data, foundingMembersJSON);

        // If companyLogo is present, set it as the preview
        if (data.companyLogo) {
          setCompanyLogoPreview(data.companyLogo);
        }
        setStartupID(data.startupID);
      }
    } catch (error) {
      loader.stop();
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [token]);
  const postFormData = async (data) => {
    //Checking if founding team details are filled or not.
    const foundingMembersJSON = foundingMember || "[]";
    data.profileCompleted = isProfileComplete(data, foundingMembersJSON);
    const formDataInput = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (typeof data[key] === "object" && !(data[key] instanceof File)) {
            formDataInput.append(key, JSON.stringify(data[key]));
          } else {
            formDataInput.append(key, data[key]);
          }
          //This is for if the user want to remove those details
        } else {
          formDataInput.append(key, data[key]);
        }
      }
    }
    if (!data.startupID) {
      formDataInput.append("startupID", startupID);
    }
    try {
      loader.start();
      const response = await PostDetails(formDataInput, token);
      loader.stop();
      if (response) {
        //Parent toast container
        Toast("Details saved successfully", "success", "editCompanyProfile");
      }
      profileCompletionPercentage();
    } catch (error) {
      Toast(
        "Error is saving details. Try again later",
        "error",
        "editCompanyProfile"
      );
      loader.stop();
    }
  };
  const profileCompletionPercentage = async () => {
    try {
      const response = await getProfileCompletionPercentage(token);
      const percent = response.data.percentage;

      const newState = {
        ...state,
        percentage: percent,
      };
      setState(newState);
    } catch (error) {}
  };
  return (
    <div className="editCompanyDetails fundrev-card">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={postFormData}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => (
          <Form className="editCompanyDetails-form">
            <div className="editCompanyDetails-form-upload-btn-wrapper">
              <button
                type="button"
                className="upload-img-btn"
                onClick={() => document.getElementById("companyLogo").click()}
              >
                <img
                  src={companyLogoPreview}
                  className="InvestorLogo"
                  alt="UploadImage"
                  style={{ cursor: "pointer" }}
                />
              </button>

              {/* Conditionally show the edit button if companyLogoPreview is not the default image */}
              {/* {companyLogoPreview !== uploadImg && (
                <label htmlFor="companyLogo" className="edit-img-btn">
                  <img src={editImg} className="editImg" alt="Edit" />
                </label>
              )} */}

              <input
                id="companyLogo"
                type="file"
                name="companyLogo"
                accept="image/png, image/jpeg" // Accept only JPEG and PNG
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFieldValue("companyLogo", file); // Set file in Formik state
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setCompanyLogoPreview(reader.result); // Set preview image URL
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                style={{ display: "none" }}
              />
              {touched.companyLogo && errors.companyLogo && (
                <div className="error text-[#d32f2f] text-xs ">
                  {errors.companyLogo}
                </div>
              )}
            </div>
            <div className="company-details-input-fields">
              {/* Company Name */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Company Name</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </StyledFormControl>

              {/* Legal Entity Name */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Legal Entity Name</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Legal Entity Name"
                  name="legalEntityName"
                  value={values.legalEntityName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.legalEntityName && errors.legalEntityName
                  )}
                  helperText={touched.legalEntityName && errors.legalEntityName}
                />
              </StyledFormControl>

              {/* Headquarters */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Headquarters</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Headquarters"
                  name="headquarters"
                  value={values.headquarters}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.headquarters && errors.headquarters)}
                  helperText={touched.headquarters && errors.headquarters}
                />
              </StyledFormControl>

              {/* Founded Year */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Founded Year</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Founded Year"
                  name="foundedYear"
                  type="number"
                  value={values.foundedYear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.foundedYear && errors.foundedYear)}
                  helperText={touched.foundedYear && errors.foundedYear}
                />
              </StyledFormControl>

              {/* No. of Employees */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>No. of Employees</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Number of Employees"
                  name="numEmployees"
                  type="number"
                  value={values.numEmployees}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.numEmployees && errors.numEmployees)}
                  helperText={touched.numEmployees && errors.numEmployees}
                />
              </StyledFormControl>

              {/* Company Website */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Company Website</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Company Website"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
              </StyledFormControl>

              {/* Industry Verticals */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Industry Verticals</StyledFormLabel>
                <CustomSelect
                  label="Industry Verticals"
                  name="industryVerticals"
                  multiple={false}
                  singleAsArray={true}
                  value={values.industryVerticals}
                  onChange={handleChange}
                  options={industryVerticalsOptions}
                  placeholder="Select Industry Verticals"
                  error={Boolean(
                    touched.industryVerticals && errors.industryVerticals
                  )}
                  helperText={
                    touched.industryVerticals && errors.industryVerticals
                  }
                />
              </StyledFormControl>

              {/* CIN/LLPIN */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>CIN/LLPIN</StyledFormLabel>
                <CustomInput
                  placeholder="Enter CIN/LLPIN"
                  name="cin"
                  value={values.cin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.cin && errors.cin)}
                  helperText={touched.cin && errors.cin}
                />
              </StyledFormControl>

              {/* Tax Identification Number */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>Tax Identification Number</StyledFormLabel>
                <CustomInput
                  placeholder="Enter Tax Identification Number"
                  name="taxNumber"
                  value={values.taxNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.taxNumber && errors.taxNumber)}
                  helperText={touched.taxNumber && errors.taxNumber}
                />
              </StyledFormControl>

              {/* End Market Focus */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>End Market Focus</StyledFormLabel>
                <CustomSelect
                  label="End Market Focus"
                  name="endMarket"
                  multiple={true}
                  value={values.endMarket}
                  onChange={handleChange}
                  options={endMarketFocusOptions}
                  placeholder="Select End Market Focus"
                  error={Boolean(touched.endMarket && errors.endMarket)}
                  helperText={touched.endMarket && errors.endMarket}
                />
              </StyledFormControl>

              {/* ERP Platform Used */}
              <StyledFormControl fullWidth>
                <StyledFormLabel>ERP Platform Used</StyledFormLabel>
                <CustomInput
                  placeholder="Enter ERP Platform Used"
                  name="erpPlatform"
                  value={values.erpPlatform}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.erpPlatform && errors.erpPlatform)}
                  helperText={touched.erpPlatform && errors.erpPlatform}
                />
              </StyledFormControl>
            </div>
            <div className="editCompanyDetails-submitBtn-section">
              <CustomBlackBtn type="submit" text="Save" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCompanyDetails;
