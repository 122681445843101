import React, { useState } from "react";

import CustomInput from "../components/custom/customInput";
import CustomSelect from "../components/custom/customSelect";
import CustomDateInput from "../components/custom/customDateInput";
import CustomUploadInput from "../components/custom/customUploadInput";
import CustomBlackBtn from "../components/custom/customBlackBtn";
import { useLoader } from "../LoaderProvider";
import Toast from "../Toast";

import { postStageData } from "../../../endpoints/deal";

import { Modal } from "@mui/material";
import { Box } from "@mui/joy";
import { StyledFormControl, StyledFormLabel } from "../functions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import closeIcon from "../../../Assets/Images/InvestorPage/Dashboard/closeIcon.svg";
import { ToastContainer } from "react-toastify";

const calculateStakeAcquired = (investmentAmount, postMoneyValuation) => {
  if (!investmentAmount || !postMoneyValuation) return 0;
  return ((investmentAmount / postMoneyValuation) * 100).toFixed(2);
};

const LOIBidModal = ({
  LOIBidModalOpen,
  setLOIBidModalOpen,
  deal,
  investorID,
  sentBy,
  setStage,
  page,
  getDealInterestByStage,
}) => {
  const navigate = useNavigate();
  const loader = useLoader();
  const token = localStorage.getItem("token");
  const [percentage, setPercentage] = useState(0);
  const [stakeAcquired, setStakeAcquired] = useState(0);

  const calculatePercentage = (value, total) => {
    setPercentage(((value / total) * 100).toFixed(2));
  };
  const validationSchema = Yup.object({
    investmentAmount: Yup.number()
      .required("Amount of Investment is required")
      .positive("Amount of Investment must be a positive number")
      .max(
        deal?.FundingRequired,
        `Investment amount cannot be greater than ${deal?.FundingRequired} Cr`
      ),

    postMoneyValuation: Yup.number()
      .required("Valuation Amount is required")
      .positive("Valuation Amount must be a positive number")
      .min(
        deal?.MinPostMoneyValuation,
        `Valuation amount must be greater than ${deal?.MinPostMoneyValuation}`
      ),

    investmentType: Yup.string().required("Investor Type is required"),

    valuationConsiderations: Yup.string()
      .required("Valuation Considerations are required")
      .max(500, "Valuation Considerations must be 500 characters or less"),

    transactionCompletionDate: Yup.date()
      .required("Expected Transaction Complete Date is required")
      .nullable(),

    paymentOptions: Yup.string().required("Payment Options are required"),

    closingConditions: Yup.string().required("Closing Conditions are required"),

    LOILetter: Yup.mixed().required("LOI Letter is required"),
  });

  const InvestmentTypeOptions = [
    { value: "Co-Invest", label: "Co-Invest" },
    { value: "Lead Round", label: "Lead Round" },
    { value: "Sole Investor", label: "Sole Investor" },
  ];

  const handleLOISubmit = async (values) => {
    const formData = new FormData();
    formData.append("DealID", deal.DealID);
    formData.append("investorID", investorID);
    formData.append("IsInterested", true);
    formData.append("Stage", "loiSubmitted");
    formData.append("sentBy", sentBy);

    // Append LOI_Bid_Details as individual fields
    Object.keys(values).forEach((key) => {
      if (key === "LOILetter" && values[key] === null) {
        formData.append(`LOI_Bid_Details[${key}]`, "");
      } else if (key !== "LOILetter") {
        formData.append(`LOI_Bid_Details[${key}]`, values[key]);
      }
    });

    // Append LOI_Letter file
    if (values.LOILetter) {
      formData.append("LOILetter", values.LOILetter);
    }

    try {
      loader.start("Submitting Due Diligence...");
      const response = await postStageData(formData, token);
      loader.stop();
      if (response.data !== null) {
        setStage("loiSubmitted");
      }
      setLOIBidModalOpen(false);
      if (page === "companyDetailsPage") {
        navigate("/investor/dueDiligence", {
          state: {
            stage: "loiSubmitted",
            dealId: deal?.DealID,
            companyName: deal?.CompanyName,
            fundingRequired: deal?.FundingRequired,
            minPostMoneyValuation: deal?.MinPostMoneyValuation,
            deal: deal,
          },
        });
      } else {
        setLOIBidModalOpen(false);
        await getDealInterestByStage(
          deal.DealID,
          investorID,
          "loiSubmitted",
          token
        );
      }
    } catch (error) {
      console.log(error);
      Toast(
        "Error in submitting the Due Diligence. Please try again later",
        "error",
        "LOIBidSubmission"
      );
      console.log(error);
      loader.stop();
    }
  };

  return (
    <>
      <ToastContainer position="top-center" containerId="LOIBidSubmission" />
      <Modal
        open={LOIBidModalOpen}
        onClose={() => setLOIBidModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box" style={{ width: "750px" }}>
          <div className="IOI-bid-modal-heading-section">
            <p>Due Diligence</p>
            <img
              src={closeIcon}
              alt="close"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setLOIBidModalOpen(false)}
            />
          </div>
          <Formik
            initialValues={{
              investmentAmount: "",
              postMoneyValuation: "",
              investmentType: "",
              valuationConsiderations: "",
              transactionCompletionDate: null,
              paymentOptions: "",
              closingConditions: "",
              LOILetter: null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleLOISubmit}
          >
            {({
              errors,
              touched,
              handleChange: formikHandleChange,
              handleBlur,
              setFieldValue,
              values,
            }) => {
              const handleChange = (e) => {
                formikHandleChange(e);
                if (e.target.name === "postMoneyValuation") {
                  setStakeAcquired(
                    calculateStakeAcquired(
                      values.investmentAmount,
                      e.target.value
                    )
                  );
                }
                if (e.target.name === "investmentAmount") {
                  setStakeAcquired(
                    calculateStakeAcquired(
                      e.target.value,
                      values.postMoneyValuation
                    )
                  );
                  calculatePercentage(e.target.value, deal?.FundingRequired);
                }
              };

              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    width: "100%",
                  }}
                >
                  <div className="IOI-input-fields">
                    <div className="IOI-first-section">
                      <StyledFormControl fullWidth>
                        <StyledFormLabel>
                          Amount of Investment (₹Cr)
                        </StyledFormLabel>
                        <CustomInput
                          placeholder="Enter amount"
                          name="investmentAmount"
                          type="number"
                          value={values.investmentAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.investmentAmount && errors.investmentAmount
                          )}
                          helperText={
                            touched.investmentAmount && errors.investmentAmount
                          }
                          endDecorator={
                            <p className="ioi-endDecorator">
                              {`${percentage} % of current round`}
                            </p>
                          }
                        />
                        <p className="helper-text-input">
                          Current round: {deal?.FundingRequired}Cr
                        </p>
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>
                          Valuation Amount (₹Cr)
                        </StyledFormLabel>
                        <CustomInput
                          placeholder="Enter valuation amount"
                          name="postMoneyValuation"
                          type="number"
                          value={values.postMoneyValuation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.postMoneyValuation &&
                              errors.postMoneyValuation
                          )}
                          helperText={
                            touched.postMoneyValuation &&
                            errors.postMoneyValuation
                          }
                          endDecorator={
                            <p className="ioi-endDecorator">
                              {`min. post money valuation : ${deal?.MinPostMoneyValuation} Cr`}
                            </p>
                          }
                        />
                        <p className="helper-text-input">
                          Stake Acquired : {stakeAcquired}%
                        </p>
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Investor Type</StyledFormLabel>
                        <CustomSelect
                          label="Investor Type"
                          name="investmentType"
                          value={values.investmentType}
                          onChange={handleChange}
                          options={InvestmentTypeOptions}
                          placeholder="Select Investor Type"
                          error={Boolean(
                            touched.investmentType && errors.investmentType
                          )}
                          helperText={
                            touched.investmentType && errors.investmentType
                          }
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>
                          Valuation Considerations
                        </StyledFormLabel>
                        <CustomInput
                          placeholder="Enter Valuation Considerations"
                          name="valuationConsiderations"
                          type="text"
                          value={values.valuationConsiderations}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.valuationConsiderations &&
                              errors.valuationConsiderations
                          )}
                          helperText={
                            touched.valuationConsiderations &&
                            errors.valuationConsiderations
                          }
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>
                          Expected Transaction Complete Date
                        </StyledFormLabel>
                        <CustomDateInput
                          label="Select date"
                          value={values.transactionCompletionDate}
                          onChange={(newValue) =>
                            setFieldValue("transactionCompletionDate", newValue)
                          }
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.transactionCompletionDate &&
                              errors.transactionCompletionDate
                          )}
                          helperText={
                            touched.transactionCompletionDate &&
                            errors.transactionCompletionDate
                          }
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Payment Options</StyledFormLabel>
                        <CustomInput
                          placeholder="Enter Payment Options"
                          name="paymentOptions"
                          type="text"
                          value={values.paymentOptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.paymentOptions && errors.paymentOptions
                          )}
                          helperText={
                            touched.paymentOptions && errors.paymentOptions
                          }
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Closing Conditions</StyledFormLabel>
                        <CustomInput
                          placeholder="Enter Closing Conditions"
                          name="closingConditions"
                          type="text"
                          value={values.closingConditions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.closingConditions &&
                              errors.closingConditions
                          )}
                          helperText={
                            touched.closingConditions &&
                            errors.closingConditions
                          }
                        />
                      </StyledFormControl>

                      <StyledFormControl fullWidth>
                        <StyledFormLabel>Attach LOI Letter</StyledFormLabel>
                        <CustomUploadInput
                          onFileUpload={(file) => {
                            setFieldValue("LOILetter", file);
                          }}
                        />
                        {touched.LOILetter && errors.LOILetter && (
                          <p
                            color="error"
                            variant="body2"
                            style={{
                              color: "#d32f2f",
                              fontSize: "12px",
                              margin: "0px",
                            }}
                          >
                            {errors.LOILetter}
                          </p>
                        )}
                      </StyledFormControl>
                    </div>
                  </div>

                  <div className="sign-in-btn-section">
                    <CustomBlackBtn type="submit" text="Submit" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default LOIBidModal;
