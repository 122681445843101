import React, { useEffect, useState } from "react";
import { getLoginLogs } from "../../endpoints/admin";

const LoginLogs = () => {
  const [data, setData] = useState(null);
  const token = localStorage.getItem("token");
  const getLogs = async () => {
    if (token) {
      const response = await getLoginLogs(token);
      setData(response?.data);
    }
  };

  useEffect(() => {
    getLogs();
  }, []);
  return (
    <>
      {data && (
        <div className="InvestorSide-box table-container">
          <table aria-label="Investor Toolkit Leads Table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.id}</td>
                    <td>{row?.name}</td>
                    <td>{row?.firmName}</td>
                    <td>{row?.email}</td>
                    <td>{new Date(row?.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default LoginLogs;
