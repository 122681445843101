import React, { useEffect, useState } from "react";
import {
  getAllDealInteractions,
  dealInterestByStage,
  getCoInvestorData,
} from "../../../../endpoints/deal";
import { investorState } from "../investorState";
import { getqnAs } from "../../../../endpoints/qnA";

import TimelineComponent from "../../../Common/components/timeline";

import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Helmet } from "react-helmet";

import noQuestions from "../../../../Assets/Images/FounderPage/Dashboard/noQuestions.svg";
import CoInvestorTable from "../Components/CoInvestorTable";
import { useLoader } from "../../../Common/LoaderProvider";

import "../../../../CSS/InvestorPage/Dashboard/activity.css";
import DocumentFile from "../../../Common/components/documentFile";

const Activity = ({ deal, newQuestionAsked, setNewQuestionAsked }) => {
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const loader = useLoader();
  const { investorId } = useRecoilValue(investorState);
  const [interactions, setInteractions] = useState([]);
  const companyName = deal ? deal?.CompanyName : "";
  const [IoiBid, setIoiBid] = useState([]);
  const [LoiAgreement, setLoiAgreement] = useState([]);
  const [SPA, setSPA] = useState([]);
  const [qnAs, setqnAs] = useState([]);
  const [latestStage, setLatestStage] = useState("");
  const [coInvestorData, setCoInvestorData] = useState(null);

  useEffect(() => {
    const fetchCoInvestorData = async () => {
      try {
        const response = await getCoInvestorData({
          dealId: deal?.DealID,
          investorId: investorId,
          token: token,
        });
        setCoInvestorData(response.data);
      } catch (error) {
        loader.stop();
      }
    };
    if (investorId) fetchCoInvestorData();
  }, [investorId]);

  useEffect(() => {}, [coInvestorData]);

  useEffect(() => {
    const fetchAllInteractions = async () => {
      try {
        const response = await getAllDealInteractions(
          deal?.DealID,
          investorId,
          token
        );
        setLatestStage(response.data[response.data.length - 1].Stage);
        setInteractions(response.data);
        loader.stop();
      } catch (error) {
        loader.stop();
      }
    };
    if (deal && deal?.DealID && investorId && token) {
      loader.start("Fetching activity");
      fetchAllInteractions();
    }
  }, [investorId]);

  // Define the stages and their order
  const stages = [
    { label: "Deal Published", dateKey: "initialStage", index: 0 },
    { label: "Request Sent", dateKey: "requestSent", index: 1 },
    { label: "Request Accepted", dateKey: "requestAccepted", index: 2 },
    { label: "IOI Submitted", dateKey: "ioiSubmitted", index: 3 },
    { label: "IOI Accepted", dateKey: "ioiAccepted", index: 4 },
    { label: "LOI Submitted", dateKey: "loiSubmitted", index: 5 },
    { label: "LOI Accepted", dateKey: "loiAccepted", index: 6 },
    { label: "SPA Negotiation", dateKey: "spaNegotiation", index: 7 },
    { label: "Deal Closed", dateKey: "dealClosed", index: 8 },
  ];

  const stageRoutes = {
    "IOI Accepted": {
      path: "/investor/TermSheet",
      state: {
        stage: "ioiSubmitted",
        fundingRequired: deal ? deal?.FundingRequired : "",
        companyName: deal ? deal?.CompanyName : "",
        dealId: deal ? deal?.DealID : "",
      },
    },
    "LOI Accepted": {
      path: "/investor/dueDiligence",
      state: {
        stage: "loiSubmitted",
        companyName: deal ? deal?.CompanyName : "",
        dealId: deal ? deal?.DealID : "",
      },
    },
    "SPA Negotiation": {
      path: "/investor/spaNegotiation",
      state: {
        stage: "spaNegotiation",
        companyName: deal ? deal?.CompanyName : "",
        dealId: deal ? deal?.DealID : "",
      },
    },
  };
  const handleClick = (stageLabel) => {
    const route = stageRoutes[stageLabel];
    if (route) {
      Navigate(route.path, {
        state: {
          ...route.state,
        },
      });
    }
  };
  // Mapping of interactions to stages
  const interactionsMap = interactions.reduce((acc, interaction) => {
    acc[interaction.Stage] = interaction;
    return acc;
  }, {});

  const getDealInterestByStage = async (dealId, investorID, stage) => {
    try {
      const response = await dealInterestByStage(
        dealId,
        investorID,
        stage,
        token
      );
      loader.stop();
      if (response) {
        if (stage === "ioiSubmitted") {
          const IOIBids = response.data.map((item) => item.IOI_Bid_Details);
          setIoiBid(IOIBids[0].IOILetter);
        } else if (stage === "loiSubmitted") {
          const LOIAgreements = response.data.map(
            (item) => item.LOI_Bid_Details
          );
          setLoiAgreement(LOIAgreements[0].LOILetter);
        } else if (stage === "spaNegotiation") {
          const filteredData = response.data.filter((item) => item.SPA);
          const SPAs = filteredData.map((item) => item.SPA);
          setSPA(SPAs[0]);
        }
      }
    } catch (error) {
      loader.stop();
    }
  };
  // 1. Added index for each stage to reduce the number of APIs
  // 2. We will make API calls to only those stages which were before the latest stage
  const getDealDocuments = async () => {
    if (deal?.DealID && investorId) {
      const index = stages.findIndex((stage) => stage.dateKey === latestStage);

      if (index >= 3) {
        getDealInterestByStage(deal?.DealID, investorId, "ioiSubmitted");
      }
      if (index >= 5) {
        getDealInterestByStage(deal?.DealID, investorId, "loiSubmitted");
      }
      if (index >= 7) {
        getDealInterestByStage(deal?.DealID, investorId, "spaNegotiation");
      }
      loader.stop();
    }
  };
  useEffect(() => {
    if (deal) {
      getDealDocuments();
    }
  }, [deal, latestStage, investorId]);

  const files = [
    { file: IoiBid, name: "IoiBid" },
    { file: LoiAgreement, name: "LoiAgreement" },
    { file: SPA, name: "SPA" },
  ];
  const addOrUpdateQnAs = (qnAData) => {
    const qnAMap = new Map(qnAs.map((qnA) => [qnA.qnAID, qnA]));

    qnAData.forEach((qnA) => {
      qnAMap.set(qnA.qnAID, qnA);
    });

    setqnAs(Array.from(qnAMap.values()));
  };
  const getqnAdata = async () => {
    if (deal && deal?.DealID && investorId) {
      const response = await getqnAs({
        dealID: deal?.DealID,
        investorID: investorId,
      });
      try {
        if (response.response.data === "No qnA found") {
          return;
        }
      } catch {
        const qnAData = response.data;
        addOrUpdateQnAs(qnAData);
        setNewQuestionAsked(false);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    getqnAdata();
  }, [deal, investorId]);
  useEffect(() => {
    if (newQuestionAsked) getqnAdata();
  }, [newQuestionAsked]);

  return (
    <div className="activity-page">
      <Helmet>
        <title>{`Investor | ${companyName} - activity`}</title>
      </Helmet>
      <TimelineComponent
        passedStage={latestStage}
        dealId={deal?.DealID}
        investorID={investorId}
        companyName={deal?.CompanyName}
        role="investor"
      />
      <div className="activity-right-section NoNamePreview-right">
        <div className="activity-deal-documents fundrev-card">
          <p className="fundrev-card-heading">Deal Documents</p>
          <div className="all-file-uploads">
            {files && files.length > 0 ? (
              files.every(({ file }) => file.length === 0) ? (
                <p>Looks like there are no documents here.</p>
              ) : (
                files.map(
                  ({ file, name }) =>
                    file.length > 0 && (
                      <DocumentFile
                        key={name}
                        file={file}
                        fileName={name}
                        loader={loader}
                        containerId="investorActivity"
                      />
                    )
                )
              )
            ) : (
              <p>Looks like there are no documents here.</p>
            )}
          </div>
        </div>
        <div className="fundrev-card">
          <p className="fundrev-card-heading">Co-Investors Info</p>
          <CoInvestorTable data={coInvestorData} />
        </div>
        <div className="fundrev-card">
          <p className="fundrev-card-heading">QnA Section</p>
          {qnAs.length > 0 ? (
            qnAs.map((qnA, index) => {
              return (
                <div
                  className="QnA"
                  key={index}
                  style={{ borderBottom: index === qnAs.length - 1 && "0px" }}
                >
                  <div className="QnA-question">
                    <span className="Q-text">{`Q${index + 1}.`}</span>&nbsp;{" "}
                    <span className="qna-question-text">{qnA.question}</span>
                  </div>
                  {qnA.answer && (
                    <div className="QnA-answer">
                      <span className="Q-text">A.</span>&nbsp;{" "}
                      <span className="qna-question-text">{qnA.answer}</span>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <>
              <p
                style={{
                  fontSize: "20px",
                  color: "#696F79",
                  margin: "20px 0px 0px 30px",
                }}
              >
                Currently, there are no questions or answers posted
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                }}
              >
                <img src={noQuestions} alt="No questions" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
