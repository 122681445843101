import React, { useState } from "react";
import CardTags from "../../../Common/components/molecules/cardTags";
import ProgressBar from "../../../Common/components/molecules/progressBar";

const CompanyOverviewLeftCard = ({ deal }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const maxLength = 135;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="NoName-heading-block">
      <div className="detailed-page-img-block">
        <img src={deal ? deal.CompanyLogo : ""} alt="Company logo" />
        <p className="noName-heading">{deal ? deal.CompanyName : ""}</p>
      </div>
      <p className="noName-subheading">
        <span>
          {deal && deal?.SubHeading && deal.SubHeading.length > maxLength && (
            <>
              {isExpanded
                ? deal.SubHeading
                : deal.SubHeading.substr(
                    0,
                    deal.SubHeading.lastIndexOf(" ", maxLength)
                  )}
              {isExpanded ? (
                <span
                  onClick={toggleExpand}
                  style={{ color: "#00A6FB", cursor: "pointer" }}
                >
                  {" show less"}
                </span>
              ) : (
                <span
                  onClick={toggleExpand}
                  style={{ color: "#00A6FB", cursor: "pointer" }}
                >
                  {"... more"}
                </span>
              )}
            </>
          )}
          {deal && deal?.SubHeading && deal.SubHeading.length <= maxLength && (
            <span>{deal.SubHeading}</span>
          )}
        </span>
      </p>
      <CardTags deal={deal} />
      <div style={{ width: "100%" }}>
        <ProgressBar
          totalFundingRaised={deal?.TotalFundingRaised}
          fundingRequired={deal?.FundingRequired}
          roundType={deal?.RoundType}
        />
      </div>
    </div>
  );
};

export default CompanyOverviewLeftCard;
