import {
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formatToLakhs } from "../../../../../../utilities/numberFormatter";

const TopCustomerTable = ({ data }) => {
  const [option, setOption] = useState(`ltm`);
  const handleSelect = (event) => {
    setOption(event.target.value);
  };
  const headers = data?.data?.header;
  return (
    <>
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "Karla, sans-serif",
            color: "var(--Primary-Text-Color)",
          }}
        >
          {data.name}
        </p>
        <Select
          value={option}
          onChange={handleSelect}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            width: "25%",
            height: "28px",
            fontFamily: "Karla,sans-serif",
            fontSize: "14px",
            fontWeight: "400",
            color: "#636363",
            borderRadius: 1,
          }}
        >
          <MenuItem
            sx={{
              fontFamily: "Karla,sans-serif",
              fontSize: "14px",
              fontWeight: "400",
            }}
            value={`ltm`}
          >
            LTM
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: "Karla,sans-serif",
              fontSize: "14px",
              fontWeight: "400",
            }}
            value={`overall`}
          >
            Overall
          </MenuItem>
        </Select>
      </Box>
      <Paper
        sx={{
          marginTop: "16px",
          width: "100%",
          overflow: "hidden",
          boxShadow: "0",
        }}
      >
        <TableContainer>
          <Table aria-label="Top 5 Customer Table" size="small">
            <TableHead>
              <TableRow>
                {headers?.map((header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: 300,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "#B9BBBD30",
                      paddingY: "3px",
                      width: "100%",
                    }}
                    align={index === 0 ? "left" : "right"}
                  >
                    {header !== "Customer" && header}
                    {header === "Customer" && (
                      <Box
                        sx={{
                          display: `flex`,
                          gap: "2px",
                          alignItems: "baseline",
                        }}
                      >
                        Customer
                        <Typography
                          sx={{
                            fontFamily: `Karla, sans-serif`,
                            fontSize: "10px",
                            fontWeight: "700px",
                            color: "#6B7494",
                          }}
                        >
                          (in lakhs)
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.[option].map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: index === 5 || index === 7 ? 700 : 500,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (index === 5 || index === 7) && "var(--brand-color)",
                      paddingY: "3px",
                      borderTop:
                        (index === 5 || index === 7) &&
                        "2px solid var(--brand-color)",
                    }}
                    align="left"
                  >
                    {row.customer}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: index === 5 || index === 7 ? 700 : 500,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (index === 5 || index === 7) && "var(--brand-color)",
                      paddingY: "3px",
                      borderTop:
                        (index === 5 || index === 7) &&
                        "2px solid var(--brand-color)",
                    }}
                    align="right"
                  >
                    {/* {Math.round(row.revenue)} */}
                    {formatToLakhs(row.revenue)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Karla, sans-serif",
                      fontWeight: index === 5 || index === 7 ? 700 : 500,
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      background: "white",
                      color:
                        (index === 5 || index === 7) && "var(--brand-color)",
                      fontStyle: "italic",
                      paddingY: "3px",
                      borderTop:
                        (index === 5 || index === 7) &&
                        "2px solid var(--brand-color)",
                    }}
                    align="right"
                  >
                    {row.percentage}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TopCustomerTable;
